import gql from 'graphql-tag'
import moment from 'moment'
import React, { useState } from 'react'
import { Plus } from 'react-feather'
import { FuelSurcharge, GetAllFuelSurchargesByCarrierKeyQueryVariables, SortEnumType, useDeleteFuelSurchargeMutation, useGetAllFuelSurchargesByCarrierKeyQuery } from '../../../generated/graphql'
import CreateFuelSurchargeModal from '../../AppliedModals/FuelSurchargeModal/CreateFuelSurchargeModal'
import UpdateFuelSurchargeModal from '../../AppliedModals/FuelSurchargeModal/UpdateFuelSurchargeModal'
import Button, { ButtonType } from '../../General/Button/Button'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import EmptyState from '../../General/Tile/EmptyState/EmptyState'
import Tile from '../../General/Tile/Tile'
import Modal from '../../Modal/Modal'

interface Props {
    carrierKey: number
}

export const AllFuelSurchargeQuery = gql`
   query GetAllFuelSurchargesByCarrierKey($filterFuelSurcharge: FuelSurchargeFilterInput, $sort: [FuelSurchargeSortInput!], $pageSize: Int!) {
      fuelSurchargeGetAll(order: $sort, where: $filterFuelSurcharge, first: $pageSize) {
          
         nodes{
            carrierKey
            fuelSurchargeKey
            startDate
            endDate
            rate
         }
      }
   }
`

export const DeleteFuelSurchargeMutation = gql`
   mutation DeleteFuelSurcharge($id: Int!) {
      fuelSurchargeDelete(id: $id)
   }
`

const FuelSurchargeTile = (props: Props) => {
    const { carrierKey } = props
    const [showingCreateFuelSurcharge, setShowingCreateFuelSurcharge] = useState(false)
    const [updateFuelSurchargeKey, setUpdateFuelSurchargeKey] = useState(undefined)
    const [currentlyDeletingFuelSurcharge, setCurrentlyDeletingFuelSurcharge] = useState<number>(null)

    const getFuelSurchargeVariables: GetAllFuelSurchargesByCarrierKeyQueryVariables = { filterFuelSurcharge: { carrierKey: { eq: carrierKey } }, sort: { startDate: SortEnumType.Desc }, pageSize: 6 }
    const { data, loading, error, refetch } = useGetAllFuelSurchargesByCarrierKeyQuery({ variables: getFuelSurchargeVariables })
    const [deleteFuelSurchargeMutation] = useDeleteFuelSurchargeMutation()

    const closeCreateFuelSurchargeModal = () => setShowingCreateFuelSurcharge(false)

    const confirmFuelSurchargeDelete = (fuelSurchargeKey: number) => {
        deleteFuelSurchargeMutation({ variables: { id: fuelSurchargeKey } })
            .then(() => {
                refetch()
                setCurrentlyDeletingFuelSurcharge(null)
                setUpdateFuelSurchargeKey(undefined)
            })

    }

    const openUpdateFuelSurchargeModal = (line) => {
        setUpdateFuelSurchargeKey(line["fuelSurchargeKey"])
    }

    const openFuelSurchargeDelete = (fuelSurcharge: number) => {
        setCurrentlyDeletingFuelSurcharge(fuelSurcharge)
    }

    const closeUpdateFuelSurchargeModal = () => {
        setUpdateFuelSurchargeKey(undefined)
    }

    const cancelDelete = () => {
        setCurrentlyDeletingFuelSurcharge(null)
    }

    const fuelSurchargeGridOptions: GridOptions<FuelSurcharge> = {
        columnDefs: [
            {
                headerName: "Start Date", field: "startDate", dataTransform: o => moment.utc(o.startDate).format('LL')
            },
            { headerName: "End Date", field: "endDate", dataTransform: o => moment.utc(o.endDate).format('LL') },
            { headerName: "Rate", field: "rate" }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.fuelSurchargeGetAll.nodes,

        dataKeyColumn: "fuelSurchargeKey",
        clickAction: openUpdateFuelSurchargeModal
    }

    if (loading || error) {
        return <Tile title="Fuel Surcharges"></Tile>
    }

    return (
        <Tile title="Fuel Surcharges" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingCreateFuelSurcharge(true)}><Plus /></Button>}
            headerButtonCondition={(data.fuelSurchargeGetAll && data.fuelSurchargeGetAll.nodes.length > 0)}>

            <EmptyState text="You don't have any fuel surcharges linked to this carrier yet. Why don't you create one?"
                data={data.fuelSurchargeGetAll.nodes} buttonText="Create Fuel Surcharge" buttonAction={() => setShowingCreateFuelSurcharge(true)}>
                <GridBase gridOptions={fuelSurchargeGridOptions} />
            </EmptyState>

            {showingCreateFuelSurcharge && <CreateFuelSurchargeModal closeModal={closeCreateFuelSurchargeModal} carrierKey={carrierKey} callback={refetch} />}
            {updateFuelSurchargeKey && <UpdateFuelSurchargeModal closeModal={closeUpdateFuelSurchargeModal} delete={openFuelSurchargeDelete} fuelSurchargeKey={updateFuelSurchargeKey} callback={refetch} />}
            {currentlyDeletingFuelSurcharge && <Modal headerText="Delete Rate?"
                footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmFuelSurchargeDelete(currentlyDeletingFuelSurcharge)}>Delete</Button>}>
                <p>Once you delete this Fuel Surcharge, it will not be able to be reversed.</p>
            </Modal>}
        </Tile>
    )
}

export default FuelSurchargeTile