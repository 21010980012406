import React, { Component, ChangeEvent } from 'react'
import styles from './Input.module.scss'
import InputWrapper from './InputWrapper';
import { DefaultInputProps } from './HookedTextArea';
import checkboxStyles from './HookedCheckbox.module.scss'

interface Props extends DefaultInputProps<boolean> {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    inline?: boolean
}
interface State { }

export default class HookedCheckbox extends Component<Props, State> {
    render() {
        const currentError = this.props.errors[this.props.propertyKey];
        const checkboxClass = this.props.inline ? checkboxStyles.hookedCheckbox__inline : checkboxStyles.hookedCheckbox__block;

        return (
            <InputWrapper<boolean> {...this.props} isCheckbox={true} className={`${checkboxClass}`}>
                <input
                    type="checkbox"
                    checked={this.props.value}
                    className={`${checkboxStyles.hookedCheckbox__input} ${styles.input} ${currentError ? styles.input__error : ''}`}
                    defaultChecked={this.props.defaultValue}
                    ref={this.props.register}
                    name={this.props.propertyKey}
                    onChange={this.props.onChange} />
            </InputWrapper>
        )
    }
}