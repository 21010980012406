import React, { ReactElement, useState, useEffect } from 'react'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import gql from 'graphql-tag'
import { CreateTaskMutationVariables, BasicContactFragment, ContactMethod, useGetCurrentUserQuery, CustomerGetAllEdge, BasicLocationFragment, useCreateFutureTaskMutation } from '../../generated/graphql'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'
import CustomerSearch from '../../components/Search/CustomerSearch/CustomerSearch'
import UserSearch from '../../components/Search/UserSearch/UserSearch'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import TaskContactSearch from '../../components/Search/CustomerSearch/TaskContactSearch'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ControlledLocationSearch from '../../components/Search/LocationSearch/ControlledLocationSearch'


interface Props {

}

export const CreatTaskMutation = gql`
    mutation CreateTask($task: TaskInput!) {
        taskCreate(task: $task) {
            taskKey
        }
    }

    mutation CreateFutureTask($task: TaskInput!) {
        taskCreateFuture(task: $task) {
            taskKey
        }
    }

    query GetCurrentUser {
        currentUser {
            id
            userName
            name
        }
    }
`

export default function TaskCreate(props: Props): ReactElement {
    const navigate = useNavigate()

    const [locationKey, setLocationKey] = useState(undefined)
    const [customerKey, setCustomerKey] = useState(undefined)
    const [navigatedFromHome, setNavigatedFromHome] = useState(false)
    const [isCreatingNewTask, setIsCreatingNewTask] = useState(true)
    const currentDate = new Date()
    const [taskDate, setTaskDate] = useState(currentDate)

    const [searchParams,] = useSearchParams()

    const [selectDefaultContact, setSelectDefaultContact] = useState(true)
    const [contactKey, setContactKey] = useState<number>()

    useEffect(() => {
        if (searchParams.get("l")) {
            setLocationKey(parseInt(searchParams.get("l")))
            setNavigatedFromHome(true)
        }
        if (searchParams.get("c")) {
            setCustomerKey(parseInt(searchParams.get("c")))
            setNavigatedFromHome(true)
        }

    }, [searchParams, setLocationKey, setCustomerKey])

    const { data } = useGetCurrentUserQuery()
    const [createTaskMutation] = useCreateFutureTaskMutation()

    const submit = (variables: CreateTaskMutationVariables) => {
        const contactKey = parseInt(variables.task.contactKey.toString())
        const locationKey = parseInt(variables.task.locationKey.toString())

        const mergedVars: CreateTaskMutationVariables = { task: { ...variables.task, isComplete: false, contactKey, locationKey } }

        createTaskMutation({ variables: mergedVars })
            .then(() => navigate("/"))
    }

    const validationSchema = yup.object().shape({
        task: yup.object().shape({
            taskDate: yup.date().required(),
            contactKey: yup.number().required(),
            userKey: yup.number().required()
        })
    })

    const { register, setValue, handleSubmit, errors } = useForm<CreateTaskMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)

    const toggleNextContact = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsCreatingNewTask(!isCreatingNewTask)
        e.preventDefault()
    }

    const onContactSelect = (contact: BasicContactFragment) => {
        //if the contact is being cleared
        if (contact === undefined) {
            //if there was previously a contact selected
            //prevent it from selecting the default automatically
            if (contactKey) {
                setSelectDefaultContact(false)
            }
        }
        setContactKey(contact ? contact.contactKey : undefined)
    }

    const onLocationSelect = (location: BasicLocationFragment) => {
        setLocationKey(location ? location.locationKey : undefined)
        if (location) {
            setCustomerKey(location.customer.customerKey)
            if (locationKey !== location.locationKey) {
                setContactKey(undefined)
            }
            //if they're selecting a new location, auto pick the default contact
            setSelectDefaultContact(true)
        }
        else if (location === undefined) {
            setContactKey(undefined)
        }
    }

    const onCustomerSelect = (customer: CustomerGetAllEdge) => {
        setCustomerKey(customer && customer.node ? customer.node.customerKey : undefined)
        if (customer === undefined) {
            setLocationKey(null)
        }
        else {
            //if they're selecting a new location, auto pick the default contact
            setSelectDefaultContact(true)
        }
    }

    const contactMethods: SelectOption<ContactMethod>[] = Object.keys(ContactMethod).map(c => {
        return { key: ContactMethod[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const navigateToHomePage = () => {
        navigate("/")
    }

    const onTaskDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setTaskDate(date)
        }
    }

    const footerLeftContent = (<Button clickAction={navigateToHomePage} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Schedule Task</Button>)

    return (
        <FormPage title="Create Task" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                showTimeSelect={true}
                                setValue={setValue}
                                register={register}
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.taskDate)}
                                label="Task Date and Time"
                                defaultValue={taskDate}
                                Change={onTaskDateChange}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <HookedSelectOptions
                                options={contactMethods}
                                label="Contact Method"
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.contactMethod)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <CustomerSearch
                                register={register}
                                label="Customer"
                                onSelect={onCustomerSelect}
                                prefilledCustomerKey={customerKey}
                                propertyKey={undefined}
                                errors={errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <ControlledLocationSearch
                                register={register}
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.locationKey)}
                                label="Location"
                                onSelect={onLocationSelect}
                                locationKey={locationKey}
                                customerKey={customerKey}
                                errors={errors}
                                prefillIfOnlyLocation={true}
                                showCustomer={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TaskContactSearch
                                register={register}
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.contactKey)}
                                label="Contact"
                                selectedContactKey={contactKey}
                                onSelect={onContactSelect}
                                errors={errors}
                                locationKey={locationKey}
                                selectFirst={selectDefaultContact}
                                selectDefault={selectDefaultContact}
                            />
                        </div>
                        <div className="col-md-6">
                            <UserSearch
                                label="User"
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.userKey)}
                                prefilledUserKey={data ? data.currentUser.id : undefined}
                                // onSelect={}
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>



                </FormTile>

                {navigatedFromHome && <FormTile>
                    <div className="row">
                        {isCreatingNewTask && <div className="col-md-12">
                            <HookedDateTime
                                showTimeSelect={true}
                                setValue={setValue}
                                register={register}
                                propertyKey={propName<CreateTaskMutationVariables>(o => o.task.taskDate)}
                                label="Next Task Date and Time"
                                errors={errors} />
                        </div>}
                        <div className="col-md-12">
                            <Button buttonType={ButtonType.Secondary} clickAction={toggleNextContact}>{isCreatingNewTask ? "Cancel Next Contact" : "+ Next Contact"}</Button>
                        </div>
                    </div>

                </FormTile>}
            </form>
        </FormPage>
    )
}
