import React, { ReactElement, ReactNode } from 'react'
import { DefaultInputProps } from './HookedTextArea'
import styles from './Input.module.scss'

interface Props<T> extends DefaultInputProps<T> {
    children: ReactNode,
    isCheckbox?: boolean,
    className?: string,
}

export default function InputWrapper<T>({ children, propertyKey, label, errors, isCheckbox, className, alignCenter }: Props<T>): ReactElement {
    const currentError = errors[propertyKey];

    //if it's a typical required error from yup, display the prettier required error. Otherwise return whatever comes from yup.
    const errorMessage = !!currentError ?
        `${(currentError.message.indexOf("but the final value was") > 0
            || currentError.message.indexOf("is a required field") > 0) ?
            `${label} is required` : currentError.message}` : ''

    return (
        <div className={`${styles.formGroup} ${className ? className : ""} ${alignCenter && styles.formGroup__alignCenter}`}>
            {!isCheckbox ? (label && <label
                // This disables checkboxes. If strange click behavior is caused, investigate here
                // onClick={e => e.preventDefault()}
                className={styles.input__wrapper}>

                {label}</label>) : ""}

            {children}

            {isCheckbox ? (<label
                // This disables checkboxes. If strange click behavior is caused, investigate here
                // onClick={e => e.preventDefault()}
                className={styles.input__wrapper}>

                {label}</label>) : ""}

            {currentError && <span className={styles.input__errorMessage}>
                {errorMessage}
            </span>}


        </div>
    )
}
