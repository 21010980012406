import React from 'react'
import styles from './FormPage.module.scss'
import GeneralPage from '../GeneralPage/GeneralPage'


interface Props {
    title?: string
    beforeChildren?: React.ReactNode
    afterChildren?: React.ReactNode
    headerContent?: React.ReactNode

    footerLeftContent?: React.ReactNode
    footerRightContent?: React.ReactNode

    centerText?: string
    centerContent?: React.ReactNode

    hideHeader?: boolean
    hideFooter?: boolean
    showSidebar?: boolean
    fullWidth?: boolean

    sidebarContent?: React.ReactNode
    onScroll?(event: any)

    children?: React.ReactNode
}

const FormPage: React.FC<Props> = ({
    title, children, beforeChildren, afterChildren, headerContent, centerText, centerContent,
    hideHeader, hideFooter, footerLeftContent, footerRightContent, showSidebar, sidebarContent, fullWidth, onScroll }) => {

    let footer = !hideFooter && (
        <div className={styles.FormPage__footer}>
            <div className={styles.FormPage__leftSection}>
                {footerLeftContent}
            </div>
            <div className={styles.FormPage__rightSection}>
                {footerRightContent}
            </div>
        </div>
    )

    return (
        <GeneralPage title={title} beforeChildren={beforeChildren}
            afterChildren={footer} headerContent={headerContent} centerText={centerText} centerContent={centerContent} hideHeader={hideHeader}
            hasFooter={true} showSidebar={showSidebar} sidebarContent={sidebarContent} onScroll={onScroll}>

            <div className={styles.FormPage}>
                <div className={`${styles.FormPage__wrapper} ${fullWidth && styles.FormPage__wrapperFullWidth}`}>
                    {children}
                </div>
            </div>

        </GeneralPage>
    )
}

export default FormPage
