import React, { useState } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import { useNavigate } from 'react-router-dom'
import { useAllSwapDealQuery, AllSwapDealQueryVariables, useDeleteSwapDealMutation, FlatSwapDealFilterInput, InnerFlatSwapDealFragment, FlatSwapDealSortInput, SortEnumType } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import Tile from '../../components/General/Tile/Tile'
import moment from 'moment'
import EmptyState from '../../components/General/Tile/EmptyState/EmptyState'

export const SwapDealFragment = gql`
    fragment innerFlatSwapDeal on FlatSwapDeal {
        swapDealKey
        basisType
        salesAgreements
        suppliers
        dealNumber
        sglCost
        effectiveStartDate
        effectiveEndDate
        monthlyVolume
    }
`

export const AllSwapDealQuery = gql`
    query AllSwapDeal($sort: [FlatSwapDealSortInput!], $filter: FlatSwapDealFilterInput) {
        flatSwapDealGetAll(order: $sort, where: $filter) {
            ...innerFlatSwapDeal
        }
    }
`

export const DeleteSwapDealMutation = gql`
    mutation DeleteSwapDeal($key: Int!) {
        swapDealDelete(swapDealKey: $key)
    }
`

interface SwapDealDataWrapperProps {
    currentSort: FlatSwapDealSortInput
    updateSort(newSort: FlatSwapDealSortInput)

    currentFilter: FlatSwapDealFilterInput
    updateFilter(newFilter: FlatSwapDealFilterInput)

    editSwapDeal(swapDeal: InnerFlatSwapDealFragment)
    deleteSwapDeal(swapDeal: InnerFlatSwapDealFragment)
    currentlyDeletingSwapDeal: InnerFlatSwapDealFragment
}

const SwapDealDataWrapper = (props: SwapDealDataWrapperProps): JSX.Element => {
    const [deleteMutation] = useDeleteSwapDealMutation()
    const navigate = useNavigate()

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const variables: AllSwapDealQueryVariables = {
        sort: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllSwapDealQuery({ variables })

    const confirmDelete = (key: number) => {
        deleteMutation({ variables: { key } })
            .then(() => refetch())
            .then(() => props.deleteSwapDeal(null))
    }

    const cancelDelete = () => {
        props.deleteSwapDeal(null)
    }

    const navigateToNewSwapDeal = () => {
        navigate('/pricing/swap-deals/new')
    }

    const alignRight: React.CSSProperties = { textAlign: "right" }
    const justifyFlexEnd: React.CSSProperties = { justifyContent: "flex-end" }

    const gridOptions: GridOptions<InnerFlatSwapDealFragment> = {
        columnDefs: [
            { headerName: "Basis", field: "basisType", dataTransform: o => o.basisType, sortable: true, searchable: true },
            { headerName: "Assignment", field: "salesAgreements", dataTransform: o => o.salesAgreements, searchable: true },
            { headerName: "Supplier", field: "suppliers", dataTransform: o => o.suppliers, searchable: true },
            { headerName: "Deal #", field: "dealNumber", dataTransform: o => o.dealNumber.toString() },
            { headerName: "SGL Cost", field: "sglCost", dataTransform: o => o.sglCost.toString(), headerStyles: justifyFlexEnd, styles: alignRight },
            { headerName: "Monthly Volume", field: "monthlyVolume", dataTransform: o => o.monthlyVolume.toString(), headerStyles: justifyFlexEnd, styles: alignRight },
            { headerName: "Monthly Cost", field: "monthlyVolume", dataTransform: o => `${moneyFormatter.format((o.monthlyVolume * o.sglCost))}`, headerStyles: justifyFlexEnd, styles: alignRight },
            { headerName: "Start Date", field: "effectiveStartDate", dataTransform: o => moment.utc(o.effectiveStartDate).format("L") },
            { headerName: "End Date", field: "effectiveEndDate", dataTransform: o => moment.utc(o.effectiveEndDate).format("L") },
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.flatSwapDealGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editSwapDeal },
                    { displayName: "Delete", action: props.deleteSwapDeal }
                ]
            }
        ],
        dataKeyColumn: "swapDealKey",
        linkToPath: "/pricing/swap-deals/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    if (loading) {
        return <></>
    }
    else if (error) {
        return <h5>Error Loading Swap Deals</h5>
    }

    return (<>
        <EmptyState text="You don't have any Swap Deals yet. Why don't you create one?"
            data={data.flatSwapDealGetAll} buttonText="Create Swap Deal" buttonAction={() => navigateToNewSwapDeal()}>
            <GridBase<InnerFlatSwapDealFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingSwapDeal && <Modal headerText="Delete?"
                footerLeftContent={<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>}
                footerRightContent={<Button clickAction={() => confirmDelete(props.currentlyDeletingSwapDeal.swapDealKey)} buttonType={ButtonType.Danger}>Delete</Button>}>
                <p>Once you delete this swap deal, it will not be able to be reversed.</p>
                <p>Swap Deal: {props.currentlyDeletingSwapDeal.dealNumber}</p>
            </Modal>}
        </EmptyState>
    </>
    )
}

type Props = {}

const SwapDealList = (props: Props) => {
    const navigate = useNavigate()

    const [currentSort, setCurrentSort] = useState<FlatSwapDealSortInput>({ effectiveStartDate: SortEnumType.Desc })
    const [currentFilter, setCurrentFilter] = useState<FlatSwapDealFilterInput>({})
    const [currentlyDeletingSwapDeal, setCurrentlyDeletingSwapDeal] = useState<InnerFlatSwapDealFragment>()

    const sortClick = (currentSort: FlatSwapDealSortInput) => {
        setCurrentSort(currentSort)
    }

    const filterClick = (currentFilter: FlatSwapDealFilterInput) => {
        setCurrentFilter(currentFilter)
    }

    const navigateToNewSwapDeal = () => {
        navigate('/pricing/swap-deals/new')
    }

    const editSwapDeal = (swapDeal: InnerFlatSwapDealFragment) => {
        navigate(`/pricing/swap-deals/edit/${swapDeal.swapDealKey}`)
    }

    const deleteSwapDeal = (swapDeal: InnerFlatSwapDealFragment) => {
        setCurrentlyDeletingSwapDeal(swapDeal)
    }

    return (
        <GeneralPage title="Swap Deals" headerContent={<Button clickAction={navigateToNewSwapDeal}>New Swap Deal</Button>}>
            <Tile>
                <SwapDealDataWrapper
                    currentSort={currentSort}
                    updateSort={sortClick}
                    currentFilter={currentFilter}
                    updateFilter={filterClick}
                    editSwapDeal={editSwapDeal}
                    deleteSwapDeal={deleteSwapDeal}
                    currentlyDeletingSwapDeal={currentlyDeletingSwapDeal} />
            </Tile>
        </GeneralPage>
    )
}

export default SwapDealList