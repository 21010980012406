import React, { ReactElement, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { useGetUserQuery, useEditUserMutation, EditUserMutationVariables, useDeleteUserMutation, useGetCurrentUserQuery } from '../../generated/graphql'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import propName from '../../helpers/propName'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'

import Modal from '../../components/Modal/Modal'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiCarrierSearch from '../../components/Search/CarrierSearch/MultiCarrierSearch'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const GetSingleUserQuery = gql`
    query GetUser($userKey: Int!) {
        userGetSingle(userKey: $userKey) {
            ...innerUser
        }
    }
`

export const EditUserMutation = gql`
    mutation EditUser($user: UserInput!, $email: String, $newPassword: String, $carriers: [Int!]) {
        userUpdate(user: $user, newEmail: $email, newPassword: $newPassword, carriers: $carriers) {
            ...innerUser
        }
    }
`

interface MatchParams extends Params {
    userKey: string
}

interface Props {
}

export default function EditUser(props: Props): ReactElement {
    const navigate = useNavigate()

    const { userKey: key } = useParams() as MatchParams

    const [carrierKeys, setCarrierKeys] = useState<number[]>(undefined)

    const { data: userD } = useGetCurrentUserQuery()

    const userKey = parseInt(key)
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [currentlyDeletingUser, setCurrentlyDeleting] = useState(false)

    const validationSchema = yup.object().shape({
        user: yup.object().shape({
            email: yup.string().required().trim(),
            name: yup.string().required().trim(),
            phoneNumber: yup.string().notRequired().trim(),
            passwordHash: yup.string().notRequired(),
            marketingUser: yup.boolean().required(),
            preferPrefixSearch: yup.boolean().required()
        })
    })

    const { data, loading, error } = useGetUserQuery({ variables: { userKey } })

    const { register, handleSubmit, errors } = useForm<EditUserMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const navigateToList = () => {
        navigate('/settings/users')
    }

    useEffect(() => {
        if (data && data.userGetSingle && data.userGetSingle.userCarriers) {
            setCarrierKeys(data.userGetSingle.userCarriers.map(y => y.carrierKey))
        }
    }, [data, setCarrierKeys])

    const [EditUser] = useEditUserMutation()
    const [DeleteUser] = useDeleteUserMutation()

    if (loading || !userD) {
        return <FormPage></FormPage>
    }
    const currentUserName = userD.currentUser.userName.toLowerCase()
    if (currentUserName !== "admin@bulb.digital" && currentUserName !== "steve" && currentUserName !== "steve@smithgas.com") {
        return <div>You do not have access to user management.</div>
    }

    if (error) {
        return <p>There was a problem loading the user</p>
    }

    const confirmDelete = () => {
        DeleteUser({ variables: { email: data.userGetSingle.email } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const addCarrier = (num: number) => {
        if (carrierKeys === undefined) {
            setCarrierKeys([num])
        }
        else if (!carrierKeys.some(x => x === num)) {
            setCarrierKeys([...carrierKeys, num])
        }
    }

    const removeCarrier = (num: number) => {
        setCarrierKeys([...carrierKeys.filter(x => x !== num)])
    }

    const submit = (variables: EditUserMutationVariables) => {
        variables.newPassword = variables.user.passwordHash.trim() !== "" ? variables.user.passwordHash : ""
        if (variables.newPassword !== "") {
            var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")
            const matches = mediumRegex.test(variables.newPassword)
            if (!matches) {
                setShowPasswordError(true)
                return null
            }
        }

        variables.email = variables.user.email !== data.userGetSingle.email ? variables.user.email : ""
        variables.user.email = data.userGetSingle.email
        variables.user.userName = data.userGetSingle.email
        variables.user.lockoutEnabled = false
        variables.user.passwordHash = undefined
        variables.carriers = carrierKeys
        EditUser({ variables })
            .then(navigateToList)
    }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update User</Button>)

    return (
        <FormPage title="Edit User" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.name)}
                                defaultValue={data.userGetSingle.name}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Email"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.email)}
                                defaultValue={data.userGetSingle.email}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Phone Number"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.phoneNumber)}
                                defaultValue={data.userGetSingle.phoneNumber}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Password"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.passwordHash)}
                                isPassword={true}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiCarrierSearch
                                removeCarrier={removeCarrier}
                                addCarrier={addCarrier}
                                data={carrierKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedCheckbox
                                label="Marketing User"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.marketingUser)}
                                defaultValue={data.userGetSingle.marketingUser}
                                {...commonProps}
                            />

                            <HookedCheckbox
                                label="Prefer Prefix Search"
                                propertyKey={propName<EditUserMutationVariables>(o => o.user.preferPrefixSearch)}
                                defaultValue={data.userGetSingle.preferPrefixSearch}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            {showPasswordError && <p>Password must contain at least 8 characters, lowercase, uppercase and numbers</p>}
                        </div>
                    </div>
                </FormTile>
            </form>
            {currentlyDeletingUser && <Modal headerText="Delete?"
                footerRightContent={<Button clickAction={confirmDelete} buttonType={ButtonType.Primary}>Delete</Button>}
                footerLeftContent={<Button clickAction={cancelDelete} buttonType={ButtonType.Tertiary}>Cancel</Button>}>
                <p>Once you delete this user, it will not be able to be reversed.</p>

            </Modal>}
        </FormPage>
    )
}