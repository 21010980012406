import React from 'react'
import Modal from '../../Modal/Modal'
import useForm from 'react-hook-form'
import { CreatePatCostMutationResult, useCreatePatCostMutation, CreatePatCostMutationVariables } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import Button, { ButtonType } from '../../General/Button/Button'

interface Props {
    closeModal(): void
    callback(result: CreatePatCostMutationResult): Promise<any>
}

const CreatePatCostModal = (props: Props) => {
    const [createPatCostMutation] = useCreatePatCostMutation()

    const submit = (variables: CreatePatCostMutationVariables) => {
        createPatCostMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const validationSchema = yup.object().shape({
        patCost: yup.object().shape({
            effectiveDate: yup.date().required(),
            cost: yup.number().required(),
            state: yup.string().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreatePatCostMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create Pat Cost</Button>

    return (
        <Modal headerText="Create Pat Cost" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <HookedDateTime
                            label="Effective Date"
                            propertyKey={propName<CreatePatCostMutationVariables>(o => o.patCost.effectiveDate)}
                            defaultValue={new Date()}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-3">
                        <HookedTextInput
                            label="Cost"
                            propertyKey={propName<CreatePatCostMutationVariables>(o => o.patCost.cost)}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-3">
                        <HookedTextInput
                            label="State"
                            propertyKey={propName<CreatePatCostMutationVariables>(o => o.patCost.state)}
                            defaultValue="OH"
                            {...commonProps}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    )
}
export default CreatePatCostModal