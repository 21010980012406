import React, { useState } from 'react'

import gql from 'graphql-tag'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import { useGetSingleSupplierQuery, useGetAllContactBySupplierKeyQuery, GetAllContactBySupplierKeyQueryVariables, useDeleteContactMutation, Contact, InnerPostingFragment, useMostRecentPostingsForSupplierQuery } from '../../generated/graphql'
import CreateContactModal from '../../components/AppliedModals/ContactModal/CreateContactModal'
import { Printer, Phone, Plus, Edit } from 'react-feather'
import Tile from '../../components/General/Tile/Tile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import EmptyState from '../../components/General/Tile/EmptyState/EmptyState'
import ContactTile from '../../components/Tiles/ContactTile/ContactTile'
import Modal from '../../components/Modal/Modal'
import EditContactModal from '../../components/AppliedModals/ContactModal/EditContactModal'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import moment from 'moment'
import propName from '../../helpers/propName'
import BulkUpdateSupplierPostingsModal from '../../components/AppliedModals/BulkUpdateSupplierPostingsModal/BulkUpdateSupplierPostingsModal'
import IndexContractTile from '../../components/Tiles/IndexContractTile/IndexContractTile'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const GetSingleSupplier = gql`
   fragment singleSupplier on Supplier {
      ...allSupplierListing
      indexContracts {
         indexContractKey
         terminal {
            name
         }
         accountInfo
         effectiveDate
         effectiveEndDate
      }
   }

   query GetSingleSupplier($id: Int!) {
      supplierGetSingle(id: $id) {
         ...singleSupplier
      }
   }
`
export const AllContactQuery = gql`
   query GetAllContactBySupplierKey($filterContact: ContactFilterInput) {
      contactGetAll(where: $filterContact){
         supplierKey
         contactKey
         firstName
         lastName
         phone
         email
         notes
         default
         cellPhone
         homePhone
         deleted
      }
   }
`

export const PostingsForSupplier = gql`
    query PostingsForSupplier($supplierKey: Int!, $currentDate: DateTime!, $sort: [PostingSortInput!]) {
        postingGetAll(where: {and: [{effectiveEndDate: {gt: $currentDate}}, {supplierKey: {eq: $supplierKey }}]}, order: $sort) {
            ...AllPostingListing
        }
    }
`

export const MostRecentPostingsForSupplier = gql`
    query MostRecentPostingsForSupplier($supplierKey: Int!){
       postingsGetAllForSupplierMostRecent(supplierKey: $supplierKey){
          postingKey
        supplierKey
        supplier {
            name
        }
        terminalKey
        terminal {
            name
        }
        cost
        effectiveDate
        effectiveEndDate
       }
    }
`

interface MatchParams extends Params {
   supplierKey: string
}

interface Props {
}

const SupplierShow = (props: Props) => {
   const navigate = useNavigate()

   const { supplierKey: key } = useParams() as MatchParams

   const supplierKey = parseInt(key)

   const { data, loading, error } = useGetSingleSupplierQuery({ variables: { id: supplierKey } })
   const [isShowingCreateModal, setShowingCreateModal] = useState(false)
   const [isShowingBulkUpdatePostingsModal, setShowingBulkUpdatePostingsModal] = useState(false)
   const [currentlyDeletingContact, setCurrentlyDeletingContact] = useState<Contact>(null)
   const [currentlyEditingContact, setCurrentlyEditingContact] = useState<Contact>()

   const { data: postingData, loading: postingsLoading, error: postingsError, refetch: refetchPostings } = useMostRecentPostingsForSupplierQuery({ variables: { supplierKey: supplierKey } })

   const [deleteContactMutation] = useDeleteContactMutation()

   const confirmContactDelete = (contactKey: number) => {
      deleteContactMutation({ variables: { id: contactKey } })
         .then(() => { refetchContacts(); setCurrentlyDeletingContact(null) })
   }

   const getContactsVariables: GetAllContactBySupplierKeyQueryVariables = { filterContact: { supplierKey: { eq: supplierKey }, deleted: { eq: false } } }
   const { data: contactData, loading: contactLoading, error: contactError, refetch } = useGetAllContactBySupplierKeyQuery({ variables: getContactsVariables })

   const refetchContacts = () => {
      return refetch(getContactsVariables)
   }
   if (loading || contactLoading || postingsLoading) { return (null) }
   if (error || contactError || postingsError) { return <p>Error</p> }

   const closeCreateModal = () => setShowingCreateModal(false)

   const closeEditModal = () => setCurrentlyEditingContact(null)

   const openBulkUpdateSupplierPostingsModal = () => setShowingBulkUpdatePostingsModal(true)
   const closeBulkUpdateSupplierPostingsModal = () => setShowingBulkUpdatePostingsModal(false)

   const navigateToEditSupplier = (supplierKey: number) => {
      navigate(`/suppliers/edit/${supplierKey}`)
   }

   const navigateToCreateSupplierPosting = () => {
      navigate(`/settings/postings/new?supplierKey=${supplierKey}`)
   }

   const postingsGridOptions: GridOptions<InnerPostingFragment> = {
      columnDefs: [
         { headerName: "Terminal", dataTransform: (entity) => entity.terminal.name },
         { headerName: "Cost", dataTransform: (entity) => entity.cost.toFixed(4) },
         { headerName: "Effective Date", dataTransform: (entity) => moment(entity.effectiveDate).format("LLL") },
         { headerName: "End Date", dataTransform: (entity) => moment(entity.effectiveEndDate).format("LLL") },
      ],
      isErrored: !!error,
      isLoading: postingsLoading,
      rowData: (postingsLoading || postingsError) ? [] : postingData.postingsGetAllForSupplierMostRecent,
      dataKeyColumn: propName<InnerPostingFragment>(o => o.postingKey)
      // linkToPath: "/settings/postings/edit/:key",
   }

   const refetchPostingsCallback = () => {
      return refetchPostings({ supplierKey: supplierKey })
   }

   return (
      <GeneralPage title="Suppliers" headerContent={
         <Button clickAction={() => { navigateToEditSupplier(data.supplierGetSingle.supplierKey) }}>Edit Supplier</Button>
      }>
         <div className="row">
            <div className="col-md-6">
               <Tile>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="row">
                           <div className="col-md-12">
                              <h2>{data.supplierGetSingle.name}</h2>
                           </div>
                        </div>
                        {data.supplierGetSingle.address1 ? <div className="row">
                           <div className="col-md-12">
                              <h5>{data.supplierGetSingle.address1}{data.supplierGetSingle.address2 && <span><br />{data.supplierGetSingle.address2}</span>}</h5>
                           </div>
                        </div> : <h5>No Address</h5>}
                        {data.supplierGetSingle.city && data.supplierGetSingle.state && <div className="row">
                           <div className="col-md-12">
                              <h5>{data.supplierGetSingle.city}, {data.supplierGetSingle.state} {data.supplierGetSingle.zipCode}</h5>
                           </div>
                        </div>}
                        <div className="row">
                           <div className="col-md-6">
                              <h5><strong><Phone /></strong> {data.supplierGetSingle.phone}</h5>
                           </div>
                           {data.supplierGetSingle.fax && <div className="col-md-6">
                              <h5><strong><Printer /></strong> {data.supplierGetSingle.fax}</h5>
                           </div>}
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              {data.supplierGetSingle.notes}
                           </div>
                        </div>
                     </div>
                  </div>
               </Tile>
            </div>
            <div className="col-md-6">
               <Tile title="Contacts" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingCreateModal(true)}><Plus /></Button>} headerButtonCondition={(contactData.contactGetAll && contactData.contactGetAll.length > 0)}>

                  <EmptyState text="You don't have any contacts linked to this supplier yet. Why don't you create one?"
                     data={contactData.contactGetAll} buttonText="Create Contact" buttonAction={() => setShowingCreateModal(true)}></EmptyState>

                  {contactData.contactGetAll.map(contact => {
                     return <ContactTile key={contact.contactKey} firstName={contact.firstName} lastName={contact.lastName} email={contact.email} phone={contact.phone} cellPhone={contact.cellPhone} homePhone={contact.homePhone}
                        default={contact.default} editAction={() => { setCurrentlyEditingContact(contact) }} removeAction={() => { setCurrentlyDeletingContact(contact) }}></ContactTile>
                  })}

               </Tile>
            </div>
         </div>
         <div className="row">
            <div className="col-md-6">
               <Tile title="Postings" headerButton={<Button buttonType={ButtonType.TightSecondary} clickAction={() => openBulkUpdateSupplierPostingsModal()}><Edit /></Button>}
                  headerButtonCondition={(postingData.postingsGetAllForSupplierMostRecent && postingData.postingsGetAllForSupplierMostRecent.length > 0)}>

                  <EmptyState text="You don't have any postings linked to this supplier yet. Why don't you create one?"
                     data={postingData.postingsGetAllForSupplierMostRecent} buttonText="Create Supplier Posting" buttonAction={() => navigateToCreateSupplierPosting()}></EmptyState>

                  {postingData.postingsGetAllForSupplierMostRecent.length > 0 && <GridBase<InnerPostingFragment> gridOptions={postingsGridOptions} />}
               </Tile>
            </div>

            <div className="col-md-6">
               <IndexContractTile supplierKey={supplierKey} />
            </div>
         </div>

         {isShowingCreateModal && <CreateContactModal closeModal={closeCreateModal} supplierKey={supplierKey} callback={refetchContacts} />}
         {currentlyEditingContact && <EditContactModal closeModal={closeEditModal} supplierKey={supplierKey} callback={refetchContacts} contact={currentlyEditingContact} />}
         {isShowingBulkUpdatePostingsModal && <BulkUpdateSupplierPostingsModal closeModal={closeBulkUpdateSupplierPostingsModal} supplierKey={supplierKey} callback={refetchPostingsCallback} />}
         {currentlyDeletingContact && <Modal headerText="Delete Contact?">
            <p>Once you delete this contact, it will not be able to be reversed.</p>
            <button onClick={() => setCurrentlyDeletingContact(null)}>Cancel</button> <button onClick={() => confirmContactDelete(currentlyDeletingContact.contactKey)}>Delete</button>
         </Modal>}
      </GeneralPage>
   )
}
export default SupplierShow