import React, { useState } from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { CreatePointToPointCarrierRateDateSpanMutationVariables, useCreatePointToPointCarrierRateDateSpanMutation } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from "yup"
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../General/Button/Button'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import HookedCheckbox from '../../General/Inputs/HookedCheckbox'

interface Props {
    closeModal(): void
    callback?(): Promise<any>
    carrierKey?: number
}

export const CreatePointToPointCarrierRateDateSpan = gql`
	mutation CreatePointToPointCarrierRateDateSpan($carrierPointToPointRateDateSpan: CarrierPointToPointRateDateSpanInput!, $percentMultiplier: Float!, $copyActiveRates: Boolean!) {
		carrierPointToPointRateDateSpanCreate(carrierPointToPointRateDateSpan: $carrierPointToPointRateDateSpan, percentMultiplier: $percentMultiplier, copyActiveRates: $copyActiveRates) {
			carrierPointToPointRateDateSpanKey
		}
	}
`

const CreatePointToPointCarrierRateDateSpanModal = (props: Props) => {
    const navigate = useNavigate()
    const today = moment().startOf('day')
    const [startDate, setStartDate] = useState(new Date(today.format("LL")))
    const [copyRates, setCopyRates] = useState(true)

    const [createPointToPointCarrierRateDateSpanMutation] = useCreatePointToPointCarrierRateDateSpanMutation()
    const onCreate = (variables: CreatePointToPointCarrierRateDateSpanMutationVariables) => {
        variables.carrierPointToPointRateDateSpan.carrierKey = props.carrierKey
        createPointToPointCarrierRateDateSpanMutation({ variables })
            .then(res => {
                navigate(`/carriers/point-to-point/${res.data.carrierPointToPointRateDateSpanCreate.carrierPointToPointRateDateSpanKey}`)
            })
    }

    const validationSchema = yup.object().shape({
        carrierPointToPointRateDateSpan: yup.object().shape({
            startDate: yup.date().required()
        }),
        percentMultiplier: yup.number().required(),
        copyActiveRates: yup.bool()
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreatePointToPointCarrierRateDateSpanMutationVariables>({ validationSchema })

    const commonProps = { register, errors, setValue }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
        }
    }

    const onSubmit = handleSubmit(onCreate)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>

    return (
        <Modal headerText="Create Point to Point Rate Date Span" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll>
            <form onSubmit={onSubmit}>

                <div className="row">
                    <div className="col-md-6">
                        <HookedDateTime
                            label="Start Date"
                            propertyKey={propName<CreatePointToPointCarrierRateDateSpanMutationVariables>(o => o.carrierPointToPointRateDateSpan.startDate)}
                            defaultValue={startDate}
                            Change={onStartDateChange}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedTextInput
                            label="Percent Multiplier"
                            propertyKey={propName<CreatePointToPointCarrierRateDateSpanMutationVariables>(o => o.percentMultiplier)}
                            defaultValue={"1.0"}
                            disabled={!copyRates}
                            {...commonProps}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                        <HookedCheckbox
                            label="Copy Active Rates?"
                            propertyKey={propName<CreatePointToPointCarrierRateDateSpanMutationVariables>(o => o.copyActiveRates)}
                            defaultValue={copyRates}
                            onChange={(event) => setCopyRates(event.target.checked)}
                            {...commonProps}
                        />
                    </div>

                </div>
            </form>

        </Modal>
    )
}
export default CreatePointToPointCarrierRateDateSpanModal 