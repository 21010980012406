import React, { ReactElement } from 'react'
import gql from 'graphql-tag'
import { BasicContactFragment, GetContactsSearchQueryVariables, useGetContactsSearchQuery, useGetSingleContactQuery } from '../../../generated/graphql'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'

interface Props extends DefaultInputProps<BasicContactFragment> {
    onSelect?(contact: BasicContactFragment)
    prefilledContactKey?: number
    locationKey?: number
    customerKey?: number
}

export default function ContactSearch(props: Props): ReactElement {
    const { data, loading } = useGetSingleContactQuery({ variables: { contactKey: props.prefilledContactKey }, skip: !props.prefilledContactKey })

    const prefilledData = data && data.contactGetSingle
    const prefilledDataDisplay = prefilledData && `${prefilledData.firstName} ${prefilledData.lastName}`

    return <TextSearchDropdown<BasicContactFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.contactKey.toString())}
        searchWrapper={<Inner locationKey={props.locationKey} customerKey={props.customerKey} searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        disabled={!props.locationKey && !props.customerKey}
        {...props}
    />
}


export const getAvailableContacts = gql`

    fragment BasicContact on Contact {
        contactKey
        firstName
        lastName
        email
        phone
        cellPhone
        homePhone
        default
    }

    query GetSingleContact($contactKey: Int!) {
        contactGetSingle(contactKey: $contactKey) {
            ...BasicContact
        }
    }

    query GetContactsSearch($searchTerm: String, $locationKey: Int, $customerKey: Int, $archived: Boolean) {
        contactGetByLocationKey(searchTerm: $searchTerm, locationKey: $locationKey, customerKey: $customerKey, archived: $archived, order: [{ firstName: ASC }]) {
            ...BasicContact
        }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicContactFragment>) => {

    const searchTerm = props.searchTerm.trim()
    const locationKey = props.locationKey
    const customerKey = props.customerKey

    const variables: GetContactsSearchQueryVariables = {
        searchTerm,
        locationKey,
        customerKey,
        archived: false
    }

    const { data, loading, error } = useGetContactsSearchQuery({ variables })

    // Combine loading and error into one boolean to ensure data
    // variable is populated
    const showData = !loading && !error

    // Check for showData ensuring not loading or error
    const contactResults = showData &&

        // Map over contacts returned from query
        data.contactGetByLocationKey.map(contact => {
            const display = `${contact.firstName} ${contact.lastName}`
            return { key: contact.contactKey, object: contact, display }
        })

    const displayProps: SearchDisplayProps<BasicContactFragment> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Contacts", results: contactResults }
        ],
        isLoading: loading, error, searchTerm
    }

    return <SearchDisplay {...displayProps} />
}
