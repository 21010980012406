
import gql from 'graphql-tag'
import { GetSinglePostingQueryVariables, useGetSinglePostingQuery, useEditPostingMutation, EditPostingMutationVariables, useDeletePostingMutation } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from "../../helpers/propName"
import * as yup from "yup"
import React, { ReactElement, useState } from 'react'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import { Params, useNavigate, useParams } from 'react-router-dom'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'

export const EditPosting = gql`
    mutation EditPosting($posting: PostingInput! $id: Int!) {
        postingUpdate(posting: $posting, id: $id) {
            postingKey
        }
    }
`

export const GetSinglePosting = gql`
    fragment postingListing on Posting {
            postingKey
            supplierKey
            supplier {
                name
            }
            terminalKey
            terminal {
                name
            }
            cost
            effectiveDate
            effectiveEndDate
    }

    query GetSinglePosting($id: Int!) {
        postingGetSingle(id: $id) {
            ...postingListing
        }
    }
`

interface MatchParams extends Params {
    postingKey: string
}

interface Props {
}

export default function PostingEdit(props: Props): ReactElement {
    const navigate = useNavigate()

    const { postingKey: key } = useParams() as MatchParams

    const postingKey = parseInt(key)
    const [currentlyDeletingPosting, setCurrentlyDeleting] = useState(false)

    const validationSchema = yup.object().shape({
        posting: yup.object().shape({
            cost: yup.number().required(),
            effectiveDate: yup.date().required(),
            effectiveEndDate: yup.date().required().min(yup.ref('effectiveDate'), 'End date must be after start date'),
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required()
        })
    })

    const getVariables: GetSinglePostingQueryVariables = { id: postingKey }
    const { data, loading, error } = useGetSinglePostingQuery({ variables: getVariables })

    const [editPostingMutation] = useEditPostingMutation()
    const [deleteMutation] = useDeletePostingMutation()

    const { register, handleSubmit, errors, setValue } = useForm<EditPostingMutationVariables>({ validationSchema })

    if (loading) { return <FormPage></FormPage> }
    if (error) { return <p>Error</p> }

    const commonProps = { register, errors, setValue }

    const navigateToList = () => {
        navigate("/settings/postings")
    }

    const onSubmit = handleSubmit((variables: EditPostingMutationVariables) => {
        editPostingMutation({ variables: { ...variables, ...{ id: postingKey } } })
            .then(navigateToList)
    })

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const footerLeftContent = (<div>
        <Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button>
    </div>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Posting</Button>)

    return (
        <FormPage title="Edit Supplier Posting" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<EditPostingMutationVariables>(o => o.posting.supplierKey)}
                                prefilledSupplierKey={data.postingGetSingle.supplierKey}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <TerminalSearch
                                label="Terminal"
                                propertyKey={propName<EditPostingMutationVariables>(o => o.posting.terminalKey)}
                                prefilledTerminalKey={data.postingGetSingle.terminalKey}
                                {...commonProps}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <h5 style={{ "color": "red" }}>These details are not validated on save. Be sure that what you are editing does not cause any date overlaps or pricing will be wrong.</h5>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Cost"
                                propertyKey={propName<EditPostingMutationVariables>(o => o.posting.cost)}
                                defaultValue={data.postingGetSingle.cost.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedDateTime
                                label="Effective Date"
                                propertyKey={propName<EditPostingMutationVariables>(o => o.posting.effectiveDate)}
                                defaultValue={new Date(data.postingGetSingle.effectiveDate)}
                                showTimeSelect={true}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedDateTime
                                label="Effective End Date"
                                propertyKey={propName<EditPostingMutationVariables>(o => o.posting.effectiveEndDate)}
                                defaultValue={data.postingGetSingle.effectiveEndDate ? new Date(data.postingGetSingle.effectiveEndDate) : undefined}
                                showTimeSelect={true}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>

            {currentlyDeletingPosting && <DeleteEntityModal
                cancel={cancelDelete}
                confirm={confirmDelete}
                entityKey={postingKey}
                entityName="posting"
                orderFilter={{ postingKey: { eq: postingKey } }} />}
        </FormPage>
    )

}
