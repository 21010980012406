import React, { Component, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import BasisTypeUpdatePricesModal from '../../components/AppliedModals/BasisTypesUpdateModal/BasisTypesUpdatePricesModal'
import { useAllBasisTypesQuery, AllBasisTypeListingFragment, useDeleteBasisTypeMutation, BasisTypeSortInput, BasisTypeFilterInput, SortEnumType, AllBasisTypesQueryVariables } from '../../generated/graphql'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import moment from 'moment'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const BasisTypeFragment = gql`
    fragment allBasisTypeListing on BasisType {
        basisTypeKey
        description
        name
        isArchived
        currentPrice {
            price
            effectiveDate
        }
    }
`

export const BasisTypesQuery = gql`
    query AllBasisTypes($sortObject: [BasisTypeSortInput!], $filterObject: BasisTypeFilterInput) {
        basisTypeGetAll(order: $sortObject, where: $filterObject) {
           ...allBasisTypeListing
        }
    }
`
export const DeleteBasisTypeMutation = gql`
    mutation DeleteBasisType($id: Int!) {
        basisTypeDelete(id: $id)
    }
`

interface BasisTypeDataWrapperProps {
    currentSort: BasisTypeSortInput

    editBasisType(basisType: AllBasisTypeListingFragment)
    deleteBasisType(basisType: AllBasisTypeListingFragment)
    currentlyDeletingBasisTypes?: AllBasisTypeListingFragment

    currentFilter: BasisTypeFilterInput

    updateClosed: Boolean
}

const BasisTypeDataWrapper = (props: BasisTypeDataWrapperProps): JSX.Element => {

    const variables: AllBasisTypesQueryVariables = {
        sortObject: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllBasisTypesQuery({ variables })

    const [deleteMutation] = useDeleteBasisTypeMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteBasisType(null))
    }

    useEffect(() => {
        if (!props.updateClosed) {
            refetch()
        }
    }, [props.updateClosed, refetch])

    const cancelDelete = () => {
        props.deleteBasisType(null)
    }

    const gridOptions: GridOptions<AllBasisTypeListingFragment> = {
        columnDefs: [
            { headerName: "Basis Type", field: "name", dataTransform: o => o.name },
            { headerName: "Description", field: "description", dataTransform: o => o.description },
            { headerName: "Price", field: "price", dataTransform: o => o.currentPrice.price.toFixed(4) },
            { headerName: "Effective Date", field: "date", dataTransform: o => moment.utc(o.currentPrice.effectiveDate).format('LL') }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.basisTypeGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editBasisType },
                    { displayName: "Delete", action: props.deleteBasisType }
                ]
            }
        ],
        dataKeyColumn: "basisTypeKey",
        linkToPath: "/pricing/basis-types/show/:key"
    }

    return (
        <div>
            <GridBase<AllBasisTypeListingFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingBasisTypes && <Modal headerText="Delete?">
                <p>Once you delete this Basis Type, it will not be able to be reversed.</p>
                <p>Basis Type: {props.currentlyDeletingBasisTypes.name}</p>
                <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(props.currentlyDeletingBasisTypes.basisTypeKey)}>Delete</button>
            </Modal>}
        </div>
    )
}

const BasisTypeList = () => {
    const navigate = useNavigate()

    return (
        <BasisTypeListInnerWrapper navigate={navigate} />
    )
}

export default BasisTypeList

interface Props {
    navigate: NavigateFunction
}
interface State {
    currentSort: BasisTypeSortInput
    currentFilter: BasisTypeFilterInput
    currentlyDeletingBasisTypes?: AllBasisTypeListingFragment
    updateAll: Boolean
}

class BasisTypeListInnerWrapper extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: BasisTypeSortInput = { name: SortEnumType.Asc }
        const currentFilter: BasisTypeFilterInput = { isArchived: { eq: false } }

        this.state = {
            currentSort,
            currentFilter,
            updateAll: false
        }
    }

    navigateToNewBasisType() {
        this.props.navigate('/pricing/basis-types/new')
    }

    editBasisType(basisType: AllBasisTypeListingFragment) {
        this.props.navigate(`/pricing/basis-types/edit/${basisType.basisTypeKey}`)
    }

    deleteBasisTypes(basisType: AllBasisTypeListingFragment) {
        this.setState({ currentlyDeletingBasisTypes: basisType })
    }

    updateAll() {
        this.setState({ updateAll: true })
    }

    closeUpdateAll() {
        this.setState({ updateAll: false })
    }

    setShowArchived(showArchived: boolean) {
        let newFilter = Object.assign({}, this.state.currentFilter)
        newFilter.isArchived = { eq: showArchived }

        this.setState({ currentFilter: newFilter })
    }

    render() {
        return (
            <GeneralPage title="Basis Types" headerContent={
                <>
                    <HookedCheckbox
                        label="Show Archived"
                        defaultValue={false}
                        propertyKey={"showArchived"}
                        errors={{}}
                        register={React.createRef()}
                        onChange={(event) => this.setShowArchived(event.target.checked)}
                        inline={true}
                    />
                    <Button clickAction={this.updateAll.bind(this)} buttonType={ButtonType.Success}>Update All</Button>
                    <Button clickAction={this.navigateToNewBasisType.bind(this)}>New Basis Type</Button>
                </>
            }>
                <Tile>
                    <BasisTypeDataWrapper
                        currentSort={this.state.currentSort}
                        currentlyDeletingBasisTypes={this.state.currentlyDeletingBasisTypes}
                        deleteBasisType={this.deleteBasisTypes.bind(this)}
                        currentFilter={this.state.currentFilter}
                        editBasisType={this.editBasisType.bind(this)}
                        updateClosed={this.state.updateAll} />
                </Tile>
                {this.state.updateAll && <BasisTypeUpdatePricesModal
                    closeModal={this.closeUpdateAll.bind(this)}
                />}
            </GeneralPage>
        )
    }
}