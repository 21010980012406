import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import { FullTagFragment, useTagSearchQuery, useGetSingleTagLazyQuery, useCreateTagMutation } from '../../../generated/graphql'

export const TagSearchQuery = gql`
    query TagSearch($search: String) {
        tagGetAll(where: { name: {contains: $search }}, order: [{ name: ASC }]) {
            ...fullTag
        }
    }

    query AllTag($filter: TagFilterInput) {
        tagGetAll(where: $filter, order: [{ name: ASC }]) {
            ...fullTag
        }
    }

    query GetSingleTag($tagKey: Int!) {
        tagGetSingle(tagKey: $tagKey) {
            ...fullTag
        }
    }

    fragment fullTag on Tag {
        tagKey
        name
    }

    mutation CreateTag($tag: TagInput!) {
        tagCreate(tag: $tag) {
            tagKey
        }
    }
`

interface Props extends DefaultInputProps<FullTagFragment> {
    onSelect?(tag: FullTagFragment)
    prefilledTagKey?: number
    clearOnSelect: boolean
    onAdd?(tag)
    noAdd?: boolean
}

export default function TagSearch(props: Props): ReactElement {

    const [createTag] = useCreateTagMutation()

    const onAdd = (name: string) => {
        createTag({ variables: { tag: { name } } })
            .then(_ => props.onAdd(_.data.tagCreate))
        // .then(_ => setTagKey(_.data.tagCreate.tagKey))
        // .then(_ => executeGetSingleTagQuery)
    }

    const [executeGetSingleTagQuery, { data, loading }] = useGetSingleTagLazyQuery({ variables: { tagKey: props.prefilledTagKey } })

    useEffect(() => {
        if (props.prefilledTagKey) {
            executeGetSingleTagQuery()
        }
    }, [props.prefilledTagKey, executeGetSingleTagQuery])

    const prefilledData = data && data.tagGetSingle
    const prefilledDataDisplay = data && `${data.tagGetSingle.name}`

    return <TextSearchDropdown<FullTagFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.tagKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={props.onSelect} onAdd={props.onAdd !== undefined ? onAdd : undefined} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}
        {...props}
    />
}

const Inner = (props: InnerSearchLogicProps<FullTagFragment>) => {

    const { data, loading, error } = useTagSearchQuery({ variables: { search: props.searchTerm } })

    const showData = !loading && !error

    const results = showData &&
        data.tagGetAll.map(t => {
            const display = t.name
            return { key: t.tagKey, object: t, display }
        })

    const displayProps: SearchDisplayProps<FullTagFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Tags", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay onAdd={props.onAdd} {...displayProps} />
}