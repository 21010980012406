import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { useGetSingleSupplierLazyQuery, BasicSupplierFragment, useSearchSuppliersQuery, SortEnumType } from '../../../generated/graphql'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'

interface Props extends DefaultInputProps<BasicSupplierFragment> {
    onSelect?(supplier: BasicSupplierFragment)
    prefilledSupplierKey?: number
    prefilledSupplier?: BasicSupplierFragment
    clearOnSelect?: boolean
}

export default function SupplierSearch(props: Props): ReactElement {
    const [executeGetSupplier, { data, loading }] = useGetSingleSupplierLazyQuery({ variables: { id: props.prefilledSupplierKey } })
    const { prefilledSupplierKey } = props

    useEffect(() => {
        if (prefilledSupplierKey) {
            executeGetSupplier()
        }
    }, [prefilledSupplierKey, executeGetSupplier])

    const prefilledData = data && data.supplierGetSingle
    const prefilledDataDisplay = data && `${data.supplierGetSingle.name} - ${data.supplierGetSingle.city}, ${data.supplierGetSingle.state}`

    return <TextSearchDropdown<BasicSupplierFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.supplierKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}
        {...props}
    />
}

export const SearchSuppliers = gql`
    fragment BasicSupplier on Supplier {
        supplierKey
        name
        city
        state
    }

    query SearchSuppliers($searchTerm: String, $sort: [SupplierSortInput!]) {
        supplierGetAll(where: {
             name: {contains: $searchTerm},
             isArchived: {eq: false}
        }, order: $sort) {
           ...BasicSupplier
        }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicSupplierFragment>) => {
    const { data, loading, error } = useSearchSuppliersQuery({ variables: { searchTerm: props.searchTerm, sort: { name: SortEnumType.Asc } } })

    const showData = !loading && !error

    const results = showData &&

        data.supplierGetAll.map(supplier => {
            const display = `${supplier.name} - ${supplier.city}, ${supplier.state}`
            return { key: supplier.supplierKey, object: supplier, display }
        })

    const displayProps: SearchDisplayProps<BasicSupplierFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Suppliers", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}