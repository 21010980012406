import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { BasicTerminalFragment, useSearchTerminalsQuery, useGetSingleTerminalLazyQuery } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'

interface Props extends DefaultInputProps<BasicTerminalFragment> {
    onSelect?(terminal: BasicTerminalFragment)
    prefilledTerminalKey?: number
    prefilledTerminal?: BasicTerminalFragment
    locationKey?: number
    clearOnSelect?: boolean
    disabled?: boolean
}

export default function TerminalSearch(props: Props): ReactElement {

    const [executeGetTerminal, { data, loading }] = useGetSingleTerminalLazyQuery({ variables: { id: props.prefilledTerminalKey } })
    const { prefilledTerminalKey } = props

    useEffect(() => {
        if (prefilledTerminalKey) {
            executeGetTerminal()
        }
    }, [prefilledTerminalKey, executeGetTerminal])

    const prefilledData = data && data.terminalGetSingle
    const prefilledDataDisplay = data && `${data.terminalGetSingle.name} - ${data.terminalGetSingle.city}, ${data.terminalGetSingle.state}`

    return <TextSearchDropdown<BasicTerminalFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.terminalKey.toString())}
        searchWrapper={<Inner searchTerm="" locationKey={props.locationKey} onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        locationKey={props.locationKey}
        clearOnSelect={props.clearOnSelect}
        disabled={props.disabled}

        {...props}
    />
}


export const SearchTerminals = gql`
    fragment BasicTerminal on Terminal {
        terminalKey
        name
        city
        state
        locationTerminals {
            mileage
            locationKey
        }
    }

    query SearchTerminals($searchTerm: String, $locationKey: Int) {
        terminalSearch(
            where: {
                or: [
                { name: {contains: $searchTerm }},
                { city: {contains: $searchTerm }},
                { state: {contains: $searchTerm }},
                ],
                and: {isArchived: {eq: false}},
            }, 
            locationKey: $locationKey, 
            order: [{ name: ASC }]) {
                ...BasicTerminal
            }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicTerminalFragment>) => {
    const { data, loading, error } = useSearchTerminalsQuery({ variables: { searchTerm: props.searchTerm, locationKey: props.locationKey } })

    const showData = !loading && !error

    const results = showData &&

        data.terminalSearch.map(terminal => {
            const display = `${terminal.name} - ${terminal.city}, ${terminal.state}`
            return { key: terminal.terminalKey, object: terminal, display }
        })

    const displayProps: SearchDisplayProps<BasicTerminalFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Terminals", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}