import React, { useState } from 'react'

import gql from 'graphql-tag'
import { useGetSingleTerminalQuery, useGetAllContactByTerminalKeyQuery, GetAllContactByTerminalKeyQueryVariables, useDeleteContactMutation, Contact, useGetAllIndexContractsByTerminalKeyQuery, IndexContract } from '../../generated/graphql'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import CreateContactModal from '../../components/AppliedModals/ContactModal/CreateContactModal'
import Tile from '../../components/General/Tile/Tile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { Phone, Mail, Plus, Printer } from 'react-feather'
import EmptyState from '../../components/General/Tile/EmptyState/EmptyState'
import ContactTile from '../../components/Tiles/ContactTile/ContactTile'
import Modal from '../../components/Modal/Modal'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import moment from 'moment'
import EditContactModal from '../../components/AppliedModals/ContactModal/EditContactModal'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const GetSingleTerminal = gql`
   fragment singleTerminal on Terminal {
      terminalKey
      name
      city
      state
      zipCode
      address1
      address2
      directions
      email
      fax
      phone
      isArchived
      locationTerminals {
         locationKey
         mileage
      }
      notes
   }
   query GetSingleTerminal($id: Int!) {
      terminalGetSingle(id: $id) {
         ...singleTerminal
      }
   }
`
export const AllContactQuery = gql`
   query GetAllContactByTerminalKey($filterContact: ContactFilterInput) {
      contactGetAll(where: $filterContact){
         terminalKey
         contactKey
         firstName
         lastName
         phone
         email
         notes
         default
         cellPhone
         homePhone
         deleted
      }
   }
`

export const IndexContractsForTerminal = gql`
   query GetAllIndexContractsByTerminalKey($terminalKey: Int!) {
      indexContractsGetAll(where: { terminalKey: {eq: $terminalKey} }, order: [{ effectiveDate: DESC }]) {
         indexContractKey
         supplier {
            name
        }
        effectiveDate
      }
   }
`

interface MatchParams extends Params {
   terminalKey: string
}

interface Props {
}

const TerminalShow = (props: Props) => {
   const navigate = useNavigate()
   const { terminalKey: key } = useParams() as MatchParams

   const terminalKey = parseInt(key)

   const { data, loading, error } = useGetSingleTerminalQuery({ variables: { id: terminalKey } })
   const [isShowingCreateModal, setShowingCreateModal] = useState(false)
   const [currentlyDeletingContact, setCurrentlyDeletingContact] = useState<Contact>()
   const [currentlyEditingContact, setCurrentlyEditingContact] = useState<Contact>()

   const getContactsVariables: GetAllContactByTerminalKeyQueryVariables = { filterContact: { terminalKey: { eq: terminalKey } } }
   const { data: contactData, loading: contactLoading, error: contactError, refetch } = useGetAllContactByTerminalKeyQuery({ variables: getContactsVariables })

   const { data: indexContractData, loading: indexContractsLoading, error: indexContractsError } = useGetAllIndexContractsByTerminalKeyQuery({ variables: { terminalKey: terminalKey } })

   const [deleteContactMutation] = useDeleteContactMutation()

   const confirmContactDelete = (contactKey: number) => {
      deleteContactMutation({ variables: { id: contactKey } })
         .then(() => { refetchContacts(); setCurrentlyDeletingContact(null) })
   }

   const refetchContacts = () => {
      return refetch(getContactsVariables)
   }

   if (loading || indexContractsLoading || contactLoading) { return (null) }
   if (error || indexContractsError || contactError) { return <p>Error</p> }

   const navigateToEditTerminal = (terminalKey: number) => {
      navigate(`/terminals/edit/${terminalKey}`)
   }

   const closeCreateModal = () => setShowingCreateModal(false)
   const closeEditModal = () => setCurrentlyEditingContact(null)

   const indexContractGridOptions: GridOptions<IndexContract> = {
      columnDefs: [
         {
            headerName: "Supplier", dataTransform: (entity) => entity.supplier.name
         },
         { headerName: "Effective Date", field: "effectiveDate", dataTransform: o => moment(o.effectiveDate).format('LL') },
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: indexContractData.indexContractsGetAll as IndexContract[],
      dataKeyColumn: "indexContractKey",
      linkToPath: "/pricing/index-contracts/edit/:key",
   }

   const navigateToCreateIndexContract = () => {
      navigate(`/pricing/index-contracts/new`)
   }

   return (
      <GeneralPage title="Terminal" headerContent={
         <Button clickAction={() => { navigateToEditTerminal(data.terminalGetSingle.terminalKey) }}>Edit Terminal</Button>
      }>
         <div className="row">
            <div className="col-md-12">
               <Tile>
                  <div className="row">
                     <div className="col-md-6">
                        <div className="row">
                           <div className="col-md-12">
                              <h2>{data.terminalGetSingle.name}</h2>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <h5>{data.terminalGetSingle.address1}{data.terminalGetSingle.address2 && <span><br />{data.terminalGetSingle.address2}</span>}</h5>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <h5>{data.terminalGetSingle.city}, {data.terminalGetSingle.state} {data.terminalGetSingle.zipCode}</h5>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-6">
                              <h5><strong><Phone /></strong> {data.terminalGetSingle.phone}</h5>
                           </div>
                           <div className="col-md-6">
                              {data.terminalGetSingle.fax && <h5><strong><Printer /></strong> {data.terminalGetSingle.fax}</h5>}
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-6">
                              <h5><strong><Mail /></strong> {data.terminalGetSingle.email}</h5>
                           </div>
                        </div>
                        {data.terminalGetSingle.directions &&
                           <div className="row">
                              <div className="col-md-12">
                                 <h5>Directions: {data.terminalGetSingle.directions}</h5>
                              </div>
                           </div>
                        }
                     </div>
                  </div>
               </Tile>

            </div>
         </div>
         <div className="row">
            <div className="col-md-6">
               <Tile title="Contacts" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingCreateModal(true)}><Plus /></Button>} headerButtonCondition={(contactData.contactGetAll && contactData.contactGetAll.length > 0)}>

                  <EmptyState text="You don't have any contacts linked to this terminal yet. Why don't you create one?"
                     data={contactData.contactGetAll} buttonText="Create Contact" buttonAction={() => setShowingCreateModal(true)}></EmptyState>

                  {contactData.contactGetAll.map(contact => {
                     return (<ContactTile key={contact.contactKey} firstName={contact.firstName} lastName={contact.lastName} email={contact.email} default={contact.default} phone={contact.phone}
                        cellPhone={contact.cellPhone} homePhone={contact.homePhone} editAction={() => { setCurrentlyEditingContact(contact) }} removeAction={() => { setCurrentlyDeletingContact(contact) }}></ContactTile>)
                  })}

               </Tile>
            </div>
            <div className="col-md-6">
               <Tile title="Index Contracts" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => navigateToCreateIndexContract()}><Plus /></Button>} headerButtonCondition={(indexContractData.indexContractsGetAll && indexContractData.indexContractsGetAll.length > 0)}>
                  <EmptyState text="You don't have any index contracts linked to this terminal yet. Why don't you create one?"
                     data={indexContractData.indexContractsGetAll}
                     buttonText="Create Index Contract" buttonAction={() => navigateToCreateIndexContract()}></EmptyState>
                  {indexContractData.indexContractsGetAll.length > 0 && <GridBase gridOptions={indexContractGridOptions} />}
               </Tile>
            </div>
         </div>
         {isShowingCreateModal && <CreateContactModal closeModal={closeCreateModal} terminalKey={terminalKey} callback={refetchContacts} />}
         {currentlyEditingContact && <EditContactModal closeModal={closeEditModal} terminalKey={terminalKey} callback={refetchContacts} contact={currentlyEditingContact} />}
         {currentlyDeletingContact && <Modal headerText="Delete Contact?">
            <p>Once you delete this contact, it will not be able to be reversed.</p>
            <button onClick={() => setCurrentlyDeletingContact(null)}>Cancel</button> <button onClick={() => confirmContactDelete(currentlyDeletingContact.contactKey)}>Delete</button>
         </Modal>}
      </GeneralPage>
   )
}
export default TerminalShow 