import gql from 'graphql-tag'
import * as yup from 'yup'
import React from 'react'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { GetAllCarrierRateBandByCarrierRateKeyQueryVariables, useGetAllCarrierRateBandByCarrierRateKeyQuery, useCreateCarrierRateBandMutation, CreateCarrierRateBandMutationVariables, useGetSingleCarrierRateQuery } from '../../generated/graphql'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import propName from '../../helpers/propName'
import useForm from 'react-hook-form'
import CarrierRateBandUpdate from '../../components/CarrierRateBand/CarrierRateBand'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const GetSingleCarrierRate = gql`
    fragment singleCarrierRate on CarrierRate {
        carrierRateKey
        carrierKey
        basePercent
        startDate
        endDate
        default
        fuelPercent
    }

    query GetSingleCarrierRate($key: Int!) {
        carrierRateGetSingle(id: $key) {
            ...singleCarrierRate
        }
    }
`

interface MatchParams extends Params {
    carrierRateKey
}

interface CarrierRateProps {
}


const CarrierRateEdit = (props: CarrierRateProps) => {
    const navigate = useNavigate()

    const { carrierRateKey: key } = useParams() as MatchParams

    const carrierRateKey = parseInt(key)

    const { data: crData } = useGetSingleCarrierRateQuery({ variables: { key: carrierRateKey } })


    const getCarrierRateBandVariables: GetAllCarrierRateBandByCarrierRateKeyQueryVariables = { carrierRateKey: carrierRateKey }
    const { data: rateData, loading: rateLoading, error: rateError, refetch: rateRefetch } = useGetAllCarrierRateBandByCarrierRateKeyQuery({ variables: getCarrierRateBandVariables })

    const validationSchema = yup.object().shape({
        carrierRateBand: yup.object().shape({
            mileageFrom: yup.number().required(),
            mileageTo: yup.number().required(),
            ratePerGallon: yup.number().required()
        })
    })

    const [createCarrierRateBand] = useCreateCarrierRateBandMutation()
    const submitCreateRateBandTask = (variables: CreateCarrierRateBandMutationVariables) => {

        variables.carrierRateBand.carrierRateKey = carrierRateKey

        createCarrierRateBand({ variables: variables })
            .then(() => rateRefetch())
    }

    const createCarrierRateBandSubmit = useForm<CreateCarrierRateBandMutationVariables>({ validationSchema })
    const createCommonProps = { register: createCarrierRateBandSubmit.register, errors: createCarrierRateBandSubmit.errors, setValue: createCarrierRateBandSubmit.setValue }
    const createCarrierRate = createCarrierRateBandSubmit.handleSubmit(submitCreateRateBandTask)

    const navigateToCarrier = () => {
        if (crData) {
            navigate(`/carriers/show/${crData.carrierRateGetSingle.carrierKey}`)

        }
    }

    return (
        <FormPage title="Carrier Rate" headerContent={
            <Button clickAction={navigateToCarrier}>Back to Carrier</Button>
        }>
            <FormTile title="New Carrier Rate Band">
                <form id="create-carrier-rate-band-form" onSubmit={createCarrierRate}>

                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                propertyKey={propName<CreateCarrierRateBandMutationVariables>(o => o.carrierRateBand.mileageFrom)}
                                label="Mileage From"
                                {...createCommonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                propertyKey={propName<CreateCarrierRateBandMutationVariables>(o => o.carrierRateBand.mileageTo)}
                                label="Mileage To"
                                {...createCommonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                propertyKey={propName<CreateCarrierRateBandMutationVariables>(o => o.carrierRateBand.ratePerGallon)}
                                label="Rate Per Gallon"
                                {...createCommonProps}
                            />
                        </div>
                    </div>
                    <Button buttonType={ButtonType.Primary} clickAction={createCarrierRate}>Create Carrier Rate Band</Button>
                </form>
            </FormTile>
            <FormTile title="Rate Details">
                <div className="row">
                    <div className="col-md-12">
                        {rateLoading || rateError ?
                            <p>Loading</p> :
                            rateData.carrierRateBandGetAll.sort((first, second) => first.mileageTo - second.mileageFrom).map(carrierRateBand => {
                                return (
                                    <CarrierRateBandUpdate
                                        carrierRateBandKey={carrierRateBand.carrierRateBandKey}
                                        carrierRateKey={carrierRateKey}
                                        mileageFrom={carrierRateBand.mileageFrom}
                                        mileageTo={carrierRateBand.mileageTo}
                                        rate={carrierRateBand.ratePerGallon}
                                        callback={rateRefetch}
                                        key={carrierRateBand.carrierRateBandKey}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </FormTile>
        </FormPage>
    )
}

export default CarrierRateEdit