import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { InnerPrePurchaseAgreementFragment, useGetSinglePrePurchaseAgreementLazyQuery, usePrePurchaseAgreementSearchQuery, PrePurchaseAgreement, PrePurchaseAgreementSearchQueryVariables } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'

interface Props extends DefaultInputProps<InnerPrePurchaseAgreementFragment> {
    onSelect?(prePruchaseAgreement: InnerPrePurchaseAgreementFragment)
    prefilledPrePurchaseAgreementKey?: number
    terminalKey?: number
    supplierKey?: number
    clearOnSelect?: boolean
    activeDate?: Date
}

export default function PrePurchaseAgreementSearch(props: Props): ReactElement {
    const [executeGetPrePurchaseAgreement, { data, loading }] = useGetSinglePrePurchaseAgreementLazyQuery({ variables: { prePurchaseAgreementKey: props.prefilledPrePurchaseAgreementKey } })
    const { prefilledPrePurchaseAgreementKey } = props

    useEffect(() => {
        if (prefilledPrePurchaseAgreementKey) {
            executeGetPrePurchaseAgreement()
        }
    }, [prefilledPrePurchaseAgreementKey, executeGetPrePurchaseAgreement])

    const prefilledData = data && data.prePurchaseAgreementGetSingle
    const prefilledDataDisplay = data && `${data.prePurchaseAgreementGetSingle.supplierAgreement}`

    return <TextSearchDropdown<InnerPrePurchaseAgreementFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.prePurchaseAgreementKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} terminalKey={props.terminalKey} supplierKey={props.supplierKey} date={props.activeDate} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}
        {...props}
    />
}

export const PrePurchaseAgreementSearchQuery = gql`
    query PrePurchaseAgreementSearch($searchTerm: String!, $terminalKey: Int, $supplierKey: Int, $activeDate: DateTime) {
        prePurchaseAgreementBySupplierTerminal(where: { supplierAgreement: {contains: $searchTerm }}, terminalKey: $terminalKey, supplierKey: $supplierKey, order: [{ supplierAgreement: ASC }], activeDate: $activeDate) {
            ...innerPrePurchaseAgreement
        }
    }
`

const Inner = (props: InnerSearchLogicProps<InnerPrePurchaseAgreementFragment>) => {
    const variables: PrePurchaseAgreementSearchQueryVariables = {
        searchTerm: props.searchTerm,
        terminalKey: props.terminalKey,
        supplierKey: props.supplierKey,
        activeDate: props.date
        // ...(props.terminalKey && { terminalKey : props.terminalKey }),
        // ...(props.supplierKey && { supplierKey: props.supplierKey })
    }
    const { data, loading, error } = usePrePurchaseAgreementSearchQuery({ variables })

    const showData = !loading && !error

    const results = showData &&
        data.prePurchaseAgreementBySupplierTerminal.map(sa => {
            const display = `${sa.supplierAgreement}`
            return { key: sa.prePurchaseAgreementKey, object: sa, display }
        })

    const displayProps: SearchDisplayProps<PrePurchaseAgreement> = {
        onSelect: props.onSelect,
        groups: [{ title: "Pre Purchase Agreements", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}