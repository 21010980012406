import gql from "graphql-tag"
import { Params, useNavigate, useParams } from "react-router-dom"
import { useGetSingleCarrierQuery, GetAllContactByCarrierKeyQueryVariables, useGetAllContactByCarrierKeyQuery, useGetAllCarrierRateByCarrierKeyQuery, GetAllCarrierRateByCarrierKeyQueryVariables, useDeleteContactMutation, CarrierRate, useDeleteCarrierRateMutation, Contact, CarrierType, useGetAllCarrierPointToPointRateDateSpansByCarrierKeyQuery, GetAllCarrierPointToPointRateDateSpansByCarrierKeyQueryVariables, CarrierPointToPointRateDateSpan, useDeleteCarrierPointToPointRateDateSpanMutation, SortEnumType } from "../../generated/graphql"
import React, { useState } from "react"
import GeneralPage from "../../components/General/GeneralPage/GeneralPage"
import CreateContactModal from "../../components/AppliedModals/ContactModal/CreateContactModal"
import CreateCarrierRateModal from "../../components/AppliedModals/CarrierRate/CreateCarrierRateModal"
import Tile from "../../components/General/Tile/Tile"
import Button, { ButtonType } from "../../components/General/Button/Button"
import EmptyState from "../../components/General/Tile/EmptyState/EmptyState"
import ContactTile from "../../components/Tiles/ContactTile/ContactTile"
import { Phone, Mail, Plus, Printer } from "react-feather"
import Modal from "../../components/Modal/Modal"
import GridBase, { GridOptions } from "../../components/General/GridBase/GridBase"
import moment from "moment"
import EditContactModal from "../../components/AppliedModals/ContactModal/EditContactModal"
import UpdateCarrierRateModal from "../../components/AppliedModals/CarrierRate/UpdateCarrierRateModal"
import FuelSurchargeTile from "../../components/Tiles/FuelSurchargeTile/FuelSurchargeTile"
import CreatePointToPointCarrierRateDateSpanModal from "../../components/AppliedModals/CarrierPointToPointRate/CreatePointToPointCarrierRateDateSpanModal"
import UpdatePointToPointCarrierRateDateSpanModal from "../../components/AppliedModals/CarrierPointToPointRate/UpdatePointToPointCarrierRateDateSpanModal"
import ellipsish from '../../icons/ellipsis-h.svg'

export const GetSingleCarrier = gql`
   fragment singleCarrier on Carrier {
      ...allCarrierListing
   }
   query GetSingleCarrier($id: Int!) {
      carrierGetSingle(id: $id) {
         ...singleCarrier
      }
   }
`

export const AllContactQuery = gql`
   query GetAllContactByCarrierKey($filterContact: ContactFilterInput) {
      contactGetAll(where: $filterContact) {
         carrierKey
         contactKey
         firstName
         lastName
         phone
         email
         notes
         default
         cellPhone
         homePhone
         deleted
      }
   }
`
export const AllCarrierRateQuery = gql`
   query GetAllCarrierRateByCarrierKey($filterCarrierRate: CarrierRateFilterInput, $sort: [CarrierRateSortInput!], $pageSize: Int!) {
      carrierRateGetAll(order: $sort, where: $filterCarrierRate, first: $pageSize) {
          edges {
                cursor
                node {
                  carrierKey
                  carrierRateKey
                  startDate
                  endDate
                  basePercent
                  default
                  fuelPercent
                }
          }
      }
   }
`

export const AllCarrierPointToPointRateQuery = gql`
   query GetAllCarrierPointToPointRateByCarrierKey($filterCarrierRate: CarrierPointToPointRateFilterInput, $sort: [CarrierPointToPointRateSortInput!]) {
      carrierPointToPointRateGetAll(order: $sort, where: $filterCarrierRate) {
         carrierPointToPointRateKey
         rate
         originCityKey
         originCity {
            name
            cityKey
            stateKey
            state{
               stateKey
               name
            }
         }
         destinationCityKey
         destinationCity {
            name
            cityKey
            stateKey
            state{
               stateKey
               name
            }
         }
         miles
         tolls
      }
   }
`

export const AllCarrierPointToPointRateDateSpansQuery = gql`
   query GetAllCarrierPointToPointRateDateSpansByCarrierKey($filterDateSpans: CarrierPointToPointRateDateSpanFilterInput, $sort: [CarrierPointToPointRateDateSpanSortInput!]) {
      carrierPointToPointRateDateSpanGetAll(order: $sort, where: $filterDateSpans) {
         carrierPointToPointRateDateSpanKey
         startDate
         endDate
         carrierKey
      }
   }
`

export const DeleteContactMutation = gql`
   mutation DeleteContact($id: Int!) {
      contactArchive(contactKey: $id)
   }
`

export const DeleteCarrierRateMutation = gql`
   mutation DeleteCarrierRate($id: Int!) {
      carrierRateDelete(id: $id)
   }
`

export const DeleteCarrierPointToPointRateMutation = gql`
   mutation DeleteCarrierPointToPointRate($id: Int!) {
      carrierPointToPointRateDelete(id: $id)
   }
`

interface MatchParams extends Params {
   carrierKey: string
}
interface Props {
}
const CarrierShow = (props: Props) => {
   const navigate = useNavigate()

   const { carrierKey: key } = useParams() as MatchParams

   const carrierKey = parseInt(key)

   const { data, loading, error } = useGetSingleCarrierQuery({ variables: { id: carrierKey } })
   const [isShowingCreateModal, setShowingCreateModal] = useState(false)
   const [isShowingCreateCarrierRate, setShowingCreateCarrierRate] = useState(false)
   const [isShowingCreatePointToPointCarrierRateDateSpan, setShowingCreatePointToPointCarrierRateDateSpan] = useState(false)
   const [currentlyDeletingContact, setCurrentlyDeletingContact] = useState<Contact>(null)
   const [currentlyDeletingRate, setCurrentlyDeletingRate] = useState<number>(null)
   const [currentlyDeletingPointToPointRateDateSpan, setCurrentlyDeletingPointToPointRateDateSpan] = useState<number>(null)
   const [currentlyEditingContact, setCurrentlyEditingContact] = useState<Contact>()
   const [updateCarrierRateKey, setUpdateCarrierRateKey] = useState<number>(undefined)
   const [updateCarrierPointToPointRateDateSpanKey, setUpdateCarrierPointToPointRateDateSpanKey] = useState<number>(undefined)
   const [showAll, setShowAll] = useState(false)

   const getContactsVariables: GetAllContactByCarrierKeyQueryVariables = { filterContact: { carrierKey: { eq: carrierKey }, deleted: { eq: false } } }
   const { data: contactData, loading: contactLoading, error: contactError, refetch: contactRefetch } = useGetAllContactByCarrierKeyQuery({ variables: getContactsVariables })
   const getCarrierRateVariables: GetAllCarrierRateByCarrierKeyQueryVariables = { filterCarrierRate: { carrierKey: { eq: carrierKey } }, sort: { startDate: SortEnumType.Desc }, pageSize: showAll ? 1000 : 2 }
   // const getCarrierRateVariables: GetAllCarrierRateByCarrierKeyQueryVariables = { filterCarrierRate: { carrierKey: { eq: carrierKey } }, sort: { startDate: SortEnumType.Desc }, pageSize: showAll ? 1000 : 2 }
   const { data: carrierRateData, loading: carrierRateLoading, error: carrierRateError, refetch: rateRefetch } = useGetAllCarrierRateByCarrierKeyQuery({ variables: getCarrierRateVariables })
   const getCarrierPointToPointRateDateSpanVariables: GetAllCarrierPointToPointRateDateSpansByCarrierKeyQueryVariables = { filterDateSpans: { carrierKey: { eq: carrierKey } }, sort: { startDate: SortEnumType.Desc } }
   const { data: carrierPointToPointRateDateSpanData, loading: carrierPointToPointRateDateSpanLoading, error: carrierPointToPointRateDateSpanError, refetch: pointToPointRateDateSpanRefetch } = useGetAllCarrierPointToPointRateDateSpansByCarrierKeyQuery({ variables: getCarrierPointToPointRateDateSpanVariables })

   const refetchContacts = () => {
      return contactRefetch(getContactsVariables)
   }

   const refetchRates = () => {
      return rateRefetch(getCarrierRateVariables)
   }

   const refetchPointToPointRateDateSpans = () => {
      return pointToPointRateDateSpanRefetch(getCarrierPointToPointRateDateSpanVariables)
   }

   const [deleteContactMutation] = useDeleteContactMutation()
   const [deleteCarrierRateMutation] = useDeleteCarrierRateMutation()
   const [deleteDateSpanMutation] = useDeleteCarrierPointToPointRateDateSpanMutation()

   if (loading || contactLoading || carrierRateLoading || carrierPointToPointRateDateSpanLoading) { return (null) }
   if (error || contactError || carrierRateError || carrierPointToPointRateDateSpanError) { return <p>Error</p> }

   const closeCreateModal = () => setShowingCreateModal(false)
   const closeCreateCarrierRateModal = () => setShowingCreateCarrierRate(false)
   const closeCreateCarrierPointToPointRateDateSpanModal = () => setShowingCreatePointToPointCarrierRateDateSpan(false)
   const closeEditModal = () => setCurrentlyEditingContact(null)

   const confirmContactDelete = (contactKey: number) => {
      deleteContactMutation({ variables: { id: contactKey } })
         .then(() => { refetchContacts(); setCurrentlyDeletingContact(null) })
   }

   const confirmRateDelete = (rateKey: number) => {
      deleteCarrierRateMutation({ variables: { id: rateKey } })
         .then(() => { refetchRates(); setCurrentlyDeletingRate(null); setUpdateCarrierRateKey(undefined) })
   }

   const confirmDateSpanDelete = (dateSpanKey: number) => {
      deleteDateSpanMutation({ variables: { id: dateSpanKey } })
         .then(() => {
            refetchPointToPointRateDateSpans()
            setCurrentlyDeletingPointToPointRateDateSpan(null)
         })
   }

   const cancelDelete = () => {
      setCurrentlyDeletingContact(null)
      setCurrentlyDeletingRate(null)
      setCurrentlyDeletingPointToPointRateDateSpan(null)
   }

   const openCarrierRateDelete = (carrierRate: number) => {
      setCurrentlyDeletingRate(carrierRate)
   }

   const closeUpdateCarrierRateModal = () => {
      setUpdateCarrierRateKey(undefined)
   }

   const openUpdateCarrierRateModal = (line) => {
      setUpdateCarrierRateKey(line["carrierRateKey"])
   }

   const closeUpdateCarrierPointToPointRateDateSpanModal = () => {
      setUpdateCarrierPointToPointRateDateSpanKey(undefined)
   }

   const openUpdateCarrierPointToPointRateDateSpanModal = (line) => {
      setUpdateCarrierPointToPointRateDateSpanKey(line["carrierPointToPointRateDateSpanKey"])
   }

   const openCarrierPointToPointRateDateSpanDelete = (dateSpan: CarrierPointToPointRateDateSpan) => {
      setCurrentlyDeletingPointToPointRateDateSpan(dateSpan.carrierPointToPointRateDateSpanKey)
   }

   const showAllRates = () => {
      setShowAll(true)
   }

   const today = new Date()
   const carrierRateGridOptions: GridOptions<CarrierRate> = {
      columnDefs: [
         {
            headerName: "Start Date", field: "startDate", dataTransform: o => moment.utc(o.startDate).format('LL')
         },
         { headerName: "End Date", field: "endDate", dataTransform: o => moment.utc(o.endDate).format('LL') },
         { headerName: "Base Percent", field: "basePercent" }
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: (carrierRateLoading || carrierRateError) ? [] : carrierRateData.carrierRateGetAll.edges.map(cr => cr.node),
      cellStyleRules: [{
         // diminish the appearance of rates that aren't active
         ruleFn: (rate: CarrierRate) => !(new Date(moment.utc(rate.startDate).format('LL')) <= today && new Date(moment.utc(rate.endDate).format('LL')) >= today),
         cssProperties: {
            backgroundColor: "#E5E5E5",
            color: "#5F6163"
         }
      },
      {
         // highlight with green for default rate
         ruleFn: (rate: CarrierRate) => rate.default,
         cssProperties: {
            backgroundColor: "#0DA17F",
            color: "white",
         }
      }
      ],
      dataKeyColumn: "carrierRateKey",
      clickAction: openUpdateCarrierRateModal
   }

   const carrierPointToPointRateDateSpanGridOptions: GridOptions<CarrierPointToPointRateDateSpan> = {
      columnDefs: [
         {
            headerName: "Start Date", field: "startDate", dataTransform: o => moment.utc(o.startDate).format('LL')
         },
         {
            headerName: "End Date", field: "end", dataTransform: o => o.endDate ? moment.utc(o.endDate).format('LL') : "None"
         },
      ],
      rowActions: [
         {
            icon: ellipsish, items: [
               { displayName: "Delete", action: openCarrierPointToPointRateDateSpanDelete },
            ]
         }
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: (carrierPointToPointRateDateSpanLoading || carrierPointToPointRateDateSpanError) ? [] : carrierPointToPointRateDateSpanData.carrierPointToPointRateDateSpanGetAll,
      dataKeyColumn: "carrierPointToPointRateDateSpanKey",
      clickAction: openUpdateCarrierPointToPointRateDateSpanModal
   }

   const navigateToEditCarrier = (carrierKey: number) => {
      navigate(`/carriers/edit/${carrierKey}`)
   }

   const updateRateBand = (key) => {
      navigate(`/carriers/rate/${key}`)
   }

   return (
      <GeneralPage title="Carriers" headerContent={
         <Button clickAction={() => { navigateToEditCarrier(data.carrierGetSingle.carrierKey) }}>Edit Carrier</Button>
      }>
         <Tile>
            <div className="row">
               <div className="col-md-6">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>{data.carrierGetSingle.name}</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <h5>{data.carrierGetSingle.address1}{data.carrierGetSingle.address2 && <span><br />{data.carrierGetSingle.address2}</span>}</h5>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <h5>{data.carrierGetSingle.city}, {data.carrierGetSingle.state} {data.carrierGetSingle.zipCode}</h5>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-6">
                        <h5><strong><Phone /></strong> {data.carrierGetSingle.phone}</h5>
                     </div>
                     {data.carrierGetSingle.fax && <div className="col-md-6">
                        <h5><strong><Printer /></strong> {data.carrierGetSingle.fax}</h5>
                     </div>}
                  </div>
                  {data.carrierGetSingle.email && <div className="row">
                     <div className="col-md-12">
                        <h5><Mail /> {data.carrierGetSingle.email}</h5>
                     </div>
                  </div>}
               </div>
            </div>
         </Tile>
         <div className="row">
            <div className="col-md-6">
               <Tile title="Contacts" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingCreateModal(true)}><Plus /></Button>} headerButtonCondition={(contactData.contactGetAll && contactData.contactGetAll.length > 0)}>

                  <EmptyState text="You don't have any contacts linked to this carrier yet. Why don't you create one?"
                     data={contactData.contactGetAll} buttonText="Create Contact" buttonAction={() => setShowingCreateModal(true)}></EmptyState>


                  {contactData.contactGetAll.map(contact => {
                     return <ContactTile key={contact.contactKey} firstName={contact.firstName} lastName={contact.lastName} email={contact.email} phone={contact.phone}
                        default={contact.default} cellPhone={contact.cellPhone} homePhone={contact.homePhone} editAction={() => { setCurrentlyEditingContact(contact) }} removeAction={() => { setCurrentlyDeletingContact(contact as Contact) }}></ContactTile>
                  })}
               </Tile>
            </div>
            <div className="col-md-6">
               <FuelSurchargeTile carrierKey={carrierKey}></FuelSurchargeTile>
               {data && data.carrierGetSingle.carrierType === CarrierType.Mileage && <Tile title="Rates" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingCreateCarrierRate(true)}><Plus /></Button>} headerButtonCondition={(carrierRateData.carrierRateGetAll && carrierRateData.carrierRateGetAll.edges.length > 0)}>

                  <EmptyState text="You don't have any rates linked to this carrier yet. Why don't you create one?"
                     data={carrierRateData.carrierRateGetAll.edges.map(r => r.node)} buttonText="Create Rate" buttonAction={() => setShowingCreateCarrierRate(true)}>
                     {carrierRateData.carrierRateGetAll.edges.length > 0 && <GridBase gridOptions={carrierRateGridOptions} />}
                     {!showAll && <Button buttonType={ButtonType.Secondary} clickAction={showAllRates}>Show All</Button>}
                  </EmptyState>

               </Tile>}

               {data && data.carrierGetSingle.carrierType === CarrierType.PointToPoint &&
                  <Tile title="Rates"
                     headerButton={<Button buttonType={ButtonType.TightPrimary}
                        clickAction={() => setShowingCreatePointToPointCarrierRateDateSpan(true)}><Plus /></Button>}
                     headerButtonCondition={(carrierPointToPointRateDateSpanData.carrierPointToPointRateDateSpanGetAll && carrierPointToPointRateDateSpanData.carrierPointToPointRateDateSpanGetAll.length > 0)}>

                     <EmptyState text="You don't have any rates linked to this carrier yet. Why don't you create one?"
                        data={carrierPointToPointRateDateSpanData.carrierPointToPointRateDateSpanGetAll} buttonText="Create Rate" buttonAction={() => setShowingCreatePointToPointCarrierRateDateSpan(true)}></EmptyState>
                     {carrierPointToPointRateDateSpanData.carrierPointToPointRateDateSpanGetAll.length > 0 && <GridBase gridOptions={carrierPointToPointRateDateSpanGridOptions} />}

                  </Tile>}
            </div>
         </div>
         {isShowingCreateModal && <CreateContactModal closeModal={closeCreateModal} carrierKey={carrierKey} callback={refetchContacts} />}
         {isShowingCreateCarrierRate && <CreateCarrierRateModal closeModal={closeCreateCarrierRateModal} carrierKey={carrierKey} callback={rateRefetch} />}
         {isShowingCreatePointToPointCarrierRateDateSpan && <CreatePointToPointCarrierRateDateSpanModal closeModal={closeCreateCarrierPointToPointRateDateSpanModal} carrierKey={carrierKey} callback={pointToPointRateDateSpanRefetch} />}
         {currentlyEditingContact && <EditContactModal closeModal={closeEditModal} carrierKey={carrierKey} callback={refetchContacts} contact={currentlyEditingContact} />}
         {updateCarrierRateKey && <UpdateCarrierRateModal closeModal={closeUpdateCarrierRateModal} delete={openCarrierRateDelete} carrierRateKey={updateCarrierRateKey} callback={rateRefetch} updateRateBand={updateRateBand} />}
         {updateCarrierPointToPointRateDateSpanKey &&
            <UpdatePointToPointCarrierRateDateSpanModal
               closeModal={closeUpdateCarrierPointToPointRateDateSpanModal}
               delete={openCarrierPointToPointRateDateSpanDelete}
               carrierPointToPointRateDateSpanKey={updateCarrierPointToPointRateDateSpanKey}
               callback={refetchPointToPointRateDateSpans} />}

         {currentlyDeletingContact && <Modal headerText="Delete Contact?">
            <p>Once you delete this contact, it will not be able to be reversed.</p>
            <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmContactDelete(currentlyDeletingContact.contactKey)}>Delete</button>
         </Modal>}

         {currentlyDeletingRate && <Modal headerText="Delete Rate?"
            footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
            footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmRateDelete(currentlyDeletingRate)}>Delete</Button>}>
            <p>Once you delete this Carrier Rate, it will not be able to be reversed.</p>
         </Modal>}

         {currentlyDeletingPointToPointRateDateSpan && <Modal headerText="Delete Rate Date Span?"
            footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
            footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmDateSpanDelete(currentlyDeletingPointToPointRateDateSpan)}>Delete</Button>}>
            <p>Once you delete these rates, it will not be able to be reversed.</p>
         </Modal>}
      </GeneralPage>
   )
}
export default CarrierShow