import React, { Component } from 'react'
import { DefaultInputProps } from './HookedTextArea'
import styles from './Input.module.scss'
import InputWrapper from './InputWrapper';

interface Props extends DefaultInputProps<string> {
    isPassword?: boolean
    onChange?(event: any)
    value?: any
    disabled?: boolean
}
interface State {
    
}

export default class HookedTextInput extends Component<Props, State> {
    render() {
        const currentError = this.props.errors[this.props.propertyKey];

        return (
            <InputWrapper<string> {...this.props}>
                <input
                    type={this.props.isPassword ? "password" : "input"}
                    onClick={this.props.onClick && this.props.onClick}
                    className={`${styles.input} ${currentError ? styles.input__error : ''}`}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    ref={this.props.register}
                    name={this.props.propertyKey} 
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}/>
            </InputWrapper>

        )
    }
}
