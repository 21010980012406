import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import { InnerDeliveryTimeFragment, useGetDeliveryTimeLazyQuery, useDeliveryTimeSearchQuery, DeliveryTimeSortInput, SortEnumType } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'

interface Props extends DefaultInputProps<InnerDeliveryTimeFragment> {
    onSelect?(deliveryTime: InnerDeliveryTimeFragment)
    prefilledDeliveryTimeKey?: number
}

export default function DeliveryTimeSearch(props: Props): ReactElement {

    const [executeGetDeliveryTime, { data, loading }] = useGetDeliveryTimeLazyQuery({ variables: { deliveryTimeKey: props.prefilledDeliveryTimeKey } })

    const { prefilledDeliveryTimeKey } = props

    useEffect(() => {
        if (prefilledDeliveryTimeKey) {
            executeGetDeliveryTime()
        }
    }, [prefilledDeliveryTimeKey, executeGetDeliveryTime])

    const prefilledData = data && data.deliveryTimeGetSingle
    const prefilledDataDisplay = data && `${data.deliveryTimeGetSingle.code}-${data.deliveryTimeGetSingle.description}`

    return <TextSearchDropdown<InnerDeliveryTimeFragment>
        mapEntityToKey={e => parseInt(e.deliveryTimeKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        {...props}
    />
}

export const DeliveryTimeQuery = gql`
    fragment innerDeliveryTime on DeliveryTime {
        deliveryTimeKey
        code
        description
    }

    query DeliveryTimeSearch($searchTerm: String, $sort: [DeliveryTimeSortInput!]) {
        deliveryTimeGetAll(where: { or: [ { code: {contains: $searchTerm }}, { description: {contains: $searchTerm }}]}, order: $sort) {
            ...innerDeliveryTime
        }
    }

    query GetDeliveryTime($deliveryTimeKey: Int!) {
        deliveryTimeGetSingle(deliveryTimeKey: $deliveryTimeKey) {
            ...innerDeliveryTime
        }
    }
`

const Inner = (props: InnerSearchLogicProps<InnerDeliveryTimeFragment>) => {
    const sort: DeliveryTimeSortInput = { code: SortEnumType.Asc }

    const { data, loading, error } = useDeliveryTimeSearchQuery({ variables: { searchTerm: props.searchTerm, sort } })

    const showData = !loading && !error

    const results = showData &&
        data.deliveryTimeGetAll.map(dt => {
            const display = `${dt.code}-${dt.description}`
            return { key: dt.deliveryTimeKey, object: dt, display }
        })

    const displayProps: SearchDisplayProps<InnerDeliveryTimeFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Delivery Time", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}