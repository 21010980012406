import * as yup from 'yup'
import React, { useState } from 'react'
import Button, { ButtonType } from '../../../components/General/Button/Button'
import { useGetSinglePointToPointCarrierRateDateSpanQuery, CarrierPointToPointRate, useDeleteCarrierPointToPointRateMutation } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import useForm from 'react-hook-form'
import FormPage from '../../../components/General/FormPage/FormPage'
import FormTile from '../../../components/Tiles/FormTile/FormTile'
import { Params, useNavigate, useParams } from 'react-router-dom'
import GridBase, { GridOptions } from '../../../components/General/GridBase/GridBase'
import Tile from '../../../components/General/Tile/Tile'
import EmptyState from '../../../components/General/Tile/EmptyState/EmptyState'
import { Plus } from 'react-feather'
import CreatePointToPointCarrierRateModal from '../../../components/AppliedModals/CarrierRate/CreatePointToPointCarrierRateModal'
import UpdatePointToPointCarrierRateModal from '../../../components/AppliedModals/CarrierRate/UpdatePointToPointCarrierRateModal'
import Modal from '../../../components/Modal/Modal'
import HookedDateTime from '../../../components/General/Inputs/HookedDateTime'
import moment from 'moment'

interface MatchParams extends Params {
    carrierPointToPointRateDateSpanKey
}

interface CarrierRateProps {
}


const UpdateCarrierPointToPointRates = (props: CarrierRateProps) => {
    const navigate = useNavigate()

    const { carrierPointToPointRateDateSpanKey: key } = useParams() as MatchParams

    const carrierPointToPointRateDateSpanKey = parseInt(key)

    const { data, loading, error, refetch } = useGetSinglePointToPointCarrierRateDateSpanQuery({ variables: { key: carrierPointToPointRateDateSpanKey } })
    const [deleteCarrierPointToPointRateMutation] = useDeleteCarrierPointToPointRateMutation()

    const [isShowingCreatePointToPointCarrierRate, setShowingCreatePointToPointCarrierRate] = useState(false)
    const [updateCarrierPointToPointRateKey, setUpdateCarrierPointToPointRateKey] = useState<number>(undefined)
    const [currentlyDeletingPointToPointRate, setCurrentlyDeletingPointToPointRate] = useState<number>(null)

    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)

    const closeCreateCarrierPointToPointRateModal = () => setShowingCreatePointToPointCarrierRate(false)
    const closeUpdateCarrierPointToPointRateModal = () => setUpdateCarrierPointToPointRateKey(undefined)

    const openCarrierPointToPointRateDelete = (carrierRateKey: number) => {
        setCurrentlyDeletingPointToPointRate(carrierRateKey)
    }

    const cancelDelete = () => {
        setCurrentlyDeletingPointToPointRate(null)
    }

    const validationSchema = yup.object().shape({
        carrierRateBand: yup.object().shape({
            mileageFrom: yup.number().required(),
            mileageTo: yup.number().required(),
            ratePerGallon: yup.number().required()
        })
    })

    function compareRates(a, b) {
        // Use toUpperCase() to ignore character casing
        const cityA = a.destinationCity.name.toUpperCase()
        const cityB = b.destinationCity.name.toUpperCase()

        let comparison = 0
        if (cityA > cityB) {
            comparison = 1
        } else {
            comparison = -1
        }
        return comparison
    }

    const openUpdateCarrierPointToPointRateModal = (line) => {
        setUpdateCarrierPointToPointRateKey(line["carrierPointToPointRateKey"])
    }

    const confirmPointToPointRateDelete = (rateKey: number) => {
        deleteCarrierPointToPointRateMutation({ variables: { id: rateKey } })
            .then(() => {
                refetch()
                setCurrentlyDeletingPointToPointRate(null)
                setUpdateCarrierPointToPointRateKey(undefined)
            })
    }

    const carrierPointToPointRateGridOptions: GridOptions<CarrierPointToPointRate> = {
        columnDefs: [
            {
                headerName: "Destination", field: "endDate", dataTransform: o => `${o.destinationCity.name}, ${o.destinationCity.state.name}`
            },
            { headerName: "Miles", field: "miles" },
            { headerName: "Tolls", field: "tolls" },
            { headerName: "Rate", field: "rate", dataTransform: o => o.rate.toFixed(4) }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.carrierPointToPointRateDateSpanGetSingle.carrierPointToPointRates.sort(compareRates),
        groupBy: o => o.originCity.name,
        groupByHeaderText: o => { return `Origin: ${o.originCity.name}` },
        dataKeyColumn: "carrierPointToPointRateKey",
        clickAction: openUpdateCarrierPointToPointRateModal
    }

    const navigateToCarrier = () => {
        if (data) {
            navigate(`/carriers/show/${data.carrierPointToPointRateDateSpanGetSingle.carrierKey}`)
        }
    }

    const { register, errors, setValue } = useForm<CarrierPointToPointRate>({ validationSchema })
    const commonProps = { register, errors, setValue }


    if (loading || error) {
        return <FormPage title="Carrier Point to Point Rates" />
    }

    if (startDate === undefined && data) {
        setStartDate(new Date(moment.utc(data.carrierPointToPointRateDateSpanGetSingle.startDate).format('LL')))
    }

    if (endDate === undefined && data && data.carrierPointToPointRateDateSpanGetSingle.endDate) {
        setEndDate(new Date(moment.utc(data.carrierPointToPointRateDateSpanGetSingle.endDate).format('LL')))
    }

    return (
        <FormPage title={`${data.carrierPointToPointRateDateSpanGetSingle.carrier.name} Point to Point Rates`} headerContent={
            <Button clickAction={navigateToCarrier}>Back to Carrier</Button>
        }>
            <FormTile title="Dates">
                <div className="row">
                    <div className="col-md-6">
                        <HookedDateTime
                            label="Start Date"
                            propertyKey={propName<CarrierPointToPointRate>(o => o.carrierPointToPointRateDateSpan.startDate)}
                            defaultValue={startDate}
                            disabled
                            {...commonProps}
                        />
                    </div>
                    {(endDate) &&
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<CarrierPointToPointRate>(o => o.carrierPointToPointRateDateSpan.endDate)}
                                defaultValue={endDate}
                                disabled
                                {...commonProps}
                            />
                        </div>}
                </div>
            </FormTile>
            {data &&
                <Tile title="Rates"
                    headerButton={<Button buttonType={ButtonType.TightPrimary}
                        clickAction={() => setShowingCreatePointToPointCarrierRate(true)}><Plus /></Button>}
                    headerButtonCondition={(data.carrierPointToPointRateDateSpanGetSingle
                        && data.carrierPointToPointRateDateSpanGetSingle.carrierPointToPointRates
                        && data.carrierPointToPointRateDateSpanGetSingle.carrierPointToPointRates.length > 0)}>

                    <EmptyState text="You don't have any rates linked to this carrier yet. Why don't you create one?"
                        data={data.carrierPointToPointRateDateSpanGetSingle.carrierPointToPointRates} buttonText="Create Rate" buttonAction={() => setShowingCreatePointToPointCarrierRate(true)}></EmptyState>
                    {data.carrierPointToPointRateDateSpanGetSingle.carrierPointToPointRates.length > 0 && <GridBase gridOptions={carrierPointToPointRateGridOptions} />}

                </Tile>}
            {isShowingCreatePointToPointCarrierRate && <CreatePointToPointCarrierRateModal closeModal={closeCreateCarrierPointToPointRateModal} carrierPointToPointDateSpanKey={carrierPointToPointRateDateSpanKey} callback={refetch} />}
            {updateCarrierPointToPointRateKey && <UpdatePointToPointCarrierRateModal closeModal={closeUpdateCarrierPointToPointRateModal} delete={openCarrierPointToPointRateDelete} carrierRateKey={updateCarrierPointToPointRateKey} callback={refetch} />}
            {currentlyDeletingPointToPointRate && <Modal headerText="Delete Rate?"
                footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmPointToPointRateDelete(currentlyDeletingPointToPointRate)}>Delete</Button>}>
                <p>Once you delete this Carrier Rate, it will not be able to be reversed.</p>
            </Modal>}
        </FormPage>
    )
}

export default UpdateCarrierPointToPointRates