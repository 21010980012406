import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { BasicBasisTypeFragment, useSearchBasisTypesQuery, useGetSingleBasisTypeLazyQuery } from '../../../generated/graphql'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'

interface Props extends DefaultInputProps<BasicBasisTypeFragment> {
    onSelect?(basisType: BasicBasisTypeFragment)
    prefilledBasisTypeKey?: number
    prefilledBasisType?: BasicBasisTypeFragment
}

export default function BasisTypeSearch(props: Props): ReactElement {

    const [executeGetBasisType, { data, loading }] = useGetSingleBasisTypeLazyQuery({ variables: { id: props.prefilledBasisTypeKey } })

    const { prefilledBasisTypeKey } = props

    useEffect(() => {
        if (prefilledBasisTypeKey) {
            executeGetBasisType()
        }
    }, [prefilledBasisTypeKey, executeGetBasisType])

    const prefilledData = data && data.basisTypeGetSingle
    const prefilledDataDisplay = data && `${data.basisTypeGetSingle.name}`

    return <TextSearchDropdown<BasicBasisTypeFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.basisTypeKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}

        {...props}
    />
}

export const SearchBasisTypes = gql`
    fragment BasicBasisType on BasisType {
        basisTypeKey
        name
    }

    query SearchBasisTypes($searchTerm: String) {
        basisTypeGetAll(where: { name: {contains: $searchTerm}, isArchived: {eq: false }}) {
           ...BasicBasisType
        }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicBasisTypeFragment>) => {
    const { data, loading, error } = useSearchBasisTypesQuery({ variables: { searchTerm: props.searchTerm } })

    const showData = !loading && !error

    const results = showData &&

        data.basisTypeGetAll.map(basisType => {
            const display = basisType.name
            return { key: basisType.basisTypeKey, object: basisType, display }
        })

    const displayProps: SearchDisplayProps<BasicBasisTypeFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Basis Types", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}