import React, { ReactElement, useState, useEffect } from 'react'
import { ContactMethod, useCreateTaskAndUpdateTaskMutation, CreateTaskAndUpdateTaskMutationVariables, useUpdateTaskMutation, UpdateTaskMutationVariables, useGetSingleTaskLazyQuery, BasicLocationFragment, CustomerGetAllEdge, useCreateTaskMutation, TaskInput, useGetCurrentUserQuery, Pricing, SortEnumType, PricingSortInput } from '../../generated/graphql'
import propName from '../../helpers/propName'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import Button, { ButtonType } from '../../components/General/Button/Button'
import useForm from 'react-hook-form'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import * as yup from "yup"
import gql from 'graphql-tag'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import FormPage from '../../components/General/FormPage/FormPage'

import FormTile from '../../components/Tiles/FormTile/FormTile'
import PricingGrid from '../../components/Pricing/PricingGrid'
import CustomerSearch from '../../components/Search/CustomerSearch/CustomerSearch'
import UserSearch from '../../components/Search/UserSearch/UserSearch'
import Modal from '../../components/Modal/Modal'
import TaskHistoryTile from '../../components/Tiles/TaskHistoryTile/TaskHistoryTile'
import { Mail, Phone, Smartphone, Home, Star } from 'react-feather'
import StickyHeader from '../../components/General/StickyHeader/StickyHeader'
import OrderReferenceTile from '../../components/Tiles/OrderReferenceTile/OrderReferenceTile'
import TaskContactSearch from '../../components/Search/CustomerSearch/TaskContactSearch'
import { Params, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ControlledLocationSearch from '../../components/Search/LocationSearch/ControlledLocationSearch'

export const GetTaskQuery = gql`
    fragment TaskShow on Task {
        contact {
            contactKey
            firstName
            lastName
        }
        location {
            customerKey
            locationKey
            name
        }
        user {
            id
            email
            name
        }
        taskKey
        isComplete
        taskDate
        callDetails
        contactMethod
        completedDate
        locationKey
        
    }
    query GetSingleTask($taskKey: Int!) {
        taskGetSingle(id: $taskKey) {
            ...TaskShow
        }
    }
`

export const CreateActivityAndTaskMutation = gql`
    query GetCompletedTasksForLocation($locationKey: Int!, $date: DateTime) {
        taskGetAll(where: { and: [{ isComplete: { eq: true }}, { locationKey: {eq: $locationKey }}, {taskDate: {gt: $date}}] }) {
            ...TaskShow
        }
    }

    mutation UpdateTask($task: TaskInput!, $taskKey: Int!) {
        taskUpdate(task: $task, taskKey: $taskKey) {
            taskKey
        }
    }

    mutation CreateTaskAndUpdateTask($newTask: TaskInput!, $task: TaskInput!, $currentTaskKey: Int!) {
        taskUpdate(task: $task, taskKey: $currentTaskKey) {
            taskKey
        }

        taskCreate(task: $newTask) {
            taskKey
        }
    }
`

export const DeleteTaskMutation = gql`
   mutation DeleteTask($id: Int!) {
      taskDelete(id: $id)
   }
`

interface MatchParams extends Params {
    taskKey: string
}

interface Props {
}

// TODO: This form should also complete the current task
export default function ActivityCreateDisplay(props: Props): ReactElement {
    const navigate = useNavigate()

    const { taskKey: key } = useParams() as MatchParams

    const [searchParams,] = useSearchParams()

    const l = searchParams.get('location')
    const c = searchParams.get('customer')

    const [pricingSort, setPricingSort] = useState<PricingSortInput>({ primaryCarrierDelivered: SortEnumType.Asc })
    const [locationKey, setLocationKey] = useState(l ? parseInt(l) : undefined)
    const [customerKey, setCustomerKey] = useState(c ? parseInt(c) : undefined)
    const [contactKey, setContactKey] = useState<number>()

    const [createOrder, setCreateOrder] = useState(undefined)
    const [phone, setPhone] = useState(undefined)
    const [mobilePhone, setMobilePhone] = useState(undefined)
    const [homePhone, setHomePhone] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [defaultContact, setDefaultContact] = useState(false)
    const [salesAgreement, setSalesAgreement] = useState(undefined)
    const [salesAgreementModal, setSalesAgreementModal] = useState(undefined)
    const [contactMethod, setContactMethod] = useState(undefined)
    const [showStickyHeader, setShowStickyHeader] = useState(false)
    const [nextContactIsSet, setNextContactIsSet] = useState(false)

    const [selectDefaultContact, setSelectDefaultContact] = useState(true)

    const { data: currentUserData } = useGetCurrentUserQuery()
    const [callDetails, setCallDetails] = useState("")

    const callDetailsInput: React.RefObject<HTMLInputElement> = React.createRef()

    // Load task data for designated task in url params
    const taskKey = parseInt(key)
    const [executeGetTask, { data: taskData, loading: taskLoading, error: taskError }] = useGetSingleTaskLazyQuery({ variables: { taskKey } })

    useEffect(() => {
        if (taskKey !== 0) {
            executeGetTask()
        }
    }, [taskKey, executeGetTask])

    const navigateHome = () => {
        navigate("/")
    }

    const [createAndUpdateTasks] = useCreateTaskAndUpdateTaskMutation()
    const [updateTask] = useUpdateTaskMutation()
    const [createAndCloseTask] = useCreateTaskMutation()

    const totalSchemaShape = {
        newTask: yup.object().shape({
            taskDate: yup.date().required(),
            userKey: yup.number().required()
        }),
        task: yup.object().shape({
            locationKey: yup.number().required(),
            contactKey: yup.number().required(),
            callDetails: yup.string()
        })
    }

    const validationSchema = yup.object().shape({
        newTask: totalSchemaShape.newTask,
        task: totalSchemaShape.task
    })

    const createAndUpdateTaskUseForm = useForm<CreateTaskAndUpdateTaskMutationVariables>({ validationSchema })

    const updateOnlySchema = yup.object().shape({
        task: totalSchemaShape.task
    })

    const updateTaskOnlyUseForm = useForm<UpdateTaskMutationVariables>({ validationSchema: updateOnlySchema })

    useEffect(() => {
        if (taskData) {
            setLocationKey(taskData.taskGetSingle.locationKey)
            setCustomerKey(taskData.taskGetSingle.location.customerKey)
            setContactKey(taskData.taskGetSingle.contact.contactKey)
        }
    }, [taskData, setCustomerKey, setLocationKey])

    // Setup state to display the new task option
    const [isCreatingNewTask, setIsCreatingNewTask] = useState(true)


    if (taskLoading) { return null }
    if (taskError) { return <p>Error</p> }

    const taskDate = taskData ? taskData.taskGetSingle.taskDate : new Date()

    // // Toggles the state and prevents default so the form isn't submitted by
    // // pressing the button
    const toggleNextContact = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsCreatingNewTask(!isCreatingNewTask)
        e.preventDefault()
    }

    const onLocationSelect = (location: BasicLocationFragment) => {
        setLocationKey(location ? location.locationKey : undefined)
        if (location) {
            setCustomerKey(location.customer.customerKey)
            if (locationKey !== location.locationKey) {
                setContactKey(undefined)
            }
            //if they're selecting a new location, auto pick the default contact
            setSelectDefaultContact(true)
        }
        else if (location === undefined) {
            setContactKey(undefined)
        }
    }

    const onCustomerSelect = (customer: CustomerGetAllEdge) => {
        setCustomerKey(customer && customer.node ? customer.node.customerKey : undefined)
        if (customer === undefined) {
            setLocationKey(null)
        }
        else {
            //if they're selecting a new location, auto pick the default contact
            setSelectDefaultContact(true)
        }
    }

    const onContactSelect = (contact) => {
        //if the contact is being cleared
        if (contact === undefined) {
            setPhone(undefined)
            setEmail(undefined)
            setHomePhone(undefined)
            setMobilePhone(undefined)
            setDefaultContact(false)

            //if there was previously a contact selected
            //prevent it from selecting the default automatically
            if (contactKey) {
                setSelectDefaultContact(false)
            }
        }
        else if (contact !== undefined) {
            if (contact.phone !== undefined) {
                setPhone(contact.phone)
            }
            if (contact.email !== undefined) {
                setEmail(contact.email)
            }
            if (contact.cellPhone !== undefined) {
                setMobilePhone(contact.cellPhone)
            }
            if (contact.homePhone !== undefined) {
                setHomePhone(contact.homePhone)
            }
            if (contact.default !== undefined) {
                setDefaultContact(contact.default)
            }
        }
        setContactKey(contact ? contact.contactKey : undefined)
    }


    // Setup options for contact methods and call classifications
    // TODO: refactor this out into a method
    const contactMethods: SelectOption<ContactMethod>[] = Object.keys(ContactMethod).map(c => {
        return { key: ContactMethod[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })
    const defaultContactMethod = taskData ? contactMethods.find(c => c.key === taskData.taskGetSingle.contactMethod) : contactMethods[0]


    const createTask = (task: TaskInput) => {
        return createAndCloseTask({ variables: { task } })
    }

    // Option 1: Setup create task and update task mutation
    // This option is used when isCreatingNewTask is set to true, utilizing
    // the mutation to create both task and update the current task
    const submitCreateAndUpdateTask = (variables: CreateTaskAndUpdateTaskMutationVariables) => {
        const mergedVars: CreateTaskAndUpdateTaskMutationVariables = {
            newTask: { ...variables.newTask, contactMethod, isComplete: false, contactKey: variables.task.contactKey, locationKey },
            task: { ...variables.task, contactMethod, locationKey, userKey: currentUserData ? currentUserData.currentUser.id : 1, taskDate, isComplete: true, completedDate: new Date() },
            currentTaskKey: taskData ? taskData.taskGetSingle.taskKey : 0
        }

        if (taskKey === 0) {
            createTask(mergedVars.newTask)
                .then(_ => createTask(mergedVars.task))
                .then(_ => {
                    if (createOrder) {
                        navigate(`/orders/new?${createOrder}`)
                    }
                    else {
                        navigateHome()
                    }
                })

            return
        }

        createAndUpdateTasks({ variables: mergedVars })
            .then(() => {
                if (createOrder) {
                    navigate(`/orders/new?${createOrder}`)
                }
                else {
                    navigateHome()
                }
            })
    }

    // Option 2: Setup update task only
    // This option is used when the task is just to be completed, and 
    // no new task will be created. This option is chosen when
    // isCreatingNewTask state is set to false
    const submitUpdateTask = (variables: UpdateTaskMutationVariables) => {
        const mergedVars: UpdateTaskMutationVariables = {
            task: { ...variables.task, contactMethod, locationKey, userKey: currentUserData ? currentUserData.currentUser.id : 1, taskDate, isComplete: true, completedDate: new Date() },
            taskKey: taskData ? taskData.taskGetSingle.taskKey : 0
        }

        if (taskKey === 0) {
            createTask(mergedVars.task)
                .then(_ => {
                    if (createOrder) {
                        navigate(`/orders/new?${createOrder}`)
                    }
                    else {
                        navigateHome()
                    }
                })

            return
        }

        updateTask({ variables: mergedVars })
            .then(() => {
                if (createOrder) {
                    navigate(`/orders/new?${createOrder}`)
                }
                else {
                    navigateHome()
                }
            })
    }

    const updateAndCreateSubmit = createAndUpdateTaskUseForm.handleSubmit(submitCreateAndUpdateTask)
    const createOnlySubmit = updateTaskOnlyUseForm.handleSubmit(submitUpdateTask)

    // Choose activeForm depending on isCreatingNewTask, see above two options
    // for more details
    const activeForm = isCreatingNewTask ? createAndUpdateTaskUseForm : updateTaskOnlyUseForm

    // Tie together the final onSubmit for the form below
    const onSubmit = isCreatingNewTask ? updateAndCreateSubmit : createOnlySubmit

    const commonProps = { register: activeForm.register, errors: activeForm.errors, setValue: activeForm.setValue }

    // const removeTaskCallback = (task: Task) => {
    //     deleteMutation({ variables: { id: task.taskKey } })
    //         .then(() => refetchCompletedTasks());
    // }

    const contactMethodChanged = (value: ContactMethod) => {
        setContactMethod(value)
    }

    const orderClick = (line: Pricing) => {
        if (line.queryString.includes("salesAgreement") && line.supplierName === "Pick your supply") {
            const pricingKeyParam = line.queryString.split('&').length > 1 ? line.queryString.split('&')[1] : null
            if (pricingKeyParam === null) {
                return
            }
            const pricingKey = pricingKeyParam.split('=').length > 1 ? parseInt(pricingKeyParam.split('=')[1]) : null

            setSalesAgreement(pricingKey)
            setSalesAgreementModal(true)
        }
        else {
            if (salesAgreement) {
                setCreateOrder(`${line.queryString}&salesAgreement=${salesAgreement}`)
            }
            else {
                setCreateOrder(`${line.queryString}`)
            }
        }
    }

    const cancelCreateOrder = () => {
        setCreateOrder(undefined)
    }

    const clearSalesAgreement = () => {
        setSalesAgreement(undefined)
    }

    const onDetailChange = (res: any) => {
        setCallDetails(res.target.value)
    }

    const onNextContactDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setNextContactIsSet(true)
        }
        else {
            setNextContactIsSet(false)
        }
    }

    const onScrollFunc = (e: any) => {

        if (callDetailsInput.current) {
            let position = callDetailsInput.current.getBoundingClientRect()

            if (position.top < 0 && !showStickyHeader) {
                setShowStickyHeader(true)
            }
            else if (position.top > 0 && showStickyHeader) {
                setShowStickyHeader(false)
            }
        }
    }

    const goToLocation = () => {
        if (locationKey) {
            window.open(`/locations/show/${locationKey}`, "_blank")
        }
    }

    const footerLeftContent = (<Button clickAction={navigateHome} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Close Task</Button>)

    const modalFooterRight = (<>
        {isCreatingNewTask && !nextContactIsSet && <Button clickAction={_ => { setIsCreatingNewTask(false) }} buttonType={ButtonType.Primary}>Cancel Next Contact</Button>}
        <Button clickAction={onSubmit} buttonType={ButtonType.Success}>Close Task</Button>
    </>)
    const modalFooterLeft = (<Button clickAction={cancelCreateOrder} buttonType={ButtonType.Tertiary}>Cancel</Button>)

    return (
        <FormPage title="Update Task" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} fullWidth={true} onScroll={onScrollFunc} headerContent={locationKey && <Button buttonType={ButtonType.Secondary} clickAction={goToLocation}>Go to Location</Button>}>
            <form id="create-activity-form" onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormTile>
                            <div className="row">
                                <div className="col-md-12">
                                    <CustomerSearch
                                        propertyKey={"CustomerSearch"}
                                        label="Customer"
                                        onSelect={onCustomerSelect}
                                        prefilledCustomerKey={customerKey}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <ControlledLocationSearch
                                        propertyKey={propName<UpdateTaskMutationVariables>(o => o.task.locationKey)}
                                        label="Location"
                                        onSelect={onLocationSelect}
                                        locationKey={locationKey}
                                        customerKey={customerKey}
                                        prefillIfOnlyLocation={true}
                                        showCustomer={!customerKey}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <TaskContactSearch
                                        label="Contact"
                                        propertyKey={propName<UpdateTaskMutationVariables>(o => o.task.contactKey)}
                                        selectedContactKey={contactKey}
                                        locationKey={locationKey}
                                        selectFirst={selectDefaultContact}
                                        selectDefault={selectDefaultContact}
                                        onSelect={onContactSelect}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <h5>{defaultContact && <Star />}{phone && <span><Phone /> {phone}</span>}{mobilePhone && <span> <Smartphone /> {mobilePhone}</span>} {homePhone && <span> <Home /> {homePhone}</span>}</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5>{email && <span><Mail /> {email}</span>}</h5>
                                </div>

                                <div className="col-md-12">
                                    <HookedSelectOptions
                                        options={contactMethods}
                                        label="Contact Method"
                                        propertyKey={propName<UpdateTaskMutationVariables>(o => o.task.contactMethod)}
                                        setDefaultValue={defaultContactMethod}
                                        onChange={contactMethodChanged}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-12" ref={callDetailsInput}>
                                    <HookedTextArea
                                        label="Details"
                                        propertyKey={propName<UpdateTaskMutationVariables>(o => o.task.callDetails)}
                                        value={callDetails}
                                        onChange={onDetailChange}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </FormTile>


                        <FormTile title="Next Contact">
                            <div className="row">
                                {isCreatingNewTask && <><div className="col-md-12">
                                    <HookedDateTime
                                        showTimeSelect={true}
                                        setValue={activeForm.setValue}
                                        register={activeForm.register}
                                        propertyKey={propName<CreateTaskAndUpdateTaskMutationVariables>(o => o.newTask.taskDate)}
                                        label="Next Task Date and Time" errors={activeForm.errors}
                                        Change={onNextContactDateChange} />
                                </div>
                                    <div className="col-md-12">
                                        <UserSearch
                                            label="User"
                                            propertyKey={propName<CreateTaskAndUpdateTaskMutationVariables>(o => o.newTask.userKey)}
                                            prefilledUserKey={taskData ? taskData.taskGetSingle.user.id : currentUserData ? currentUserData.currentUser.id : undefined}
                                            {...commonProps}
                                        />
                                    </div></>}
                                <div className="col-md-12">
                                    <Button buttonType={ButtonType.Secondary} clickAction={toggleNextContact}>{isCreatingNewTask ? "Cancel Next Contact" : "+ Next Contact"}</Button>
                                </div>
                            </div>
                        </FormTile>
                    </div>
                    <div className="col-md-6">
                        {locationKey && <TaskHistoryTile locationKey={locationKey} showDelete={true} limitHeight={true}></TaskHistoryTile>}
                        {locationKey && <OrderReferenceTile locationKey={locationKey} />}
                    </div>
                </div>

            </form>
            {locationKey &&
                <PricingGrid
                    locationKey={locationKey}
                    newSort={pricingSort}
                    sortPricing={setPricingSort}
                    click={orderClick}
                    salesAgreementSelected={salesAgreement !== undefined}
                    clearSalesAgreement={clearSalesAgreement}
                />}
            {createOrder && <Modal headerText="Close Task?" footerRightContent={modalFooterRight} footerLeftContent={modalFooterLeft} noScroll>
                {activeForm.errors["newTask.taskDate"] && <p style={{ color: "red" }}>Please fill out the next contact date or cancel the next contact</p>}

                <p>Are you sure you are ready to close this task and create an order?</p>
            </Modal>}
            {salesAgreementModal && <Modal footerLeftContent={<Button clickAction={_ => { setSalesAgreementModal(undefined) }} buttonType={ButtonType.Primary}>Ok</Button>}>
                Sales Agreement Selected
            </Modal>}

            <StickyHeader show={showStickyHeader}>
                <div className="col-md-12">
                    <HookedTextArea
                        label="Details"
                        propertyKey={propName<UpdateTaskMutationVariables>(o => o.task.callDetails)}
                        onChange={onDetailChange}
                        value={callDetails}
                        shortMobile={true}
                        narrowMobile={true}
                        {...commonProps}
                    />
                </div>
            </StickyHeader>

        </FormPage>
    )
}