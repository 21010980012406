import React, { ReactElement } from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { useCreateIndexContractMutation, CreateIndexContractMutationVariables, SupplierType, ContractPeriod, ContractType } from '../../../generated/graphql'

import useForm from 'react-hook-form'
import HookedTextInput from '../../../components/General/Inputs/HookedTextInput'
import propName from '../../../helpers/propName'
import TerminalSearch from '../../../components/Search/TerminalSearch/TerminalSearch'
import SupplierSearch from '../../../components/Search/SupplierSearch/SupplierSearch'
import HookedDateTime from '../../../components/General/Inputs/HookedDateTime'
import BasisTypeSearch from '../../../components/Search/BasisTypeSearch/BasisTypeSearch'
import FormPage from '../../../components/General/FormPage/FormPage'

import styles from './CreateIndexContract.module.scss'
import { SelectOption } from '../../../components/General/SelectOptions/SelectOptions'
import HookedTextArea from '../../../components/General/Inputs/HookedTextArea'
import HookedSelectOptions from '../../../components/General/Inputs/HookedSelectOptions'
import HookedCheckbox from '../../../components/General/Inputs/HookedCheckbox'
import FormTile from '../../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../../components/General/Button/Button'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'


interface Props {

}

export const CreateIndexContractQuery = gql`
    mutation CreateIndexContract($indexContract: IndexContractInput!) {
        indexContractCreate(indexContract: $indexContract) {
            indexContractKey
        }
    }
`

export default function CreateIndexContract(props: Props): ReactElement {
    const navigate = useNavigate()
    const [searchParams,] = useSearchParams()

    const [createIndexContract] = useCreateIndexContractMutation()
    const [startDate, setStartDate] = useState<Date>(new Date())
    let oneYearFromStart = moment().startOf('day').add(1, 'year').toDate()

    const [endDate, setEndDate] = useState<Date>(oneYearFromStart)

    const supplierKey = searchParams.get("supplierKey") ? parseInt(searchParams.get("supplierKey")) : 0

    const submit = (variables: CreateIndexContractMutationVariables) => {

        variables.indexContract.contractType = ContractType.DayOf

        createIndexContract({ variables })
            .then(r => navigate(`/pricing/index-contracts/edit/${r.data.indexContractCreate.indexContractKey}`))
    }

    const validationSchema = yup.object().shape({
        indexContract: yup.object().shape({
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required(),
            basisTypeKey: yup.number().required(),

            effectiveDate: yup.date().required(),

            effectiveEndDate: yup.date().required().min(yup.ref('effectiveDate'), 'End date must be after start date'),
            accountInfo: yup.string().notRequired(),

            januaryIndex: yup.number().required(),
            februaryIndex: yup.number().required(),
            marchIndex: yup.number().required(),
            aprilIndex: yup.number().required(),
            mayIndex: yup.number().required(),
            juneIndex: yup.number().required(),
            julyIndex: yup.number().required(),
            augustIndex: yup.number().required(),
            septemberIndex: yup.number().required(),
            octoberIndex: yup.number().required(),
            novemberIndex: yup.number().required(),
            decemberIndex: yup.number().required(),

            dayPrior: yup.boolean().required(),

            contractPeriod: yup.string().required(),
            supplierType: yup.string().required(),

            maximumGallons: yup.number().notRequired(),
            maximumLoads: yup.number().notRequired(),

            minimumGallons: yup.number().notRequired(),
            minimumLoads: yup.number().notRequired(),

            isIndication: yup.boolean().required()

        })
    })



    const { register, handleSubmit, errors, setValue } = useForm<CreateIndexContractMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)

    const commonProps = { register, errors, setValue }

    const supplierTypes: SelectOption<SupplierType>[] = Object.keys(SupplierType).map(c => {
        return { key: SupplierType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const contractPeriods: SelectOption<ContractPeriod>[] = Object.keys(ContractPeriod).map(c => {
        return { key: ContractPeriod[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const navigateToList = () => {
        navigate("/pricing/index-contracts")
    }

    const startDateChanged = (key, val) => {
        setStartDate(val)
        //when creating a new index contract, I figure it would be nice for the end date to update based on a change to the start date. End date can then be adjusted manually.
        setEndDate(moment(val).add(1, 'year').toDate())
        setValue(key, val)
    }

    const endDateChanged = (key, val) => {
        setEndDate(val)
        setValue(key, val)
    }

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Index Contract</Button>)

    return (
        <FormPage title="Create Index Contract" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <div className={styles.wrapper}>
                    <div>
                        <FormTile>
                            <div className="row">
                                <div className="col-md-6">

                                    <TerminalSearch
                                        label="Terminal"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.terminalKey)}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-6">

                                    <SupplierSearch
                                        label="Supplier"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.supplierKey)}
                                        prefilledSupplierKey={supplierKey !== 0 ? supplierKey : undefined}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <BasisTypeSearch
                                        label="Basis Type"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.basisTypeKey)}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-4">

                                    <HookedDateTime
                                        label="Effective Start Date"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.effectiveDate)}
                                        defaultValue={startDate}
                                        setValue={startDateChanged}
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-md-4">

                                    <HookedDateTime
                                        label="Effective End Date"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.effectiveEndDate)}
                                        defaultValue={endDate}
                                        setValue={endDateChanged}
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <HookedTextArea
                                        label="Account Info"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.accountInfo)}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </FormTile>
                        <FormTile>
                            <div className="row">
                                <div className="col-md-12">
                                    <HookedSelectOptions
                                        options={supplierTypes}
                                        label="Supplier Type"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.supplierType)}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <HookedSelectOptions
                                        options={contractPeriods}
                                        label="Period"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.contractPeriod)}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedCheckbox
                                        label="Day Prior"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.dayPrior)}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedCheckbox
                                        label="Indication"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.isIndication)}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Minimum Loads"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.minimumLoads)}
                                        defaultValue={"0"}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Maximum Loads"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.maximumLoads)}
                                        defaultValue={"0"}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Minimum Gallons"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.minimumGallons)}
                                        defaultValue={"0"}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Maximum Gallons"
                                        propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.maximumGallons)}
                                        defaultValue={"0"}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </FormTile>
                    </div>
                    <div className={styles.pricingInputs}>
                        <HookedTextInput
                            label="Jan"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.januaryIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Feb"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.februaryIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Mar"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.marchIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Apr"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.aprilIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="May"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.mayIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Jun"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.juneIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />
                        <HookedTextInput
                            label="Jul"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.julyIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Aug"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.augustIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Sep"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.septemberIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />
                        <HookedTextInput
                            label="Oct"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.octoberIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Nov"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.novemberIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Dec"
                            propertyKey={propName<CreateIndexContractMutationVariables>(o => o.indexContract.decemberIndex)}
                            defaultValue={"0"}
                            {...commonProps}
                        />
                    </div>

                </div>
            </form>

        </FormPage>
    )
}
