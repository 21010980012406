import gql from "graphql-tag"
import useForm from "react-hook-form"
import propName from "../../helpers/propName"
import React, { ReactElement, useState } from "react"
import { Params, useNavigate, useParams } from "react-router-dom"
import { EditSupplierMutationVariables, useEditSupplierMutation, useGetSingleSupplierQuery, GetSingleSupplierQueryVariables, useDeleteSupplierMutation } from "../../generated/graphql"
import HookedTextArea from "../../components/General/Inputs/HookedTextArea"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import * as yup from "yup"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormPage from "../../components/General/FormPage/FormPage"
import FormTile from "../../components/Tiles/FormTile/FormTile"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import DeleteEntityModal from "../../components/AppliedModals/DeleteEntity/DeleteEntityModal"


export const EditSupplierQuery = gql`
   mutation EditSupplier($supplier: SupplierInput! $id: Int!) {
      supplierUpdate(supplier: $supplier, id: $id) {
         supplierKey
      }
   }
`

interface MatchParams extends Params {
   supplierKey: string
}

interface Props {
}



export default function EditSupplier(props: Props): ReactElement {
   const navigate = useNavigate()

   const { supplierKey: key } = useParams() as MatchParams

   const supplierKey = parseInt(key)
   const [currentlyDeletingSupplier, setCurrentlyDeleting] = useState(false)

   const validationSchema = yup.object().shape({
      supplier: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().notRequired().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().notRequired().trim(),
         directions: yup.string().notRequired().trim(),
         fax: yup.string().notRequired().trim(),
         phone: yup.string().notRequired().trim(),
         state: yup.string().notRequired().trim(),
         zipCode: yup.string().notRequired().trim(),
         notes: yup.string().notRequired().trim()
      })
   })

   const getVariables: GetSingleSupplierQueryVariables = { id: supplierKey }
   const { data: d, loading: l, error: e } = useGetSingleSupplierQuery({ variables: getVariables })

   const [editSupplierMutation] = useEditSupplierMutation()
   const [deleteMutation] = useDeleteSupplierMutation()

   const { register, handleSubmit, errors } = useForm<EditSupplierMutationVariables>({ validationSchema })

   if (l) return <div>Loading</div>
   if (e) return <div>Error Loading Record</div>

   const onSubmit = handleSubmit(
      (variables: EditSupplierMutationVariables) =>
         editSupplierMutation({ variables: { ...variables, ...{ id: supplierKey } } })
            .then(navigateToList))

   const navigateToList = () => {
      navigate("/suppliers")
   }

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Supplier</Button>)


   return (
      <FormPage title="Update Supplier" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.name}
                        register={register}
                        label="Name"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.address1}
                        register={register}
                        label="Address 1"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.address2}
                        register={register}
                        label="Address 2"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.city}
                        register={register}
                        label="City"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.city)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.state}
                        register={register}
                        label="State"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.zipCode}
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        defaultValue={d.supplierGetSingle.notes}
                        register={register}
                        label="Notes"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.notes)}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
            <FormTile>
               <div className="row">
                  <div className="col-md-6">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.phone}
                        register={register}
                        label="Phone"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedTextInput
                        defaultValue={d.supplierGetSingle.fax}
                        register={register}
                        label="Fax"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.fax)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        defaultValue={d.supplierGetSingle.directions}
                        register={register}
                        label="Directions"
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.directions)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedCheckbox
                        label="Archived"
                        register={register}
                        propertyKey={propName<EditSupplierMutationVariables>(o => o.supplier.isArchived)}
                        defaultValue={d.supplierGetSingle.isArchived}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingSupplier && <DeleteEntityModal
            cancel={cancelDelete}
            confirm={confirmDelete}
            entityKey={supplierKey}
            entityName="supplier"
            orderFilter={{ supplierKey: { eq: supplierKey } }}
            indexContractFilter={{ supplierKey: { eq: supplierKey } }}
            postingFilter={{ supplierKey: { eq: supplierKey } }}
            prepurchaseFilter={{ supplierKey: { eq: supplierKey } }}
            salesAgreementFilter={{ supplierKey: { eq: supplierKey } }}
         />}
      </FormPage>
   )
}
