import React, { CSSProperties, useState } from 'react'
import { DefaultInputProps } from "../../General/Inputs/HookedTextArea"
import inputStyles from '../../General/Inputs/Input.module.scss'
import InputWrapper from '../../General/Inputs/InputWrapper'
import styles from './TextSearchDropdown.module.scss'
import { X } from 'react-feather'

interface TextProps<T> extends DefaultInputProps<T> {
    onSelect?(entity: T)
    mapEntityToKey(entity: T): number
    searchWrapper: JSX.Element

    isLoadingPrefilled?: boolean
    selectedEntity?: T
    selectedEntityDisplay?: string
    disabled?: boolean
    clearOnSelect?: boolean
}

export default function ControlledTextSearchDropdown<T>(props: TextProps<T>) {
    const [isShowingDropdown, setIsShowingDropdown] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const onFocus = () => {
        setIsShowingDropdown(true)
    }

    const onBlur = () => {
        setTimeout(() => {
            setIsShowingDropdown(false)
            setIsClicked(false)
        }, 200)
    }

    const onClick = (e: any) => {
        setIsShowingDropdown(true)
        setIsClicked(true)
    }

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const onSelect = (entity: T, entityDisplay: string) => {
        setSearchValue("")
        props.onSelect && props.onSelect(entity)
    }

    const renderContents = () => {
        if (props.isLoadingPrefilled) {
            return <span className={styles.selection}>Loading</span>
        }
        return props.selectedEntity &&
            <span className={styles.selection}>{`${props.selectedEntityDisplay}`}{props.disabled !== true ? <span onClick={clearSelected.bind(this)} className={styles.removeButton}>  <X size="13" /></span> :
                <></>}
            </span>

    }

    const clearSelected = () => {
        onSelect(undefined, undefined)
    }

    const displayStyles: CSSProperties = {
        display: "none"
    }

    const currentError = props.errors[props.propertyKey]

    return (
        <InputWrapper {...props}>
            {props.selectedEntity !== undefined && <input
                style={displayStyles}
                value={props.mapEntityToKey(props.selectedEntity)}
                ref={props.register}
                name={props.propertyKey}
                type="number"
                onChange={() => { }}
            />}
            <div className={styles.inputWrapper}>
                {renderContents()}
                <input
                    className={`${inputStyles.input} ${currentError && inputStyles.input__error}`}
                    onChange={onSearch.bind(this)}
                    value={searchValue}
                    onFocus={onFocus.bind(this)}
                    onBlur={onBlur.bind(this)}
                    disabled={props.disabled ? true : false}
                    onClick={onClick.bind(this)}
                    autoComplete="randomstringtodisableautocomplete"
                />

                {(searchValue.trim().length > 0 || isClicked) && isShowingDropdown &&
                    React.cloneElement(props.searchWrapper, {
                        onSelect: onSelect.bind(this), searchTerm: searchValue.trim()
                    })}
            </div>
        </InputWrapper>
    )
}