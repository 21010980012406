import React, { ReactElement, useEffect } from 'react'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { useGetSearchLocationsQuery, BasicLocationFragment, useGetSingleLocationQuery, SortEnumType, LocationSortInput } from '../../../generated/graphql'
import ControlledTextSearchDropdown from '../TextSearchDropdown/ControlledTextSearchDropdown'

interface Props extends DefaultInputProps<BasicLocationFragment> {
    onSelect?(location: BasicLocationFragment)
    locationKey?: number
    clearOnSelect?: boolean
    customerKey?: number
    prefillIfOnlyLocation?: boolean
    showCustomer?: boolean
    requireCustomerKey?: boolean
}

export default function ControlledLocationSearch(props: Props): ReactElement {
    const sort: LocationSortInput = { name: SortEnumType.Asc }

    const { customerKey, requireCustomerKey, prefillIfOnlyLocation, onSelect, locationKey } = props

    const { data: singleLocationData, loading: singleLocationLoading } = useGetSingleLocationQuery({ variables: { locationKey: props.locationKey }, skip: !props.locationKey })
    const { data: allLocationData, loading: locationsLoading } = useGetSearchLocationsQuery({ variables: { customerKey: props.customerKey, searchTerm: "", sort } })

    useEffect(() => {
        //if it's trying to load the locations, wait, skip this digest
        if (locationsLoading) { }

        // otherwise if there's no customer key when there should be a customer key, clear out a location that may be selected
        else if (!customerKey && requireCustomerKey) {
            onSelect(undefined)
        }

        // otherwise I have locations for a specific customer and should select it if there is only one location
        else if (allLocationData != null && (customerKey && prefillIfOnlyLocation) && allLocationData.locationGetByCustomer.length === 1) {
            //get the first location
            const onlyLocation = allLocationData.locationGetByCustomer[0]

            //if a new location is being selected
            if (onlyLocation.locationKey !== locationKey) {
                //if props has an onSelect method passed in
                if (onSelect) {
                    //call it with the location info
                    onSelect(allLocationData.locationGetByCustomer[0])
                }
            }
        }
    }, [allLocationData, customerKey, prefillIfOnlyLocation, locationsLoading, requireCustomerKey, onSelect, locationKey])

    const loc = singleLocationData ? singleLocationData.locationGetSingle : null
    const selectedData = loc ? loc : undefined
    const selectedDataDisplay = singleLocationData && `${props.showCustomer ? `${loc.customer.name} - ` : ""}${loc.name ? loc.name : `${loc.city}, ${loc.state}`}`

    return <ControlledTextSearchDropdown<BasicLocationFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e ? e.locationKey.toString() : '0')}
        searchWrapper={<Inner searchTerm="" customerKey={props.customerKey} onSelect={() => null} showCustomer={props.showCustomer} />}
        isLoadingPrefilled={singleLocationLoading}
        selectedEntity={selectedData}
        selectedEntityDisplay={selectedDataDisplay}
        clearOnSelect={props.clearOnSelect}
        disabled={requireCustomerKey && !customerKey}
        {...props}
    />
}

const Inner = (props: InnerSearchLogicProps<BasicLocationFragment>) => {
    const sort: LocationSortInput = { name: SortEnumType.Asc }
    const { data, loading, error } = useGetSearchLocationsQuery({ variables: { customerKey: props.customerKey, searchTerm: props.searchTerm, sort } })

    const showData = !loading && !error

    const results = showData && data.locationGetByCustomer.map(location => {
        const display = `${props.showCustomer ? `${location.customer.name} - ` : ""} ${location.name ? location.name : `${location.city}, ${location.state}`}`
        return { key: location.locationKey, object: location, display }
    })

    const displayProps: SearchDisplayProps<BasicLocationFragment> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Locations", results }
        ],
        searchTerm: props.searchTerm,
        isLoading: loading,
        error
    }

    return <SearchDisplay {...displayProps} />

}
