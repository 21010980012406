import React, { Component } from 'react'
import { DashboardOrderQuery } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import Tile from '../../General/Tile/Tile'

interface Props {
    orderQuery: DashboardOrderQuery
}

interface UnpaidData {
    name: string,
    key: number,
    total: number,
    patPerc?: number,
    otherCost?: number
}

export default class UnpaidOrderTile extends Component<Props> {
    state = {}

    render() {
        const moneyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        const orders = this.props.orderQuery.orderDashboard.edges.map(e => e.node)
        const groupedOrdersByCarrier: UnpaidData[] = []
        orders.filter(o => !o.carrierPaid).forEach((value) => {
            let index = groupedOrdersByCarrier.map(o => o.key).indexOf(value.carrier.carrierKey)

            const freight = value.freight * (value.actualGallons ?? value.gallons)

            if (index < 0) {
                groupedOrdersByCarrier.push({ name: value.carrier.name, key: value.carrier.carrierKey, total: freight, otherCost: value.otherCost })
            }
            else {
                groupedOrdersByCarrier[index].total += freight
                groupedOrdersByCarrier[index].otherCost += value.otherCost
            }
        }, {})

        const groupedOrdersBySupplier: UnpaidData[] = []

        orders.filter(o => !o.supplierPaid).forEach((value) => {
            let index = groupedOrdersBySupplier.map(o => o.key).indexOf(value.supplier.supplierKey)

            const productCost = value.product * (value.actualGallons ?? value.gallons)
            const patPercCost = value.assessment * (value.actualGallons ?? value.gallons)

            if (index < 0) {
                groupedOrdersBySupplier.push({ name: value.supplier.name, key: value.supplier.supplierKey, total: productCost, patPerc: patPercCost })
            }
            else {
                groupedOrdersBySupplier[index].total += productCost
                groupedOrdersBySupplier[index].patPerc += patPercCost
            }
        }, {})

        const alignRight: React.CSSProperties = { textAlign: "right" }
        const justifyFlexEnd: React.CSSProperties = { justifyContent: "flex-end" }

        const carrierGridOptions: GridOptions<UnpaidData> = {
            columnDefs: [
                { headerName: "Carrier", dataTransform: (entity) => entity.name, field: "name" },
                { headerName: "Other Cost", dataTransform: (entity) => moneyFormatter.format(entity.otherCost), field: "otherCost", headerStyles: justifyFlexEnd, styles: alignRight },
                { headerName: "Freight", dataTransform: (entity) => moneyFormatter.format(entity.total), field: "total", headerStyles: justifyFlexEnd, styles: alignRight }
            ],
            isErrored: false,
            isLoading: false,
            rowData: groupedOrdersByCarrier,
            dataKeyColumn: propName<UnpaidData>(o => o.key)
        }

        const supplierGridOptions: GridOptions<UnpaidData> = {
            columnDefs: [
                { headerName: "Supplier", dataTransform: (entity) => entity.name, field: "name" },
                { headerName: "Pat/Perc", dataTransform: (entity) => moneyFormatter.format(entity.patPerc), field: "patPerc", headerStyles: justifyFlexEnd, styles: alignRight },
                { headerName: "Product", dataTransform: (entity) => moneyFormatter.format(entity.total), field: "total", headerStyles: justifyFlexEnd, styles: alignRight }
            ],
            isErrored: false,
            isLoading: false,
            rowData: groupedOrdersBySupplier,
            dataKeyColumn: propName<UnpaidData>(o => o.key)
        }

        return (
            <div className="row">
                <div className="col-md-6">
                    <Tile title="Unpaid Carriers">
                        <GridBase<UnpaidData> gridOptions={carrierGridOptions} />
                    </Tile>
                </div>

                <div className="col-md-6">
                    <Tile title="Unpaid Suppliers">
                        <GridBase<UnpaidData> gridOptions={supplierGridOptions} />
                    </Tile>
                </div>
            </div>
        )
    }
}
