import React, { Component } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import { useAllFlatDirectMarkupsQuery, AllFlatDirectMarkupListingFragment, FlatDirectMarkupFilterInput, FlatDirectMarkupSortInput, useDeleteDirectMarkupMutation, AllFlatDirectMarkupsQueryVariables, SortEnumType } from '../../generated/graphql'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const DirectMarkupFragment = gql`
    fragment allDirectMarkupListing on DirectMarkup {
        directMarkupKey
        markup
        terminal {
            name
        }
        location {
            name
            city
            state
            customer {
                name
            }
        }
        supplier {
            name
        }
        directMarkupType
    }

    fragment allFlatDirectMarkupListing on FlatDirectMarkup {
        directMarkupKey
        markup
        directMarkupType
        terminal
        location
        supplier
    }
`

export const DirectMarkupsQuery = gql`
    query AllDirectMarkups {
        directMarkupGetAll {
           ...allDirectMarkupListing
        }
    }

    query AllFlatDirectMarkups($sort: [FlatDirectMarkupSortInput!], $filter: FlatDirectMarkupFilterInput!) {
        flatDirectMarkupGetAll(order: $sort, where: $filter) {
            ...allFlatDirectMarkupListing
        }
    }
`
export const DeleteDirectMarkupMutation = gql`
    mutation DeleteDirectMarkup($id: Int!) {
        directMarkupDelete(id: $id)
    }
`

interface DirectMarkupDataWrapperProps {
    editDirectMarkup(directMarkup: AllFlatDirectMarkupListingFragment)
    deleteDirectMarkup(directMarkup: AllFlatDirectMarkupListingFragment)
    currentlyDeletingDirectMarkups?: AllFlatDirectMarkupListingFragment

    currentSort: FlatDirectMarkupSortInput
    updateSort(sort: FlatDirectMarkupSortInput)
    currentFilter: FlatDirectMarkupFilterInput
    updateFilter(filter: FlatDirectMarkupFilterInput)
}

const DirectMarkupDataWrapper = (props: DirectMarkupDataWrapperProps): JSX.Element => {

    const variables: AllFlatDirectMarkupsQueryVariables = {
        sort: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllFlatDirectMarkupsQuery({ variables })

    const [deleteMutation] = useDeleteDirectMarkupMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteDirectMarkup(null))
            .then(refetch)
    }

    const cancelDelete = () => {
        props.deleteDirectMarkup(null)
    }

    const deleteModalFooterLeftContent = (<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const deleteModalFooterRightContent = (<Button clickAction={() => confirmDelete(props.currentlyDeletingDirectMarkups.directMarkupKey)} buttonType={ButtonType.Danger}>Delete Direct Markup</Button>)

    const gridOptions: GridOptions<AllFlatDirectMarkupListingFragment> = {
        columnDefs: [
            { headerName: "Supplier", field: "supplier", dataTransform: o => o.supplier, sortable: true, searchable: true },
            { headerName: "Terminal", field: "terminal", dataTransform: o => o.terminal, sortable: true, searchable: true },
            { headerName: "Location", field: "location", dataTransform: o => o.location, sortable: true, searchable: true },
            { headerName: "Markup", field: "markup", dataTransform: o => o.markup.toFixed(4) },
            { headerName: "Type", field: "type", dataTransform: o => o.directMarkupType.toString() }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.flatDirectMarkupGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editDirectMarkup },
                    { displayName: "Delete", action: props.deleteDirectMarkup }
                ]
            }
        ],
        dataKeyColumn: "directMarkupKey",
        linkToPath: "/pricing/direct-markup/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    return (
        <div>
            <GridBase<AllFlatDirectMarkupListingFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingDirectMarkups && <Modal headerText="Delete?" footerLeftContent={deleteModalFooterLeftContent} footerRightContent={deleteModalFooterRightContent}>
                <p>Once you delete this direct markup, it will not be able to be reversed.</p>
            </Modal>}
        </div>
    )
}

const DirectMarkupList = () => {
    const navigate = useNavigate()

    return (
        <DirectMarkupListInnerWrapper navigate={navigate} />
    )
}

export default DirectMarkupList

interface Props {
    navigate: NavigateFunction
}
interface State {
    currentlyDeletingDirectMarkups?: AllFlatDirectMarkupListingFragment
    currentFilter: FlatDirectMarkupFilterInput
    currentSort: FlatDirectMarkupSortInput
}

class DirectMarkupListInnerWrapper extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: FlatDirectMarkupSortInput = { location: SortEnumType.Asc }
        const currentFilter: FlatDirectMarkupFilterInput = { isArchived: { eq: false } }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    navigateToNewDirectMarkup() {
        this.props.navigate('/pricing/direct-markup/new')
    }

    editDirectMarkup(directMarkup: AllFlatDirectMarkupListingFragment) {
        this.props.navigate(`/pricing/direct-markup/edit/${directMarkup.directMarkupKey}`)
    }

    deleteDirectMarkups(directMarkup: AllFlatDirectMarkupListingFragment) {
        this.setState({ currentlyDeletingDirectMarkups: directMarkup })
    }

    sortClick(currentSort: FlatDirectMarkupSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: FlatDirectMarkupFilterInput) {
        currentFilter.isArchived = this.state.currentFilter.isArchived
        this.setState({ currentFilter })
    }

    setShowArchived(showArchived: boolean) {
        let newFilter = Object.assign({}, this.state.currentFilter)
        newFilter.isArchived = { eq: showArchived }

        this.setState({ currentFilter: newFilter })
    }

    render() {
        return (
            <GeneralPage title="Direct Markup" headerContent={
                <>
                    <HookedCheckbox
                        label="Show Archived"
                        defaultValue={false}
                        propertyKey={"showArchived"}
                        errors={{}}
                        register={React.createRef()}
                        onChange={(event) => this.setShowArchived(event.target.checked)}
                        inline={true}
                    />
                    <Button clickAction={this.navigateToNewDirectMarkup.bind(this)}>New Direct Markup</Button>
                </>
            }>
                <Tile>
                    <DirectMarkupDataWrapper
                        currentlyDeletingDirectMarkups={this.state.currentlyDeletingDirectMarkups}
                        deleteDirectMarkup={this.deleteDirectMarkups.bind(this)}
                        editDirectMarkup={this.editDirectMarkup.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)} />
                </Tile>
            </GeneralPage>
        )
    }
}