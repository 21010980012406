import React, { Component } from 'react'
import styles from "./Spacer.module.scss"

interface Props {
    mobileOnly?: boolean
    spacerSize: number
}

export default class Spacer extends Component<Props> {
    state = {}

    render() {
        const style: React.CSSProperties = { marginBottom: this.props.spacerSize }

        return (
            <div className={this.props.mobileOnly ? styles.mobileOnly : ''} style={style}>

            </div>
        )
    }
}
