import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import HookedTextInput from '../General/Inputs/HookedTextInput'
import FormTile from '../Tiles/FormTile/FormTile'
import HookedTextArea from '../General/Inputs/HookedTextArea'
import HookedCheckbox from '../General/Inputs/HookedCheckbox'
import Button from '../General/Button/Button'
import { CarrierType, FlatCarrierRate, OrderStatus } from '../../generated/graphql'

interface Props {
    product?: number
    productProp: any
    markup?: number
    markupProp: any
    freight?: number
    freightProp: any
    toll?: number
    tollProp: any
    marketCarrierRate?: FlatCarrierRate
    perc?: number
    percProp: any
    misc?: number
    miscProp: any
    fob?: number
    fobProp: any
    delivered?: number
    deliveredProp: any
    otherCharge?: number
    otherChargeProp: any
    otherCost?: number
    otherCostProp: any
    costDescription?: string
    costDescriptionProp: any
    patCost?: number
    patCostTotal?: number
    patCostTotalProp: any
    commonProps: any
    gallons?: number
    quotedPrice?: boolean
    quotedPriceProp: any
    carrierType?: CarrierType
    freightError?: boolean
    updateFreightCallback?(): void
    orderStatus?: OrderStatus
    stateAssessmentProp: any
    stateTaxProp: any
    stateAssessment?: number
    stateTax?: number
}

export default function PricingTextBoxes(props: Props): ReactElement {
    const { stateTax, stateAssessment } = props

    // This will limit the number to 4 digits after decimal and setting back to number again removes any trailing 0s
    const formatNumber = (num: Number): number => {
        // This stops us from returning 0 if empty string or . or .0 ect
        if (num === null) {
            return null
        }
        return Number(Number(num).toFixed(4))
    }

    const [perc, setPerc] = useState(props.perc)
    const [product, setProduct] = useState(props.product)
    const [markup, setMarkup] = useState(formatNumber(props.markup))
    const [freight, setFreight] = useState<string>(undefined)
    const [toll, setToll] = useState<string>(undefined)
    const [misc, setMisc] = useState(props.misc)
    const [otherCharge, setOtherCharge] = useState<string>(props.otherCharge ? props.otherCharge.toString() : "")
    const [otherCost, setOtherCost] = useState<string>(props.otherCost ? props.otherCost.toString() : "")
    const [delivered, setDelivered] = useState(formatNumber(props.delivered))
    const [quotedPrice, setQuotedPrice] = useState(props.quotedPrice)


    if (freight === undefined && toll === undefined && props.freight !== undefined && props.gallons !== 0) {
        setFreight((props.freight).toFixed(4))
        if (props.toll) {
            setToll(props.toll.toFixed(4))
        }
    }

    const getOtherCost = useCallback((): number => {
        if (otherCost === undefined) {
            return 0
        }
        return Number(otherCost)
    }, [otherCost])

    const getOtherCharge = useCallback((): number => {
        if (otherCharge === undefined) {
            return 0
        }
        return Number(otherCharge)
    }, [otherCharge])

    const getCurrentGallons = useCallback(() => {
        return !!props.gallons ? props.gallons : 1
    }, [props.gallons])

    const getOtherCostPerGallon = useCallback(() => {
        return Number((getOtherCost() / getCurrentGallons()).toFixed(4))
    }, [getOtherCost, getCurrentGallons])

    const getOtherChargePerGallon = useCallback(() => {
        return Number((getOtherCharge() / getCurrentGallons()).toFixed(4))
    }, [getOtherCharge, getCurrentGallons])

    const getDeliveredPrice = useCallback(() => {
        return Number(product) + Number(freight) + Number(perc) + Number(stateAssessment) + Number(stateTax) + Number(markup) + Number(misc) + Number(props.patCost) + getOtherCostPerGallon() - getOtherChargePerGallon()
    }, [product, freight, markup, perc, misc, props.patCost, getOtherCostPerGallon, getOtherChargePerGallon, stateAssessment, stateTax])

    const getDeliveredMarkup = useCallback(() => {
        return Number(delivered) - Number(freight) - Number(product) - Number(perc) - Number(stateAssessment) - Number(stateTax) - Number(misc) - Number(props.patCost) - getOtherCostPerGallon() + getOtherChargePerGallon()
    }, [product, freight, perc, misc, props.patCost, delivered, getOtherCostPerGallon, getOtherChargePerGallon, stateAssessment, stateTax])

    useEffect(() => {
        if (freight !== undefined) {
            if (!isNaN(delivered) && !isNaN(Number(freight)) && !isNaN(product) && !isNaN(misc) && !isNaN(markup) && !isNaN(perc)) {
                const delivered = getDeliveredPrice()
                const deliveredMarkup = getDeliveredMarkup()
                if (quotedPrice) {
                    setMarkup(formatNumber(deliveredMarkup))
                }
                else {
                    setDelivered(formatNumber(delivered))
                }
            }
        }
    }, [product, markup, freight, misc, delivered, setMarkup, perc, props.patCost, quotedPrice, getDeliveredMarkup, getDeliveredPrice])

    const productChange = (prod) => {
        setProduct(prod)
    }

    const freightChange = (newFreight: string) => {
        const freightNum = Number(newFreight)
        if (!isNaN(freightNum)) {
            setFreight(newFreight)
        }
    }

    const miscChange = (mis) => {
        setMisc(mis)
    }

    const percChange = (perc) => {
        setPerc(perc)
    }

    const deliveredChange = (del) => {
        setDelivered(del)
    }

    const otherCostChange = (newOtherCost) => {
        const otherCostNum = Number(newOtherCost)
        if (!isNaN(otherCostNum)) {
            setOtherCost(newOtherCost)
        }
    }

    const updateFreightRate = () => {
        setFreight(getMarketFreight())
        setToll(props.marketCarrierRate.toll.toFixed(4))
        setOtherCost(props.marketCarrierRate.toll.toString())
        props.updateFreightCallback && props.updateFreightCallback()
    }

    const getOrderValue = () => {
        return ((getDeliveredPrice() * getCurrentGallons()) + Number(otherCharge)).toFixed(2)
    }

    const getMarketFreight = () => {
        return (props.marketCarrierRate.ratePerGallon).toFixed(4)
    }

    const shouldShowUpdateFreightButton = () => {
        if (props.orderStatus === undefined || props.orderStatus !== OrderStatus.Closed) {
            if (props.marketCarrierRate) {
                if ((props.marketCarrierRate.toll.toString() !== otherCost && !(props.marketCarrierRate.toll === 0 && otherCost === "")) || (props.marketCarrierRate.ratePerGallon).toFixed(4) !== freight) {
                    return true
                }
            }
        }
        return false
    }

    return (
        <FormTile>
            <div className="row">
                <div className="col-md-12">
                    {props.freightError && <h4 style={{ color: "red" }}>Cannot find a valid freight for this order configuration.</h4>}
                    {shouldShowUpdateFreightButton() && <Button clickAction={updateFreightRate}>Update Freights {`${getMarketFreight()} | ${props.marketCarrierRate.toll}`}</Button>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <HookedTextInput
                        label="Product"
                        propertyKey={props.productProp}
                        defaultValue={props.product.toFixed(4)}
                        onChange={_ => productChange(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Markup"
                        propertyKey={props.markupProp}
                        value={markup}
                        onChange={_ => setMarkup(_.target.value)}
                        {...props.commonProps}
                        disabled={quotedPrice}
                    />

                </div>

                <div className="col-md-2">
                    <HookedTextInput
                        label="Misc"
                        propertyKey={props.miscProp}
                        value={misc}
                        onChange={_ => miscChange(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="PERC"
                        propertyKey={props.percProp}
                        value={perc}
                        onChange={_ => percChange(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="State Assessment"
                        propertyKey={props.stateAssessmentProp}
                        value={props.stateAssessment ? props.stateAssessment.toString() : "0.000"}
                        disabled
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="State Tax"
                        propertyKey={props.stateTaxProp}
                        value={props.stateTax ? props.stateTax.toString() : "0.000"}
                        disabled
                        {...props.commonProps}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <HookedTextInput
                        label="FOB"
                        propertyKey={props.fobProp}
                        value={(Number(product) + Number(perc) + Number(props.stateAssessment) + Number(props.stateTax) + Number(markup) + Number(misc)).toFixed(4)}
                        {...props.commonProps}
                        disabled={true}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Freight"
                        propertyKey={props.freightProp}
                        value={freight === undefined ? "" : freight}
                        onChange={_ => freightChange(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Delivered"
                        propertyKey={props.deliveredProp}
                        value={delivered}
                        onChange={_ => deliveredChange(_.target.value)}
                        {...props.commonProps}
                        disabled={!quotedPrice}
                    />
                </div>
                <div className="col-md-2">
                    <HookedCheckbox
                        label="Quoted Price"
                        propertyKey={props.quotedPriceProp}
                        defaultValue={props.quotedPrice ? props.quotedPrice : false}
                        onChange={_ => setQuotedPrice(_.target.checked)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Order Value"
                        propertyKey="orderValue"
                        value={`$${getOrderValue()}`}
                        {...props.commonProps}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <HookedTextInput
                        label="Other Cost"
                        propertyKey={props.otherCostProp}
                        value={otherCost}
                        onChange={_ => otherCostChange(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Other Charge"
                        propertyKey={props.otherChargeProp}
                        defaultValue={props.otherCharge}
                        onChange={_ => setOtherCharge(_.target.value)}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Other Cost/Gal"
                        propertyKey="otherCostPerGallon"
                        value={(getOtherCost() / getCurrentGallons()).toFixed(4)}
                        disabled={true}
                        {...props.commonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        label="Other Charge/Gal"
                        propertyKey="otherChargePerGallon"
                        value={(getOtherCharge() / getCurrentGallons()).toFixed(4)}
                        disabled={true}
                        {...props.commonProps}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <HookedTextArea
                        label="Cost Description"
                        propertyKey={props.costDescriptionProp}
                        defaultValue={props.costDescription}
                        {...props.commonProps}
                    />
                </div>
            </div>
        </FormTile>
    )
}