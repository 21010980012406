import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import moment from 'moment'
import { CreateSwapDealMutationVariables, SwapDealSalesAgreementInput, useCreateSwapDealMutation, useCreateSwapDealSalesAgreementMutation } from '../../generated/graphql'
import BasisTypeSearch from '../../components/Search/BasisTypeSearch/BasisTypeSearch'
import MultiSalesAgreementSearch from '../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch'
import { useNavigate } from 'react-router-dom'

export const CreateSwapDealMutation = gql`
    mutation CreateSwapDeal($swapDeal: SwapDealInput!) {
        swapDealCreate(swapDeal: $swapDeal) {
            swapDealKey
        }
    }
`

export const CreateSwapDealSalesAgreementMutation = gql`
    mutation CreateSwapDealSalesAgreement($sdsa: SwapDealSalesAgreementInput!) {
        swapDealSalesAgreementCreate(swapDealSalesAgreement: $sdsa) {
            swapDealKey
            salesAgreementKey
        }
    }
`

interface Props {
}



export default function SwapDealCreate(props: Props): ReactElement {
    const navigate = useNavigate()

    const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)

    const [CreateSwapDeal] = useCreateSwapDealMutation()
    const [CreateSwapDealSalesAgreement] = useCreateSwapDealSalesAgreementMutation()

    const navigateToList = () => {
        navigate('/pricing/swap-deals')
    }

    const submit = (variables: CreateSwapDealMutationVariables) => {

        CreateSwapDeal({ variables })
            .then(r => {
                if (salesAgreementKeys) {
                    salesAgreementKeys.forEach(key => {
                        const sdInput: SwapDealSalesAgreementInput = { salesAgreementKey: key, swapDealKey: r.data.swapDealCreate.swapDealKey }
                        CreateSwapDealSalesAgreement({ variables: { sdsa: sdInput } })
                    })
                }
                navigateToList()
            })
    }

    const addSalesAgreement = (num: number) => {
        if (salesAgreementKeys === undefined) {
            setSalesAgreementKeys([num])
        }
        else if (!salesAgreementKeys.some(x => x === num)) {
            setSalesAgreementKeys([...salesAgreementKeys, num])
        }
    }

    const removeSalesAgreement = (num: number) => {
        setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
    }

    const validationSchema = yup.object().shape({
        swapDeal: yup.object().shape({
            basisTypeKey: yup.number().required(),
            dealNumber: yup.number().required(),
            sglCost: yup.number().required(),
            monthlyVolume: yup.number().required(),
            effectiveStartDate: yup.date().required(),
            effectiveEndDate: yup.date().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateSwapDealMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }
    const onSubmit = handleSubmit(submit)

    const leftFooterContent = (<Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create Swap Deal</Button>)

    return (
        <FormPage title="Create Swap Deal" footerLeftContent={leftFooterContent} footerRightContent={rightFooterContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="Deal #"
                                propertyKey={propName<CreateSwapDealMutationVariables>(t => t.swapDeal.dealNumber)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="SGL Cost"
                                propertyKey={propName<CreateSwapDealMutationVariables>(t => t.swapDeal.sglCost)}
                                defaultValue="0.0000"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="Monthly Volume"
                                propertyKey={propName<CreateSwapDealMutationVariables>(t => t.swapDeal.monthlyVolume)}
                                defaultValue="10000"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<CreateSwapDealMutationVariables>(t => t.swapDeal.effectiveStartDate)}
                                defaultValue={new Date(moment.utc().format('LL'))}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<CreateSwapDealMutationVariables>(t => t.swapDeal.effectiveEndDate)}
                                defaultValue={new Date(moment.utc().add(1, 'month').format('LL'))}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <BasisTypeSearch
                                label="Basis Type"
                                propertyKey={propName<CreateSwapDealMutationVariables>(o => o.swapDeal.basisTypeKey)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiSalesAgreementSearch
                                removeSalesAgreement={removeSalesAgreement}
                                addSalesAgreement={addSalesAgreement}
                                data={salesAgreementKeys} />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}