import React, { Component } from 'react'
import styles from "./Button.module.scss"

export enum ButtonType {
    Primary,
    Secondary,
    Tertiary,
    Success,
    Danger,
    Transparent,
    TightPrimary,
    TightSecondary
}

interface Props {
    clickAction?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    buttonType?: ButtonType
    isSubmit?: boolean
    submitValue?: string
    isDisabled?: boolean
    children?: React.ReactNode
}

export default class Button extends Component<Props> {
    render() {
        const extraClass = (() => {
            switch (this.props.buttonType) {

                case ButtonType.Secondary:
                    return styles.button__secondary

                case ButtonType.Success:
                    return styles.button__success

                case ButtonType.Danger:
                    return styles.button__danger

                case ButtonType.Tertiary:
                    return styles.button__tertiary

                case ButtonType.Transparent:
                    return styles.button__transparent

                case ButtonType.TightPrimary:
                    return styles.button__tight

                case ButtonType.TightSecondary:
                    return `${styles.button__secondary} ${styles.button__tight}`

                default:
                    return ''

            }
        })()

        const props = {
            onClick: this.props.clickAction,
            className: `${styles.button} ${extraClass}`
        }

        if (this.props.isSubmit) {
            return <input type="submit" value={this.props.submitValue} {...props} />
        }

        return (
            <button {...props} type="button" disabled={this.props.isDisabled}>
                {this.props.children}
            </button>
        )
    }
}
