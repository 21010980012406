import React, { useState } from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { CreatePointToPointCarrierRateDateSpanMutationVariables, useGetSinglePointToPointCarrierRateDateSpanQuery, UpdatePointToPointCarrierRateDateSpanMutationVariables, useUpdatePointToPointCarrierRateDateSpanMutation, UpdatePointToPointCarrierRateDateSpanMutationResult, useDeleteCarrierPointToPointRateDateSpanMutation } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from "yup"
import Button, { ButtonType } from '../../General/Button/Button'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import moment from 'moment'
import { useNavigate } from 'react-router'

interface Props {
    closeModal(): void
    carrierPointToPointRateDateSpanKey?: number
    callback?(result: UpdatePointToPointCarrierRateDateSpanMutationResult): Promise<any>
    delete(key): void
}

export const UpdatePointToPointCarrierRateDateSpan = gql`
	mutation UpdatePointToPointCarrierRateDateSpan($carrierPointToPointRateDateSpan: CarrierPointToPointRateDateSpanInput!, $dateSpanKey: Int!) {
		carrierPointToPointRateDateSpanUpdate(carrierPointToPointRateDateSpan: $carrierPointToPointRateDateSpan, id: $dateSpanKey) {
			carrierPointToPointRateDateSpanKey
		}
	}
`

export const GetSinglePointToPointCarrierRateDateSpan = gql`
    query GetSinglePointToPointCarrierRateDateSpan($key: Int!) {
        carrierPointToPointRateDateSpanGetSingle(id: $key) {
            carrierKey
            startDate
            endDate
            carrierPointToPointRates {
                ...singlePointToPointCarrierRate
            }
            carrier{
                name
            }
        }
    }
`

export const DeleteCarrierPointToPointRateDateSpanMutation = gql`
   mutation DeleteCarrierPointToPointRateDateSpan($id: Int!) {
      carrierPointToPointRateDateSpanDelete(id: $id)
   }
`

const UpdatePointToPointCarrierRateDateSpanModal = (props: Props) => {
    const navigate = useNavigate()

    const { data, loading, error } = useGetSinglePointToPointCarrierRateDateSpanQuery({ variables: { key: props.carrierPointToPointRateDateSpanKey } })
    const [updateDateSpanMutation, { error: updateError }] = useUpdatePointToPointCarrierRateDateSpanMutation()
    const [deleteDateSpanMutation] = useDeleteCarrierPointToPointRateDateSpanMutation()

    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [currentlyDeletingPointToPointRateDateSpan, setCurrentlyDeletingPointToPointRateDateSpan] = useState<number>(null)

    const submit = (variables: UpdatePointToPointCarrierRateDateSpanMutationVariables) => {
        variables.dateSpanKey = props.carrierPointToPointRateDateSpanKey
        variables.carrierPointToPointRateDateSpan.carrierKey = data.carrierPointToPointRateDateSpanGetSingle.carrierKey
        updateDateSpanMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
        }
    }

    const cancelDelete = () => {
        setCurrentlyDeletingPointToPointRateDateSpan(null)
    }

    const validationSchema = yup.object().shape({
        carrierPointToPointRateDateSpan: yup.object().shape({
            startDate: yup.date().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<UpdatePointToPointCarrierRateDateSpanMutationVariables>({ validationSchema })

    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    if (loading || error) {
        return <div></div>
    }

    if (startDate === undefined) {
        setStartDate(new Date(moment.utc(data.carrierPointToPointRateDateSpanGetSingle.startDate).format('LL')))
    }

    if (endDate === undefined && data.carrierPointToPointRateDateSpanGetSingle.endDate) {
        setEndDate(new Date(moment.utc(data.carrierPointToPointRateDateSpanGetSingle.endDate).format('LL')))
    }

    const addEndDate = () => {
        setEndDate(new Date())
    }

    const goToUpdateRates = () => {
        navigate(`/carriers/point-to-point/${props.carrierPointToPointRateDateSpanKey}`)
    }

    const confirmDateSpanDelete = (dateSpanKey: number) => {
        deleteDateSpanMutation({ variables: { id: dateSpanKey } })
            .then(() => { props.callback(null); props.closeModal() })
    }

    const footerLeftContent = <>
        <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
        <Button buttonType={ButtonType.Danger} clickAction={() => setCurrentlyDeletingPointToPointRateDateSpan(props.carrierPointToPointRateDateSpanKey)}>Delete</Button>
    </>

    const footerRightContent = <>
        {!endDate && <Button clickAction={addEndDate}>Add End Date</Button>}
        <Button buttonType={ButtonType.Secondary} clickAction={goToUpdateRates}>Update Rates</Button>
        <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>
    </>

    return (
        <>
            <Modal headerText="Update Point to Point Rate Date Span" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll>
                <form onSubmit={onSubmit}>
                    {updateError && <div className="row">
                        <div className="col-md-12">
                            <h4 style={{ color: "red" }}>{updateError.message}</h4>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<CreatePointToPointCarrierRateDateSpanMutationVariables>(o => o.carrierPointToPointRateDateSpan.startDate)}
                                defaultValue={startDate}
                                Change={onStartDateChange}
                                {...commonProps}
                            />
                        </div>
                        {(endDate) &&
                            <div className="col-md-6">
                                <HookedDateTime
                                    label="End Date"
                                    propertyKey={propName<CreatePointToPointCarrierRateDateSpanMutationVariables>(o => o.carrierPointToPointRateDateSpan.endDate)}
                                    defaultValue={endDate}
                                    Change={onEndDateChange}
                                    {...commonProps}
                                />
                            </div>}
                    </div>
                </form>

            </Modal>
            {currentlyDeletingPointToPointRateDateSpan && <Modal headerText="Delete Rate Date Span?"
                footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmDateSpanDelete(currentlyDeletingPointToPointRateDateSpan)}>Delete</Button>}>
                <p>Once you delete these rates, it will not be able to be reversed.</p>
            </Modal>}
        </>
    )
}
export default UpdatePointToPointCarrierRateDateSpanModal
