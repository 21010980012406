import gql from "graphql-tag"
import useForm from "react-hook-form"
import React, { ReactElement } from "react"
import * as yup from "yup"
import { CreateCarrierMutationVariables, useCreateCarrierMutation, CarrierType } from "../../generated/graphql"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import propName from "../../helpers/propName"
import FormPage from "../../components/General/FormPage/FormPage"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormTile from "../../components/Tiles/FormTile/FormTile"
import { SelectOption } from "../../components/General/SelectOptions/SelectOptions"
import HookedSelectOptions from "../../components/General/Inputs/HookedSelectOptions"
import { useNavigate } from "react-router-dom"

export const CreateCarrierQuery = gql`
   mutation CreateCarrier($carrier: CarrierInput!) {
      carrierCreate(carrier: $carrier) {
         carrierKey
      }
   }
`



export default function CreateCarrier(props: Props): ReactElement {
   const navigate = useNavigate()

   const [createCarrierMutation] = useCreateCarrierMutation()
   const navigateToList = () => {
      navigate("/carriers")
   }
   const navigateToCarrier = (id: number) => {
      navigate(`/carriers/show/${id}`)
   }
   const submit = (variables: CreateCarrierMutationVariables) => {
      variables.carrier.isArchived = false

      createCarrierMutation({ variables })
         .then(r => navigateToCarrier(r.data.carrierCreate.carrierKey))
   }

   const carrierTypes: SelectOption<CarrierType>[] = Object.keys(CarrierType).map(c => {
      return { key: CarrierType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
   })

   const defaultCarrierType = carrierTypes[0]

   const validationSchema = yup.object().shape({
      carrier: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().required().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().required().trim(),
         state: yup.string().required().trim(),
         zipCode: yup.string().required().trim(),
         phone: yup.string().required().trim(),
         fax: yup.string().notRequired().trim(),
         email: yup.string().notRequired().trim(),
         carrierType: yup.string().required()
      })
   })

   const { register, handleSubmit, errors, setValue } = useForm<CreateCarrierMutationVariables>({ validationSchema })

   const commonProps = { register, errors, setValue }

   const onSubmit = handleSubmit(submit)

   const footerLeftContent = <Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Carrier</Button>)


   return (
      <FormPage title="Create Carrier" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Name"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 1"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 2"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="City"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.city)}
                        errors={errors}
                     />
                  </div>

                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="State"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Phone"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Fax"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.fax)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Email"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.email)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedSelectOptions
                        options={carrierTypes}
                        label="Carrier Type"
                        propertyKey={propName<CreateCarrierMutationVariables>(o => o.carrier.carrierType)}
                        setDefaultValue={defaultCarrierType}
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
         </form>
      </FormPage>
   )
}
interface Props {
}