import React, { Component } from 'react'
import styles from './StickyHeader.module.scss'


interface Props {
    show?: boolean

    children: React.ReactNode
}
interface State {

}

export default class StickyHeader extends Component<Props, State> {
    state = {}

    render() {
        return (
            <div className={`${styles.stickyHeader} ${!this.props.show ? styles["stickyHeader--off-screen"] : ""}`}>
                {this.props.children}
            </div>
        )
    }
}
