
import gql from 'graphql-tag'
import { CreateBasisTypeMutationVariables, useCreateBasisTypeMutation } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from "../../helpers/propName"
import * as yup from "yup"
import React, { ReactElement } from 'react'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { useNavigate } from 'react-router-dom'

export const CreateBasisTypeQuery = gql`
      mutation CreateBasisType($basisType: BasisTypeInput!) {
         basisTypeCreate(basisType: $basisType) {
            basisTypeKey
         }
      }
`
interface Props {
}

export default function CreateBasisType(props: Props): ReactElement {
   const navigate = useNavigate()

   const [createBasisTypeMutation] = useCreateBasisTypeMutation()

   const navigateToList = () => {
      navigate("/pricing/basis-types")
   }

   const navigateToBasisType = (id: number) => {
      navigate(`/pricing/basis-types/show/${id}`)
   }

   const submit = (variables: CreateBasisTypeMutationVariables) => {
      variables.basisType.basisTypePrices[0].basisTypeKey = -1
      variables.basisType.isArchived = false
      createBasisTypeMutation({ variables })
         .then(r => navigateToBasisType(r.data.basisTypeCreate.basisTypeKey))
   }

   const validationSchema = yup.object().shape({
      basisType: yup.object().shape({
         name: yup.string().required().trim(),
         description: yup.string().trim(),

         basisTypePrices: yup.array(yup.object().shape({
            price: yup.number().required(),
            effectiveDate: yup.date().default(new Date()).required()
         }))
      })
   })

   const { register, handleSubmit, errors, setValue } = useForm<CreateBasisTypeMutationVariables>({ validationSchema })

   const onSubmit = handleSubmit(submit)

   const tomorrow = new Date()
   tomorrow.setDate(tomorrow.getDate() + 1)

   const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Basis Type</Button>)


   return (
      <FormPage title="Create Basis Type" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <FormTile>
            <div className="row">
               <div className="col-md-5">
                  <HookedTextInput
                     register={register}
                     label="Name"
                     propertyKey={propName<CreateBasisTypeMutationVariables>(o => o.basisType.name)}
                     errors={errors}
                  />
               </div>

               <div className="col-md-2">
                  <HookedTextInput
                     register={register}
                     label="Price"
                     propertyKey={propName<CreateBasisTypeMutationVariables>(o => o.basisType.basisTypePrices[0].price, 0)}
                     errors={errors}
                  />
               </div>
               <div className="col-md-5">
                  <HookedDateTime
                     register={register}
                     setValue={setValue}
                     label="Effective Date"
                     defaultValue={tomorrow}
                     propertyKey={propName<CreateBasisTypeMutationVariables>(o => o.basisType.basisTypePrices[0].effectiveDate, 0)}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <HookedTextArea
                     register={register}
                     label="Description"
                     propertyKey={propName<CreateBasisTypeMutationVariables>(o => o.basisType.description)}
                     errors={errors}
                  />
               </div>
            </div>
         </FormTile>
      </FormPage>
   )
}

