import React from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { useCreatePointToPointCarrierRateMutation, CreatePointToPointCarrierRateMutationVariables } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from "yup"
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../General/Button/Button'
import StateSearch from '../../Search/StateSearch/StateSearch'

interface Props {
    closeModal(): void
    callback?(): Promise<any>
    carrierPointToPointDateSpanKey?: number
    locationKey?: number
}

export const CreatePointToPointCarrierRate = gql`
	mutation CreatePointToPointCarrierRate($carrierPointToPointRate: CarrierPointToPointRateInput!) {
		carrierPointToPointRateCreate(carrierPointToPointRate: $carrierPointToPointRate) {
			carrierPointToPointRateKey
		}
	}
`

const CreatePointToPointCarrierRateModal = (props: Props) => {

    const [createPointToPointCarrierRateMutation] = useCreatePointToPointCarrierRateMutation()
    const onCreate = (variables: CreatePointToPointCarrierRateMutationVariables) => {

        variables.carrierPointToPointRate.carrierPointToPointRateDateSpanKey = props.carrierPointToPointDateSpanKey
        variables.carrierPointToPointRate.destinationCity.cityKey = 0
        variables.carrierPointToPointRate.originCity.cityKey = 0
        variables.carrierPointToPointRate.originCityKey = 0
        variables.carrierPointToPointRate.destinationCityKey = 0
        createPointToPointCarrierRateMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const validationSchema = yup.object().shape({
        carrierPointToPointRate: yup.object().shape({
            originCity: yup.object().shape({
                name: yup.string().required().trim(),
                stateKey: yup.number().required()
            }),
            destinationCity: yup.object().shape({
                name: yup.string().required().trim(),
                stateKey: yup.number().required()
            }),
            miles: yup.number().required(),
            tolls: yup.number().required(),
            rate: yup.number().required(),
        })
    })

    const { register, handleSubmit, errors } = useForm<CreatePointToPointCarrierRateMutationVariables>({ validationSchema })

    const commonProps = { register, errors }

    const onSubmit = handleSubmit(onCreate)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>

    return (
        <Modal headerText="Create Point to Point Rate" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll>
            <form onSubmit={onSubmit}>

                <div className="row">
                    <div className="col-xl-4 col-lg-8">
                        <HookedTextInput
                            register={register}
                            label="Origin City"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.originCity.name)}
                            errors={errors}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                        <StateSearch
                            label="Origin State"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.originCity.stateKey)}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-8">
                        <HookedTextInput
                            register={register}
                            label="Destination City"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.destinationCity.name)}
                            errors={errors}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                        <StateSearch
                            label="Destination State"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.destinationCity.stateKey)}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Miles"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.miles)}
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Tolls"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.tolls)}
                            errors={errors}
                            defaultValue="0"
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Rate"
                            propertyKey={propName<CreatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.rate)}
                            errors={errors}
                            defaultValue="0"
                        />
                    </div>
                </div>
            </form>

        </Modal>
    )
}
export default CreatePointToPointCarrierRateModal 