import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { useGetSingleSalesAgreementLazyQuery, useSalesAgreementSearchQuery, SalesAgreementListingFragment, InnerSalesAgreementFragment, SalesAgreementSortInput, SortEnumType } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import moment from 'moment'

interface Props extends DefaultInputProps<InnerSalesAgreementFragment> {
    onSelect?(salesAgreement: InnerSalesAgreementFragment)
    prefilledSalesAgreementKey?: number
    locationKey?: number
    clearOnSelect?: boolean
    disabled?: boolean
}

export default function SalesAgreementSearch(props: Props): ReactElement {
    const [executeGetSalesAgreement, { data, loading }] = useGetSingleSalesAgreementLazyQuery({ variables: { id: props.prefilledSalesAgreementKey } })
    const { prefilledSalesAgreementKey } = props

    useEffect(() => {
        if (prefilledSalesAgreementKey) {
            executeGetSalesAgreement()
        }
    }, [prefilledSalesAgreementKey, executeGetSalesAgreement])

    const prefilledData = data && data.salesAgreementGetSingle
    const prefilledDataDisplay = data && `${data.salesAgreementGetSingle.agreementName}`

    return <TextSearchDropdown<InnerSalesAgreementFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.salesAgreementKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} locationKey={props.locationKey} />}
        isLoadingPrefilled={loading}
        prefilledEntityDisplay={prefilledDataDisplay}
        prefilledEntity={prefilledData}
        clearOnSelect={props.clearOnSelect}
        disabled={props.disabled}

        {...props}
    />
}

export const SalesAgreementSearchQuery = gql`
    query SalesAgreementSearch($searchTerm: String!, $locationKey: Int, $date: DateTime, $sort: [SalesAgreementSortInput!]) {
        salesAgreementByLocation(where: { and: [{agreementName: {contains: $searchTerm}}, {startShipDate: {lte: $date}}, {endShipDate: {gte: $date}}] }, locationKey: $locationKey, order: $sort) {
            ...innerSalesAgreement
        }
    }
`

const Inner = (props: InnerSearchLogicProps<SalesAgreementListingFragment>) => {
    const sort: SalesAgreementSortInput = { agreementName: SortEnumType.Asc }

    const today = moment().startOf('day')
    const { data, loading, error } = useSalesAgreementSearchQuery({ variables: { searchTerm: props.searchTerm, locationKey: props.locationKey, date: today, sort } })

    const showData = !loading && !error

    const results = showData &&
        data.salesAgreementByLocation.map(sa => {
            const display = `${sa.agreementName}`
            return { key: sa.salesAgreementKey, object: sa, display }
        })

    const displayProps: SearchDisplayProps<SalesAgreementListingFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Sales Agreements", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}