import React from 'react'
import {
    AllLocationsQueryVariables, DashboardOrderQueryVariables, FlatIndexContractFilterInput, FlatIndexContractListingFragment,
    InnerLocationFragment, InnerOrderFragment, LocationFilterInput, OrderFilterInput, OrderStatus, OrderType, useAllLocationsQuery,
    useDashboardOrderQuery, useGetAllFlatIndexContractsQuery, useAllFlatPostingsQuery, FlatPostingFilterInput, InnerFlatPostingFragment,
    AllFlatPostingsQueryVariables, useAllFlatPrePurchaseAgreementQuery, FlatPrePurchaseAgreementFilterInput,
    AllFlatPrePurchaseAgreementQueryVariables, InnerFlatPrePurchaseAgreementFragment, AllFlatSalesAgreementQueryVariables, FlatSalesAgreementFilterInput,
    useAllFlatSalesAgreementQuery,
    InnerFlatSalesAgreementFragment
} from '../../../generated/graphql'
import Button, { ButtonType } from '../../General/Button/Button'
import { SelectOption } from '../../General/SelectOptions/SelectOptions'
import Modal from '../../Modal/Modal'
import moment from 'moment'
import GridBase, { GridOptions, GridSize } from '../../General/GridBase/GridBase'
import Badge from '../../General/Badge/Badge'
import propName from '../../../helpers/propName'

type Props = {
    cancel: () => void
    confirm: (key: number) => void
    entityKey: number
    entityName: string
    orderFilter: OrderFilterInput
    indexContractFilter?: FlatIndexContractFilterInput
    locationFilter?: LocationFilterInput
    postingFilter?: FlatPostingFilterInput
    prepurchaseFilter?: FlatPrePurchaseAgreementFilterInput
    salesAgreementFilter?: FlatSalesAgreementFilterInput
}

const DeleteEntityModal = (props: Props) => {
    const orderFilter: OrderFilterInput = props.orderFilter

    const variables: DashboardOrderQueryVariables = {
        pageSize: 10,
        filterObject: orderFilter,
        status: OrderStatus.All,
        cursor: "LTE=",
    }

    const { data, loading, error } = useDashboardOrderQuery({ variables })
    const { data: icData, loading: icLoading, error: icError } = useGetAllFlatIndexContractsQuery({ variables: { filter: props.indexContractFilter } })

    const locationVariables: AllLocationsQueryVariables = {
        cursor: "LTE=",
        pageSize: 10,
        filterObject: props.locationFilter
    }
    const { data: locationData, loading: locationLoading, error: locationError } = useAllLocationsQuery({ variables: locationVariables })

    const postingVariables: AllFlatPostingsQueryVariables = {
        cursor: "LTE=",
        pageSize: 10,
        filter: props.postingFilter
    }
    const { data: postingData, loading: postingLoading, error: postingError } = useAllFlatPostingsQuery({ variables: postingVariables })

    const prepurchaseVariables: AllFlatPrePurchaseAgreementQueryVariables = {
        cursor: "LTE=",
        pageSize: 10,
        filter: props.prepurchaseFilter
    }
    const { data: prepurchaseData, loading: prepurchaseLoading, error: prepurchaseError } = useAllFlatPrePurchaseAgreementQuery({ variables: prepurchaseVariables })

    const salesAgreementVariables: AllFlatSalesAgreementQueryVariables = {
        cursor: "LTE=",
        pageSize: 10,
        filter: props.salesAgreementFilter
    }

    const { data: saData, loading: saLoading, error: saError } = useAllFlatSalesAgreementQuery({ variables: salesAgreementVariables })

    const orderTypes: SelectOption<OrderType>[] = Object.keys(OrderType).map(c => {
        return { key: OrderType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const gridOptions: GridOptions<InnerOrderFragment> = {
        columnDefs: [
            {
                headerName: "Customer", dataTransform: o => `${o.customer.name} - ${o.location.name ? o.location.name : `${o.location.city}, ${o.location.state}`}`,
            },
            {
                headerName: "Supplier", dataTransform: o => o.supplier.name
            },
            { headerName: "Type", dataTransform: o => orderTypes.filter(x => x.key === o.orderType)[0].value },
            {
                headerName: "Terminal", dataTransform: o => o.terminal.name
            },
            {
                headerName: "Carrier", dataTransform: o => o.carrier.name,
            },
            { headerName: "Delivery Date", dataTransform: o => moment.utc(o.deliveryDate).format('LL'), field: "deliveryDate", sortable: true },
            { headerName: "", contentOverride: o => o.spotContract ? <Badge textColor="#D77328" backgroundColor="#F2E6DD">S</Badge> : '' },
            { headerName: "Gallons", dataTransform: o => o.actualGallons ? o.actualGallons.toString() : (o.tripsActualGallons ? o.tripsActualGallons.toString() : o.gallons.toString()) },
            { headerName: "Created By", dataTransform: o => o.createdByUser ? o.createdByUser.name : "" }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.orderDashboard.edges.map(x => x.node),
        linkToPath: `/orders/edit/:key`,
        dataKeyColumn: "orderKey",
        size: GridSize.small
    }

    const icGridOptions: GridOptions<FlatIndexContractListingFragment> = {
        columnDefs: [
            { headerName: "Supplier", dataTransform: (entity) => entity.supplier, field: "supplier" },
            { headerName: "Terminal", dataTransform: (entity) => entity.terminal, field: "terminal" },
            { headerName: "Account Info", dataTransform: (entity) => entity.accountInfo },
            { headerName: "Effective Date", dataTransform: (entity) => moment.utc(entity.effectiveDate).format("L"), field: "effectiveDate", date: true },
            { headerName: "Effective End Date", dataTransform: (entity) => moment.utc(entity.effectiveEndDate).format("L"), field: "effectiveDate", date: true },
        ],
        isErrored: !!icError,
        isLoading: icLoading,
        rowData: (icLoading || icError) ? [] : icData.flatIndexContractGetAll,
        dataKeyColumn: propName<FlatIndexContractListingFragment>(o => o.indexContractKey),
        linkToPath: "/pricing/index-contracts/edit/:key",
    }

    const locationGridOptions: GridOptions<InnerLocationFragment> = {

        columnDefs: [
            {
                headerName: "Location Name",
                field: "name",
                dataTransform: o => o.name ? o.name : `${o.city}, ${o.state}`,
            },
            {
                headerName: "City",
                field: "city",
                dataTransform: o => o.city,
            },
            {
                headerName: "State",
                field: "state",
                dataTransform: o => o.state,
            },
            {
                headerName: "Customer",
                field: "customer",
                dataTransform: o => o.customer.name
            },
        ],

        isErrored: !!locationError,
        isLoading: locationLoading,
        rowData: ((locationLoading || locationError) && !locationData) ? [] : locationData.locationGetAll.edges.map(x => x.node),

        dataKeyColumn: "locationKey",
        linkToPath: "/locations/show/:key",

    }

    const postingGridOptions: GridOptions<InnerFlatPostingFragment> = {
        columnDefs: [
            {
                headerName: "Supplier",
                dataTransform: o => o.supplier,
                field: "supplier",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Terminal",
                dataTransform: o => o.terminal,
                field: "terminal",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Cost",
                field: "cost",
                dataTransform: o => o.cost.toString(),
                sortable: true,
            },
            {
                headerName: "Effective Date",
                field: "effectiveDate",
                // do not use UTC since this is DateTime not Date
                dataTransform: o => moment(o.effectiveDate).format('LLL'),
                sortable: true
            },
            {
                headerName: "Effective End Date",
                field: "effectiveEndDate",
                // do not use UTC since this is DateTime not Date
                dataTransform: o => moment(o.effectiveEndDate).format('LLL'),
                sortable: true
            }
        ],

        isErrored: !!postingError,
        isLoading: postingLoading,
        rowData: ((postingLoading || postingError) || !postingData) ? [] : postingData.flatPostingGetAll.edges.map(x => x.node),
        dataKeyColumn: "postingKey",
        linkToPath: "/settings/postings/edit/:key",
    }

    const prepurchaseGridOptions: GridOptions<InnerFlatPrePurchaseAgreementFragment> = {
        columnDefs: [
            { headerName: "Key", field: "prePurchaseAgreementKey", dataTransform: o => o.prePurchaseAgreementKey.toString() },
            { headerName: "Supplier Agreement", field: "supplierAgreement", dataTransform: o => o.supplierAgreement },
            { headerName: "Supplier", dataTransform: o => o.supplier, field: "supplier" },
            { headerName: "Terminal", dataTransform: o => o.terminal, field: "terminal" }
        ],
        isErrored: !!prepurchaseError,
        isLoading: prepurchaseLoading,
        rowData: ((prepurchaseLoading || prepurchaseError) && !prepurchaseData) ? [] : prepurchaseData.flatPrePurchaseAgreementGetAll.edges.map(x => x.node),
        dataKeyColumn: "prePurchaseAgreementKey",
        linkToPath: "/pricing/pre-purchase-agreements/edit/:key"
    }

    const salesAgreementGridOptions: GridOptions<InnerFlatSalesAgreementFragment> = {
        columnDefs: [
            {
                headerName: "Name",
                field: "agreementName",
                dataTransform: o => o.agreementName,
                sortable: true,
                searchable: true
            },
            {
                headerName: "Supplier",
                dataTransform: o => o.supplier ? o.supplier : "",
                field: "supplier",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Terminal",
                dataTransform: o => o.terminal ? o.terminal : "",
                field: "terminal",
                sortable: true,
                searchable: true
            }
        ],
        isErrored: !!saError,
        isLoading: saLoading,
        rowData: ((saLoading || saError) && !saData) ? [] : saData.flatSalesAgreementGetAll.edges.map(x => x.node),
        dataKeyColumn: "salesAgreementKey",
        linkToPath: "/pricing/sales-agreements/edit/:key"
    }

    const readyToDelete = (data && data.orderDashboard.totalCount === 0 && (!props.indexContractFilter || (icData && icData.flatIndexContractGetAll.length === 0))
        && (!props.locationFilter || (locationData && locationData.locationGetAll.totalCount === 0))
        && (!props.postingFilter || (postingData && postingData.flatPostingGetAll.totalCount === 0))
        && (!props.prepurchaseFilter || (prepurchaseData && prepurchaseData.flatPrePurchaseAgreementGetAll.totalCount === 0))
        && (!props.salesAgreementFilter || (saData && saData.flatSalesAgreementGetAll.totalCount === 0)))

    return (
        <Modal headerText="Delete?"
            footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={props.cancel}>Cancel</Button>}
            footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => props.confirm(props.entityKey)} isDisabled={loading || (data && data.orderDashboard.totalCount > 0)}>Delete</Button>}
        >
            {(loading || icLoading) ? <h4>Loading...</h4> :
                !readyToDelete ?
                    (<>
                        {(data && data.orderDashboard.totalCount > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to orders</p>
                                <GridBase<InnerOrderFragment> gridOptions={gridOptions} />
                            </>
                            : <h6>No Linked Orders</h6>}

                        {props.indexContractFilter && ((icData && icData.flatIndexContractGetAll.length > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to index contracts</p>
                                <GridBase<FlatIndexContractListingFragment> gridOptions={icGridOptions} />
                            </> : <h6>No Linked Index Contracts</h6>)}

                        {props.locationFilter && ((locationData && locationData.locationGetAll.totalCount > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to locations</p>
                                <GridBase<InnerLocationFragment> gridOptions={locationGridOptions} />
                            </> : <h6>No Linked Locations</h6>)}

                        {props.postingFilter && ((postingData && postingData.flatPostingGetAll.totalCount > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to postings</p>
                                <GridBase<InnerFlatPostingFragment> gridOptions={postingGridOptions} />
                            </> : <h6>No Linked Postings</h6>)}

                        {props.prepurchaseFilter && ((prepurchaseData && prepurchaseData.flatPrePurchaseAgreementGetAll.totalCount > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to pre purchase agreements</p>
                                <GridBase<InnerFlatPrePurchaseAgreementFragment> gridOptions={prepurchaseGridOptions} />
                            </> : <h6>No Linked Pre Purchase Agreements</h6>)}

                        {props.salesAgreementFilter && ((saData && saData.flatSalesAgreementGetAll.totalCount > 0) ?
                            <>
                                <p>This {props.entityName} cannot be deleted because it is related to sales agreements</p>
                                <GridBase<InnerFlatSalesAgreementFragment> gridOptions={salesAgreementGridOptions} />
                            </> : <h6>No Linked Sales Agreements</h6>)}
                    </>)

                    :
                    <p>Once you delete this {props.entityName}, it will not be able to be reversed.</p>
            }
        </Modal>
    )
}

export default DeleteEntityModal