import React, { useState, useEffect } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import Tile from '../../components/General/Tile/Tile'
import gql from 'graphql-tag'
import { useDeleteContractVolumeMutation, SortEnumType, useAllContractVolumesQuery, InnerContractVolumeFragment, ContractVolumeFilterInput, ContractVolumeSortInput, AllContractVolumesQueryVariables } from '../../generated/graphql'
import moment from 'moment'
import ellipsish from '../../icons/ellipsis-h.svg'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Button, { ButtonType } from '../../components/General/Button/Button'
import Modal from '../../components/Modal/Modal'
import { useNavigate } from 'react-router-dom'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'

export const ContractVolumeFragment = gql`
    fragment innerContractVolume on ContractVolume {
        contractVolumeKey
        startDate
        contractVolumeType
        contractVolumeTerminals {
            terminalKey
            terminal {
                name
            }
        }
        supplierKey
        supplier {
            name
        }
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
    }

    fragment innerContractVolumeListing on ContractVolumeGetAllEdge {
        node {
            ...innerContractVolume
        }
    }

    fragment allContractVolumeListing on ContractVolumeGetAllConnection {
        edges {
            cursor
            ...innerContractVolumeListing
        }
        totalCount
    }

    fragment innerFlatContractVolume on FlatContractVolume {
        contractVolumeKey
        startDate
        supplier
        terminals
    }

    fragment innerFlatContractVolumeListing on FlatContractVolumeGetAllEdge {
        node {
            ...innerFlatContractVolume
        }
    }

    fragment allFlatContractVolumeListing on FlatContractVolumeGetAllConnection {
        edges {
            cursor
            ...innerFlatContractVolumeListing
        }
        totalCount
    }
`

export const AllContractVolumeQuery = gql`
    query AllContractVolumes($sortObject: [ContractVolumeSortInput!], $filterObject: ContractVolumeFilterInput, $cursor: String!, $pageSize: Int!) {
        contractVolumeGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
            ...allContractVolumeListing
        }
    }

    query AllFlatContractVolumes($sort: [FlatContractVolumeSortInput!], $filter: FlatContractVolumeFilterInput, $cursor: String!, $pageSize: Int!) {
        flatContractVolumeGetAll(order: $sort, where: $filter, first: $pageSize, after: $cursor) {
            ...allFlatContractVolumeListing
        }
    }

    query ContractVolumeForIndexContract($id: Int!){
        contractVolumeGetByIndexContract(id: $id){
            ...innerContractVolume
        }
    }
`

export const DeleteContractVolumeMutation = gql`
    mutation DeleteContractVolume($id: Int!) {
        contractVolumeDelete(id: $id)
    }
`

interface ContractVolumesWrapperProps {
    currentSort: ContractVolumeSortInput
    updateSort(newSort: ContractVolumeSortInput)

    currentFilter: ContractVolumeFilterInput
    updateFilter(newFilter: ContractVolumeFilterInput)

    editContractVolume(contractVolume: InnerContractVolumeFragment)

    deleteContractVolume(contractVolume: InnerContractVolumeFragment)
    currentlyDeleteingContractVolume?: InnerContractVolumeFragment
}

const ContractVolumesWrapper = (props: ContractVolumesWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllContractVolumesQueryVariables = {
        sortObject: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllContractVolumesQuery({ variables })

    const [deleteMutation] = useDeleteContractVolumeMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteContractVolume(null))
            .then(refetchContractVolumes)
    }

    const cancelDelete = () => {
        props.deleteContractVolume(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerContractVolumeFragment> = {
        columnDefs: [
            {
                headerName: "Terminal",
                dataTransform: o => o.contractVolumeTerminals.map(t => t.terminal.name).join(", "),
                field: "contractVolumeTerminals.some.terminal.name",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Supplier",
                dataTransform: o => o.supplier.name,
                field: "supplier.name",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Start Date",
                dataTransform: o => moment.utc(o.startDate).format('LL'),
                field: "startDate",
                sortable: true
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.contractVolumeGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editContractVolume },
                    { displayName: "Delete", action: props.deleteContractVolume }
                ]
            }
        ],
        dataKeyColumn: "contractVolumeKey",
        linkToPath: "/settings/contract-volumes/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,

        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    const refetchContractVolumes = () => {
        return refetch()
    }

    return (
        <div>
            <PaginationWrapper
                pageSize={pageSize}
                totalCount={data ? data.contractVolumeGetAll.totalCount : 0}
                changeCursor={pageChange} >
                <GridBase<InnerContractVolumeFragment> gridOptions={gridOptions} />
                {!!props.currentlyDeleteingContractVolume && <Modal headerText="Delete?">
                    <p>Once you delete this contract volume it will not be able to be reversed</p>
                    <Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Delete</Button> <Button clickAction={() => confirmDelete(props.currentlyDeleteingContractVolume.contractVolumeKey)}>Delete</Button>
                </Modal>}
            </PaginationWrapper>
        </div>
    )
}

type Props = {}

const ContractVolumes = (props: Props) => {
    const navigate = useNavigate()

    const [currentSort, setCurrentSort] = useState<ContractVolumeSortInput>({ startDate: SortEnumType.Desc })

    const yearAgo = moment().startOf('day').add(-1, 'year')
    const initialFilter: ContractVolumeFilterInput = {
        startDate: { gte: yearAgo }
    }

    const [currentFilter, setCurrentFilter] = useState<ContractVolumeFilterInput>(initialFilter)
    const [currentlyDeletingContractVolume, setCurrentlyDeletingContractVolume] = useState<InnerContractVolumeFragment>()

    const sortClick = (currentSort: ContractVolumeSortInput) => {
        setCurrentSort(currentSort)
    }

    const filterClick = (newFilter: ContractVolumeFilterInput) => {
        newFilter.startDate = currentFilter.startDate
        setCurrentFilter(newFilter)
    }

    const editContractVolume = (contractVolume: InnerContractVolumeFragment) => {
        navigate(`/settings/contract-volumes/edit/${contractVolume.contractVolumeKey}`)
    }

    const deleteContractVolume = (contractVolume: InnerContractVolumeFragment) => {
        setCurrentlyDeletingContractVolume(contractVolume)
    }

    const createContractVolume = () => {
        navigate('/settings/contract-volumes/new')
    }

    const setShowExpired = (showExpired: boolean) => {
        let newFilter = Object.assign({}, currentFilter)
        const yearAgo = moment().startOf('day').add(-1, 'year')

        if (!showExpired) {
            newFilter.startDate = { gte: yearAgo }
        }
        else {
            newFilter.startDate = { lte: yearAgo }
        }
        setCurrentFilter(newFilter)
    }

    return (
        <GeneralPage title="Contract Volumes"
            headerContent={
                <div>
                    <HookedCheckbox
                        label="Show Expired"
                        defaultValue={false}
                        propertyKey={"showExpired"}
                        errors={{}}
                        register={React.createRef()}
                        onChange={(event) => setShowExpired(event.target.checked)}
                        inline={true}
                    />
                    <Button clickAction={createContractVolume}>Create Contract Volume</Button>
                </div>
            }>
            <Tile>
                <ContractVolumesWrapper
                    currentSort={currentSort}
                    updateSort={sortClick}
                    currentFilter={currentFilter}
                    updateFilter={filterClick}
                    editContractVolume={editContractVolume}
                    deleteContractVolume={deleteContractVolume}
                    currentlyDeleteingContractVolume={currentlyDeletingContractVolume}
                />
            </Tile>
        </GeneralPage>
    )
}

export default ContractVolumes