import React, { ReactElement } from 'react'
import { AllLocationsQueryVariables, useAllLocationsQuery, BasicLocationFragment, SortEnumType } from '../../../generated/graphql'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import ellipsish from '../../../icons/ellipsis-h.svg'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import LocationSearch from './LocationSearch'
import styles from '../MultiSearch/MultiSearch.module.scss'

interface Props {
    removeLocation(location: any)
    addLocation(location: any)
    addLocationEntity?(locationEntity: BasicLocationFragment)
    data: any[]
    showCustomer?: boolean
}

export default function MultiLocationSearch(props: Props): ReactElement {
    const variables: AllLocationsQueryVariables = {
        sortObject: { name: SortEnumType.Asc },
        cursor: "LTE=",
        filterObject: { locationKey: { in: props.data } },
         // changed the number of page size due to errors of getting a lower max value that is allowed than the value 
        // we want to be maxed allowed.
        pageSize: 50
    }
    const { data, loading, error } = useAllLocationsQuery({ variables })

    const validationSchema = yup.object().shape({
        location: yup.object().shape({
            locationKey: yup.number().required()
        })
    })

    const removeLocation = (location: any) => {
        props.removeLocation(location.locationKey)
    }

    const locationGridOptions: GridOptions<BasicLocationFragment> = {
        columnDefs: [
            {
                headerName: "Name",
                dataTransform: o => `${o.customer.name} - ${o.name ? o.name : `${o.city}, ${o.state}`}`,
                field: "name"
            },
            { headerName: "State", field: "state" }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.locationGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeLocation }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onLocationSelect = (location: BasicLocationFragment) => {
        if (location) {
            props.addLocation(location.locationKey)
            if (props.addLocationEntity) props.addLocationEntity(location)
        }
    }

    const header = (
        <div>
            <LocationSearch
                label="Locations"
                propertyKey={propName<string>(o => o)}
                onSelect={onLocationSelect}
                clearOnSelect={true}
                showCustomer={props.showCustomer}
                {...commonProps} />
        </div>
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={locationGridOptions} />}
                </div>
            </div>
        </div>
    )
}