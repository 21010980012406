import React, { ReactElement } from 'react'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import ellipsish from '../../../icons/ellipsis-h.svg'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import SalesAgreementSearch from './SalesAgreementSearch'
import { AllSalesAgreementQueryVariables, useAllSalesAgreementQuery, InnerSalesAgreementFragment } from '../../../generated/graphql'
import styles from '../MultiSearch/MultiSearch.module.scss'

interface Props {
    removeSalesAgreement(salesAgreement: any)
    addSalesAgreement(salesAgreement: any)
    data: any[]
}

export default function MultiSalesAgreementSearch(props: Props): ReactElement {
    const variables: AllSalesAgreementQueryVariables = {
        sortObject: {},
        cursor: "LTE=",
        filterObject: { salesAgreementKey: { in: props.data } },
        pageSize: 25
    }

    const { data, loading, error } = useAllSalesAgreementQuery({ variables })

    const validationSchema = yup.object().shape({
        salesAgreement: yup.object().shape({
            salesAgreementKey: yup.number().required()
        })
    })

    const removeSalesAgreement = (salesAgreement: any) => {
        props.removeSalesAgreement(salesAgreement.salesAgreementKey)
    }

    const salesAgreementGridOptions: GridOptions<InnerSalesAgreementFragment> = {
        columnDefs: [
            {
                headerName: "Agreement Name",
                dataTransform: o => o.agreementName
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.salesAgreementGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeSalesAgreement }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onSelect = (salesAgreement: InnerSalesAgreementFragment) => {
        if (salesAgreement) {
            props.addSalesAgreement(salesAgreement.salesAgreementKey)
        }
    }

    const header = (
        <SalesAgreementSearch
            label="Sales Agreements"
            propertyKey={propName<string>(o => o)}
            onSelect={onSelect}
            clearOnSelect={true}
            {...commonProps} />
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={salesAgreementGridOptions} />}
                </div>
            </div>
        </div>
    )
}