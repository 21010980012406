import React, { useState, ReactElement } from 'react'
import { Phone, X, MessageCircle, Mail, Printer } from 'react-feather'
import styles from './TaskTile.module.scss'
import { Task, ContactMethod, useDeleteTaskMutation } from '../../../generated/graphql'
import moment from 'moment'
import Button, { ButtonType } from '../../General/Button/Button'
import Modal from '../../Modal/Modal'

interface Props {
    task: Task
    showDelete?: boolean
    removeCallback?()
}

export default function TaskTile(props: Props): ReactElement {

    const [currentlyDeletingTask, setCurrentlyDeletingTask] = useState(false)
    const [deleteMutation] = useDeleteTaskMutation()

    const removeTask = () => {
        deleteMutation({ variables: { id: props.task.taskKey } })
            .then(() => props.removeCallback())
    }

    const openRemoveModal = () => {
        setCurrentlyDeletingTask(true)
    }

    const closeRemoveModal = () => {
        setCurrentlyDeletingTask(false)
    }

    const contactIcon = (() => {
        switch (props.task.contactMethod) {

            case ContactMethod.Chat:
                return <MessageCircle />

            case ContactMethod.Email:
                return <Mail />

            case ContactMethod.Fax:
                return <Printer />

            case ContactMethod.Phone:
                return <Phone />

            default:
                return <Phone />

        }
    })()
    const firstName = props.task.user.name ? props.task.user.name.split(' ')[0] : ''

    return (
        <div className={styles.taskTile}>
            <div className={styles.taskTile__icon}>{contactIcon}</div>
            <div className="details">
                <div className={styles.taskTile__info}><span>{moment(props.task.taskDate).format("M/D/YY")}</span> <span>{props.task.contact.firstName} {props.task.contact.lastName}</span><span> : {firstName}</span></div>
                <span>{props.task.callDetails}</span>
            </div>
            <div className={styles.taskTile__actionButtons}>
                {props.showDelete && <div className={styles.taskTile__actionButton}><Button buttonType={ButtonType.TightSecondary} clickAction={openRemoveModal}><X /></Button></div>}
            </div>
            {currentlyDeletingTask && <Modal headerText="Delete Task?" narrow={true} footerLeftContent={<Button buttonType={ButtonType.Tertiary} clickAction={closeRemoveModal}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={removeTask}>Delete</Button>}>
                <p>Once you delete this task, it will not be able to be reversed.</p>
            </Modal>}
        </div>
    )
}
