import React from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { useCreateLocationContactMutation, CreateLocationContactMutationVariables, CreateLocationContactMutationResult } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import Button, { ButtonType } from '../../General/Button/Button'
import ContactSearch from '../../Search/CustomerSearch/ContactSearch'

interface Props {
    closeModal(): void
    callback(result: CreateLocationContactMutationResult): Promise<any>
    createContact(): void
    locationKey: number
    customerKey: number
}

export const CreateLocationContact = gql`
    mutation CreateLocationContact($lc: LocationContactInput!) {
        locationContactCreate(locationContact: $lc) {
            locationKey
            contactKey
        }
    }
`

export const DeleteLocationContact = gql`
    mutation DeleteLocationContact($locationKey: Int!, $contactKey: Int!) {
        locationContactDelete(locationKey: $locationKey, contactKey: $contactKey)
    }
`

const LinkContactModal = (props: Props) => {
    const [createLocationContact] = useCreateLocationContactMutation()

    const validationSchema = yup.object().shape({
        lc: yup.object().shape({
            contactKey: yup.number().required()
        })
    })

    const { register, handleSubmit, errors } = useForm<CreateLocationContactMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const submit = (variables: CreateLocationContactMutationVariables) => {
        variables.lc.locationKey = props.locationKey
        variables.lc.default = false

        createLocationContact({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = (<div>
        <Button buttonType={ButtonType.Transparent} clickAction={props.closeModal}>Cancel</Button>
    </div>)

    const footerRightContent = (<div>
        <Button buttonType={ButtonType.Primary} clickAction={props.createContact}>Create New Contact</Button>
        <Button buttonType={ButtonType.Success} isSubmit={true} submitValue="Link Contact" />
    </div>)

    return (
        <form onSubmit={onSubmit} >
            <Modal headerText="Link Contact to Location" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
                <ContactSearch
                    propertyKey={propName<CreateLocationContactMutationVariables>(o => o.lc.contactKey)}
                    label="Contact"
                    {...commonProps}
                    customerKey={props.customerKey}
                />
            </Modal>
        </form>
    )
}
export default LinkContactModal