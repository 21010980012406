import React from 'react'
import SubNav, { SubNavLink } from '../components/SubNav/SubNav'
import { Outlet } from 'react-router'
import { HamburgerState } from '../components/NavBar/NavBar'

interface Props {
    hamburgerState: HamburgerState
}

const PricingPage = (props: Props) => {
    const linkList: SubNavLink[] = [
        { name: "Basis Types", link: "/pricing/basis-types" },
        { name: "Index Contracts", link: "/pricing/index-contracts" },
        { name: "Pre Purchase Agreements", link: "/pricing/pre-purchase-agreements" },
        { name: "Sales Agreements", link: "/pricing/sales-agreements" },
        { name: "Term Contracts", link: "/pricing/term-contract" },
        { name: "Direct Markup", link: "/pricing/direct-markup" },
        { name: "Mass Markup", link: "/pricing/mass-markup" }
        // { name: "Swap Deals", link: "/pricing/swap-deals" }
    ]

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <SubNav
                title="Pricing"
                links={linkList}
                hamburgerState={props.hamburgerState}
            />
            <Outlet />
        </div >
    )
}
export default PricingPage