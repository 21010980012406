import React from 'react'
import Modal from '../../Modal/Modal'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import propName from '../../../helpers/propName'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import gql from 'graphql-tag'
import Button, { ButtonType } from '../../General/Button/Button'
import { useUpdateCarrierRateMutation, UpdateCarrierRateMutationVariables, useGetSingleCarrierRateQuery } from '../../../generated/graphql'
import HookedCheckbox from '../../General/Inputs/HookedCheckbox'
import moment from 'moment'

interface Props {
    closeModal(): void
    callback(): Promise<any>
    updateRateBand(key): void
    carrierRateKey: number
    delete(key): void
}

export const updateCarrierRate = gql`
    mutation UpdateCarrierRate($carrierRate: CarrierRateInput!, $carrierRateKey: Int!) {
        carrierRateUpdate(carrierRate: $carrierRate, id: $carrierRateKey) {
            carrierRateKey
        }
    }
`

const UpdateCarrierRateModal = (props: Props) => {
    const [updateCarrierRate] = useUpdateCarrierRateMutation()
    const { data, loading, error } = useGetSingleCarrierRateQuery({ variables: { key: props.carrierRateKey } })

    const submit = (variables: UpdateCarrierRateMutationVariables) => {
        variables.carrierRateKey = props.carrierRateKey
        //this field is deprecated, but since it was previously used we're keeping it for viewing historical data in reports
        variables.carrierRate.fuelPercent = data.carrierRateGetSingle.fuelPercent
        variables.carrierRate.carrierKey = data.carrierRateGetSingle.carrierKey

        updateCarrierRate({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const validationSchema = yup.object().shape({
        carrierRate: yup.object().shape({
            startDate: yup.date().required(),
            endDate: yup.date().required(),
            basePercent: yup.number().required(),
            default: yup.boolean().required()
        })
    })

    const { register, setValue, handleSubmit, errors } = useForm<UpdateCarrierRateMutationVariables>({ validationSchema })

    const onSubmit = handleSubmit(submit)
    if (loading || error) {
        return <div></div>
    }

    const footerLeftContent = <><Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button><Button buttonType={ButtonType.Tertiary} clickAction={_ => props.delete(props.carrierRateKey)}>Delete</Button></>
    const footerRightContent = <><Button buttonType={ButtonType.Primary} clickAction={_ => props.updateRateBand(props.carrierRateKey)}>Update Rate Bands</Button><Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update Carrier Rate</Button></>

    return (
        <Modal headerText="Update Carrier Rate" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <HookedDateTime
                            setValue={setValue}
                            register={register}
                            propertyKey={propName<UpdateCarrierRateMutationVariables>(o => o.carrierRate.startDate)}
                            defaultValue={new Date(moment.utc(data.carrierRateGetSingle.startDate).format('LL'))}
                            label="Start Date"
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedDateTime
                            setValue={setValue}
                            register={register}
                            propertyKey={propName<UpdateCarrierRateMutationVariables>(o => o.carrierRate.endDate)}
                            defaultValue={new Date(moment.utc(data.carrierRateGetSingle.endDate).format('LL'))}
                            label="End Date"
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            propertyKey={propName<UpdateCarrierRateMutationVariables>(o => o.carrierRate.basePercent)}
                            defaultValue={data.carrierRateGetSingle.basePercent.toString()}
                            label="Rate"
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedCheckbox
                            register={register}
                            errors={errors}
                            propertyKey={propName<UpdateCarrierRateMutationVariables>(o => o.carrierRate.default)}
                            defaultValue={data.carrierRateGetSingle.default}
                            label="Default for Rate Bands"
                        />
                    </div>
                </div>
            </form>
        </Modal>
    )
}
export default UpdateCarrierRateModal