import React, { ReactElement } from 'react'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import ellipsish from '../../../icons/ellipsis-h.svg'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import styles from '../MultiSearch/MultiSearch.module.scss'
import { AllTagQueryVariables, useAllTagQuery, FullTagFragment } from '../../../generated/graphql'
import TagSearch from './TagSearch'

interface Props {
    removeTag(tag: any)
    addTag(tag: any)
    data: any[]
}

export default function MultiTagSearch(props: Props): ReactElement {
    const variables: AllTagQueryVariables = {
        filter: { tagKey: { in: props.data } }
    }

    const { data, loading, error } = useAllTagQuery({ variables })

    const validationSchema = yup.object().shape({
        tagKey: yup.number().required()
    })

    const removeTag = (tag: any) => {
        props.removeTag(tag.tagKey)
    }

    const tagGridOptions: GridOptions<FullTagFragment> = {
        columnDefs: [
            {
                headerName: "Tag",
                dataTransform: o => `${o.name}`,
                field: "name"
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.tagGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeTag }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onTagSelect = (tag: any) => {
        if (tag) {
            props.addTag(tag)
        }
    }

    const header = (
        <div>
            <TagSearch
                label="Tag"
                propertyKey={propName<string>(o => o)}
                onSelect={onTagSelect}
                clearOnSelect={true}
                onAdd={onTagSelect}
                {...commonProps} />
        </div>
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={tagGridOptions} />}
                </div>
            </div>
        </div>
    )
}
