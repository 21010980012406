
import gql from 'graphql-tag'
import { CreateDirectMarkupMutationVariables, useCreateDirectMarkupMutation, DirectMarkupType } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from "../../helpers/propName"
import * as yup from "yup"
import React, { ReactElement, useState } from 'react'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import LocationSearch from '../../components/Search/LocationSearch/LocationSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import { useNavigate } from 'react-router-dom'

export const CreateDirectMarkupQuery = gql`
      mutation CreateDirectMarkup($directMarkup: DirectMarkupInput!) {
         directMarkupCreate(directMarkup: $directMarkup) {
            directMarkupKey
         }
      }
`
interface Props {
}

export default function CreateDirectMarkup(props: Props): ReactElement {
   const navigate = useNavigate()

   const [createDirectMarkupMutation] = useCreateDirectMarkupMutation()
   const [errorMessage, setErrorMessage] = useState<string>(null)

   const navigateToList = () => {
      navigate("/pricing/direct-markup")
   }

   const submit = (variables: CreateDirectMarkupMutationVariables) => {
      variables.directMarkup.isArchived = false

      setErrorMessage(null)
      createDirectMarkupMutation({ variables })
         .then(navigateToList)
         .catch(err => {
            setErrorMessage("Failed to create direct markup. Check that it does not exist already.")
         })
   }

   const validationSchema = yup.object().shape({
      directMarkup: yup.object().shape({
         markup: yup.number().required(),
         directMarkupType: yup.string().trim(),
         supplierKey: yup.number().required(),
         locationKey: yup.number().required(),
         terminalKey: yup.number().required()
      })
   })

   const { register, handleSubmit, errors, setValue } = useForm<CreateDirectMarkupMutationVariables>({ validationSchema })

   const onSubmit = handleSubmit(submit)

   const markupTypes: SelectOption<DirectMarkupType>[] = Object.keys(DirectMarkupType).map(c => {
      return { key: DirectMarkupType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
   })

   const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Direct Markup</Button>)

   const commonProps = { register, errors, setValue }

   return (
      <FormPage title="Create Direct Markup" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  {errorMessage && <div className="col-md-12" style={{ color: "red" }}>{errorMessage}</div>}
                  <div className="col-md-6">
                     <HookedTextInput
                        register={register}
                        label="Markup"
                        propertyKey={propName<CreateDirectMarkupMutationVariables>(o => o.directMarkup.markup)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedSelectOptions
                        options={markupTypes}
                        label="Markup Type"
                        propertyKey={propName<CreateDirectMarkupMutationVariables>(o => o.directMarkup.directMarkupType)}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <SupplierSearch
                        label="Supplier"
                        propertyKey={propName<CreateDirectMarkupMutationVariables>(o => o.directMarkup.supplierKey)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <LocationSearch
                        label="Location"
                        propertyKey={propName<CreateDirectMarkupMutationVariables>(o => o.directMarkup.locationKey)}
                        {...commonProps}
                        showCustomer={true}
                     // onSelect={onLocationSelect}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <TerminalSearch
                        label="Terminal"
                        propertyKey={propName<CreateDirectMarkupMutationVariables>(o => o.directMarkup.terminalKey)}
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
         </form>
      </FormPage>
   )
}

