import React from 'react'
import Modal from '../../Modal/Modal';
import gql from 'graphql-tag';
import useForm from 'react-hook-form';
import { CreateContactMutationVariables, useCreateContactMutation, CreateContactMutationResult } from '../../../generated/graphql';
import propName from '../../../helpers/propName';
import * as yup from "yup"
import HookedTextInput from '../../General/Inputs/HookedTextInput';
import Button, { ButtonType } from '../../General/Button/Button';
import HookedTextArea from '../../General/Inputs/HookedTextArea';
import HookedCheckbox from '../../General/Inputs/HookedCheckbox';

interface Props {
	closeModal(): void
	callback(result: CreateContactMutationResult): Promise<any>
	terminalKey?: number
	supplierKey?: number
	carrierKey?: number
	locationKey?: number
}

export const CreateContact = gql`
	mutation CreateContact($contact: ContactInput!) {
		contactCreate(contact: $contact) {
			contactKey
			default
		}
	}
`

const CreateContactModal = (props: Props) => {

	const [createContactMutation] = useCreateContactMutation()
	const onCreate = (variables: CreateContactMutationVariables) => {

		variables.contact.terminalKey = props.terminalKey
		variables.contact.supplierKey = props.supplierKey
		variables.contact.carrierKey = props.carrierKey
		variables.contact.deleted = false
		createContactMutation({ variables })
			.then(props.callback)
			.then(() => props.closeModal());
	}

	const validationSchema = yup.object().shape({
		contact: yup.object().shape({
			firstName: yup.string().required().trim(),
			lastName: yup.string().notRequired().trim(),
			email: yup.string().trim(),
			phone: yup.string().notRequired().trim(),
			default: yup.bool().required(),
			cellPhone: yup.string().notRequired().trim(),
			homePhone: yup.string().notRequired().trim()
		})
	})

	const { register, handleSubmit, errors } = useForm<CreateContactMutationVariables>({ validationSchema })

	const onSubmit = handleSubmit(onCreate)

	const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
	const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>

	return (
		<Modal headerText="Create Contact" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
			<form onSubmit={onSubmit}>

				<div className="row">
					<div className="col-md-6">
						<HookedTextInput
							register={register}
							label="First Name"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.firstName)}
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<HookedTextInput
							register={register}
							label="Last Name"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.lastName)}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedTextInput
							register={register}
							label="Email"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.email)}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Work Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.phone)}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Cell Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.cellPhone)}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Home Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.homePhone)}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedTextArea
							register={register}
							label="Notes"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.notes)}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedCheckbox
							register={register}
							label="Default"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.default)}
							errors={errors}
						/>
					</div>
				</div>
			</form>

		</Modal>
	)
}
export default CreateContactModal 