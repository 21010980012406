const propName = <T extends {}>(
  property: (object: T) => void,
  index?: number
): string => {
  var chaine = property.toString();

  if (chaine.indexOf("=>") > -1) {
    chaine = chaine.split("=>")[1].trim();
  } else {
    chaine = chaine.split("{")[1].split(";")[0].trim();
  }
  const fullChaine = chaine
    .split(".")
    .slice(1)
    .join(".")
    .replace("{", "")
    .replace("}", "");

  return index === undefined
    ? fullChaine
    : fullChaine.replace("[0]", `[${index}]`);
};

export default propName;
