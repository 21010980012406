import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { InnerUserFragment, useGetUserLazyQuery, useAllUserSearchQuery } from '../../../generated/graphql'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'

interface Props extends DefaultInputProps<InnerUserFragment> {
    onSelect?(user)
    prefilledUserKey?: number
}

export default function UserSearch(props: Props): ReactElement {
    const [executeGetUser, { data, loading }] = useGetUserLazyQuery({ variables: { userKey: props.prefilledUserKey } })

    useEffect(() => {
        if (props.prefilledUserKey) {
            executeGetUser()
        }
    }, [props.prefilledUserKey, executeGetUser])

    const prefilledData = data && data.userGetSingle
    const prefilledDataDisplay = data && `${data.userGetSingle.name}`

    return <TextSearchDropdown<InnerUserFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.id.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={props.onSelect} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        {...props}
    />
}

export const UserSearchQuery = gql`
    query AllUserSearch($search: String) {
        usersGetAll(where: {and: [{ name: {contains: $search }}, { marketingUser: {eq: true }}]}, order: [{ name: ASC}], first: 1000, after: "LTE=") {
            ...allUserListing
        }
    }
`

const Inner = (props: InnerSearchLogicProps<InnerUserFragment>) => {
    const { data, loading, error } = useAllUserSearchQuery({ variables: { search: props.searchTerm } })

    const showData = !loading && !error

    const results = showData &&
        data.usersGetAll.edges.map(user => {
            const display = `${user.node.name}`
            return { key: user.node.id, object: user.node, display }
        })

    const displayProps: SearchDisplayProps<InnerUserFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Users", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}
