import React, { useEffect, ReactElement } from 'react'
import gql from 'graphql-tag'
import { BasicContactFragment, GetContactsSearchQueryVariables, useGetContactsSearchQuery, useGetSingleContactQuery } from '../../../generated/graphql'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'

interface Props extends DefaultInputProps<BasicContactFragment> {
    onSelect?(contact: BasicContactFragment)
    selectedContactKey?: number
    selectedContact?: BasicContactFragment
    locationKey?: number
    customerKey?: number
    selectFirst?: boolean
    selectDefault?: boolean
}

export default function TaskContactSearch(props: Props): ReactElement {
    const { locationKey, selectFirst, onSelect, selectedContactKey, selectDefault } = props

    const { data: singleContactData, loading } = useGetSingleContactQuery({ variables: { contactKey: selectedContactKey }, skip: !selectedContactKey })

    const variables: GetContactsSearchQueryVariables = {
        searchTerm: "",
        locationKey: props.locationKey,
    }

    const { data: contactData, loading: contactsLoading } = useGetContactsSearchQuery({ variables: variables, skip: !locationKey })

    useEffect(() => {
        // I have contacts, so I can select one if I haven't yet (no contactKey prop)
        if (contactData && !contactsLoading && !selectedContactKey && selectDefault) {
            // if there's a default contact in the list
            if (contactData.contactGetByLocationKey.some(x => x.default)) {
                //get the default
                var contact = contactData.contactGetByLocationKey.find(x => x.default)
                //if I have it...
                if (contact) {
                    //and it's different from the key already selected...
                    if (contact.contactKey !== selectedContactKey) {
                        //select it
                        onSelect(contact)
                    }
                }

            }

            // otherwise select the first contact
            else if (contactData.contactGetByLocationKey.length > 0 && selectFirst) {
                onSelect(contactData.contactGetByLocationKey[0])
            }
        }
    }, [contactsLoading, contactData, selectFirst, onSelect, selectedContactKey, selectDefault])

    const prefilledData = singleContactData && props.locationKey ? singleContactData.contactGetSingle : undefined
    const prefilledDataDisplay = prefilledData && `${prefilledData.firstName} ${prefilledData.lastName}`

    return <TextSearchDropdown<BasicContactFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.contactKey.toString())}
        searchWrapper={<Inner locationKey={props.locationKey} searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        disabled={!props.locationKey}
        {...props}
    />
}

export const GetTasksForTaskPage = gql`
    query GetTasksForTaskPage($locationKey: ComparableInt32OperationFilterInput, $date: DateTime) {
        taskGetAll(where: { and: [{ locationKey: $locationKey, taskDate: {gt: $date}}]}) {
            ...TaskShow
        }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicContactFragment>) => {

    const searchTerm = props.searchTerm.trim()
    const locationKey = props.locationKey
    const customerKey = props.customerKey

    const variables: GetContactsSearchQueryVariables = {
        searchTerm,
        locationKey,
        customerKey,
        archived: false
    }

    const { data, loading, error } = useGetContactsSearchQuery({ variables })

    // Combine loading and error into one boolean to ensure data
    // variable is populated
    const showData = !loading && !error

    // Check for showData ensuring not loading or error
    const contactResults = showData &&

        // Map over contacts returned from query
        data.contactGetByLocationKey.map(contact => {
            const display = `${contact.firstName} ${contact.lastName}`
            return { key: contact.contactKey, object: contact, display }
        })

    const displayProps: SearchDisplayProps<BasicContactFragment> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Contacts", results: contactResults }
        ],
        isLoading: loading, error, searchTerm
    }

    return <SearchDisplay {...displayProps} />
}
