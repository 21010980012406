import React, { ReactElement } from 'react'
import TerminalSearch from './TerminalSearch'
import propName from '../../../helpers/propName'
import styles from '../MultiSearch/MultiSearch.module.scss'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import { AllTerminalQueryVariables, useAllTerminalQuery, InnerTerminalFragment } from '../../../generated/graphql'
import * as yup from 'yup'
import ellipsish from '../../../icons/ellipsis-h.svg'
import useForm from 'react-hook-form'

interface Props {
    removeTerminal(terminal: any)
    addTerminal(terminal: any)
    data: any[]
}

export default function MultiTerminalSearch(props: Props): ReactElement {
    const variables: AllTerminalQueryVariables = {
        sortObject: {},
        cursor: "LTE=",
        filterObject: { terminalKey: { in: props.data } },
        pageSize: 25
    }

    const { data, loading, error } = useAllTerminalQuery({ variables })

    const validationSchema = yup.object().shape({
        terminalKey: yup.number().required()
    })

    const removeTerminal = (terminal: any) => {
        props.removeTerminal(terminal.terminalKey)
    }

    const terminalGridOptions: GridOptions<InnerTerminalFragment> = {
        columnDefs: [
            {
                headerName: "Name",
                dataTransform: o => o.name,
                field: "name"
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.terminalGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeTerminal }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onTerminalSelect = (terminal: InnerTerminalFragment) => {
        if (terminal) {
            props.addTerminal(terminal.terminalKey)
        }
    }

    const header = (
        <TerminalSearch
            label="Terminal"
            propertyKey={propName<any>(o => o.terminalKey)}
            onSelect={onTerminalSelect}
            clearOnSelect={true}
            {...commonProps}
        />
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={terminalGridOptions} />}
                </div>
            </div>
        </div>
    )
}