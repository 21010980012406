import React, { useState } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import gql from 'graphql-tag'
import { useGetSingleCustomerQuery, LocationListingFragment, useDeleteLocationMutation, BusinessType } from '../../generated/graphql'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import propName from '../../helpers/propName'
import ellipsisHorizontal from '../../icons/ellipsis-h.svg'
import Tile from '../../components/General/Tile/Tile'
import { Plus } from 'react-feather'
import EmptyState from '../../components/General/Tile/EmptyState/EmptyState'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { Params, useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const GetSingleCustomer = gql`
    fragment locationListing on Location {
        locationKey
        name
        city
        state
        zipCode
    }

    fragment singleCustomer on Customer {
        customerKey
        description
        name
        dbaName
        businessType
        deliveryTimeKey
        deliveryTime {
            code
            description
        }
        address1
        address2
        city
        state
        zipCode
        directions

        creditLimit
        creditNotes

        locations {
            ...locationListing
        }
    }

    query GetSingleCustomer($id: Int!) {
        customerGetSingle(id: $id) {
            ...singleCustomer
        }
    }
`

export const DeleteLocationMutation = gql`
   mutation DeleteLocation($id: Int!) {
      locationDelete(id: $id)
   }
`

interface MatchParams extends Params {
    customerKey: string
}

interface Props {
}

const CustomerShow = (props: Props) => {
    const navigate = useNavigate()

    const [searchParams,] = useSearchParams()

    const { customerKey: key } = useParams() as MatchParams

    const customerKey = parseInt(key)
    const [currentlyDeletingLocation, setCurrentlyDeleting] = useState<LocationListingFragment>(null)
    const [deleteMutation] = useDeleteLocationMutation()

    const { data, loading, error, refetch } = useGetSingleCustomerQuery({ variables: { id: customerKey } })
    if (loading) { return (null) }
    if (error) { return <p>Error</p> }

    const shouldForward = searchParams.get("navigate")
    // Replace current url to make sure navigate is not in the url so people can use the back button
    if (data.customerGetSingle.locations.length === 1 && shouldForward) {
        navigate(`/locations/show/${data.customerGetSingle.locations[0].locationKey}`)
    }

    const businessTypes: SelectOption<BusinessType>[] = Object.values(BusinessType).map(c => { return { key: c, value: c.charAt(0).toUpperCase() + c.slice(1).toLowerCase() } })
    const defaultBusinessType = businessTypes.find(bt => bt.key === data.customerGetSingle.businessType)

    const navigateToLocationCreate = () => navigate(`/locations/create/${customerKey}`)
    const navigateToEditCustomer = (customerKey: number) => navigate(`/customers/edit/${customerKey}`)

    const editLocation = (location: LocationListingFragment) => {
        navigate(`/locations/edit/${location.locationKey}`)
    }

    const deleteAction = (location: LocationListingFragment) => {
        setCurrentlyDeleting(location)
    }

    const gridOptions: GridOptions<LocationListingFragment> = {
        columnDefs: [
            { headerName: "Name", dataTransform: o => o.name ? o.name : `${o.city}, ${o.state}` },
            { headerName: "City", dataTransform: o => o.city },
            { headerName: "State", dataTransform: o => o.state },
            { headerName: "Zip", dataTransform: o => o.zipCode },
        ],

        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.customerGetSingle.locations.sort(function (a, b) { return a.name > b.name ? 1 : -1 }),
        rowActions: [
            {
                icon: ellipsisHorizontal, items: [
                    { displayName: "Edit", action: editLocation },
                    { displayName: "Delete", action: deleteAction }
                ]
            }
        ],
        dataKeyColumn: propName<LocationListingFragment>(o => o.locationKey),
        linkToPath: "/locations/show/:key"
    }

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => { refetch(); setCurrentlyDeleting(null) })
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(null)
    }

    const hasLocations = (data.customerGetSingle && data.customerGetSingle.locations && data.customerGetSingle.locations.length > 0)

    return (
        <GeneralPage title="Customers" headerContent={
            <div>
                <Button clickAction={() => { navigateToEditCustomer(customerKey) }}>Edit Customer</Button>
            </div>
        }>
            <Tile>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>{data.customerGetSingle.dbaName ? data.customerGetSingle.dbaName : data.customerGetSingle.name}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>
                                    {data.customerGetSingle.address1}
                                </h5>
                            </div>
                            {data.customerGetSingle.address2 && <div className="col-md-12"><h5>
                                {data.customerGetSingle.address2}
                            </h5></div>}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>{data.customerGetSingle.city}, {data.customerGetSingle.state} {data.customerGetSingle.zipCode}</h5>
                            </div>
                        </div>
                        {data.customerGetSingle.directions && <div className="row">
                            <div className="col-md-12">
                                <h5><strong>Directions:</strong> {data.customerGetSingle.directions}</h5>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-6">
                                <h5><strong>Credit Limit:</strong> {data.customerGetSingle.creditLimit}</h5>
                            </div>
                            <div className="col-md-6">
                                <h5><strong>Business Type:</strong> {defaultBusinessType.value}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                {data.customerGetSingle.deliveryTimeKey &&
                                    <h5><strong>Delivery Time:</strong> {data.customerGetSingle.deliveryTime.code}-{data.customerGetSingle.deliveryTime.description}</h5>}
                            </div>
                        </div>
                        {data.customerGetSingle.creditNotes &&
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><strong>Credit Notes:</strong> {data.customerGetSingle.creditNotes}</h5>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Tile>
            <Tile title="Locations" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => navigateToLocationCreate()}><Plus /></Button>}
                headerButtonCondition={hasLocations}>
                <GridBase<LocationListingFragment> gridOptions={gridOptions} />
                <EmptyState text="You don't have any Locations linked to this customer yet. Why don't you create one?"
                    data={data.customerGetSingle.locations} buttonText="Create Location" buttonAction={() => navigateToLocationCreate()}></EmptyState>
            </Tile>

            {currentlyDeletingLocation && <DeleteEntityModal
                cancel={cancelDelete}
                confirm={confirmDelete}
                entityKey={currentlyDeletingLocation.locationKey}
                entityName="location"
                orderFilter={{ locationKey: { eq: currentlyDeletingLocation.locationKey } }} />}
        </GeneralPage>
    )
}

export default CustomerShow