import React, { Component } from 'react'
import SubNav, { SubNavLink } from '../components/SubNav/SubNav'
import { Outlet } from 'react-router-dom'
import { HamburgerState } from '../components/NavBar/NavBar'

interface Props {
    hamburgerState: HamburgerState
}

interface State { }

class ReportPage extends Component<Props, State> {
    state = {}

    render() {
        const linkList: SubNavLink[] = [
            { name: "Gallons By State", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Monthly Contract Volumes", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSectionda6ee87020962a30c4c0&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Contract Volumes (Visual)", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection16419776bed63e443ad8&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Shipped Dispatched Orders", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection88fe07990507ce6d5d49&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Basis Type History", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection85d593a40575dbc7d083&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Call Details", link: "/reports/task-details" },
            { name: "Supplier Postings", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection2f712433cc3c5649ff91&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Supplier Posting Visual", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection802d64b837ee52b03679&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Term Contracts (Tabular)", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSectionaf2f041fd77759908ad3&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true },
            { name: "Term Contracts (Visual)", link: "https://app.powerbi.com/redirect?action=OpenReport&ReportObjectId=22afb836-5a73-487f-8092-f08cfa0ce05e&ReportPage=ReportSection726594e3bfe73c8b5687&ctid=b661d6a9-0713-4252-a694-ca8e8cddfac5", newTab: true }
        ]

        return (
            <div style={{ display: "flex", width: "100%" }}>
                <SubNav
                    title="Reporting"
                    links={linkList}
                    hamburgerState={this.props.hamburgerState}
                />

                <Outlet />
            </div>
        )

    }
}
export default ReportPage