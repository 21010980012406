import React, { ReactElement } from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import { SingleLocationTerminalFragment, useUpdateLocationTerminalMutation, UpdateLocationTerminalMutationVariables, UpdateLocationTerminalMutationResult } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../General/Button/Button'

interface Props {
    lt: SingleLocationTerminalFragment
    callback(payload: UpdateLocationTerminalMutationResult): Promise<any>
    closeModal()
}

export const UpdateLocationTerminal = gql`
    mutation UpdateLocationTerminal($locationTerminal: LocationTerminalInput!) {
        locationTerminalEdit(locationTerminal: $locationTerminal) {
            locationKey
            terminalKey
        }
    }
`

export default function EditLocationTerminalModal(props: Props): ReactElement {

    const [locationTerminalUpdate] = useUpdateLocationTerminalMutation()
    const submit = (variables: UpdateLocationTerminalMutationVariables) => {
        variables.locationTerminal.terminalKey = props.lt.terminalKey
        variables.locationTerminal.locationKey = props.lt.locationKey

        locationTerminalUpdate({ variables })
            .then(props.callback)
            .then(props.closeModal)
    }

    const validationSchema = yup.object().shape({
        locationTerminal: yup.object().shape({
            mileage: yup.number().required()
        })
    })

    const { register, handleSubmit, errors } = useForm<UpdateLocationTerminalMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update</Button>

    return (
        <Modal headerText="Update Location Terminal" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <HookedTextInput
                    propertyKey={propName<UpdateLocationTerminalMutationVariables>(o => o.locationTerminal.mileage)}
                    label="Mileage"
                    defaultValue={props.lt.mileage.toString()}
                    {...commonProps} 
                />
            </form>
        </Modal>
    )
}