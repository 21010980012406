import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import { InnerOrderFragment, useDashboardOrderQuery, useDeleteOrderMutation, OrderStatus, DashboardOrderQueryVariables, UpdateOrderMutationVariables, useOrderBulkCloseMutation, useOrderSupplierPaidMutation, useOrderCarrierPaidMutation, useOrderBolAndGallonsMutation, OrderBolAndGallonsMutationVariables, OrderType, OrderSortInput, OrderFilterInput, SortEnumType } from '../../generated/graphql'

import Button, { ButtonType } from '../../components/General/Button/Button'
import Tile from '../../components/General/Tile/Tile'
import GridBase, { GridOptions, GridSize } from '../../components/General/GridBase/GridBase'
import moment from 'moment'
import ellipsish from '../../icons/ellipsis-h.svg'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Modal from '../../components/Modal/Modal'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import OrderDispatchExportModal from '../../components/AppliedModals/OrderDispatchExportModal/OrderDispatchExportModal'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import propName from '../../helpers/propName'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import { FileText } from "react-feather"
import { GraphQLError } from 'graphql'
import styles from '../../components/General/GridBase/GridBase.module.scss'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import Badge from '../../components/General/Badge/Badge'
import UnpaidOrderTile from '../../components/Tiles/UnpaidOrderTile/UnpaidOrderTile'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const OrdersFragment = gql`
    fragment innerOrder on Order {
        orderKey
        location {
            name
            city
            state
        }
        customer {
            name
        }
        supplier {
            supplierKey
            name
        }
        terminal {
            name
        }
        carrier {
            carrierKey
            name
            email
            state
        }
        indexContract {
            fileNumber
        }
        createdByUser {
            name
        }
        deliveryDate
        tripNumber
        status
        actualGallons
        gallons
        freight
        tolls
        product
        otherCharge
        otherCost
        supplierPaid
        carrierPaid
        manifest
        delivered
        orderType
        tripsActualGallons
        tripManifest
        tripNotes
        readyForInvoicing
        specialInstructions
        costDescription
        postingKey
        indexContractKey
        legacyCostType
        spotContract
        stateAssessment
        stateTax
        assessment
    }

    fragment orderDashboardListing on OrderDashboardConnection {
        edges {
            cursor
            ...innerOrderListing
        }
        totalCount
    }

    fragment innerOrderListing on OrderDashboardEdge {
        node {
            ...innerOrder
        }
    }

    fragment orderGetAllListing on OrderGetAllConnection {
        edges {
            cursor
            ...innerOrderGetAllListing
        }
        totalCount
    }

    fragment innerOrderGetAllListing on OrderGetAllEdge {
        node {
            ...innerOrder
        }
    }
`

export const AllOrdersQuery = gql`
    query AllOrders ($sortObject: [OrderSortInput!], $filterObject: OrderFilterInput, $cursor: String!, $pageSize: Int!) {
        orderGetAll(order: $sortObject, where: $filterObject, after: $cursor, first: $pageSize) {
            ...orderGetAllListing
        }
    }
`

export const OrderDashboardQuery = gql`
    query DashboardOrder($notPaidOrders: Boolean, $sortObject: [OrderSortInput!], $filterObject: OrderFilterInput, $cursor: String!, $pageSize: Int!, $status: OrderStatus!, $customer: String, $terminal: String, $carrier: String, $supplier: String, $customSort: String, $customOrder: String) {
        orderDashboard(notPaidOrders: $notPaidOrders, order: $sortObject, where: $filterObject, after: $cursor, first: $pageSize, status: $status, customer: $customer, terminal: $terminal, carrier: $carrier, supplier: $supplier, sort: $customSort, sortDirection: $customOrder) {
            ...orderDashboardListing
        }
    }
`

export const BulkCloseOrdersMutation = gql`
    mutation orderBulkClose($ids: [Int!], $sendToQB: Boolean!) {
        orderBulkClose(ids: $ids, sendToQuickbooks: $sendToQB)
    }
`

export const MarkCarrierPaidMutation = gql`
    mutation orderCarrierPaid($orderKey: Int!) {
        orderMarkCarrierPaid(orderKey: $orderKey)
    }

    mutation orderCarrierAllPaid($carrierKey: Int!, $date: DateTime!) {
        orderMarkAllCarrierPaid(carrierKey: $carrierKey, date: $date)
    }
`

export const MarkSupplierPaidMutation = gql`
    mutation orderSupplierPaid($orderKey: Int!) {
        orderMarkSupplierPaid(orderKey: $orderKey)
    }

    mutation orderSupplierAllPaid($supplierKey: Int!, $date: DateTime!) {
        orderMarkAllSupplierPaid(supplierKey: $supplierKey, date: $date)
    }
`

export const OrderUpdateBol = gql`
    mutation orderBolAndGallons($orderKey: Int!, $manifest: String!, $gallons: Int!, $close: Boolean!) {
        orderUpdateBolAndGallons(orderKey: $orderKey, manifest: $manifest, actualGallons: $gallons, close: $close)
    }
`

interface OrderDataWrapperProps {
    currentSort: OrderSortInput
    updateSort(newSort: OrderSortInput)

    currentFilter: OrderFilterInput
    updateFilter(newFilter: OrderFilterInput)

    editOrder(order: InnerOrderFragment)
    deleteOrder(order: InnerOrderFragment)
    currentlyDeletingOrder: InnerOrderFragment

    currentlyDispatchingOrders: boolean
    updateSelectedRows(selectedRows: number[]): any
    dispatchOrders(dispatch: boolean): any

    currentlyClosingOrders: boolean
    closeOrders(close: boolean): any

    showUnpaidOrders: boolean

    carrierPaying(order: InnerOrderFragment)
    orderCarrierPaying: InnerOrderFragment
    supplierPaying(order: InnerOrderFragment)
    orderSupplierPaying: InnerOrderFragment

    updateStartDate(date: Date)
    startDate: Date
    updateEndDate(date: Date)
    endDate: Date

    updateStatus(status: OrderStatus)
    orderStatus: OrderStatus

    urlParams: URLSearchParams
}

const OrderDataWrapper = (props: OrderDataWrapperProps): JSX.Element => {
    const pageSize = 25
    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const { orderStatus } = props

    const [cursor, setCursor] = useState("LTE=")
    const [selectedRows, setSelectedRows] = useState<number[]>()
    const [unpaidOrders, setUnpaidOrders] = useState(props.showUnpaidOrders)
    const [closeOrder, setCloseOrder] = useState(undefined)
    const [errors, setErrors] = useState<GraphQLError[]>()
    const [customer, setCustomer] = useState("")
    const [carrier, setCarrier] = useState("")
    const [supplier, setSupplier] = useState("")
    const [terminal, setTerminal] = useState("")
    const [customSort, setCustomSort] = useState("")
    const [customOrder, setCustomOrder] = useState("")

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, customer, supplier, terminal, setCursor, orderStatus])

    useEffect(() => {
        if (props.showUnpaidOrders !== unpaidOrders) {
            setUnpaidOrders(props.showUnpaidOrders)
        }
    }, [props.showUnpaidOrders, unpaidOrders, setUnpaidOrders])

    useEffect(() => {
        if (props.currentSort.carrierKey !== undefined) {
            setCustomSort("carrier")
            setCustomOrder(props.currentSort.carrierKey)
        }
        else if (props.currentSort.supplierKey !== undefined) {
            setCustomSort("supplier")
            setCustomOrder(props.currentSort.supplierKey)
        }
        else if (props.currentSort.terminalKey !== undefined) {
            setCustomSort("terminal")
            setCustomOrder(props.currentSort.terminalKey)
        }
        else if (props.currentSort.customerKey !== undefined) {
            setCustomSort("customer")
            setCustomOrder(props.currentSort.customerKey)
        }
        else if (props.currentSort.tripNumber !== undefined || props.currentSort.deliveryDate !== undefined || props.currentSort.manifest !== undefined) {
            setCustomSort("")
            setCustomOrder("")
        }
    }, [props, setCustomSort, setCustomOrder])

    const variables: DashboardOrderQueryVariables = {
        sortObject: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter }),
        notPaidOrders: unpaidOrders ? true : undefined,
        status: orderStatus,
        ...(customer !== "" && { customer }),
        ...(terminal !== "" && { terminal }),
        ...(supplier !== "" && { supplier }),
        ...(carrier !== "" && { carrier }),
        customSort,
        customOrder
    }

    const { data, loading, error, refetch } = useDashboardOrderQuery({ variables: variables })

    const [deleteMutation, { loading: isDeleting }] = useDeleteOrderMutation()
    const [bulkCloseOrders] = useOrderBulkCloseMutation()
    const [supplierPaid] = useOrderSupplierPaidMutation()
    // const [supplierAllPaid] = useOrderSupplierAllPaidMutation()
    const [carrierPaid] = useOrderCarrierPaidMutation()
    // const [carrierAllPaid] = useOrderCarrierAllPaidMutation()
    const [updateBolAndGallons] = useOrderBolAndGallonsMutation()

    const orderUpdateSchema = yup.object().shape({
        manifest: yup.string().required(),
        gallons: yup.number().required(),
        close: yup.boolean().required()
    })

    const { register: r, handleSubmit: hs, errors: orderBolAndGallonsErrors, setValue: sv } = useForm<OrderBolAndGallonsMutationVariables>({ validationSchema: orderUpdateSchema })
    const commonProps = { register: r, errors: orderBolAndGallonsErrors, setValue: sv }

    const updateOrderBolAndGallons = (variables: OrderBolAndGallonsMutationVariables) => {
        variables.orderKey = closeOrder.orderKey
        updateBolAndGallons({ variables })
            .then(() => {
                setCloseOrder(undefined)
                refetch()
            })
            .catch((_) => {
                if (_.graphQLErrors) {
                    setErrors(_.graphQLErrors)
                }
            })
    }

    const onSubmit = hs(updateOrderBolAndGallons)

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => refetch())
            .then(() => props.deleteOrder(null))
    }

    const cancelDelete = () => {
        props.deleteOrder(null)
    }

    const cancelDispatch = () => {
        props.dispatchOrders(false)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const updateSelectedRows = (selectedKeys: number[]) => {
        setSelectedRows(selectedKeys)
        props.updateSelectedRows(selectedKeys)
    }

    const markSupplierPaid = (order: InnerOrderFragment) => {
        supplierPaid({ variables: { orderKey: order.orderKey } })
            .then(_ => props.supplierPaying(undefined))
            .then(_ => refetch())
    }

    const markCarrierPaid = (order: InnerOrderFragment) => {
        carrierPaid({ variables: { orderKey: order.orderKey } })
            .then(_ => props.carrierPaying(undefined))
            .then(_ => refetch())
    }

    // const markAllSupplierPaid = (order: InnerOrderFragment) => {
    //     supplierAllPaid({ variables: { supplierKey: order.supplier.supplierKey, date: order.deliveryDate } })
    //         .then(_ => props.supplierPaying(undefined))
    //         .then(_ => refetch())
    // }

    // const markAllCarrierPaid = (order: InnerOrderFragment) => {
    //     carrierAllPaid({ variables: { carrierKey: order.carrier.carrierKey, date: order.deliveryDate } })
    //         .then(_ => props.carrierPaying(undefined))
    //         .then(_ => refetch())
    // }

    const orderTypes: SelectOption<OrderType>[] = Object.keys(OrderType).map(c => {
        return { key: OrderType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const gridOptions: GridOptions<InnerOrderFragment> = {
        columnDefs: [
            {
                headerName: "Customer", dataTransform: o => `${o.customer.name} - ${o.location.name ? o.location.name : `${o.location.city}, ${o.location.state}`}`,
                searchOverride: <input type="text" onChange={e => setCustomer(e.target.value)} className={styles.search__input} />, sortable: true, field: "customerKey"
            },
            {
                headerName: "Supplier", dataTransform: o => o.supplier.name,
                searchOverride: <input type="text" onChange={e => setSupplier(e.target.value)} className={styles.search__input} />, sortable: true, field: "supplierKey"
            },
            { headerName: "Type", dataTransform: o => orderTypes.filter(x => x.key === o.orderType)[0].value },
            {
                headerName: "Terminal", dataTransform: o => o.terminal.name,
                searchOverride: <input type="text" onChange={e => setTerminal(e.target.value)} className={styles.search__input} />, sortable: true, field: "terminalKey"
            },
            {
                headerName: "Carrier", dataTransform: o => o.carrier.name,
                searchOverride: <input type="text" onChange={e => setCarrier(e.target.value)} className={styles.search__input} />, sortable: true, field: "carrierKey"
            },
            { headerName: "Delivery Date", dataTransform: o => moment.utc(o.deliveryDate).format('LL'), field: "deliveryDate", sortable: true },
            { headerName: "", contentOverride: o => o.spotContract ? <Badge textColor="#D77328" backgroundColor="#F2E6DD">S</Badge> : '' },
            { headerName: "BOL", dataTransform: o => o.manifest !== "" ? o.manifest : o.tripManifest, field: "manifest", sortable: true, searchable: true },
            { headerName: "Gallons", dataTransform: o => o.actualGallons ? o.actualGallons.toString() : (o.tripsActualGallons ? o.tripsActualGallons.toString() : o.gallons.toString()) },
            { headerName: "Created By", dataTransform: o => o.createdByUser ? o.createdByUser.name : "" }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.orderDashboard.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editOrder },
                    { displayName: "Delete", action: props.deleteOrder }
                ]
            }
        ],
        dataKeyColumn: "orderKey",
        linkToPath: `/orders/edit/:key?${props.urlParams}`,
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter,
        selectableRows: orderStatus !== OrderStatus.Closed && orderStatus !== OrderStatus.All,
        updateSelectedRows,
        size: GridSize.small
    }

    const openUpdateBol = (order) => {
        setCloseOrder(order)
    }

    const orderStatuses: SelectOption<OrderStatus>[] = Object.keys(OrderStatus).map(c => {
        return { key: OrderStatus[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const defaultOrderStatus = orderStatuses.find(os => os.key === orderStatus)

    //add trip number if viewing orders that would have a trip number
    if (orderStatus !== OrderStatus.Open) {
        gridOptions.columnDefs.splice(4, 0, { headerName: "Trip #", dataTransform: o => o.tripNumber, field: "tripNumber", sortable: true, searchable: true, copyable: true })
    }
    if (orderStatus === OrderStatus.Dispatched) {
        gridOptions.rowActions[0].items.push({ displayName: "Update and Close", action: openUpdateBol })
        gridOptions.columnDefs.splice(9, 0, { headerName: "Ready", dataTransform: o => o.readyForInvoicing ? "Yes" : "" })
    }
    if (orderStatus === OrderStatus.Closed || orderStatus === undefined || orderStatus === OrderStatus.All) {
        gridOptions.columnDefs.splice(9, 0, { headerName: "Price", dataTransform: o => moneyFormatter.format(o.delivered * (o.actualGallons ? o.actualGallons : o.gallons) + o.otherCharge) })
    }
    if (orderStatus === OrderStatus.All || orderStatus === undefined) {
        gridOptions.columnDefs.splice(3, 0, { headerName: "Status", dataTransform: o => orderStatuses.find(x => x.key === o.status).value })
    }

    if (props.showUnpaidOrders) {
        gridOptions.columnDefs.splice(8, 0, { headerName: "Product", dataTransform: o => o.product.toString() })
        gridOptions.columnDefs.splice(9, 0, { headerName: "Freight", dataTransform: o => o.freight.toString() })
        gridOptions.columnDefs.splice(10, 0, { headerName: "Carrier Paid", dataTransform: o => o.carrierPaid ? "Yes" : "No" })
        gridOptions.columnDefs.splice(11, 0, { headerName: "Supplier Paid", dataTransform: o => o.supplierPaid ? "Yes" : "No" })

        gridOptions.rowActions[0].items.push({ displayName: "Mark Supplier Paid", action: props.supplierPaying })
        gridOptions.rowActions[0].items.push({ displayName: "Mark Carrier Paid", action: props.carrierPaying })
    }

    const validationSchema = yup.object().shape({
        order: yup.object().shape({
            status: yup.string().required()
        })
    })

    const { register, setValue } = useForm<UpdateOrderMutationVariables>({ validationSchema })
    const statusChanged = (value: OrderStatus) => {
        props.updateStatus(value)
        props.updateSelectedRows(undefined)
    }

    const startDateChanged = (name: string, value) => {
        props.updateStartDate(value)
    }

    const endDateChanged = (name: string, value) => {
        props.updateEndDate(value)
    }

    const dispatchCallback = () => {
        setSelectedRows(undefined)
        props.updateSelectedRows(undefined)
        refetch()
    }

    const closeOrders = (sendToQB: boolean) => {
        bulkCloseOrders({ variables: { ids: selectedRows, sendToQB } })
            .then(_ => {
                props.closeOrders(false)
                setSelectedRows(undefined)
                props.updateSelectedRows(undefined)
                refetch()
            })
            .catch((_) => {
                if (_.graphQLErrors) {
                    setErrors(_.graphQLErrors)
                }
            })
    }

    const headerContent = (
        <div>
            <div className="row align-items-center">
                <div className="col">
                    <HookedSelectOptions<OrderStatus>
                        options={orderStatuses}
                        propertyKey={"orderStatus"}
                        setDefaultValue={defaultOrderStatus}
                        register={register}
                        errors={{}}
                        setValue={setValue}
                        onChange={statusChanged}
                        alignCenter={false}
                    />
                </div>
                <div className="col-lg-3 col-xl-2">
                    <HookedDateTime
                        label="Start Date"
                        alignCenter={false}
                        propertyKey="startDate"
                        defaultValue={props.startDate}
                        register={register}
                        errors={{}}
                        setValue={setValue}
                        Change={startDateChanged}
                    />
                </div>
                <div className="col-lg-3 col-xl-2">
                    <HookedDateTime
                        label="End Date"
                        alignCenter={false}
                        propertyKey="endDate"
                        defaultValue={props.endDate}
                        register={register}
                        errors={{}}
                        setValue={setValue}
                        Change={endDateChanged}
                    />
                </div>

            </div>
        </div>
    )

    return (
        <>
            <PaginationWrapper
                pageSize={pageSize}
                totalCount={data ? data.orderDashboard.totalCount : 0}
                changeCursor={pageChange}>
                <div className="row">
                    <div className="col-md-12">
                        {headerContent}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <GridBase<InnerOrderFragment> gridOptions={gridOptions} />
                        {!!props.currentlyDeletingOrder && <Modal headerText="Delete?"
                            footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
                            footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmDelete(props.currentlyDeletingOrder.orderKey)} isDisabled={isDeleting}>Delete</Button>}
                            noScroll
                        >
                            <p>Once you delete this order, it will not be able to be reversed.</p>
                        </Modal>}
                        {!!props.currentlyDispatchingOrders && <OrderDispatchExportModal orderKeys={selectedRows} closeModal={cancelDispatch} callback={dispatchCallback} />}
                        {!!props.currentlyClosingOrders && <Modal headerText="Close Orders?"
                            footerLeftContent={<Button clickAction={_ => { props.closeOrders(false) }} buttonType={ButtonType.Transparent}>Cancel</Button>}
                            footerRightContent={<Button clickAction={_ => closeOrders(true)} buttonType={ButtonType.Success}>Close Orders and Send To Quickbooks</Button>}
                            noScroll
                        >
                            {errors && errors.map((e, index) => {
                                return <p key={index} style={{ color: 'red' }}>{e.message}</p>
                            })}
                        </Modal>}
                        {!!props.orderCarrierPaying && <Modal headerText="Mark Carrier Paid?"
                            footerLeftContent={<Button clickAction={_ => props.carrierPaying(undefined)} buttonType={ButtonType.Transparent}>Cancel</Button>}
                            footerRightContent={<>
                                <Button clickAction={_ => markCarrierPaid(props.orderCarrierPaying)} buttonType={ButtonType.Primary}>Mark Carrier Paid</Button>
                                {/* <Button clickAction={_ => markAllCarrierPaid(props.orderCarrierPaying)} buttonType={ButtonType.Success}>{`Mark All Carrier Paid on ${moment.utc(props.orderCarrierPaying.deliveryDate).format('LL')}`}</Button> */}
                            </>}
                            noScroll
                        >
                        </Modal>}
                        {!!props.orderSupplierPaying && <Modal headerText="Mark Supplier Paid?"
                            footerLeftContent={<Button clickAction={_ => props.supplierPaying(undefined)} buttonType={ButtonType.Transparent}>Cancel</Button>}
                            footerRightContent={<>
                                <Button clickAction={_ => markSupplierPaid(props.orderSupplierPaying)} buttonType={ButtonType.Primary}>Mark Supplier Paid</Button>
                                {/* <Button clickAction={_ => markAllSupplierPaid(props.orderSupplierPaying)} buttonType={ButtonType.Success}>{`Mark All Supplier Paid on ${moment.utc(props.orderSupplierPaying.deliveryDate).format('LL')}`}</Button> */}
                            </>}
                            noScroll
                        >
                        </Modal>}
                        {!!closeOrder && <Modal headerText="Update Order?"
                            footerLeftContent={<Button clickAction={_ => { setCloseOrder(undefined) }} buttonType={ButtonType.Transparent}>Cancel</Button>}
                            footerRightContent={<Button clickAction={onSubmit} buttonType={ButtonType.Primary}>Update Order</Button>}
                        >
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        {errors && errors.map((e, index) => {
                                            return <p key={index} style={{ color: 'red' }}>{e.message}</p>
                                        })}
                                    </div>
                                    <div className="col-md-6">
                                        <HookedTextInput
                                            label="BOL"
                                            propertyKey={propName<OrderBolAndGallonsMutationVariables>(o => o.manifest)}
                                            defaultValue={closeOrder.tripManifest ? closeOrder.tripManifest : closeOrder.manifest}
                                            {...commonProps}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <HookedTextInput
                                            label="Actual Gallons"
                                            propertyKey={propName<OrderBolAndGallonsMutationVariables>(o => o.gallons)}
                                            defaultValue={closeOrder.actualGallons ? closeOrder.actualGallons : (closeOrder.tripsActualGallons ? closeOrder.tripsActualGallons : closeOrder.gallons)}
                                            // defaultValue={closeOrder.tripsActualGallons ? closeOrder.tripsActualGallons : (closeOrder.actualGallons ? closeOrder.actualGallons : closeOrder.gallons)}
                                            {...commonProps}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <strong>Special Instructions: </strong><p>{closeOrder.specialInstructions}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <strong>Cost Description: </strong><p>{closeOrder.costDescription}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <strong>Trip Notes: </strong><p>{closeOrder.tripNotes ? closeOrder.tripNotes : "No trip notes"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <HookedCheckbox
                                            label="Close Order"
                                            propertyKey={propName<OrderBolAndGallonsMutationVariables>(o => o.close)}
                                            defaultValue={true}
                                            {...commonProps}
                                        />
                                    </div>
                                </div>
                            </form>
                        </Modal>
                        }
                    </div>
                </div>
            </PaginationWrapper>
            {data && props.showUnpaidOrders && <UnpaidOrderTile orderQuery={data}></UnpaidOrderTile>}
        </>
    )
}

const Orders = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // default is orders that will be delivered tomorrow
    let initialStartDate = moment().add(1, 'day').startOf('day').toDate()
    let initialEndDate = moment().add(1, 'day').startOf('day').toDate()

    let initialStatus = OrderStatus.All


    const orderStatuses: SelectOption<OrderStatus>[] = Object.keys(OrderStatus).map(c => {
        return { key: OrderStatus[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const [currentSort, setCurrentSort] = useState<OrderSortInput>({ deliveryDate: SortEnumType.Desc })

    if (searchParams.get('startDate')) {
        if (searchParams.get('startDate') === "none") {
            initialStartDate = null
        }
        else {
            initialStartDate = moment(searchParams.get('startDate')).startOf('day').toDate()
        }
    }
    if (searchParams.get('endDate')) {
        if (searchParams.get('endDate') === "none") {
            initialEndDate = null
        }
        else {
            initialEndDate = moment(searchParams.get('endDate')).startOf('day').toDate()
        }
    }

    if (searchParams.get('status')) {
        const stat = orderStatuses.find(x => x.value.toLowerCase() === searchParams.get('status').toLowerCase())
        if (stat) {
            initialStatus = stat.key
        }
    }

    let initialFilter: OrderFilterInput = { deliveryDate: { gte: initialStartDate, lte: initialEndDate } }

    const [startDate, setStartDate] = useState(initialStartDate)
    const [endDate, setEndDate] = useState(initialEndDate)
    const [notPaid, setNotPaid] = useState(false)
    const [currentStatus, setCurrentStatus] = useState<OrderStatus>(initialStatus)
    const [currentFilter, setCurrentFilter] = useState(initialFilter)
    const [currentlyDeletingOrder, setCurrentlyDeletingOrder] = useState<InnerOrderFragment>(undefined)
    const [orderCarrierPaying, setOrderCarrierPaying] = useState<InnerOrderFragment>(undefined)
    const [orderSupplierPaying, setOrderSupplierPaying] = useState<InnerOrderFragment>(undefined)
    const [selectedKeys, setSelectedKeys] = useState<number[]>([])
    const [areRowsSelected, setAreRowsSelected] = useState(false)
    const [currentlyDispatchingOrders, setCurrentlyDispatchingOrders] = useState(false)
    const [currentlyClosingOrders, setCurrentlyClosingOrders] = useState(false)

    //runs any time it needs to update search params
    useEffect(() => {
        setSearchParams({
            status: currentStatus,
            startDate: startDate != null ? moment(startDate).format("YYYY-MM-DD") : 'none',
            endDate: endDate != null ? moment(endDate).format("YYYY-MM-DD") : 'none'
        })
    }, [currentStatus, startDate, endDate, setSearchParams])


    useEffect(() => {
        setAreRowsSelected(selectedKeys && selectedKeys.length > 0)
    }, [setAreRowsSelected, selectedKeys])


    const sortClick = (newSort: OrderSortInput) => {
        setCurrentSort(newSort)
    }

    const filterClick = (newFilter: OrderFilterInput) => {
        if (newFilter.status) {
            // Clearing out all the other filters that are not status when a new status is selected
            if (newFilter.status.eq !== currentStatus) {
                setCurrentStatus(newFilter.status.eq)
                const cf = {} as OrderFilterInput
                cf.status = newFilter.status
                newFilter = cf
            }
            if (newFilter.status.eq !== OrderStatus.Closed) {
                setNotPaid(false)
            }
            else if (newFilter.status.eq === OrderStatus.Closed) {
                setNotPaid(true)
            }
        }

        //preserve start date if selected
        if (startDate != null) {
            newFilter.deliveryDate ?
                //if it has the date filter object already, copy it to itself with the new start date
                newFilter.deliveryDate = {
                    ...newFilter.deliveryDate, gte: startDate
                } :
                newFilter.deliveryDate = { gte: startDate }
        }

        //preserve end date if selected
        if (endDate != null) {
            newFilter.deliveryDate ?
                //if it has the date filter object already, copy it to itself with the new end date   
                newFilter.deliveryDate = {
                    ...newFilter.deliveryDate, lte: endDate
                } :
                newFilter.deliveryDate = { lte: endDate }
        }

        setCurrentFilter(newFilter)
    }

    const editOrder = (order: InnerOrderFragment) => {
        navigate(`/orders/edit/${order.orderKey}?${searchParams}`)
    }

    const deleteOrder = (order: InnerOrderFragment) => {
        setCurrentlyDeletingOrder(order)
    }

    const carrierPaying = (order: InnerOrderFragment) => {
        setOrderCarrierPaying(order)
    }

    const supplierPaying = (order: InnerOrderFragment) => {
        setOrderSupplierPaying(order)
    }

    const updateSelectedRows = (newSelectedKeys: number[]) => {
        setSelectedKeys(newSelectedKeys)
    }

    const dispatchOrders = (dispatch?: boolean) => {
        if (dispatch !== null) {
            setCurrentlyDispatchingOrders(dispatch)
        }
        else if (areRowsSelected) {
            setCurrentlyDispatchingOrders(true)
        }
    }

    const closeOrders = (close?: boolean) => {
        if (close !== null) {
            setCurrentlyClosingOrders(close)
        }
        else if (areRowsSelected) {
            setCurrentlyClosingOrders(true)
        }
    }

    const openPdf = (open?: boolean) => {
        if (areRowsSelected) {
            window.open(window.location.origin + `/api/orderExport?orderKeys=${selectedKeys.join(',')}`)
        }
    }

    const notPaidFilter = (checked: boolean) => {
        setNotPaid(checked)
    }

    const updateOrderStatus = (status: OrderStatus) => {
        if (status !== currentStatus) {
            setCurrentStatus(status)

        }
        //if switching from closed, we don't need to display the extra details for not paid orders
        if (status !== OrderStatus.Closed) {
            setNotPaid(false)
        }
    }

    const updateStartDate = (date: Date) => {
        setStartDate(date)

        let newFilter = Object.assign({}, currentFilter)
        if (date) {
            newFilter.deliveryDate = { ...newFilter.deliveryDate, gte: date }
        }
        else {
            newFilter.deliveryDate = { ...newFilter.deliveryDate, gte: undefined }
        }

        setCurrentFilter(newFilter)
    }

    const updateEndDate = (date: Date) => {
        setEndDate(date)

        let newFilter = Object.assign({}, currentFilter)
        if (date) {
            newFilter.deliveryDate = { ...newFilter.deliveryDate, lte: date }
        }
        else {
            newFilter.deliveryDate = { ...newFilter.deliveryDate, lte: undefined }
        }

        setCurrentFilter(newFilter)
    }

    return (
        <GeneralPage title="Orders" headerContent={
            <>
                {(currentStatus === OrderStatus.Open && areRowsSelected) && <Button clickAction={dispatchOrders.bind(this)}>Dispatch</Button>}
                {(currentStatus === OrderStatus.Dispatched && areRowsSelected) && <><Button buttonType={ButtonType.Secondary} clickAction={openPdf.bind(this)}><FileText size={18} /> Open PDF</Button><Button clickAction={closeOrders.bind(this)}>Close Orders</Button></>}
                {currentStatus === OrderStatus.Closed && <HookedCheckbox value={notPaid} onChange={_ => notPaidFilter(_.target.checked)} label="Orders Not Paid" propertyKey="ordersNotPaid" errors={{}} register={React.createRef} />}
            </>
        }>
            <Tile>
                <OrderDataWrapper
                    currentSort={currentSort}
                    updateSort={sortClick}
                    currentFilter={currentFilter}
                    updateFilter={filterClick}
                    currentlyDeletingOrder={currentlyDeletingOrder}
                    deleteOrder={deleteOrder}
                    editOrder={editOrder}
                    currentlyDispatchingOrders={currentlyDispatchingOrders}
                    currentlyClosingOrders={currentlyClosingOrders}
                    updateSelectedRows={updateSelectedRows}
                    dispatchOrders={dispatchOrders}
                    closeOrders={closeOrders}
                    showUnpaidOrders={notPaid}
                    carrierPaying={carrierPaying}
                    supplierPaying={supplierPaying}
                    orderCarrierPaying={orderCarrierPaying}
                    orderSupplierPaying={orderSupplierPaying}
                    orderStatus={currentStatus}
                    updateStatus={updateOrderStatus}
                    updateStartDate={updateStartDate}
                    startDate={startDate}
                    updateEndDate={updateEndDate}
                    endDate={endDate}
                    urlParams={searchParams}
                />
            </Tile>
        </GeneralPage>
    )
}

export default Orders
