import React, { ReactElement } from 'react'
import gql from 'graphql-tag'
import { CreateTerminalMutationVariables, useCreateTerminalMutation } from '../../generated/graphql'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import { useNavigate } from 'react-router-dom'

export const CreateTerminalQuery = gql`
   mutation CreateTerminal($terminal: TerminalInput!) {
      terminalCreate(terminal: $terminal) {
         terminalKey
      }
   }
`
interface Props {
}



export default function CreateTerminal(props: Props): ReactElement {
   const navigate = useNavigate()

   const validationSchema = yup.object().shape({
      terminal: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().required().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().required().trim(),
         directions: yup.string().notRequired().trim(),
         email: yup.string().email().notRequired(),
         fax: yup.string().notRequired().trim(),
         phone: yup.string().required().trim(),
         state: yup.string().required().trim(),
         zipCode: yup.string().required().trim(),
         notes: yup.string().notRequired().trim()
      })
   })

   const { register, handleSubmit, errors } = useForm<CreateTerminalMutationVariables>({ validationSchema })

   const [createTerminalMutation] = useCreateTerminalMutation()

   const navigateToList = () => {
      navigate("/terminals")
   }

   const navigateToTerminal = (id: number) => {
      navigate(`/terminals/show/${id}`)
   }

   const submit = (variables: CreateTerminalMutationVariables) => {
      variables.terminal.isArchived = false
      createTerminalMutation({ variables })
         .then(r => navigateToTerminal(r.data.terminalCreate.terminalKey))
   }
   const onSubmit = handleSubmit(submit)

   const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Terminal</Button>)

   return (
      <FormPage title="Create Terminal" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Name"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 1"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 2"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="City"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.city)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="State"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        register={register}
                        label="Notes"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.notes)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Email Address"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.email)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Phone"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Fax"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.fax)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        register={register}
                        label="Directions"
                        propertyKey={propName<CreateTerminalMutationVariables>(o => o.terminal.directions)}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>
      </FormPage>
   )
}