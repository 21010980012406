import React, { Component, forwardRef, useImperativeHandle } from 'react'

import gql from 'graphql-tag'
import { useAllTasksByDateQuery, AllTasksByDateQueryVariables, TaskReportFilterInput, TaskReportListingFragment, SortEnumType, TaskReportSortInput } from '../../generated/graphql'
import GridBase, { GridOptions, GridSize } from '../../components/General/GridBase/GridBase'
import moment from 'moment'
import Tile from '../../components/General/Tile/Tile'
import Button from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import * as XLSX from 'xlsx'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'

export const TasksByDateReport = gql`
    fragment taskReportListing on TaskReport {
        taskKey
        completedDate
        locationKey
        locationName
        locationCity
        customerKey
        customerName
        contactKey
        contactName
        contactMethod
        info
        userName
    }
`

export const AllTasksByDateQuery = gql`
    query AllTasksByDate($sort: [TaskReportSortInput!], $filter: TaskReportFilterInput, $startDate: DateTime!, $endDate: DateTime!) {
        tasksByDate(order: $sort, where: $filter, startDate: $startDate, endDate: $endDate) {
            ...taskReportListing
        }
    }
`

interface TasksReportProps {
    currentSort: TaskReportSortInput
    updateSort(newSort: TaskReportSortInput)
    currentFilter: TaskReportFilterInput
    updateFilter(newFilter: TaskReportFilterInput)

    startDate: Date
    startDateChanged(name, val)
    endDate: Date
    endDateChanged(name, val)
}

const TasksReportWrapper = forwardRef((props: TasksReportProps, ref) => {

    const variables: AllTasksByDateQueryVariables = {
        sort: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter }),
        startDate: props.startDate,
        endDate: props.endDate
    }
    const { data, loading, error } = useAllTasksByDateQuery({ variables })

    var gridOptions: GridOptions<TaskReportListingFragment> = {
        columnDefs: [
            {
                headerName: "User",
                dataTransform: o => o.userName,
                field: "userName",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Date",
                // Dont use UTC since this is a DateTime
                dataTransform: o => moment(o.completedDate).format('LLL'),
                field: "completedDate",
                sortable: true
            },
            {
                headerName: "Customer",
                dataTransform: o => o.customerName,
                field: "customerName",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Location",
                dataTransform: o => o.locationName ? o.locationName : o.locationCity,
                field: "locationName",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Method",
                dataTransform: o => o.contactMethod,
                field: "contactMethod",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Info",
                dataTransform: o => o.info
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.tasksByDate,
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter,
        size: GridSize.small
    }

    const wsData = ((loading || error) && !data) ? [] : data.tasksByDate.map(x => {
        return {
            "User": x.userName,
            "Date": moment(x.completedDate).format('LL'),
            "Customer": x.customerName,
            "Location": x.locationName ? x.locationName : x.locationCity,
            "Method": x.contactMethod,
            "Info": x.info
        }
    })

    useImperativeHandle(ref, () => ({
        exportSheet() {
            var wb = XLSX.utils.book_new()

            var ws = XLSX.utils.json_to_sheet(wsData)
            XLSX.utils.book_append_sheet(wb, ws, "Calls")

            XLSX.writeFile(wb, "Tasks By Date.xlsx")
        }
    }))


    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <HookedDateTime
                        label="Start Date"
                        propertyKey={"startDate"}
                        defaultValue={props.startDate}
                        errors={{}}
                        register={React.createRef}
                        setValue={props.startDateChanged}
                    />
                </div>
                <div className="col-md-6">
                    <HookedDateTime
                        label="End Date"
                        propertyKey={"endDate"}
                        defaultValue={props.endDate}
                        errors={{}}
                        register={React.createRef}
                        setValue={props.endDateChanged}
                    />
                </div>
            </div>
            <GridBase<TaskReportListingFragment> gridOptions={gridOptions} />
        </>)
})

interface Props {
}

interface State {
    currentSort: TaskReportSortInput
    currentFilter: TaskReportFilterInput
    startDate: Date
    endDate: Date
}

export default class TasksReport extends Component<Props, State>
{
    childRef: any
    constructor(props) {
        super(props)

        this.childRef = React.createRef()

        const startDate = new Date(moment().startOf('month').format('LL'))
        const endDate = new Date(moment().endOf('month').format('LL'))

        const currentSort: TaskReportSortInput = { completedDate: SortEnumType.Asc }
        const currentFilter: TaskReportFilterInput = {}

        this.state = {
            currentSort,
            currentFilter,
            startDate,
            endDate
        }
    }

    sortClick(currentSort: TaskReportSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: TaskReportFilterInput) {
        this.setState({ currentFilter })
    }

    exportExcel() {
        this.childRef.current.exportSheet()
    }

    startDateChanged(name, val) {
        if (this.state.startDate !== val) {
            this.setState({ startDate: val })
        }
    }

    endDateChanged(name, val) {
        if (this.state.endDate !== val) {
            this.setState({ endDate: val })
        }
    }

    render() {
        return <GeneralPage title="Tasks Report" headerContent={
            <Button clickAction={this.exportExcel.bind(this)}>Export</Button>}>
            <Tile>
                <TasksReportWrapper
                    currentSort={this.state.currentSort}
                    updateSort={this.sortClick.bind(this)}
                    currentFilter={this.state.currentFilter}
                    updateFilter={this.filterClick.bind(this)}
                    ref={this.childRef}
                    startDate={this.state.startDate}
                    startDateChanged={this.startDateChanged.bind(this)}
                    endDate={this.state.endDate}
                    endDateChanged={this.endDateChanged.bind(this)}
                />
            </Tile>
        </GeneralPage>
    }
}