import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { useGetSingleFileNumberLazyQuery, useFileNumbersSearchQuery, InnerFileNumberFragment } from '../../../generated/graphql'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'


export const FileNumbersSearchQuery = gql`
    query FileNumbersSearch($search: String, $terminal: Int, $supplier: Int, $archived: Boolean) {
        fileNumberGetAll(where: {and: [{isArchived: {eq: $archived}}, { number: {contains: $search}}, {terminalKey: { eq: $terminal}}, {supplierKey: {eq: $supplier}}]}, order: [{ number: DESC }]) {
            ...innerFileNumber
        }
    }
`

interface Props extends DefaultInputProps<InnerFileNumberFragment> {
    onSelect?(fileNumber: InnerFileNumberFragment)
    prefilledFileNumberKey?: number
    supplierKey?: number
    terminalKey?: number
}

export default function FileNumberSearch(props: Props): ReactElement {

    const [executeGetSingleFileNumber, { data, loading }] = useGetSingleFileNumberLazyQuery({ variables: { key: props.prefilledFileNumberKey } })

    const { prefilledFileNumberKey } = props

    useEffect(() => {
        if (prefilledFileNumberKey) {
            executeGetSingleFileNumber()
        }
    }, [prefilledFileNumberKey, executeGetSingleFileNumber])

    const prefilledData = data && data.fileNumberGetSingle
    const prefilledDataDisplay = data && `${data.fileNumberGetSingle.number}`

    return <TextSearchDropdown<InnerFileNumberFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.fileNumberKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} supplierKey={props.supplierKey} terminalKey={props.terminalKey} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        {...props}
    />
}

const Inner = (props: InnerSearchLogicProps<InnerFileNumberFragment>) => {

    const { data, loading, error } = useFileNumbersSearchQuery({ variables: { search: props.searchTerm, terminal: props.terminalKey, supplier: props.supplierKey, archived: false } })

    const showData = !loading && !error

    const results = showData &&
        data.fileNumberGetAll.map(fn => {
            const display = fn.number
            return { key: fn.fileNumberKey, object: fn, display }
        })

    const displayProps: SearchDisplayProps<InnerFileNumberFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "File Numbers", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}