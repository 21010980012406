import React, { ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { GlobalLoginProps } from '../../App'

interface PrivateRouteProps extends GlobalLoginProps {
    children?: ReactElement
}

function PrivateRoute(props: PrivateRouteProps) {
    const { isAuthenticated, logout, children } = props

    useEffect(() => {

        const token = localStorage.getItem("token")
        if (!token && isAuthenticated) {
            logout()
        }

    }, [logout, isAuthenticated])

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />
    }

    return children
}

export default PrivateRoute