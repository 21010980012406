import React, { ReactElement } from 'react'

import gql from 'graphql-tag'
import { useCreateFileNumberMutation, CreateFileNumberMutationVariables } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'
import * as yup from 'yup'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { useNavigate } from 'react-router-dom'

export const CreateFileNumberMutation = gql`
    mutation CreateFileNumber($fileNumber: FileNumberInput!) {
        fileNumberCreate(fileNumber: $fileNumber) {
            fileNumberKey
        }
    }
`

interface Props {
}

export default function FileNumberCreate(props: Props): ReactElement {
    const navigate = useNavigate()

    const [createFileNumber] = useCreateFileNumberMutation()

    const navigateToList = () => {
        navigate("/settings/file-number")
    }

    const submit = (variables: CreateFileNumberMutationVariables) => {
        variables.fileNumber.isArchived = false
        createFileNumber({ variables })
            .then(navigateToList)
    }

    const validationSchema = yup.object().shape({
        fileNumber: yup.object().shape({
            number: yup.string().required(),
            terminalKey: yup.number().required(),
            supplierKey: yup.number().required(),
            isDefault: yup.bool().required(),
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateFileNumberMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create File Number</Button>)

    const commonProps = { register, errors, setValue }

    return (<FormPage title="Create File Number" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
        <form onSubmit={onSubmit}>
            <FormTile>
                <div className="row">
                    <div className="col-md-12">
                        <HookedTextInput
                            label="File Number"
                            propertyKey={propName<CreateFileNumberMutationVariables>(o => o.fileNumber.number)}
                            {...commonProps}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TerminalSearch
                            label="Terminal"
                            propertyKey={propName<CreateFileNumberMutationVariables>(o => o.fileNumber.terminalKey)}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <SupplierSearch
                            label="Supplier"
                            propertyKey={propName<CreateFileNumberMutationVariables>(o => o.fileNumber.supplierKey)}
                            {...commonProps}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <HookedCheckbox
                            label="Is Default"
                            propertyKey={propName<CreateFileNumberMutationVariables>(o => o.fileNumber.isDefault)}
                            {...commonProps}
                        />
                    </div>
                </div>
            </FormTile>
        </form>
    </FormPage>)
}