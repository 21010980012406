import React from 'react'
import SubNav, { SubNavLink } from '../components/SubNav/SubNav'
import { HamburgerState } from '../components/NavBar/NavBar'
import { Outlet, useNavigate } from 'react-router-dom'
import { GlobalLoginProps } from '../App'

interface Props extends GlobalLoginProps {
    hamburgerState: HamburgerState
}

const SettingsPage = (props: Props) => {
    const navigate = useNavigate()

    const logout = () => {
        props.logout()
        navigate("/login")
    }

    const linkList: SubNavLink[] = [
        { name: "Supplier Postings", link: "/settings/postings" },
        { name: "Contract Volumes", link: "/settings/contract-volumes" },
        { name: "Users", link: "/settings/users" },
        { name: "Pat Costs", link: "/settings/pat-costs" },
        { name: "State Taxes", link: "/settings/state-taxes" },
        { name: "File Numbers", link: "/settings/file-number" },
        { name: "Quickbooks", link: "/settings/quickbooks" },
        { name: "Logout", action: logout }
    ]

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <SubNav
                title="Settings"
                links={linkList}
                hamburgerState={props.hamburgerState}
            />

            <Outlet />
        </div>
    )
}

export default SettingsPage
