import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import styles from './App.module.scss'
import Home from './pages/Home'
import NavBar, { HamburgerState } from './components/NavBar/NavBar'
import SettingsPage from './pages/SettingsPage'
import PricingPage from './pages/PricingPage'
import TaskCreate from './pages/Tasks/TaskCreate'
import TaskShow from './pages/Tasks/TaskShow'
import SuppliersCreate from './pages/Suppliers/SupplierCreate'
import SupplierShow from './pages/Suppliers/SupplierShow'
import SupplierEdit from './pages/Suppliers/SupplierEdit'
import CreateLocation from './pages/Locations/LocationCreate'
import LocationShow from './pages/Locations/LocationShow'
import LocationEdit from './pages/Locations/LocationEdit'
import SupplierList from './pages/Suppliers/Suppliers'
import CarriersCreate from './pages/Carriers/CreateCarrier'
import CarrierEdit from './pages/Carriers/EditCarrier'
import CarrierShow from './pages/Carriers/ShowCarrier'
import CarrierList from './pages/Carriers/CarrierList'
import Login from './pages/Login/Login'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { UserLogin, login, LoginResponse } from './helpers/loginHelpers'
import Orders from './pages/Orders/Orders'
import OrderCreate from './pages/Orders/OrderCreate'
import OrderEdit from './pages/Orders/OrderEdit'
import TerminalShow from './pages/Terminals/TerminalShow'
import TerminalsCreate from './pages/Terminals/TerminalCreate'
import TerminalEdit from './pages/Terminals/TerminalEdit'
import TerminalList from './pages/Terminals/TerminalList'
import CarrierRateEdit from './pages/Carriers/CarrierRateEdit'
import Locations from './pages/Locations/Locations'
import ReportPage from './pages/ReportPage'
import Customers from './pages/Customers/Customers'
import CustomerShow from './pages/Customers/CustomerShow'
import CustomerCreate from './pages/Customers/CustomerCreate'
import CustomerEdit from './pages/Customers/CustomerEdit'
import TasksReport from './pages/Reports/Tasks'
import BasisTypeEdit from './pages/BasisType/BasisTypeEdit'
import BasisTypeList from './pages/BasisType/BasisTypeList'
import BasisTypeShow from './pages/BasisType/BasisTypeShow'
import CreateDirectMarkup from './pages/DirectMarkup/DirectMarkupCreate'
import EditDirectMarkup from './pages/DirectMarkup/DirectMarkupEdit'
import DirectMarkupList from './pages/DirectMarkup/DirectMarkupList'
import EditIndexContract from './pages/IndexContracts/Edit/EditIndexContract'
import IndexContractList from './pages/IndexContracts/IndexContractList'
import MassMarkup from './pages/Markup/MassMarkup'
import PrePurchaseAgreementCreate from './pages/PrePurchaseAgreements/PrePurchaseAgreementCreate'
import PrePurchaseAgreementEdit from './pages/PrePurchaseAgreements/PrePurchaseAgreementEdit'
import PrePurchaseAgreementList from './pages/PrePurchaseAgreements/PrePurchaseAgreementList'
import SalesAgreementCreate from './pages/SalesAgreements/SalesAgreementCreate'
import SalesAgreementEdit from './pages/SalesAgreements/SalesAgreementEdit'
import SalesAgreements from './pages/SalesAgreements/SalesAgreements'
import SwapDealCreate from './pages/SwapDeals/SwapDealCreate'
import SwapDealEdit from './pages/SwapDeals/SwapDealEdit'
import SwapDealList from './pages/SwapDeals/SwapDeals'
import TermContractCreate from './pages/TermContracts/TermContractCreate'
import TermContractEdit from './pages/TermContracts/TermContractEdit'
import CreateBasisType from './pages/BasisType/BasisTypeCreate'
import CreateIndexContract from './pages/IndexContracts/Create/CreateIndexContract'
import TermContracts from './pages/TermContracts/TermContracts'
import ContractVolumeCreate from './pages/ContractVolumes/ContractVolumeCreate'
import ContractVolumeEdit from './pages/ContractVolumes/ContractVolumeEdit'
import ContractVolumes from './pages/ContractVolumes/ContractVolumes'
import FileNumberCreate from './pages/FileNumbers/FileNumberCreate'
import FileNumbers from './pages/FileNumbers/FileNumbers'
import FileNumberEdit from './pages/FileNumbers/FileNumberUpdate'
import PatCosts from './pages/PatCost/PatCosts'
import PostingCreate from './pages/Postings/PostingCreate'
import PostingEdit from './pages/Postings/PostingEdit'
import Postings from './pages/Postings/Postings'
import QuickbooksAuthenticate from './pages/Quickbooks/QuickbooksAuthenticate'
import StateTaxes from './pages/StateTaxes/StateTaxes'
import UserCreate from './pages/Users/UserCreate'
import UserEdit from './pages/Users/UserEdit'
import Users from './pages/Users/Users'
import UpdateCarrierPointToPointRates from './pages/Carriers/PointToPoint/UpdatePointToPointRates'
import { jwtDecode } from "jwt-decode";
import { H } from 'highlight.run';

interface Props {

}

interface State {
    mainMenuIsOpen: boolean
    subMenuIsOpen: boolean
    lightHamburgerState: boolean
    isAuthenticated: boolean
}



export interface GlobalLoginProps {
    isAuthenticated: boolean
    login(userLogin: UserLogin): Promise<LoginResponse>
    logout()
}

export default class App extends React.Component<Props, State> {
    appInsights = null;

    state = {
        mainMenuIsOpen: false,
        subMenuIsOpen: false,
        lightHamburgerState: false,
        isAuthenticated: true,
    }

    componentDidMount() {
        const token = localStorage.getItem('token')

        if(token){
            const decoded = jwtDecode(token);
            H.identify(decoded.sub ? decoded.sub : decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"], {});
        }

        this.setState({ isAuthenticated: !!token })
    }

    setMainMenuState(desiredState: boolean) {
        this.setState({ mainMenuIsOpen: desiredState })
    }

    setSubMenuState(desiredState: boolean) {
        this.setState({ subMenuIsOpen: desiredState })

        if (!desiredState) {
            this.setState({ lightHamburgerState: false })
        }
    }

    triggerLightMenuState() {
        this.setState({ lightHamburgerState: true })
    }

    login(userLogin: UserLogin): Promise<LoginResponse> {
        return login(userLogin)
            .then(data => {
                if (data.token) {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refreshToken', data.refreshToken)
                    this.setState({ isAuthenticated: true })
                }
                return data
            })
    }

    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        this.setState({ isAuthenticated: false })
    }



    render() {
        const hamburgerState: HamburgerState = {
            setMainMenuState: this.setMainMenuState.bind(this),
            setSubMenuState: this.setSubMenuState.bind(this),
            triggerLightHamburger: this.triggerLightMenuState.bind(this),
            mainMenuState: this.state.mainMenuIsOpen,
            subMenuState: this.state.subMenuIsOpen,
            lightHamburgerState: this.state.lightHamburgerState
        }

        const globalLoginProps: GlobalLoginProps = {
            isAuthenticated: this.state.isAuthenticated,
            login: this.login.bind(this),
            logout: this.logout.bind(this)
        }

        return (
            <div className={styles.app}>
                <Router>
                    {!!this.state.isAuthenticated && <NavBar hamburgerState={hamburgerState} />}

                    <Routes>
                        <Route path="login" element={<Login {...this.props} {...globalLoginProps} />} />

                        <Route path="/" element={<PrivateRoute {...globalLoginProps}><Home /></PrivateRoute>} {...globalLoginProps} />

                        <Route path="/orders" element={<PrivateRoute {...globalLoginProps}><Orders /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/orders/new" element={<PrivateRoute {...globalLoginProps}><OrderCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/orders/edit/:orderKey" element={<PrivateRoute {...globalLoginProps}><OrderEdit /></PrivateRoute>} {...globalLoginProps} />

                        <Route path="/customers" element={<PrivateRoute {...globalLoginProps}><Customers /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/customers/new" element={<PrivateRoute {...globalLoginProps}><CustomerCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/customers/edit/:customerKey" element={<PrivateRoute {...globalLoginProps}><CustomerEdit /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/customers/show/:customerKey" element={<PrivateRoute {...globalLoginProps}><CustomerShow /></PrivateRoute>} {...globalLoginProps} />


                        <Route path="/locations/" element={<PrivateRoute {...globalLoginProps}><Locations /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/locations/create/:customerKey" element={<PrivateRoute {...globalLoginProps}><CreateLocation /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/locations/edit/:locationKey" element={<PrivateRoute {...globalLoginProps}><LocationEdit /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/locations/show/:locationKey" element={<PrivateRoute {...globalLoginProps}><LocationShow /></PrivateRoute>} {...globalLoginProps} />

                        <Route path="/carriers" element={<PrivateRoute {...globalLoginProps}><CarrierList /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/carriers/new" element={<PrivateRoute {...globalLoginProps}><CarriersCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/carriers/edit/:carrierKey" element={<PrivateRoute {...globalLoginProps}><CarrierEdit /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/carriers/show/:carrierKey" element={<PrivateRoute {...globalLoginProps}><CarrierShow /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/carriers/rate/:carrierRateKey" element={<PrivateRoute {...globalLoginProps}><CarrierRateEdit /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/carriers/point-to-point/:carrierPointToPointRateDateSpanKey" element={<PrivateRoute {...globalLoginProps}><UpdateCarrierPointToPointRates /></PrivateRoute>} {...globalLoginProps} />

                        <Route path="/tasks/new" element={<PrivateRoute {...globalLoginProps}><TaskCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/tasks/show/:taskKey" element={<PrivateRoute {...globalLoginProps}><TaskShow /></PrivateRoute>} {...globalLoginProps} />

                        <Route path="/suppliers" element={<PrivateRoute {...globalLoginProps}><SupplierList /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/suppliers/new" element={<PrivateRoute {...globalLoginProps}><SuppliersCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/suppliers/edit/:supplierKey" element={<PrivateRoute {...globalLoginProps}><SupplierEdit /></PrivateRoute>} {...globalLoginProps} />
                        <Route path="/suppliers/show/:supplierKey" element={<PrivateRoute {...globalLoginProps}><SupplierShow /></PrivateRoute>} {...globalLoginProps} />

                        <Route path='/terminals' element={<PrivateRoute {...globalLoginProps}><TerminalList /></PrivateRoute>} {...globalLoginProps} />
                        <Route path='/terminals/show/:terminalKey' element={<PrivateRoute {...globalLoginProps}><TerminalShow /></PrivateRoute>} {...globalLoginProps} />
                        <Route path='/terminals/new' element={<PrivateRoute {...globalLoginProps}><TerminalsCreate /></PrivateRoute>} {...globalLoginProps} />
                        <Route path='/terminals/edit/:terminalKey' element={<PrivateRoute {...globalLoginProps}><TerminalEdit /></PrivateRoute>} {...globalLoginProps} />


                        <Route path='/reports' element={
                            <ReportPage hamburgerState={hamburgerState} {...this.props} />}
                            {...globalLoginProps} >

                            <Route path='task-details' element={<TasksReport />} />

                        </Route>

                        <Route path="pricing" element={
                            <PricingPage hamburgerState={hamburgerState} {...this.props} />}
                            {...globalLoginProps}>
                            <Route path='mass-markup' element={<PrivateRoute {...globalLoginProps}><MassMarkup /></PrivateRoute>} />

                            <Route path='basis-types' element={<PrivateRoute {...globalLoginProps}><BasisTypeList /></ PrivateRoute>} />

                            <Route path='basis-types/new' element={<PrivateRoute {...globalLoginProps}><CreateBasisType /></ PrivateRoute>} />

                            <Route path='basis-types/show/:basisTypeKey' element={<PrivateRoute {...globalLoginProps}><BasisTypeShow /></ PrivateRoute>} />
                            <Route path='basis-types/edit/:basisTypeKey' element={<PrivateRoute {...globalLoginProps}><BasisTypeEdit /></ PrivateRoute>} />

                            <Route path='index-contracts' element={<PrivateRoute {...globalLoginProps}><IndexContractList /></ PrivateRoute>} />
                            <Route path='index-contracts/new' element={<PrivateRoute {...globalLoginProps}><CreateIndexContract /></ PrivateRoute>} />
                            <Route path='index-contracts/edit/:indexContractKey' element={<PrivateRoute {...globalLoginProps}><EditIndexContract /></ PrivateRoute>} />

                            <Route path='sales-agreements/new' element={<PrivateRoute {...globalLoginProps}><SalesAgreementCreate /></ PrivateRoute>} />
                            <Route path='sales-agreements/edit/:salesAgreementKey' element={<PrivateRoute {...globalLoginProps}><SalesAgreementEdit /></ PrivateRoute>} />
                            <Route path='sales-agreements/' element={<PrivateRoute {...globalLoginProps}><SalesAgreements /></ PrivateRoute>} />

                            <Route path='pre-purchase-agreements' element={<PrivateRoute {...globalLoginProps}><PrePurchaseAgreementList /></ PrivateRoute>} />
                            <Route path='pre-purchase-agreements/new' element={<PrivateRoute {...globalLoginProps}><PrePurchaseAgreementCreate /></ PrivateRoute>} />
                            <Route path='pre-purchase-agreements/edit/:prePurchaseAgreementKey' element={<PrivateRoute {...globalLoginProps}><PrePurchaseAgreementEdit /></ PrivateRoute>} />

                            <Route path='direct-markup' element={<PrivateRoute {...globalLoginProps}><DirectMarkupList /></ PrivateRoute>} />
                            <Route path='direct-markup/edit/:directMarkupKey' element={<PrivateRoute {...globalLoginProps}><EditDirectMarkup /></ PrivateRoute>} />
                            <Route path='direct-markup/new' element={<PrivateRoute {...globalLoginProps}><CreateDirectMarkup /></ PrivateRoute>} />

                            <Route path='term-contract' element={<PrivateRoute {...globalLoginProps}><TermContracts /></ PrivateRoute>} />
                            <Route path='term-contract/new' element={<PrivateRoute {...globalLoginProps}><TermContractCreate /></ PrivateRoute>} />
                            <Route path='term-contract/edit/:termContractKey' element={<PrivateRoute {...globalLoginProps}><TermContractEdit /></ PrivateRoute>} />

                            <Route path='swap-deals' element={<PrivateRoute {...globalLoginProps}><SwapDealList /></ PrivateRoute>} />
                            <Route path='swap-deals/new' element={<PrivateRoute {...globalLoginProps}><SwapDealCreate /></ PrivateRoute>} />
                            <Route path='swap-deals/edit/:swapDealKey' element={<PrivateRoute {...globalLoginProps}><SwapDealEdit /></ PrivateRoute>} />
                        </Route>

                        <Route path="settings" element={
                            <SettingsPage hamburgerState={hamburgerState} {...this.props} {...globalLoginProps} />}
                            {...globalLoginProps}>
                            <Route path="pat-costs" element={<PrivateRoute {...globalLoginProps}><PatCosts /></ PrivateRoute>} />

                            <Route path="state-taxes" element={<PrivateRoute {...globalLoginProps}><StateTaxes /></ PrivateRoute>} />

                            <Route path="file-number" element={<PrivateRoute {...globalLoginProps}><FileNumbers /></ PrivateRoute>} />
                            <Route path="file-number/new" element={<PrivateRoute {...globalLoginProps}><FileNumberCreate /></ PrivateRoute>} />
                            <Route path="file-number/edit/:fileNumberKey" element={<PrivateRoute {...globalLoginProps}><FileNumberEdit /></ PrivateRoute>} />

                            <Route path="users" element={<PrivateRoute {...globalLoginProps}><Users /></ PrivateRoute>} />
                            <Route path="users/new" element={<PrivateRoute {...globalLoginProps}><UserCreate /></ PrivateRoute>} />
                            <Route path="users/edit/:userKey" element={<PrivateRoute {...globalLoginProps}><UserEdit /></ PrivateRoute>} />

                            <Route path='postings' element={<PrivateRoute {...globalLoginProps}><Postings /></ PrivateRoute>} />
                            <Route path='postings/new' element={<PrivateRoute {...globalLoginProps}><PostingCreate /></ PrivateRoute>} />
                            <Route path='postings/edit/:postingKey' element={<PrivateRoute {...globalLoginProps}><PostingEdit /></ PrivateRoute>} />

                            <Route path='contract-volumes' element={<PrivateRoute {...globalLoginProps}><ContractVolumes /></ PrivateRoute>} />
                            <Route path='contract-volumes/edit/:contractVolumeKey' element={<PrivateRoute {...globalLoginProps}><ContractVolumeEdit /></ PrivateRoute>} />
                            <Route path='contract-volumes/new' element={<PrivateRoute {...globalLoginProps}><ContractVolumeCreate /></ PrivateRoute>} />

                            <Route path='quickbooks' element={<PrivateRoute {...globalLoginProps}><QuickbooksAuthenticate /></ PrivateRoute>} />
                        </Route>
                    </Routes>
                </Router>
            </div>
        )
    }
}