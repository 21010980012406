import React, { Component, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import { useAllFlatPostingsQuery, InnerFlatPostingFragment, FlatPostingSortInput, FlatPostingFilterInput, AllFlatPostingsQueryVariables, useDeletePostingMutation, InnerFlatPostingListingFragment, FlatPosting, SortEnumType } from '../../generated/graphql'
import Button from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import ellipsisHorizontal from '../../icons/ellipsis-h.svg'
import moment from 'moment'
import Tile from '../../components/General/Tile/Tile'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'

export const PostingFragment = gql`
    fragment InnerPosting on Posting {
        postingKey
        supplierKey
        supplier {
            name
        }
        terminalKey
        terminal {
            name
        }
        cost
        effectiveDate
        effectiveEndDate
    }

    fragment InnerPostingListing on PostingGetAllEdge {
        node {
            ...InnerPosting
        }
    }

    fragment AllPostingListing on PostingGetAllConnection {
        edges {
            cursor
            ...InnerPostingListing
        }
        totalCount
    }

    fragment InnerFlatPosting on FlatPosting {
        postingKey
        cost
        effectiveDate
        effectiveEndDate
        supplier
        terminal
    }

    fragment InnerFlatPostingListing on FlatPostingGetAllEdge {
        node {
            ...InnerFlatPosting
        }
    }

    fragment AllFlatPostingListing on FlatPostingGetAllConnection {
        edges {
            cursor
            ...InnerFlatPostingListing
        }
        totalCount 
    }
`

export const AllPostingQuery = gql`
    query AllPostings($sortObject: [PostingSortInput!], $filterObject: PostingFilterInput, $cursor: String!, $pageSize: Int!) {
        postingGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
            ...AllPostingListing
        }
    }

    query AllFlatPostings($sort: [FlatPostingSortInput!], $filter: FlatPostingFilterInput, $cursor: String!, $pageSize: Int!) {
        flatPostingGetAll(order: $sort, where: $filter, first: $pageSize, after: $cursor) {
            ...AllFlatPostingListing
        }
    }
`

export const DeletePostingMutation = gql`
    mutation DeletePosting($id: Int!) {
        postingDelete(id: $id)
    }
`

interface PostingDataWrapperProps {
    currentSort: FlatPostingSortInput
    updateSort(newSort: FlatPostingSortInput)

    currentFilter: FlatPostingFilterInput
    updateFilter(newFilter: FlatPostingFilterInput)

    editPosting(posting: InnerFlatPostingListingFragment)
    deletePosting(posting: InnerFlatPostingListingFragment)
    currentlyDeletingPosting?: InnerFlatPostingFragment
}

const PostingDataWrapper = (props: PostingDataWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllFlatPostingsQueryVariables = {
        sort: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllFlatPostingsQuery({ variables })

    const [deleteMutation] = useDeletePostingMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => refetch())
            .then(() => props.deletePosting(null))
    }

    const cancelDelete = () => {
        props.deletePosting(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerFlatPostingFragment> = {
        columnDefs: [
            {
                headerName: "Supplier",
                dataTransform: o => o.supplier,
                field: "supplier",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Terminal",
                dataTransform: o => o.terminal,
                field: "terminal",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Cost",
                field: "cost",
                dataTransform: o => o.cost.toString(),
                sortable: true,
            },
            {
                headerName: "Effective Date",
                field: "effectiveDate",
                // do not use UTC since this is DateTime not Date
                dataTransform: o => moment(o.effectiveDate).format('LLL'),
                sortable: true
            },
            {
                headerName: "Effective End Date",
                field: "effectiveEndDate",
                // do not use UTC since this is DateTime not Date
                dataTransform: o => moment(o.effectiveEndDate).format('LLL'),
                sortable: true
            }
        ],

        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) || !data) ? [] : data.flatPostingGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsisHorizontal, items: [
                    { displayName: "Edit", action: props.editPosting },
                    { displayName: "Delete", action: props.deletePosting }
                ]
            }
        ],
        cellStyleRules: [

        ],
        dataKeyColumn: "postingKey",
        linkToPath: "/settings/postings/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    return (
        <div>
            <PaginationWrapper
                pageSize={pageSize}
                totalCount={data ? data.flatPostingGetAll.totalCount : 0}
                changeCursor={pageChange}
            >
                <GridBase<InnerFlatPostingFragment> gridOptions={gridOptions} />
                {!!props.currentlyDeletingPosting && <DeleteEntityModal
                    cancel={cancelDelete}
                    confirm={confirmDelete}
                    entityKey={props.currentlyDeletingPosting.postingKey}
                    entityName="posting"
                    orderFilter={{ postingKey: { eq: props.currentlyDeletingPosting.postingKey } }} />}
            </PaginationWrapper>
        </div>
    )
}

const Postings = () => {
    const navigate = useNavigate()

    return (
        <PostingsInnerWrapper navigate={navigate} />
    )
}

export default Postings

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentSort: FlatPostingSortInput
    currentFilter: FlatPostingFilterInput
    currentlyDeletingPosting?: InnerFlatPostingFragment
}

class PostingsInnerWrapper extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: FlatPostingSortInput = { effectiveDate: SortEnumType.Desc }
        const currentFilter: FlatPostingFilterInput = {
            effectiveEndDate: { gt: moment().startOf('day').toDate() }
        }

        this.state = {
            currentSort,
            currentFilter
        }
    }


    sortClick(currentSort: FlatPostingSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: FlatPostingFilterInput) {
        currentFilter.effectiveEndDate = this.state.currentFilter.effectiveEndDate
        this.setState({ currentFilter })
    }

    navigateToNewPosting() {
        this.props.navigate('/settings/postings/new')
    }

    editPosting(posting: FlatPosting) {
        this.props.navigate(`/settings/postings/edit/${posting.postingKey}`)
    }

    deleteAction(posting: InnerFlatPostingListingFragment["node"]) {
        this.setState({ currentlyDeletingPosting: posting })
    }

    setShowExpired(showExpired: boolean) {
        let currentFilter = Object.assign({}, this.state.currentFilter)

        currentFilter.effectiveEndDate = {
            lt: showExpired ? moment().startOf('day').toDate() : undefined,
            gt: showExpired ? undefined : moment().startOf('day').toDate()
        }

        this.setState({ currentFilter: currentFilter })
    }

    render() {
        return (
            <GeneralPage title="Postings"
                headerContent={
                    <div>
                        <HookedCheckbox
                            label="Show Expired"
                            defaultValue={false}
                            propertyKey={"showExpired"}
                            errors={{}}
                            register={React.createRef()}
                            onChange={(event) => this.setShowExpired(event.target.checked)}
                            inline={true}
                        />
                        <Button clickAction={this.navigateToNewPosting.bind(this)}>New Posting</Button>
                    </div>
                }
            >
                <Tile>
                    <PostingDataWrapper
                        currentlyDeletingPosting={this.state.currentlyDeletingPosting}
                        editPosting={this.editPosting.bind(this)}
                        deletePosting={this.deleteAction.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}