import React, { ReactElement, useState, useEffect } from 'react'
import Tile from '../../components/General/Tile/Tile'
import Button from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import gql from 'graphql-tag'
import { useQuickbooksAuthorizationLazyQuery, useQuickBooksTokenExchangeMutation } from '../../generated/graphql'
import { useSearchParams } from 'react-router-dom'


export const QuickbooksAuthorizationQuery = gql`

   query QuickbooksAuthorization {
      quickBooksAuthorizationUrl
   }

    mutation QuickBooksTokenExchange($authCode: String!, $realmId: String!) {
        quickBooksTokenExchange(authCode: $authCode, realmId: $realmId)
    }
`

interface Props {
}

export default function QuickbooksAuthenticate(props: Props): ReactElement {
    const [searchParams,] = useSearchParams()

    const [executeGetQuickBooksAuth, { data }] = useQuickbooksAuthorizationLazyQuery()
    const [exchangeToken, { data: exchangeData, loading }] = useQuickBooksTokenExchangeMutation()
    const [exchangingToken, setExchangingToken] = useState(false)

    const hasAuthorizationCode = !!searchParams.get('code')
    const hasError = searchParams.get('error') === "access_denied"
    const hasAuthorizationUrl = data && data.quickBooksAuthorizationUrl

    let pageContent



    useEffect(() => {
        const exchangeAuthorizationCode = () => {
            var code = searchParams.get('code')
            var realmId = searchParams.get('realmId')
            exchangeToken({ variables: { realmId: realmId, authCode: code } }).then(res => {
                setExchangingToken(false)
            })
        }

        // we have exchanged the auth code for the access tokens and saved them to the db
        if (loading || exchangeData) {
            //we're done here
            return
        }
        //if it "should be exchanging the token" call the endpoint
        else if (exchangingToken) {
            exchangeAuthorizationCode()
        }
        // we have the authorization code needed to get the bearer tokens
        else if (hasAuthorizationCode && !exchangingToken) {
            setExchangingToken(true)
        }
        // redirect to intuit auth screen if we've retrieved the url for it
        else if (hasAuthorizationUrl) {
            window.location.href = data.quickBooksAuthorizationUrl
        }
    }, [data, loading, exchangeData, exchangeToken, searchParams, exchangingToken, hasAuthorizationCode, hasAuthorizationUrl])


    // get the url to redirect to for connecting accounts
    // will redirect after the next digest
    const getQuickBooksAuthorizationUrl = () => {
        executeGetQuickBooksAuth()
    }


    //if there was an error
    if (hasError) {
        pageContent = <h5>There was an error authenticating with QuickBooks.</h5>
    }
    //if the endpoint has been called and we have a result
    else if (exchangeData) {
        //if the exchange was successful
        if (exchangeData.quickBooksTokenExchange) {
            pageContent = <h5>Successfully authenticated with QuickBooks.</h5>
        }
        //if the exchange failed
        else {
            pageContent = <h5>Failed to authenticate with Quickbooks. Please navigate away from this page and then try again.</h5>
        }
    }
    // we have the authorization code needed to get the bearer tokens
    else if (hasAuthorizationCode || exchangingToken) {
        pageContent = <div>
            <h5>Exchanging Code</h5>
        </div>
    }
    //default
    else {
        pageContent = <><h5>If you are having trouble with the application creating invoices, you may have to re-authenticate.</h5>
            <Button clickAction={getQuickBooksAuthorizationUrl}>Authenticate</Button></>
    }



    return (
        <GeneralPage title="Quickbooks Authentication">
            <Tile>
                {pageContent}
            </Tile>
        </GeneralPage>
    )
}
