import React, { Component, CSSProperties } from 'react'
import styles from "./Badge.module.scss"

interface Props {
    textColor: string
    backgroundColor: string

    children: React.ReactNode
}

export default class Badge extends Component<Props> {
    state = {}

    render() {
        var inlineStyles: CSSProperties = { backgroundColor: this.props.backgroundColor, color: this.props.textColor }

        return (
            <div className={styles.Badge} style={inlineStyles}>
                {this.props.children}
            </div>
        )
    }
}
