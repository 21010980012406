import React, { ReactElement, useEffect, useState } from 'react'
import styles from './GallonsGrid.module.scss'
import { OrderFilterInput, AllOrdersQueryVariables, useAllOrdersQuery, useSingleContractVolumeQuery, ContractVolumeType } from '../../generated/graphql'
import moment from 'moment'

interface Props {
    prePurchaseAgreementKey?: number
    salesAgreementKey?: number
    indexContractKey?: number
    contractVolumeKey?: number
    showEstimatedGallons?: boolean
    toDateGallonsCallback?: (gallons: number) => void
}

export default function GallonsGrid(props: Props): ReactElement {
    const { toDateGallonsCallback } = props
    const { data, loading, error } = useSingleContractVolumeQuery({ variables: { id: props.contractVolumeKey } })
    const [gallonData, setGallonData] = useState([{ rowTitle: "Actual Gallons", jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0, jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0 },
    { rowTitle: "Estimated Gallons", jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0, jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0 }])
    const [initialized, setInitialized] = useState(false)

    let oFilter: OrderFilterInput = {}
    if (props.prePurchaseAgreementKey) {
        oFilter = { prePurchaseAgreementKey: { eq: props.prePurchaseAgreementKey } }
    }
    else if (props.salesAgreementKey) {
        oFilter = { salesAgreementKey: { eq: props.salesAgreementKey } }
    }
    else if (props.indexContractKey) {
        oFilter = { indexContractKey: { eq: props.indexContractKey } }
    }
    else if (props.contractVolumeKey && data) {
        let terminals = data.contractVolumeGetSingle.contractVolumeTerminals.map(x => x.terminalKey)
        const endContractVolumeDate = moment(data.contractVolumeGetSingle.startDate).add(1, 'year').toDate()
        oFilter = {
            supplierKey: { eq: data.contractVolumeGetSingle.supplierKey },
            terminalKey: { in: terminals },
            deliveryDate: { gte: data.contractVolumeGetSingle.startDate, lte: endContractVolumeDate }
        }
    }
    const variables: AllOrdersQueryVariables = {
        cursor: "LTE=",
        pageSize: 1000,
        filterObject: oFilter
    }
    const { data: oData, loading: oLoading, error: oError } = useAllOrdersQuery({ variables })

    useEffect(() => {
        if (toDateGallonsCallback) {
            if (gallonData.length > 0) {
                const g = gallonData[0]
                const totalGallons = (g.jan + g.feb + g.mar + g.apr + g.may + g.jun + g.jul + g.aug + g.sep + g.oct + g.nov + g.dec)

                toDateGallonsCallback(totalGallons)
            }
        }

    }, [toDateGallonsCallback, gallonData])

    if (props.contractVolumeKey && (loading || error)) {
        return <div></div>
    }

    if (!oLoading && !oError && !initialized) {
        //copy the initial data to a var we can adjust
        let tmpGallonData = Object.assign([], gallonData)
        oData.orderGetAll.edges.map(x => x.node).forEach(x => {
            if (data && data.contractVolumeGetSingle.contractVolumeType === ContractVolumeType.Posting) {
                if (x.postingKey === null && x.legacyCostType !== "POSTING") {
                    x.gallons = 0
                    x.actualGallons = 0
                }
            }
            else if (data && data.contractVolumeGetSingle.contractVolumeType === ContractVolumeType.Index) {
                if (x.indexContractKey === null && x.legacyCostType !== "INDEX") {
                    x.gallons = 0
                    x.actualGallons = 0
                }
            }
            //for combine, filter out everything aside from postings or index contracts
            else if (data && (x.postingKey === null && x.indexContractKey === null && x.legacyCostType !== "POSTING" && x.legacyCostType !== "INDEX")) {
                x.gallons = 0
                x.actualGallons = 0
            }
            var month = new Date(moment.utc(x.deliveryDate).format('LL')).getMonth()
            switch (month) {
                case 0:
                    tmpGallonData[0].jan += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].jan += x.gallons ? x.gallons : 0
                    break
                case 1:
                    tmpGallonData[0].feb += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].feb += x.gallons ? x.gallons : 0
                    break
                case 2:
                    tmpGallonData[0].mar += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].mar += x.gallons ? x.gallons : 0
                    break
                case 3:
                    tmpGallonData[0].apr += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].apr += x.gallons ? x.gallons : 0
                    break
                case 4:
                    tmpGallonData[0].may += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].may += x.gallons ? x.gallons : 0
                    break
                case 5:
                    tmpGallonData[0].jun += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].jun += x.gallons ? x.gallons : 0
                    break
                case 6:
                    tmpGallonData[0].jul += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].jul += x.gallons ? x.gallons : 0
                    break
                case 7:
                    tmpGallonData[0].aug += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].aug += x.gallons ? x.gallons : 0
                    break
                case 8:
                    tmpGallonData[0].sep += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].sep += x.gallons ? x.gallons : 0
                    break
                case 9:
                    tmpGallonData[0].oct += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].oct += x.gallons ? x.gallons : 0
                    break
                case 10:
                    tmpGallonData[0].nov += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].nov += x.gallons ? x.gallons : 0
                    break
                case 11:
                    tmpGallonData[0].dec += x.actualGallons ? x.actualGallons : x.gallons
                    tmpGallonData[1].dec += x.gallons ? x.gallons : 0
                    break
            }
        })

        // they want to see the contract volume month info in the estimated gallons row
        if (props.contractVolumeKey && data && props.showEstimatedGallons) {
            if (tmpGallonData[1]) {
                var estimatedGallons = tmpGallonData[1]
                var cv = data.contractVolumeGetSingle
                estimatedGallons.apr = cv.april
                estimatedGallons.may = cv.may
                estimatedGallons.jun = cv.june
                estimatedGallons.jul = cv.july
                estimatedGallons.aug = cv.august
                estimatedGallons.sep = cv.september
                estimatedGallons.oct = cv.october
                estimatedGallons.nov = cv.november
                estimatedGallons.dec = cv.december
                estimatedGallons.jan = cv.january
                estimatedGallons.feb = cv.february
                estimatedGallons.mar = cv.march

                tmpGallonData[1] = estimatedGallons
            }
        }
        //set state, set initialized so it doesn't happen again
        setGallonData(tmpGallonData)
        setInitialized(true)
    }




    const formatNumber = (data: number) => {
        return data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const shouldBeSmallText = (num: number) => {
        if (num > 1000000) {
            return true
        }
    }

    let rows = []
    for (let x of gallonData) {
        if (!props.showEstimatedGallons && x.rowTitle === "Estimated Gallons") {
            continue
        }
        rows.push(<div key={x.rowTitle} className={styles.gallonsGrid__row}>
            <div className="row">
                <div className="col-md-12">
                    <h6 className={styles.gallonsGrid__title}>{x.rowTitle}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1">Apr</div>
                <div className="col-md-1">May</div>
                <div className="col-md-1">Jun</div>
                <div className="col-md-1">Jul</div>
                <div className="col-md-1">Aug</div>
                <div className="col-md-1">Sep</div>
                <div className="col-md-1">Oct</div>
                <div className="col-md-1">Nov</div>
                <div className="col-md-1">Dec</div>
                <div className="col-md-1">Jan</div>
                <div className="col-md-1">Feb</div>
                <div className="col-md-1">Mar</div>
            </div>
            <div className={`row ${styles.gallonsGrid__narrow_text}`}>
                <div className={`col-md-1 ${shouldBeSmallText(x.apr) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.apr)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.may) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.may)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.jun) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.jun)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.jul) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.jul)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.aug) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.aug)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.sep) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.sep)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.oct) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.oct)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.nov) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.nov)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.dec) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.dec)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.jan) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.jan)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.feb) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.feb)}</div>
                <div className={`col-md-1 ${shouldBeSmallText(x.mar) ? styles.gallonsGrid__smallText : ''}`}>{formatNumber(x.mar)}</div>
            </div>
        </div>)
    }

    return <div>
        {rows}
    </div>
}