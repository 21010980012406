import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { useEditCustomerMutation, EditCustomerMutationVariables, useGetCustomerQuery, useDeleteCustomerMutation, BusinessType, CustomerTagInput, useCreateCustomerTagMutation, useDeleteCustomerTagMutation, OrderFilterInput } from '../../generated/graphql'
import useForm from 'react-hook-form'
import * as yup from "yup"
import propName from '../../helpers/propName'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import DeliveryTimeSearch from '../../components/Search/DeliveryTimeSearch/DeliveryTimeSearch'
import MultiTagSearch from '../../components/Search/TagSearch/MultiTagSearch'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { Params, useNavigate, useParams } from 'react-router-dom'


export const EditCustomerQuery = gql`
    mutation EditCustomer($customer: CustomerInput! $id: Int!) {
        customerUpdate(customer: $customer, id: $id) {
            customerKey
        }
    }

    mutation CreateCustomerTag($customerTag: CustomerTagInput!) {
        customerTagCreate(customerTag: $customerTag) {
            customerKey
            tagKey
        }
    }

    mutation DeleteCustomerTag($customerKey: Int!, $tagKey: Int!) {
        customerTagDelete(customerKey: $customerKey, tagKey: $tagKey)
    }
`

export const GetCustomerQuery = gql`
    query GetCustomer($customerKey: Int!) {
        customerGetSingle(id: $customerKey) {
            customerKey
            name
            dbaName
            description
            creditLimit
            creditNotes
            businessType
            quickBooksName
            deliveryTimeKey
            customerTags {
                tagKey
            }
            address1
            address2
            city
            state
            zipCode
            directions
            isArchived
        }
    }
`

interface MatchParams extends Params {
    customerKey: string
}

interface Props {
}

export default function EditCustomer(props: Props): ReactElement {
    const navigate = useNavigate()

    const { customerKey: key } = useParams() as MatchParams

    const customerKey = parseInt(key)
    const [currentlyDeletingCustomer, setCurrentlyDeleting] = useState(false)
    const [tagKeys, setTagKeys] = useState<number[]>(undefined)

    const [createCustomerTag] = useCreateCustomerTagMutation()
    const [deleteCustomerTag] = useDeleteCustomerTagMutation()

    const { data: d, loading: l, error: e } = useGetCustomerQuery({ variables: { customerKey: customerKey } })

    const [editCustomerMutation/*, { data, loading, error }*/] = useEditCustomerMutation()
    const [deleteMutation] = useDeleteCustomerMutation()

    const navigateToCustomer = () => {
        navigate(`/customers/show/${customerKey}`)
    }

    const submit = (variables: EditCustomerMutationVariables) => {
        editCustomerMutation({ variables: { ...variables, ...{ id: customerKey } } })
            .then(_ => {
                if (tagKeys) {
                    tagKeys.forEach(key => {
                        if (d.customerGetSingle.customerTags.some(y => y.tagKey === key)) {
                            return
                        }
                        const ctInput: CustomerTagInput = { customerKey: customerKey, tagKey: key }
                        createCustomerTag({ variables: { customerTag: ctInput } })
                    })
                }
                if (d.customerGetSingle.customerTags.length > 0) {
                    d.customerGetSingle.customerTags.forEach(x => {
                        if (!tagKeys.some(y => y === x.tagKey)) {
                            return deleteCustomerTag({ variables: { customerKey, tagKey: x.tagKey } })

                        }
                    })
                }
            })
            .then(navigateToCustomer)
    }

    const validationSchema = yup.object().shape({
        customer: yup.object().shape({
            name: yup.string().required().trim(),
            quickBooksName: yup.string().trim(),
            primaryCarrierKey: yup.number(),
            secondaryCarrierKey: yup.number(),
            creditLimit: yup.number(),
            creditNotes: yup.string().trim(),
            deliveryTimeKey: yup.number().required(),
            address1: yup.string().notRequired().trim(),
            address2: yup.string().notRequired().trim(),
            city: yup.string().notRequired().trim(),
            state: yup.string().notRequired().trim(),
            zipCode: yup.string().notRequired().trim(),
            directions: yup.string().trim()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<EditCustomerMutationVariables>({ validationSchema })

    if (l) return (null)
    if (e) return <div>Error Loading Record</div>

    if (d.customerGetSingle.customerTags && d.customerGetSingle.customerTags.length > 0 && tagKeys === undefined) {
        setTagKeys(d.customerGetSingle.customerTags.map(x => x.tagKey))
    }

    const onSubmit = handleSubmit(submit)

    const navigateToList = () => {
        navigate("/customers")
    }

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const addTag = (tag) => {
        if (tagKeys === undefined) {
            setTagKeys([tag.tagKey])
        }
        else if (!tagKeys.some(x => x === tag.tagKey)) {
            setTagKeys([...tagKeys, tag.tagKey])
        }
    }

    const removeTag = (tag) => {
        setTagKeys([...tagKeys.filter(x => x !== tag)])
    }

    const businessTypes: SelectOption<BusinessType>[] = Object.keys(BusinessType).map(c => {
        return { key: BusinessType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })
    const defaultBusinessType = businessTypes.find(bt => bt.key === d.customerGetSingle.businessType)

    const footerLeftContent = (<div><Button clickAction={() => { navigateToCustomer() }} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Customer</Button>)

    const commonProps = { register, errors, setValue }

    const deleteOrderFilter: OrderFilterInput = { customerKey: { eq: customerKey } }

    return (
        <FormPage title="Update Customer" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.name)}
                                defaultValue={d.customerGetSingle.name}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="DBA Name"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.dbaName)}
                                defaultValue={d.customerGetSingle.dbaName}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="QuickBooks Name"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.quickBooksName)}
                                defaultValue={d.customerGetSingle.quickBooksName}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-8">
                            <HookedSelectOptions
                                options={businessTypes}
                                label="Business Type"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.businessType)}
                                setDefaultValue={defaultBusinessType}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <DeliveryTimeSearch
                                label="Delivery Time"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.deliveryTimeKey)}
                                prefilledDeliveryTimeKey={d.customerGetSingle.deliveryTimeKey}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Description"
                                defaultValue={d.customerGetSingle.description}
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.description)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 1"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.address1)}
                                defaultValue={d.customerGetSingle.address1}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 2"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.address2)}
                                defaultValue={d.customerGetSingle.address2}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="City"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.city)}
                                defaultValue={d.customerGetSingle.city}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.state)}
                                defaultValue={d.customerGetSingle.state}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Zipcode"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.zipCode)}
                                defaultValue={d.customerGetSingle.zipCode}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Directions"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.directions)}
                                defaultValue={d.customerGetSingle.directions}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Credit Limit"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.creditLimit)}
                                register={register}
                                defaultValue={d.customerGetSingle.creditLimit.toString()}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Credit Notes"
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.creditNotes)}
                                register={register}
                                defaultValue={d.customerGetSingle.creditNotes}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <MultiTagSearch
                                removeTag={removeTag}
                                addTag={addTag}
                                data={tagKeys}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedCheckbox
                                label="Archived"
                                register={register}
                                propertyKey={propName<EditCustomerMutationVariables>(o => o.customer.isArchived)}
                                defaultValue={d.customerGetSingle.isArchived}
                                errors={errors}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>

            {currentlyDeletingCustomer && <DeleteEntityModal
                cancel={cancelDelete}
                confirm={confirmDelete}
                entityKey={customerKey}
                entityName="customer"
                orderFilter={deleteOrderFilter}
                locationFilter={{ customerKey: { eq: customerKey } }}
            />}
        </FormPage>
    )
}
