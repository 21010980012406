import React, { ReactElement, useState } from 'react'
import { Plus } from 'react-feather'
import Button, { ButtonType } from '../../General/Button/Button'
import Tile from '../../General/Tile/Tile'
import moment from 'moment'
import { IndexContract, IndexContractListingFragment, GetAllIndexContractsQueryVariables, useGetAllIndexContractsQuery, SortEnumType } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import EmptyState from '../../General/Tile/EmptyState/EmptyState'
import HookedCheckbox from '../../General/Inputs/HookedCheckbox'
import { useNavigate } from 'react-router-dom'

interface Props {
    supplierKey: number
}

export default function IndexContractTile(props: Props): ReactElement {
    const [expired, setExpired] = useState(false)

    const [today] = useState(new Date())
    const navigate = useNavigate()

    const icActiveVariables: GetAllIndexContractsQueryVariables = { filterObject: { supplierKey: { eq: props.supplierKey }, effectiveEndDate: { gte: today } }, sortObject: { effectiveDate: SortEnumType.Desc } }
    const icExpiredVariables: GetAllIndexContractsQueryVariables = { filterObject: { supplierKey: { eq: props.supplierKey }, effectiveEndDate: { lt: today } }, sortObject: { effectiveDate: SortEnumType.Desc } }
    const { data, loading, error } = useGetAllIndexContractsQuery({ variables: expired ? icExpiredVariables : icActiveVariables })

    if (loading || error) {
        return <Tile title="Index Contracts" headerButton={<>
            <HookedCheckbox value={expired} onChange={(event) => setExpired(event.target.checked)} label="Show Expired" propertyKey={"chkExpired"} errors={{}} register={React.createRef()} />
            <Button buttonType={ButtonType.TightPrimary} clickAction={() => navigateToCreateIndexContract()}><Plus /></Button>
        </>}
        />
    }

    const gridOptions: GridOptions<IndexContract> = {
        columnDefs: [
            { headerName: "Terminal", dataTransform: (entity) => entity.terminal.name },
            { headerName: "Account Info", dataTransform: (entity) => entity.accountInfo },
            { headerName: "Effective Date", dataTransform: (entity) => moment.utc(entity.effectiveDate).format("L") },
            { headerName: "End Date", dataTransform: (entity) => moment.utc(entity.effectiveEndDate).format("L") },
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.indexContractsGetAll as IndexContract[],
        dataKeyColumn: propName<IndexContractListingFragment>(o => o.indexContractKey),
        linkToPath: "/pricing/index-contracts/edit/:key"
    }

    const navigateToCreateIndexContract = () => {
        navigate(`/pricing/index-contracts/new?supplierKey=${props.supplierKey}`)
    }

    return <Tile title="Index Contracts" headerButton={<>
        <HookedCheckbox value={expired} onChange={(event) => setExpired(event.target.checked)} label="Show Expired" propertyKey={"chkExpired"} errors={{}} register={React.createRef()} />
        <Button buttonType={ButtonType.TightPrimary} clickAction={() => navigateToCreateIndexContract()}><Plus /></Button>
    </>}
    >
        <EmptyState text={expired ? "You don't have any expired index contracts for this supplier yet." : "You don't have any current index contracts for this supplier yet."} data={data.indexContractsGetAll}></EmptyState>
        {data.indexContractsGetAll.length > 0 && <GridBase gridOptions={gridOptions} />}
    </Tile>
}