import React, { useState } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import gql from 'graphql-tag'
import { useGetSingleBasisTypeQuery, useCreateBasisTypePriceMutation, CreateBasisTypePriceMutationVariables, BasisTypePrice, useGetBasisTypePricesQuery, BasisTypePriceFilterInput, BasisTypePriceSortInput, SortEnumType } from '../../generated/graphql'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import propName from '../../helpers/propName'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import Tile from '../../components/General/Tile/Tile'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import moment from 'moment'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const GetBasisTypePricingQuery = gql`
   fragment basisTypePricing on BasisTypePrice {
      basisTypePriceKey
      basisTypeKey
      price
      effectiveDate
   }

   query GetBasisTypePrices($sort: [BasisTypePriceSortInput!], $filter: BasisTypePriceFilterInput!) {
      basisTypePriceGetAll(order: $sort, where: $filter) {
         ...basisTypePricing
      }
   }
`

export const GetSingleBasisType = gql`
   fragment singleBasisType on BasisType {
      ...allBasisTypeListing
      currentPrice {
         price
         effectiveDate
      }
      basisTypePrices(order: [{effectiveDate: DESC}]) {
         basisTypePriceKey
         price
         effectiveDate
      }
   }

   query GetSingleBasisType($id: Int!) {
      basisTypeGetSingle(id: $id) {
         ...singleBasisType
      }
   }

   mutation CreateBasisTypePrice($basisTypePrice: BasisTypePriceInput!) {
      basisTypePriceCreate(basisTypePrice: $basisTypePrice) {
         basisTypePriceKey
         price
         effectiveDate
      }
   }
`
interface MatchParams extends Params {
   basisTypeKey: string
}

interface Props {
}

const BasisTypeShow = (props: Props) => {
   const navigate = useNavigate()

   const [dateError, setDateError] = useState(false)
   const { basisTypeKey: key } = useParams() as MatchParams

   const basisTypeKey = parseInt(key)
   const { data, loading, error } = useGetSingleBasisTypeQuery({ variables: { id: basisTypeKey } })

   const twoWeeksAgo = moment().startOf('day').add(-14, 'days')
   // const tomorrow = moment().startOf('day').add(1, 'days')
   const today = moment().startOf('day').toDate()


   const sort: BasisTypePriceSortInput = { effectiveDate: SortEnumType.Desc }
   const filter: BasisTypePriceFilterInput = { basisTypeKey: { eq: basisTypeKey }, effectiveDate: { gte: twoWeeksAgo } }
   const { data: d, loading: l, error: e } = useGetBasisTypePricesQuery({ variables: { filter: filter, sort: sort } })

   const [createBasisTypePrice] = useCreateBasisTypePriceMutation()

   const validationSchema = yup.object().shape({
      basisTypePrice: yup.object().shape({
         price: yup.number().required(),
         effectiveDate: yup.date().required()
      })
   })

   const { register, setValue, errors, handleSubmit } = useForm<CreateBasisTypePriceMutationVariables>({ validationSchema })

   if (loading) { return (null) }
   if (error) { return <p>Error</p> }

   const priceGridOptions: GridOptions<BasisTypePrice> = {
      columnDefs: [
         {
            headerName: "Price", field: "price",
         },
         { headerName: "Effective Date", field: "effectiveDate", dataTransform: o => moment.utc(o.effectiveDate).format('LL') },
      ],
      isErrored: !!e,
      isLoading: l,
      rowData: (l || e) ? [] : d.basisTypePriceGetAll,
      dataKeyColumn: "basisTypePriceKey",
      // linkToPath: `/pricing/basis-types/show/${basisTypeKey}`,
   }

   const navigateToList = () => {
      navigate("/pricing/basis-types")
   }

   const submit = (variables: CreateBasisTypePriceMutationVariables) => {
      if (variables.basisTypePrice.effectiveDate > moment().endOf('day').toDate()) {
         setDateError(true)
         return
      }
      setDateError(false)

      variables.basisTypePrice.basisTypeKey = basisTypeKey
      createBasisTypePrice({ variables })
         .then(() => {
            navigateToList()
         })
   }

   const onSubmit = handleSubmit(submit)

   const navigateToEditBasisType = (basisTypeKey: number) => {
      navigate(`/pricing/basis-types/edit/${basisTypeKey}`)
   }

   return (
      <GeneralPage title="Basis Type" headerContent={
         <Button clickAction={() => { navigateToEditBasisType(basisTypeKey) }}>Edit Basis Type</Button>
      }>
         <Tile>
            <div className="row">
               <div className="col-md-6">
                  <div className="row">
                     <div className="col-md-12">
                        <h2>{data.basisTypeGetSingle.name}</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        {data.basisTypeGetSingle.description && <p>{data.basisTypeGetSingle.description}</p>}
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <h5><strong>Current Price: </strong>{data.basisTypeGetSingle.currentPrice.price}</h5>
                     </div>
                  </div>

               </div>
            </div>
         </Tile>

         <div className="row">
            <div className="col-md-6">
               <Tile title="Update Price">
                  <form onSubmit={onSubmit}>
                     <HookedTextInput
                        label="Price"
                        defaultValue={data.basisTypeGetSingle.currentPrice.price.toString()}
                        register={register}
                        errors={errors}
                        propertyKey={propName<CreateBasisTypePriceMutationVariables>(o => o.basisTypePrice.price)}
                     />

                     <HookedDateTime
                        label="Effective Date"
                        defaultValue={today}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        propertyKey={propName<CreateBasisTypePriceMutationVariables>(o => o.basisTypePrice.effectiveDate)}
                        noFutureDates={true}
                     />

                     {dateError && <p>You may not choose a date in the future</p>}

                     <Button isSubmit={true} submitValue="Update Price" buttonType={ButtonType.Success}></Button>
                  </form>

               </Tile>
            </div>
            <div className="col-md-6">
               <Tile title="Price History">
                  <GridBase gridOptions={priceGridOptions} />

               </Tile>
            </div>
         </div>
      </GeneralPage>
   )
}

export default BasisTypeShow