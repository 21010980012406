import React, { Component, useState, useEffect } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import Tile from '../../components/General/Tile/Tile'
import gql from 'graphql-tag'
import { useAllCustomersTagQuery, CustomerSortInput, CustomerFilterInput, AllCustomersTagQueryVariables, useDeleteCustomerMutation, InnerCustomerFragment, SortEnumType } from '../../generated/graphql'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import ellipsisHorizontal from '../../icons/ellipsis-h.svg'
import Button from '../../components/General/Button/Button'

import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import TagSearch from '../../components/Search/TagSearch/TagSearch'
import styles from '../../components/General/GridBase/GridBase.module.scss'
import LocationSearch from '../../components/Search/LocationSearch/LocationSearch'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const CustomerFragment = gql`
    fragment innerCustomer on Customer {
        customerKey
        description
        name
        dbaName

        locations {
            locationKey
            name
            city
            state
            locationTags {
                tag {
                    name
                }
            }
        }
        customerTags {
            tag {
                name
            }
        }

    }

    fragment innerCustomerListing on CustomerGetAllEdge {
        node {
            ...innerCustomer
        }

    }
    fragment allCustomerListing on CustomerGetAllConnection {
        edges {
            cursor
            ...innerCustomerListing
        }
        totalCount
    }

    fragment innerCustomersWithFilterListing on CustomersWithFilterEdge {
        node {
            ...innerCustomer
        }

    }
    fragment customersWithFilterListing on CustomersWithFilterConnection {
        edges {
            cursor
            ...innerCustomersWithFilterListing
        }
        totalCount
    }
`

export const AllCustomersQuery_query = gql`
    query AllCustomers($sortObject: [CustomerSortInput!], $filterObject: CustomerFilterInput, $cursor: String!, $pageSize: Int!) {
        customerGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
            ...allCustomerListing
        }
    }

    query AllCustomersTag($sortObject: [CustomerSortInput!], $filterObject: CustomerFilterInput, $cursor: String!, $pageSize: Int!, $tag: Int, $location: Int) {
        customersWithFilter(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor, tagKey: $tag, locationKey: $location) {
            ...customersWithFilterListing
        }
    }
`

export const DeleteCustomerMutation = gql`
    mutation DeleteCustomer($id: Int!) {
        customerDelete(id: $id)
    }
`

interface CustomerDataWrapperProps {
    currentSort: CustomerSortInput
    updateSort(newSort: CustomerSortInput)

    currentFilter: CustomerFilterInput
    updateFilter(newFilter: CustomerFilterInput)

    editCustomer(customer: InnerCustomerFragment)
    deleteCustomer(customer: InnerCustomerFragment)
    currentlyDeletingCustomer?: InnerCustomerFragment
}

const CustomerDataWrapper = (props: CustomerDataWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")
    const [tag, setTag] = useState(undefined)
    const [location, setLocation] = useState(undefined)

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllCustomersTagQueryVariables = {
        sortObject: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,
        // Conditionally add filtering if a key exists
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter }),
        ...(tag && { tag }),
        ...(location && { location })
    }

    const { data, loading, error } = useAllCustomersTagQuery({ variables })

    const [deleteMutation/*, { data: d, loading: l, error: e }*/] = useDeleteCustomerMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteCustomer(null))
    }

    const cancelDelete = () => {
        props.deleteCustomer(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const onTagSelect = (tag) => {
        if (tag !== undefined) {
            setTag(tag.tagKey)
        }
        else {
            setTag(undefined)
        }
    }

    const onLocationSelect = (location) => {
        if (location !== undefined) {
            setLocation(location.locationKey)
        }
        else {
            setLocation(undefined)
        }
    }

    const gridOptions: GridOptions<InnerCustomerFragment> = {
        columnDefs: [
            {
                headerName: "Customer Name",
                field: "name",
                dataTransform: o => o.dbaName ? o.dbaName : o.name,
                sortable: true,
                searchable: true,
            },
            {
                headerName: "Description",
                field: "description",
                dataTransform: o => o.description,
                sortable: true,
                searchable: true,
            },
            {
                headerName: "Tags",
                // Do this weirdness to combine customer tags and locations tags and remove duplicate tags
                dataTransform: o => [...new Set(o.customerTags.map(x => x.tag.name).concat(o.locations.flatMap(y => y.locationTags.map(z => z.tag.name))))].join(', '),
                searchOverride: <TagSearch label="" propertyKey="TagKey" className={styles.search__input} onSelect={onTagSelect} register={React.createRef} errors={{}} clearOnSelect={false} />
            },
            {
                headerName: "Locations",
                field: "locationKey",
                dataTransform: o => o.locations ? o.locations.map(l => l.name ? l.name : `${l.city}, ${l.state}`).join(" | ") : "0",
                searchOverride: <LocationSearch label="" propertyKey="LocationKey" className={styles.search__inputWide} onSelect={onLocationSelect} showCustomer={true} register={React.createRef} errors={{}} clearOnSelect={false} />
            }
        ],

        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.customersWithFilter.edges.map(c => c.node),

        rowActions: [
            {
                icon: ellipsisHorizontal, items: [
                    { displayName: "Edit", action: props.editCustomer },
                    { displayName: "Delete", action: props.deleteCustomer }
                ]
            }
        ],
        dataKeyColumn: "customerKey",
        linkToPath: "/customers/show/:key?navigate=true",

        currentSort: props.currentSort,
        sortAction: props.updateSort,

        currentFilter: props.currentFilter,
        filterAction: props.updateFilter

    }

    return (
        <div>
            <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">

                </div>
            </div>
            <PaginationWrapper
                pageSize={pageSize}
                totalCount={data ? data.customersWithFilter.totalCount : 0}
                changeCursor={pageChange}
            >
                <GridBase<InnerCustomerFragment> gridOptions={gridOptions} />

                {!!props.currentlyDeletingCustomer && <DeleteEntityModal
                    cancel={cancelDelete}
                    confirm={confirmDelete}
                    entityKey={props.currentlyDeletingCustomer.customerKey}
                    entityName="customer"
                    orderFilter={{ customerKey: { eq: props.currentlyDeletingCustomer.customerKey } }}
                    locationFilter={{ customerKey: { eq: props.currentlyDeletingCustomer.customerKey } }}
                />}
            </PaginationWrapper>
        </div>
    )
}

const Customers = () => {
    const navigate = useNavigate()

    return (
        <CustomersInnerWrapper navigate={navigate} />
    )
}

export default Customers

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentSort: CustomerSortInput
    currentFilter: CustomerFilterInput
    currentlyDeletingCustomer?: InnerCustomerFragment
}


class CustomersInnerWrapper extends Component<Props, State>  {
    constructor(props) {
        super(props)

        const currentSort: CustomerSortInput = { name: SortEnumType.Asc }
        const currentFilter: CustomerFilterInput = { isArchived: { eq: false } }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: CustomerSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: CustomerFilterInput) {
        currentFilter.isArchived = this.state.currentFilter.isArchived
        this.setState({ currentFilter })
    }

    navigateToNewCustomer() {
        this.props.navigate('/customers/new')
    }

    editCustomer(customer: InnerCustomerFragment) {
        this.props.navigate(`/customers/edit/${customer.customerKey}`)
    }

    deleteAction(customer: InnerCustomerFragment) {
        this.setState({ currentlyDeletingCustomer: customer })
    }

    setShowArchived(showArchived: boolean) {
        let newFilter = Object.assign({}, this.state.currentFilter)
        newFilter.isArchived = { eq: showArchived }

        this.setState({ currentFilter: newFilter })
    }

    render() {
        return (
            <GeneralPage title="Customers"
                headerContent={
                    <>
                        <HookedCheckbox
                            label="Show Archived"
                            defaultValue={false}
                            propertyKey={"showArchived"}
                            errors={{}}
                            register={React.createRef()}
                            onChange={(event) => this.setShowArchived(event.target.checked)}
                            inline={true}
                        />
                        <Button clickAction={this.navigateToNewCustomer.bind(this)}>New Customer</Button>
                    </>
                }
            >
                <Tile>
                    <CustomerDataWrapper
                        currentlyDeletingCustomer={this.state.currentlyDeletingCustomer}
                        deleteCustomer={this.deleteAction.bind(this)}
                        editCustomer={this.editCustomer.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}

