import React from 'react'
import styles from './Dropdown.module.scss'

export interface RowActionItem {
    displayName: string;
    action(item: any): void;
}

interface Props {
    rowItems: RowActionItem[];
    keyPrefix: string;
    callingData: any;
}

const Dropdown: React.FC<Props> = ({ rowItems, keyPrefix, callingData }) => {
    return (
        <ul className={styles.dropdown}>
            {rowItems.map((item, i) => 
                <li className={styles.item} key={`action-${keyPrefix}-${i}`} onClick={() => item.action(callingData)}>{item.displayName}</li>
            )}
        </ul>
    )
}

export default Dropdown
