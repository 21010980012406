import React, { ReactElement, useEffect } from 'react'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import { useTermContractSearchQuery, useSingleSearchTermContractLazyQuery, InnerTermContractFragment } from '../../../generated/graphql'
import gql from 'graphql-tag'
import moment from 'moment'

interface Props extends DefaultInputProps<InnerTermContractFragment> {
    onSelect?(tc: InnerTermContractFragment)
    prefilledTermContractKey?: number
    clearOnSelect: boolean
    onAdd?(termContract)
    noAdd?: boolean
    locationKey?: number
}

export default function TermContractSearch(props: Props): ReactElement {


    const [executeGetSingleTermContract, { data, loading }] = useSingleSearchTermContractLazyQuery({ variables: { key: props.prefilledTermContractKey } })

    useEffect(() => {
        if (props.prefilledTermContractKey) {
            executeGetSingleTermContract()
        }
    }, [props.prefilledTermContractKey, executeGetSingleTermContract])

    const prefilledData = data && data.termContractGetSingle
    const prefilledDataDisplay = data && `${data.termContractGetSingle.name}`

    return <TextSearchDropdown<InnerTermContractFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => e.termContractKey}
        searchWrapper={<Inner searchTerm="" onSelect={props.onSelect} locationKey={props.locationKey} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}
        {...props}
    />
}

export const TermContractSearchQuery = gql`
    query TermContractSearch($search: String, $locationKey: Int, $filter: TermContractFilterInput) {
        termContractSearch(search: $search, where: $filter, locationKey: $locationKey, order: [{ name: ASC }]) {
            ...innerTermContract
        }
    }
`

const Inner = (props: InnerSearchLogicProps<InnerTermContractFragment>) => {

    const today = moment().startOf('day')
    const { data, loading, error } = useTermContractSearchQuery({ variables: { search: props.searchTerm, locationKey: props.locationKey, filter: { endDate: { gte: today } } } })

    const showData = !loading && !error

    const results = showData &&
        data.termContractSearch.map(t => {
            const display = t.name
            return { key: t.termContractKey, object: t, display }
        })

    const displayProps: SearchDisplayProps<InnerTermContractFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "Term Contracts", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}