import React, { useState } from "react"
import gql from 'graphql-tag'
import { useAllOrdersQuery, AllOrdersQueryVariables, OrderFilterInput, useOrderBulkDispatchMutation } from "../../../generated/graphql"
import Modal from "../../Modal/Modal"
import styles from './OrderDispatchExport.module.scss'
import Button, { ButtonType } from "../../General/Button/Button"
import ContactTile from "../../Tiles/ContactTile/ContactTile"
import { Send } from "react-feather"

export const GetOrderForExport = gql`
    fragment singleOrderExport on Order {
        carrier {
           name
           email
           fax
        }
        customer {
           name
        }
        tripNumber
        deliveryDate
        location {
           directions
        }
        terminal {
           name
           directions
        }
        specialInstructions
        supplier {
           name
        }
        poNumber
        miles
    }

    query GetSingleOrderForExport($id: Int!) {
        orderGetSingle(id: $id) {
            ...singleOrderExport
        }
    }
`

export const BulkDispatchOrdersMutation = gql`
    mutation orderBulkDispatch($ids: [Int!], $sendEmail: Boolean!) {
        orderBulkDispatch(ids: $ids, sendEmail: $sendEmail)
    }
`

interface Props {
   orderKeys: number[]
   closeModal(): void
   callback?(): void
}

const OrderDispatchExportModal = (props: Props) => {
   const [dispatch, setDispatch] = useState(false)
   const [dispatchSubmitted, setDispatchSubmitted] = useState(false)
   const [cursor] = useState("LTE=")
   const pageSize = 25
   const filter: OrderFilterInput = {
      orderKey: { in: props.orderKeys }
   }

   const variables: AllOrdersQueryVariables = {
      cursor: cursor,
      pageSize,
      filterObject: filter
   }

   const { data, loading, error } = useAllOrdersQuery({ variables })

   const [bulkDispatchOrderMutation] = useOrderBulkDispatchMutation()

   const [errorMessage, setErrorMessage] = useState<string>("")
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

   if (loading) return <Modal headerText="Order Dispatch">Loading...</Modal>
   if (error) return <Modal><h2>Error</h2></Modal>

   const submit = (sendEmail: boolean) => {
      setErrorMessage(null)
      setIsSubmitting(true)

      bulkDispatchOrderMutation({ variables: { ids: props.orderKeys, sendEmail } })
         .then(res => {
            if (!res.data.orderBulkDispatch) {
               setErrorMessage("Failed to send email")
               setIsSubmitting(false)
            }
            else {
               setDispatchSubmitted(true)
            }
         })
         .catch(err => {
            setErrorMessage("Failed to send email")
            setIsSubmitting(false)
         })
   }

   const dispatchOrder = () => {
      submit(false)
   }

   const dispatchOrderAndEmail = () => {
      setDispatch(false)
      submit(true)
   }

   const ok = () => {
      props.callback()
      props.closeModal()
   }

   const footerLeftContent = !dispatchSubmitted && (<Button clickAction={props.closeModal} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = !dispatchSubmitted ? (<>
      <Button clickAction={dispatchOrder} buttonType={ButtonType.Primary} isDisabled={isSubmitting}>Dispatch Without Sending</Button>
      <Button clickAction={_ => setDispatch(true)} buttonType={ButtonType.Success} isDisabled={isSubmitting}><Send size={18} /> Dispatch & Send</Button>
   </>
   ) : <Button clickAction={ok} buttonType={ButtonType.Primary}>Ok</Button>

   const carrierKeys = [...new Set(data.orderGetAll.edges.map(o => o.node.carrier.carrierKey))]
   const uniqueCarrierOrders = carrierKeys.map(carrierKey => data.orderGetAll.edges.find(o => o.node.carrier.carrierKey === carrierKey)).map(x => x.node)

   const getOrderKeysFromCarrierKey = (carrierKey: number) => {
      return data.orderGetAll.edges.filter(o => o.node.carrier.carrierKey === carrierKey).map(o => o.node.orderKey)
   }

   const goToPdfView = (carrierKey: number) => {
      const orderKeys = getOrderKeysFromCarrierKey(carrierKey)

      let pdfUrl = window.location.origin + `/api/orderExport?orderKeys=${orderKeys.join(",")}`

      window.open(pdfUrl)
   }

   const confirmLeftFooter = <Button clickAction={_ => setDispatch(false)} buttonType={ButtonType.Transparent}>Cancel</Button>
   const confirmRightFooter = <Button clickAction={dispatchOrderAndEmail} buttonType={ButtonType.Success}>Yes</Button>

   return (
      <>
         {dispatch && <Modal headerText="Dispatch?" footerLeftContent={confirmLeftFooter} footerRightContent={confirmRightFooter}>
            <p>Are you sure you want to send an email for the dispatched orders?</p>
         </Modal>
         }
         {!dispatch && <Modal headerText="Order Dispatch Export" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            {errorMessage && <p className={styles.orderDispatchExport__errorMessage}>{errorMessage}</p>}
            {dispatchSubmitted && <p>Successful Dispatch</p>}
            <div className="row">
               {uniqueCarrierOrders && uniqueCarrierOrders.map(order => {
                  return <div className="col-md-6" key={order.orderKey}>
                     <ContactTile firstName={order.carrier.name} email={order.carrier.email}></ContactTile>
                     <Button buttonType={ButtonType.Secondary} clickAction={() => goToPdfView(order.carrier.carrierKey)}>Preview PDF</Button>
                  </div>
               })}


            </div>
         </Modal >}
      </>
   )
}
export default OrderDispatchExportModal
