import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import React, { ReactElement, useState, useEffect, CSSProperties } from 'react'
import InputWrapper from './InputWrapper'
import { DefaultInputProps } from './HookedTextArea'
import inputStyles from './Input.module.scss'
import './HookedDateTime.scss'

interface Props extends DefaultInputProps<Date> {
    setValue(valueName: string, value: any)
    showTimeSelect?: boolean
    className?: string
    noFutureDates?: boolean
    Change?(valueName: string, value: any)
    disabled?: boolean
}

export default function HookedDateTime(props: Props): ReactElement {
    const [date, setDate] = useState<Date>(null)

    const { register, propertyKey, defaultValue, setValue } = props

    // Need to register the change manually for the DatePicker component
    // https://github.com/react-hook-form/react-hook-form/issues/34
    useEffect(() => {
        register({ name: propertyKey, required: true })
        if (defaultValue) {
            setDate(defaultValue)
            // This if statement stops a rendering loop incase you already have a defalut value and value
            if (defaultValue !== props.value) {
                setValue(propertyKey, defaultValue)
            }
        }
    }, [register, propertyKey, defaultValue, setValue, props.value])

    const onChange = (date: Date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault()
        }

        setDate(date)
        props.setValue(props.propertyKey, date)
        if (props.Change !== undefined) {
            props.Change(props.propertyKey, date)
        }
    }

    const currentError = props.errors[props.propertyKey]
    const datePickerStyles: CSSProperties = {
        width: '100%'
    }

    const format = props.showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"

    return (
        <InputWrapper<Date> {...props}>
            <DatePicker
                style={datePickerStyles}
                className={`${inputStyles.input} ${currentError && inputStyles.input__error}`}
                selected={date}
                onChange={onChange}
                showTimeSelect={props.showTimeSelect}
                dateFormat={format}
                maxDate={props.noFutureDates ? new Date() : undefined}
                disabled={props.disabled === true}
            />
        </InputWrapper>
    )
}
