import React, { Component } from 'react'
import Button from '../Button/Button'
import styles from './Sidebar.module.scss'

interface Props {
    content: React.ReactNode
    hide: boolean
    hideSidebarCallback?()
}

export default class Sidebar extends Component<Props> {
    state = {}

    render() {
        return (
            <div className={`${styles.sidebar} ${this.props.hide ? styles.sidebar__hide : ''}`}>
                <div className={styles.sidebar__hideSidebarButton}>
                    <Button clickAction={this.props.hideSidebarCallback}>Hide Sidebar</Button>
                </div>
                {this.props.content}
            </div>
        )
    }
}
