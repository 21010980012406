import React, { Component, CSSProperties } from 'react'
import { DefaultInputProps } from "../../General/Inputs/HookedTextArea"
import inputStyles from '../../General/Inputs/Input.module.scss'
import InputWrapper from '../../General/Inputs/InputWrapper'
import styles from './TextSearchDropdown.module.scss'
import { X } from 'react-feather'

interface TextProps<T> extends DefaultInputProps<T> {
    onSelect?(entity: T)
    mapEntityToKey(entity: T): number
    searchWrapper: JSX.Element

    isLoadingPrefilled?: boolean
    prefilledEntity?: T
    prefilledEntityDisplay?: string
    disabled?: boolean
    clearOnSelect?: boolean
}

interface TextState<T> {
    searchValue: string
    isShowingDropdown: boolean
    selectedEntity: T
    selectedDisplay: string
    isLoadingPrefilled: boolean
    clicked: boolean
}

export default class TextSearchDropdown<T> extends Component<TextProps<T>, TextState<T>> {
    state = {
        searchValue: "",
        selectedEntity: undefined,
        selectedDisplay: undefined,
        isShowingDropdown: false,
        isLoadingPrefilled: false,
        clicked: false
    }

    componentDidUpdate(prevProps) {
        if (this.state.isLoadingPrefilled && !this.props.isLoadingPrefilled) {
            this.onSelect(this.props.prefilledEntity, this.props.prefilledEntityDisplay)
            this.setState({ isLoadingPrefilled: false })
            return
        }

        if (this.props.isLoadingPrefilled && !this.state.isLoadingPrefilled) {
            this.setState({ isLoadingPrefilled: true })
        }

        if (prevProps.disabled !== this.props.disabled) {
            this.onSelect(undefined, undefined)
        }
    }

    onFocus() {
        this.setState({ isShowingDropdown: true })
    }

    onBlur() {
        setTimeout(() => {
            this.setState({ isShowingDropdown: false, clicked: false })
        }, 200)
    }

    onClick(e: any) {
        if (this.state.selectedDisplay === undefined) {
            this.setState({
                clicked: true,
                isShowingDropdown: true
            })
        }
    }

    onSearch(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchValue: event.target.value,
            selectedEntity: undefined,
            selectedDisplay: undefined,
        })
    }

    onSelect(entity: T, entityDisplay: string) {
        this.setState({ searchValue: "" })
        if (!this.props.clearOnSelect) {
            this.setState({
                selectedEntity: entity,
                selectedDisplay: entityDisplay
            })
        }
        this.props.onSelect && this.props.onSelect(entity)
    }

    renderContents() {
        if (this.props.isLoadingPrefilled) {
            return <span className={styles.selection}>Loading</span>
        }
        return this.state.selectedDisplay !== undefined && <span className={styles.selection}>{`${this.state.selectedDisplay}`}{this.props.disabled !== true ? <span onClick={this.clearSelected.bind(this)} className={styles.removeButton}>  <X size="13" /></span> : <></>}</span>

    }

    clearSelected() {
        // this.setState({ selectedEntity: undefined, selectedDisplay: undefined });
        this.onSelect(undefined, undefined)
    }

    render() {
        const displayStyles: CSSProperties = {
            display: "none"
        }

        const currentError = this.props.errors[this.props.propertyKey]

        return (
            <InputWrapper {...this.props}>
                {this.state.selectedEntity !== undefined && <input
                    style={displayStyles}
                    value={this.props.mapEntityToKey(this.state.selectedEntity)}
                    ref={this.props.register}
                    name={this.props.propertyKey}
                    type="number"
                    onChange={() => { }}
                />}
                <div className={styles.inputWrapper}>
                    {this.renderContents()}
                    <input
                        className={`${inputStyles.input} ${currentError && inputStyles.input__error}`}
                        onChange={this.onSearch.bind(this)}
                        value={this.state.searchValue}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        disabled={this.props.disabled ? true : false}
                        onClick={this.onClick.bind(this)}
                        autoComplete="randomstringtodisableautocomplete"
                    />

                    {/* {this.state.searchValue.trim().length > 0 && this.state.isShowingDropdown && <Inner onSelect={this.onSelect.bind(this)} searchTerm={this.state.searchValue.trim()} />} */}
                    {/* {this.state.searchValue.trim().length > 0 && this.state.isShowingDropdown && React.cloneElement(this.props.searchWrapper, { onSelect: this.onSelect.bind(this), searchTerm: this.state.searchValue.trim() })} */}
                    {(this.state.searchValue.trim().length > 0 || this.state.clicked) && this.state.isShowingDropdown && React.cloneElement(this.props.searchWrapper, { onSelect: this.onSelect.bind(this), searchTerm: this.state.searchValue.trim() })}
                </div>
            </InputWrapper>
        )
    }
}