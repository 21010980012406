import React, { Component, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import { useAllFlatPrePurchaseAgreementQuery, InnerFlatPrePurchaseAgreementFragment, useDeletePrePurchaseAgreementMutation, AllFlatPrePurchaseAgreementQueryVariables, FlatPrePurchaseAgreementFilterInput, FlatPrePurchaseAgreementSortInput, InnerFlatPrePurchaseAgreementListingFragment, SortEnumType } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import moment from 'moment'

export const PrePurchaseAgreementFragment = gql`
   fragment innerPrePurchaseAgreement on PrePurchaseAgreement {
      prePurchaseAgreementKey
      supplierAgreement

      supplier {
            name
        }

        terminal {
            name
        }
   }

   fragment allPrePurchaseAgreementListing on PrePurchaseAgreementGetAllConnection {
      edges {
         cursor
         ...innerPrePurchaseAgreementListing
      }
      totalCount
   }

   fragment innerPrePurchaseAgreementListing on PrePurchaseAgreementGetAllEdge {
      node {
         ...innerPrePurchaseAgreement
      }
   }

   fragment innerFlatPrePurchaseAgreement on FlatPrePurchaseAgreement {
      prePurchaseAgreementKey
      supplierAgreement
      endShipDate

      supplier
      terminal
   }

   fragment allFlatPrePurchaseAgreementListing on FlatPrePurchaseAgreementGetAllConnection {
      edges {
         cursor
         ...innerFlatPrePurchaseAgreementListing
      }
      totalCount
   }

   fragment innerFlatPrePurchaseAgreementListing on FlatPrePurchaseAgreementGetAllEdge {
      node {
         ...innerFlatPrePurchaseAgreement
      }
   }
`

export const AllPrePurchaseAgreementQuery = gql`
   query AllPrePurchaseAgreement ($sortObject: [PrePurchaseAgreementSortInput!], $filterObject: PrePurchaseAgreementFilterInput, $cursor: String!, $pageSize: Int!) {
      prePurchaseAgreementGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
         ...allPrePurchaseAgreementListing
      }
   }

   query AllFlatPrePurchaseAgreement($sort: [FlatPrePurchaseAgreementSortInput!], $filter: FlatPrePurchaseAgreementFilterInput, $cursor: String!, $pageSize: Int!) {
      flatPrePurchaseAgreementGetAll(order: $sort, where: $filter, first: $pageSize, after: $cursor) {
         ...allFlatPrePurchaseAgreementListing
      }
   }
`

export const DeletePrePurchaseAgreementMutation = gql`
   mutation DeletePrePurchaseAgreement($id: Int!) {
      prePurchaseAgreementDelete(id: $id)
   }
`

interface PrePurchaseAgreementDataWrapperProps {
   currentSort: FlatPrePurchaseAgreementSortInput
   updateSort(newSort: FlatPrePurchaseAgreementSortInput)

   currentFilter: FlatPrePurchaseAgreementFilterInput
   updateFilter(newFilter: FlatPrePurchaseAgreementFilterInput)

   editPrePurchaseAgreement(prePurchaseAgreement: InnerFlatPrePurchaseAgreementListingFragment)
   deletePrePurchaseAgreement(prePurchaseAgreement: InnerFlatPrePurchaseAgreementListingFragment)
   currentlyDeletingPrePurchaseAgreement: InnerFlatPrePurchaseAgreementFragment
}

const PrePurchaseAgreementDataWrapper = (props: PrePurchaseAgreementDataWrapperProps): JSX.Element => {
   const pageSize = 25

   const [cursor, setCursor] = useState("LTE=")

   useEffect(() => {
      setCursor("LTE=")
   }, [props.currentFilter, setCursor])

   const variables: AllFlatPrePurchaseAgreementQueryVariables = {
      sort: props.currentSort,
      cursor: cursor,
      pageSize: pageSize,

      ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
   }

   const { data, loading, error, refetch } = useAllFlatPrePurchaseAgreementQuery({ variables })

   const [deleteMutation] = useDeletePrePurchaseAgreementMutation()

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => refetch())
         .then(() => props.deletePrePurchaseAgreement(null))
   }

   const cancelDelete = () => {
      props.deletePrePurchaseAgreement(null)
   }

   const pageChange = (currentCursor: string) => {
      setCursor(currentCursor)
   }

   const gridOptions: GridOptions<InnerFlatPrePurchaseAgreementFragment> = {
      columnDefs: [
         { headerName: "Key", field: "prePurchaseAgreementKey", dataTransform: o => o.prePurchaseAgreementKey.toString(), sortable: true, searchable: true },
         { headerName: "Supplier Agreement", field: "supplierAgreement", dataTransform: o => o.supplierAgreement, sortable: true, searchable: true },
         { headerName: "Supplier", dataTransform: o => o.supplier, field: "supplier", sortable: true, searchable: true },
         { headerName: "Terminal", dataTransform: o => o.terminal, field: "terminal", sortable: true, searchable: true }
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: ((loading || error) && !data) ? [] : data.flatPrePurchaseAgreementGetAll.edges.map(x => x.node),
      rowActions: [
         {
            icon: ellipsish, items: [
               { displayName: "Edit", action: props.editPrePurchaseAgreement },
               { displayName: "Delete", action: props.deletePrePurchaseAgreement }
            ]
         }
      ],
      dataKeyColumn: "prePurchaseAgreementKey",
      linkToPath: "/pricing/pre-purchase-agreements/edit/:key",
      currentSort: props.currentSort,
      sortAction: props.updateSort,
      currentFilter: props.currentFilter,
      filterAction: props.updateFilter
   }

   if (props.currentFilter.endShipDate && props.currentFilter.endShipDate.lt) {
      gridOptions.columnDefs.push({
         headerName: "End Ship Date", field: "endShipDate", dataTransform: o => moment.utc(o.endShipDate).format('LL')
      })
   }

   return (
      <div>
         <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.flatPrePurchaseAgreementGetAll.totalCount : 0}
            changeCursor={pageChange}
         >
            <GridBase<InnerFlatPrePurchaseAgreementFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingPrePurchaseAgreement && <Modal headerText="Delete?">
               <p>Once you delete this Pre Purchase Agreement, it will not be able to be reversed.</p>
               <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(props.currentlyDeletingPrePurchaseAgreement.prePurchaseAgreementKey)}>Delete</button>
            </Modal>}
         </PaginationWrapper>
      </div>
   )
}

const PrePurchaseAgreementList = () => {
   const navigate = useNavigate()

   return (
      <PrePurchaseAgreementListInnerWrapper navigate={navigate} />
   )
}

export default PrePurchaseAgreementList

interface Props {
   navigate: NavigateFunction
}

interface State {
   currentSort: FlatPrePurchaseAgreementSortInput
   currentFilter: FlatPrePurchaseAgreementFilterInput
   currentlyDeletingPrePurchaseAgreement?: InnerFlatPrePurchaseAgreementFragment
}

class PrePurchaseAgreementListInnerWrapper extends Component<Props, State> {
   constructor(props) {
      super(props)

      const currentSort: FlatPrePurchaseAgreementSortInput = { prePurchaseAgreementKey: SortEnumType.Desc }

      const today = moment().startOf('day')

      const currentFilter: FlatPrePurchaseAgreementFilterInput = { endShipDate: { gte: today } }

      this.state = {
         currentSort,
         currentFilter,
      }
   }

   sortClick(currentSort: FlatPrePurchaseAgreementSortInput) {
      this.setState({ currentSort })
   }

   filterClick(currentFilter: FlatPrePurchaseAgreementFilterInput) {
      this.setState({ currentFilter })
   }

   navigateToNewPrePurchaseAgreement() {
      this.props.navigate('/pricing/pre-purchase-agreements/new')
   }

   editPrePurchaseAgreement(prePurchaseAgreement: InnerFlatPrePurchaseAgreementFragment) {
      this.props.navigate(`/pricing/pre-purchase-agreements/edit/${prePurchaseAgreement.prePurchaseAgreementKey}`)
   }

   deletePrePurchaseAgreements(prePurchaseAgreement: InnerFlatPrePurchaseAgreementFragment) {
      this.setState({ currentlyDeletingPrePurchaseAgreement: prePurchaseAgreement })
   }

   setShowExpired(showExpired: boolean) {
      let currentFilter: FlatPrePurchaseAgreementFilterInput = Object.assign({}, this.state.currentFilter)

      const today = moment().startOf('day')

      if (showExpired) {
         currentFilter.endShipDate = { lt: today }
      }
      else {
         currentFilter.endShipDate = { gte: today }
      }

      this.setState({ currentFilter: currentFilter })
   }

   render() {
      return (
         <GeneralPage title="Pre Purchase Agreements" headerContent={
            <div>
               <HookedCheckbox
                  label="Show Expired"
                  defaultValue={false}
                  propertyKey={"showExpired"}
                  errors={{}}
                  register={React.createRef()}
                  onChange={(event) => this.setShowExpired(event.target.checked)}
                  inline={true}
               />
               <Button clickAction={this.navigateToNewPrePurchaseAgreement.bind(this)}>New Pre Purchase Agreement</Button>
            </div>
         }>
            <Tile>
               <PrePurchaseAgreementDataWrapper
                  currentSort={this.state.currentSort}
                  updateSort={this.sortClick.bind(this)}
                  currentFilter={this.state.currentFilter}
                  updateFilter={this.filterClick.bind(this)}
                  currentlyDeletingPrePurchaseAgreement={this.state.currentlyDeletingPrePurchaseAgreement}
                  deletePrePurchaseAgreement={this.deletePrePurchaseAgreements.bind(this)}
                  editPrePurchaseAgreement={this.editPrePurchaseAgreement.bind(this)} />
            </Tile>
         </GeneralPage>
      )
   }
}
