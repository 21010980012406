import React, { useState } from 'react'
import styles from './GeneralPage.module.scss'
import Sidebar from '../Sidebar/Sidebar'
import Button, { ButtonType } from '../Button/Button'


interface Props {
    title?: string
    beforeChildren?: React.ReactNode
    afterChildren?: React.ReactNode
    headerContent?: React.ReactNode
    mobileHeaderContent?: React.ReactNode

    centerText?: string
    centerContent?: React.ReactNode

    hideHeader?: boolean
    hasFooter?: boolean
    showSidebar?: boolean
    hideSidebarCallback?()

    sidebarContent?: React.ReactNode
    onScroll?(event: any)

    children: React.ReactNode
}

const GeneralPage: React.FC<Props> = ({ title, children, beforeChildren, afterChildren, headerContent, mobileHeaderContent, centerText, centerContent, hideHeader, hasFooter, showSidebar, sidebarContent, onScroll }) => {
    document.title = title

    const [hideMobileSidebar, setHideMobileSidebar] = useState(true)

    const handleScroll = (e) => {
        if (onScroll) {
            onScroll(e)
        }
    }

    window.addEventListener('scroll', handleScroll)

    const hideSidebarCallback = () => {
        setHideMobileSidebar(true)
    }

    return (
        <div className={`${styles.GeneralPage}`} >
            <div className={styles.GeneralPage__bodyContainer} onScroll={handleScroll}>
                {beforeChildren}
                <div className={`${styles.GeneralPage__mainSection} gen-container`}>
                    {!hideHeader && <div className={styles.GeneralPage__titleSection}>
                        {title && <h2 className={styles.GeneralPage__title}>{title}</h2>}
                        <div className={styles.GeneralPage__middleSection}>
                            {centerText && <h3 className={styles.GeneralPage__centerText}>{centerText}</h3>}
                            {centerContent}
                        </div>
                        <div className={styles.GeneralPage__headerContent}>
                            {headerContent}
                            {showSidebar && <div className={styles.GeneralPage__mobileHeaderContent}>
                                <Button buttonType={ButtonType.Secondary} clickAction={() => { setHideMobileSidebar(false) }}>Show Sidebar</Button>
                            </div>}
                        </div>
                    </div>}
                    {children}
                </div>

                {afterChildren}
            </div>
            {showSidebar && <Sidebar hide={hideMobileSidebar} content={sidebarContent} hideSidebarCallback={hideSidebarCallback}></Sidebar>}
        </div>
    )
}

export default GeneralPage
