import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { CreatePrePurchaseAgreementMutationVariables, useCreatePrePurchaseAgreementMutation, PrePurchaseAgreementSalesAgreementInput, useCreatePrePurchaseAgreementSalesAgreementMutation } from '../../generated/graphql'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import MultiSalesAgreementSearch from '../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch'
import { useNavigate } from 'react-router-dom'

export const CreatePrePurchaseAgreementQuery = gql`
   mutation CreatePrePurchaseAgreement($prePurchaseAgreement: PrePurchaseAgreementInput!) {
      prePurchaseAgreementCreate(prePurchaseAgreement: $prePurchaseAgreement) {
         prePurchaseAgreementKey
      }
   }
`
interface Props {
}



export default function PrePurchaseAgreementCreate(props: Props): ReactElement {
   const navigate = useNavigate()

   const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)

   const validationSchema = yup.object().shape({
      prePurchaseAgreement: yup.object().shape({
         supplierAgreement: yup.string().required().trim(),
         prePaidDollars: yup.number().required(),
         gallons: yup.number().required(),
         toDateGallons: yup.number().required(),
         productCost: yup.number().required(),
         assessmentCost: yup.number().required(),
         otherCosts: yup.number().required(),
         units: yup.number().required(),
         freightCost: yup.number().required(),
         miscCost: yup.number().required(),
         supplierKey: yup.number().required(),
         terminalKey: yup.number().required(),

         startShipDate: yup.date().required(),
         endShipDate: yup.date().required(),

         aprilEstimate: yup.number().required(),
         mayEstimate: yup.number().required(),
         juneEstimate: yup.number().required(),
         julyEstimate: yup.number().required(),
         augustEstimate: yup.number().required(),
         septemberEstimate: yup.number().required(),
         octoberEstimate: yup.number().required(),
         novemberEstimate: yup.number().required(),
         decemberEstimate: yup.number().required(),
         januaryEstimate: yup.number().required(),
         februaryEstimate: yup.number().required(),
         marchEstimate: yup.number().required(),

         aprilPriceIncrement: yup.number().required(),
         mayPriceIncrement: yup.number().required(),
         junePriceIncrement: yup.number().required(),
         julyPriceIncrement: yup.number().required(),
         augustPriceIncrement: yup.number().required(),
         septemberPriceIncrement: yup.number().required(),
         octoberPriceIncrement: yup.number().required(),
         novemberPriceIncrement: yup.number().required(),
         decemberPriceIncrement: yup.number().required(),
         januaryPriceIncrement: yup.number().required(),
         februaryPriceIncrement: yup.number().required(),
         marchPriceIncrement: yup.number().required(),

         aprilSellBack: yup.number().required(),
         maySellBack: yup.number().required(),
         juneSellBack: yup.number().required(),
         julySellBack: yup.number().required(),
         augustSellBack: yup.number().required(),
         septemberSellBack: yup.number().required(),
         octoberSellBack: yup.number().required(),
         novemberSellBack: yup.number().required(),
         decemberSellBack: yup.number().required(),
         januarySellBack: yup.number().required(),
         februarySellBack: yup.number().required(),
         marchSellBack: yup.number().required(),
      })
   })

   const [CreatePrePurchaseAgreementSalesAgreement] = useCreatePrePurchaseAgreementSalesAgreementMutation()

   const { register, handleSubmit, errors, setValue } = useForm<CreatePrePurchaseAgreementMutationVariables>({ validationSchema })

   const commonProps = { register, errors, setValue }

   const [createPrePurchaseAgreementMutation] = useCreatePrePurchaseAgreementMutation()

   const navigateToList = () => {
      navigate("/pricing/pre-purchase-agreements")
   }

   const navigateToPrePurchaseAgreement = (id: number) => {
      navigate(`/pricing/pre-purchase-agreements/edit/${id}`)
   }

   const submit = (variables: CreatePrePurchaseAgreementMutationVariables) => {
      createPrePurchaseAgreementMutation({ variables })
         .then(r => {
            if (salesAgreementKeys) {
               salesAgreementKeys.forEach(key => {
                  const ppInput: PrePurchaseAgreementSalesAgreementInput = { salesAgreementKey: key, prePurchaseAgreementKey: r.data.prePurchaseAgreementCreate.prePurchaseAgreementKey }
                  CreatePrePurchaseAgreementSalesAgreement({ variables: { ppasa: ppInput } })
               })
            }
            navigateToPrePurchaseAgreement(r.data.prePurchaseAgreementCreate.prePurchaseAgreementKey)
         })
   }
   const onSubmit = handleSubmit(submit)

   const secondarySubmit = (a) => {
      onSubmit(a)
   }

   const addSalesAgreement = (num: number) => {
      if (salesAgreementKeys === undefined) {
         setSalesAgreementKeys([num])
      }
      else if (!salesAgreementKeys.some(x => x === num)) {
         setSalesAgreementKeys([...salesAgreementKeys, num])
      }
   }

   const removeSalesAgreement = (num: number) => {
      setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
   }

   const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Pre Purchase Agreement</Button>)

   return (
      <FormPage title="Create Pre Purchase Agreement" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={secondarySubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        label="Supplier Agreement"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.supplierAgreement)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Pre Paid Dollars"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.prePaidDollars)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Gallons"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.gallons)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="To Date Gallons"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.toDateGallons)}
                        {...commonProps}
                     />
                  </div>

               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Product Cost"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.productCost)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Perc"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.assessmentCost)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Other Costs"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.otherCosts)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>

               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Units"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.units)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Freight Cost"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.freightCost)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Misc Cost"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.miscCost)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        label="Cost Description"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.costDescription)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <HookedDateTime
                        label="Start Ship Date"
                        defaultValue={new Date()}
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.startShipDate, 0)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedDateTime
                        label="End Ship Date"
                        defaultValue={new Date()}
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.endShipDate, 0)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Include in Term Contract Vol"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.includeInTermContractVolume)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Include Freight"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.includeFreight)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Charge Tariff"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.chargeTariff)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Show on Pricing"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.showOnPricing)}
                        defaultValue={true}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">

                     <TerminalSearch
                        label="Terminal"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.terminalKey)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">

                     <SupplierSearch
                        label="Supplier"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.supplierKey)}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <MultiSalesAgreementSearch
                        removeSalesAgreement={removeSalesAgreement}
                        addSalesAgreement={addSalesAgreement}
                        data={salesAgreementKeys} />
                  </div>
               </div>
               <div>
                  <div className="col-md-12">
                     <HookedTextArea
                        label="Description"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.description)}
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <h6>Est</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.mayEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.juneEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julyEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januaryEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februaryEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchEstimate)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>


               <div className="row">
                  <div className="col-md-12">
                     <h6>Price Increment</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.mayPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.junePriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julyPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januaryPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februaryPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchPriceIncrement)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>


               <div className="row">
                  <div className="col-md-12">
                     <h6>Sell Back</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.maySellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.juneSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julySellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januarySellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februarySellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<CreatePrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchSellBack)}
                        defaultValue="0"
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
         </form>
      </FormPage>
   )
}