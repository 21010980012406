import React from 'react'
import Modal from '../../Modal/Modal';
import gql from 'graphql-tag';
import useForm from 'react-hook-form';
import { CreateContactMutationVariables, Contact, useEditContactMutation, EditContactMutationVariables, EditContactMutationResult } from '../../../generated/graphql';
import propName from '../../../helpers/propName';
import * as yup from "yup"
import HookedTextInput from '../../General/Inputs/HookedTextInput';
import Button, { ButtonType } from '../../General/Button/Button';
import HookedTextArea from '../../General/Inputs/HookedTextArea';
import HookedCheckbox from '../../General/Inputs/HookedCheckbox';

interface Props {
	closeModal(): void
	callback(result: EditContactMutationResult): Promise<any>
	terminalKey?: number
	supplierKey?: number
	carrierKey?: number
	contact: Contact
	default?: boolean
}

export const EditContactQuery = gql`
   mutation EditContact($contact: ContactInput! $id: Int!){
      contactUpdate(contact: $contact, id: $id) {
         contactKey
		 default
      }
   }
`

const EditContactModal = (props: Props) => {

	const [updateContactMutation] = useEditContactMutation()
	const onUpdate = (variables: EditContactMutationVariables) => {

		variables.contact.terminalKey = props.terminalKey
		variables.contact.supplierKey = props.supplierKey
		variables.contact.carrierKey = props.carrierKey
		variables.contact.deleted = false
		updateContactMutation({ variables: { ...variables, ...{ id: props.contact.contactKey } } })
			.then(props.callback)
			.then(() => props.closeModal());
	}

	const validationSchema = yup.object().shape({
		contact: yup.object().shape({
			firstName: yup.string().required().trim(),
			lastName: yup.string().notRequired().trim(),
			email: yup.string().trim(),
			phone: yup.string().notRequired().trim(),
			cellPhone: yup.string().notRequired().trim(),
			homePhone: yup.string().notRequired().trim()
		})
	})

	const { register, handleSubmit, errors } = useForm<CreateContactMutationVariables>({ validationSchema })

	const onSubmit = handleSubmit(onUpdate)

	const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
	const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update Contact</Button>

	return (
		<Modal headerText="Update Contact" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
			<form onSubmit={onSubmit}>

				<div className="row">

					<div className="col-md-6">

						<HookedTextInput
							register={register}
							label="First Name"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.firstName)}
							defaultValue={props.contact.firstName}
							errors={errors}
						/>
					</div>
					<div className="col-md-6">
						<HookedTextInput
							register={register}
							label="Last Name"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.lastName)}
							defaultValue={props.contact.lastName}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedTextInput
							register={register}
							label="Email"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.email)}
							defaultValue={props.contact.email}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Work Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.phone)}
							defaultValue={props.contact.phone}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Cell Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.cellPhone)}
							defaultValue={props.contact.cellPhone ? props.contact.cellPhone : ""}
							errors={errors}
						/>
					</div>
					<div className="col-md-4">
						<HookedTextInput
							register={register}
							label="Home Phone"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.homePhone)}
							defaultValue={props.contact.homePhone ? props.contact.homePhone : ""}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedTextArea
							register={register}
							label="Notes"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.notes)}
							defaultValue={props.contact.notes}
							errors={errors}
						/>
					</div>
					<div className="col-md-12">
						<HookedCheckbox
							register={register}
							label="Default"
							propertyKey={propName<CreateContactMutationVariables>(o => o.contact.default)}
							defaultValue={props.default !== undefined ? props.default : props.contact.default}
							errors={errors}
						/>
					</div>
				</div>
			</form>

		</Modal>
	)
}
export default EditContactModal 