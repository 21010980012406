import React, { useState } from 'react'
import gql from 'graphql-tag'
import Modal from '../../Modal/Modal'
import useForm from 'react-hook-form'
import { CreateStateTaxMutationVariables, StateTaxType, useGetSingleStateTaxQuery, useUpdateStateTaxMutation, UpdateStateTaxMutationVariables, UpdateStateTaxMutationResult } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import Button, { ButtonType } from '../../General/Button/Button'
import StateSearch from '../../Search/StateSearch/StateSearch'
import HookedSelectOptions from '../../General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../General/SelectOptions/SelectOptions'
import moment from 'moment'

export const updateStateTax = gql`
	mutation UpdateStateTax($stateTax: StateTaxInput!, $stateTaxKey: Int!) {
		stateTaxUpdate(stateTax: $stateTax, stateTaxKey: $stateTaxKey) {
			stateTaxKey
		}
	}
`

export const GetSingleStateTax = gql`
    query GetSingleStateTax($key: Int!) {
        stateTaxGetSingle(id: $key) {
            ...innerStateTax
        }
    }
`

interface Props {
    stateTaxKey: number
    closeModal(): void
    callback(result: UpdateStateTaxMutationResult): Promise<any>
    delete(key): void
}

const UpdateStateTaxModal = (props: Props) => {
    const [updateStateTaxMutation, { error: updateError }] = useUpdateStateTaxMutation()
    const { data, loading, error } = useGetSingleStateTaxQuery({ variables: { key: props.stateTaxKey } })
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)

    const submit = (variables: UpdateStateTaxMutationVariables) => {
        variables.stateTaxKey = props.stateTaxKey
        updateStateTaxMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const stateTaxTypes: SelectOption<StateTaxType>[] = Object.keys(StateTaxType).map(c => {
        return { key: StateTaxType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
        }
    }


    const validationSchema = yup.object().shape({
        stateTax: yup.object().shape({
            startDate: yup.date().required(),
            amount: yup.number().required(),
            stateKey: yup.number().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateStateTaxMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    if (loading || error) {
        return <div></div>
    }

    if (startDate === undefined) {
        setStartDate(new Date(moment.utc(data.stateTaxGetSingle.startDate).format('LL')))
    }

    if (endDate === undefined && data.stateTaxGetSingle.endDate) {
        setEndDate(new Date(moment.utc(data.stateTaxGetSingle.endDate).format('LL')))
    }

    const addEndDate = () => {
        setEndDate(new Date())
    }

    const defaultStateTaxType = stateTaxTypes.find(ct => ct.key === data.stateTaxGetSingle.stateTaxType)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <>{!endDate && <Button clickAction={addEndDate}>Add End Date</Button>}<Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update State Tax</Button></>

    return (
        <Modal headerText="Update State Tax" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll narrow>
            <form onSubmit={onSubmit}>
                {updateError && <div className="row">
                    <div className="col-md-12">
                        <h4 style={{ color: "red" }}>{updateError.message}</h4>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-md-4">
                        <StateSearch
                            label="State"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.stateKey)}
                            prefilledStateKey={data.stateTaxGetSingle.stateKey}
                            {...commonProps}
                        />
                    </div>

                    <div className="col-md-4">
                        <HookedTextInput
                            label="Amount"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.amount)}
                            defaultValue={data.stateTaxGetSingle.amount.toString()}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedSelectOptions
                            options={stateTaxTypes}
                            label="Tax Type"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.stateTaxType)}
                            setDefaultValue={defaultStateTaxType}
                            disabled
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedDateTime
                            label="Start Date"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.startDate)}
                            defaultValue={startDate}
                            Change={onStartDateChange}
                            {...commonProps}
                        />
                    </div>
                    {(endDate) &&
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.endDate)}
                                defaultValue={endDate}
                                Change={onEndDateChange}
                                {...commonProps}
                            />
                        </div>}
                </div>
            </form>
        </Modal>
    )
}
export default UpdateStateTaxModal