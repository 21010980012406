import React, { Component, useState, useEffect } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import Tile from '../../components/General/Tile/Tile'
import gql from 'graphql-tag'
import { useAllLocationsQuery, LocationSortInput, LocationFilterInput, AllLocationsQueryVariables, useDeleteLocationMutation, InnerLocationFragment, SortEnumType } from '../../generated/graphql'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import ellipsisHorizontal from '../../icons/ellipsis-h.svg'

import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const LocationFragment = gql`
    fragment innerLocation on Location {
        locationKey
        name
        city
        state
        zipCode
        address1
        address2

        customer {
            customerKey
            name
        }
    }

    fragment innerLocationListing on LocationGetAllEdge {
        node {
            ...innerLocation
        }

    }
    fragment allLocationListing on LocationGetAllConnection {
        edges {
            cursor
            ...innerLocationListing
        }
        totalCount
    }
`

export const AllLocationsQuery_query = gql`
    query AllLocations($sortObject: [LocationSortInput!], $filterObject: LocationFilterInput, $cursor: String!, $pageSize: Int!) {
        locationGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
            ...allLocationListing
        }
    }
`

interface LocationDataWrapperProps {
    currentSort: LocationSortInput
    updateSort(newSort: LocationSortInput)

    currentFilter: LocationFilterInput
    updateFilter(newFilter: LocationFilterInput)

    editLocation(location: InnerLocationFragment)
    newCall(location: InnerLocationFragment)
    deleteLocation(location: InnerLocationFragment)
    currentlyDeletingLocation?: InnerLocationFragment
}

const LocationDataWrapper = (props: LocationDataWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllLocationsQueryVariables = {
        sortObject: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        // Conditionally add filtering if a key exists
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error } = useAllLocationsQuery({ variables })

    const [deleteMutation] = useDeleteLocationMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteLocation(null))
    }

    const cancelDelete = () => {
        props.deleteLocation(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerLocationFragment> = {

        columnDefs: [
            {
                headerName: "Location Name",
                field: "name",
                dataTransform: o => o.name ? o.name : `${o.city}, ${o.state}`,
                sortable: true,
                searchable: true,
            },
            {
                headerName: "Address",
                field: "address",
                dataTransform: o => `${o.address1}${o.address2 && " - " + o.address2}`
            },
            {
                headerName: "City",
                field: "city",
                dataTransform: o => o.city,
                sortable: true,
                searchable: true
            },
            {
                headerName: "State",
                field: "state",
                dataTransform: o => o.state,
                sortable: true,
                searchable: true
            },
            {
                headerName: "Zip Code",
                field: "zipCode",
                dataTransform: o => o.zipCode
            },
            {
                headerName: "Customer",
                field: "customer",
                dataTransform: o => o.customer.name
            },
        ],

        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.locationGetAll.edges.map(x => x.node),

        rowActions: [
            {
                icon: ellipsisHorizontal, items: [
                    { displayName: "Edit", action: props.editLocation },
                    { displayName: "New Call", action: props.newCall },
                    { displayName: "Delete", action: props.deleteLocation }
                ]
            }
        ],

        dataKeyColumn: "locationKey",
        linkToPath: "/locations/show/:key",

        currentSort: props.currentSort,
        sortAction: props.updateSort,

        currentFilter: props.currentFilter,
        filterAction: props.updateFilter

    }

    return (
        <div>
            <PaginationWrapper
                pageSize={pageSize}
                totalCount={data ? data.locationGetAll.totalCount : 0}
                changeCursor={pageChange}
            >
                <GridBase<InnerLocationFragment> gridOptions={gridOptions} />

                {!!props.currentlyDeletingLocation && <DeleteEntityModal
                    cancel={cancelDelete}
                    confirm={confirmDelete}
                    entityKey={props.currentlyDeletingLocation.locationKey}
                    entityName="location"
                    orderFilter={{ locationKey: { eq: props.currentlyDeletingLocation.locationKey } }} />}
            </PaginationWrapper>
        </div>
    )
}

const Locations = () => {
    const navigate = useNavigate()

    return (
        <LocationsInnerWrapper navigate={navigate} />
    )
}

export default Locations

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentSort: LocationSortInput
    currentFilter: LocationFilterInput
    currentlyDeletingLocation?: InnerLocationFragment
}


class LocationsInnerWrapper extends Component<Props, State>  {
    constructor(props) {
        super(props)

        const currentSort: LocationSortInput = { name: SortEnumType.Asc }
        const currentFilter: LocationFilterInput = {}

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: LocationSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: LocationFilterInput) {
        this.setState({ currentFilter })
    }

    navigateToNewLocation() {
        this.props.navigate('/locations/new')
    }

    navigateToNewCall(location: InnerLocationFragment) {
        this.props.navigate(`/tasks/new?l=${location.locationKey}&c=${location.customer.customerKey}`)
    }

    editLocation(location: InnerLocationFragment) {
        this.props.navigate(`/locations/edit/${location.locationKey}`)
    }

    deleteAction(location: InnerLocationFragment) {
        this.setState({ currentlyDeletingLocation: location })
    }

    render() {
        return (
            <GeneralPage title="Locations" >
                <Tile>
                    <LocationDataWrapper
                        currentlyDeletingLocation={this.state.currentlyDeletingLocation}
                        deleteLocation={this.deleteAction.bind(this)}
                        editLocation={this.editLocation.bind(this)}
                        newCall={this.navigateToNewCall.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}

