import React, { ReactElement, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useGetPricingSalesAgreementLazyQuery, useGetPricingPostingLazyQuery, useGetPricingIndexContractLazyQuery, useGetPricingPrePurchaseAgreementLazyQuery, CreateOrderMutationVariables, useCreateOrderMutation, useGetLocationSingleQuery, AllCarrierListingFragment, OrderStatus, BasicLocationFragment, useAllFileNumbersLazyQuery, OrderType, useGetFreightForOrderLazyQuery, GetFreightForOrderQueryVariables, FlatCarrierRate, useGetStateTaxesForLocationQuery, StateTaxType } from '../../generated/graphql'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import LocationSearch from '../../components/Search/LocationSearch/LocationSearch'

import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import propName from '../../helpers/propName'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import CarrierSearch from '../../components/Search/CarrierSearch/CarrierSearch'
import FileNumberSearch from '../../components/Search/FileNumber/FileNumberSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'
import PricingTextBoxes from '../../components/Pricing/PricingTextBoxes'
import moment from 'moment'
import SalesAgreementSearch from '../../components/Search/SalesAgreementSearch/SalesAgreementSearch'
import PrePurchaseAgreementSearch from '../../components/Search/PrePurchaseAgreementSearch/PrePurchaseAgreementSearch'
import DeliveryTimeSearch from '../../components/Search/DeliveryTimeSearch/DeliveryTimeSearch'
import TermContractSearch from '../../components/Search/TermContractSearch/TermContractSearch'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const CreateOrderMutation = gql`
    mutation CreateOrder($order: OrderInput!, $copy: Boolean) {
        orderCreate(order: $order, copy: $copy) {
            orderKey
        }
    }
`

export const GetPricingPostingQuery = gql`
    query GetPricingPosting($locationKey: Int!, $postingKey: Int!, $pricingDate: DateTime!, $salesAgreementKey: Int) {
        pricingPostingGet(locationKey: $locationKey, postingKey: $postingKey, pricingDate: $pricingDate, salesAgreementKey: $salesAgreementKey) {
            ...pricingListing
        }
    }
`

export const GetPricingIndexContractQuery = gql`
    query GetPricingIndexContract($locationKey: Int!, $indexContractKey: Int!, $pricingDate: DateTime!, $salesAgreementKey: Int) {
        pricingIndexContractGet(locationKey: $locationKey, indexContractKey: $indexContractKey, pricingDate: $pricingDate, salesAgreementKey: $salesAgreementKey) {
            ...pricingListing
        }
    }
`

export const GetPricingPrePurchaseAgreementQuery = gql`
    query GetPricingPrePurchaseAgreement($locationKey: Int!, $prePurchaseAgreementKey: Int!, $pricingDate: DateTime!, $salesAgreementKey: Int) {
        pricingPrePurchaseAgreementGet(locationKey: $locationKey, prePurchaseAgreementKey: $prePurchaseAgreementKey, pricingDate: $pricingDate, salesAgreementKey: $salesAgreementKey) {
            ...pricingListing
        }
    }
`

export const GetPricingSalesAgreementQuery = gql`
    query GetPricingSalesAgreement($locationKey: Int!, $salesAgreementKey: Int!, $pricingDate: DateTime!) {
        pricingSalesAgreementGet(locationKey: $locationKey, salesAgreementKey: $salesAgreementKey, pricingDate: $pricingDate) {
            ...pricingListing
        }
    }
`

export const GetFreightForOrderQuery = gql`
    query GetFreightForOrder($carrierKey: Int!, $pricingDate: DateTime!, $mileage: Int!, $terminalKey: Int!, $locationKey: Int!) {
        freightForOrderGet(carrierKey: $carrierKey, pricingDate: $pricingDate, mileage: $mileage, terminalKey: $terminalKey, locationKey: $locationKey){
            carrierRateKey
            rateType
            ratePerGallon
            toll
        }
    }
`

export const GetStateTaxesForOrderQuery = gql`
    query GetStateTaxesForOrder($state: String, $pricingDate: DateTime!) {
        stateTaxGetForStateOnDate(state: $state, date: $pricingDate){
            stateTaxKey
            amount
            stateTaxType
        }
    }

    query GetStateTaxesForLocation($locationKey: Int!, $pricingDate: DateTime!) {
        stateTaxGetForLocationOnDate(locationKey: $locationKey, date: $pricingDate){
            stateTaxKey
            amount
            stateTaxType
        }
    }
`


interface Props {
}

interface pricing {
    product: number
    markup: number
    freight: number
    misc: number
    fob: number
    delivered: number
    // quoted: number
    miles: number
    patCost: number,
    perc: number,
    stateTax: number,
    stateAssessment: number
}

export default function CreateOrder(props: Props): ReactElement {
    const navigate = useNavigate()
    const [searchParams,] = useSearchParams()

    const [freight, setFreight] = useState<number>(undefined)
    const [toll, setToll] = useState<number>(undefined)
    const [marketCarrierRate, setMarketCarrierRate] = useState<FlatCarrierRate>(undefined)
    const [customerKey, setCustomerKey] = useState(undefined)
    const [locationKey, setLocationKey] = useState(undefined)
    const [terminalKey, setTerminalKey] = useState(undefined)
    const [tKey, setTKey] = useState(0)
    const [sKey, setSKey] = useState(0)
    const [fileNumber, setFileNumber] = useState(undefined)
    const [mileage, setMileage] = useState(undefined)
    const [carrierKey, setCarrierKey] = useState(undefined)
    const [gallons, setGallons] = useState(9500)
    const [shouldBeTermContract, setShouldBeTermContract] = useState(false)
    const [fobCustomer, setFobCustomer] = useState(false)
    const [pricingDate] = useState(searchParams.get("date") ? new Date(moment(searchParams.get("date")).format('LL')) : new Date(moment().add(1, 'day').format('LL')))

    const [getPosting, { data: postingData, loading: postingLoading, error: postingError }] = useGetPricingPostingLazyQuery()
    const [getIndexContract, { data: icData, loading: icLoading, error: icError }] = useGetPricingIndexContractLazyQuery()
    const [getPrePurchase, { data: ppaData, loading: ppaLoading, error: ppaError }] = useGetPricingPrePurchaseAgreementLazyQuery()
    const [getSalesAgreement, { data: saData, loading: saLoading, error: saError }] = useGetPricingSalesAgreementLazyQuery()
    const [getFreight, { data: freightData, loading: freightLoading, error: freightError }] = useGetFreightForOrderLazyQuery()
    const [getFileNumber, { data: fileNumberData }] = useAllFileNumbersLazyQuery()

    const postingKey = searchParams.get("posting") ? parseInt(searchParams.get("posting")) : 0
    const indexContractKey = searchParams.get("indexContract") ? parseInt(searchParams.get("indexContract")) : 0
    if (locationKey === undefined) {
        setLocationKey(searchParams.get("location") ? parseInt(searchParams.get("location")) : 0)
    }
    const prePurchaseAgreementKey = searchParams.get("prePurchaseAgreement") ? parseInt(searchParams.get("prePurchaseAgreement")) : 0
    const salesAgreementKey = searchParams.get("salesAgreement") ? parseInt(searchParams.get("salesAgreement")) : 0

    const { data: stateTaxData } = useGetStateTaxesForLocationQuery({ variables: { locationKey: locationKey ? locationKey : 0, pricingDate: pricingDate } })

    const today = moment().endOf('day').toDate()

    let supplierKey = 0
    let termContractKey = 0

    useEffect(() => {
        if (postingKey !== 0) {
            getPosting({ variables: { locationKey: locationKey, postingKey: postingKey, pricingDate: pricingDate, salesAgreementKey: salesAgreementKey !== 0 ? salesAgreementKey : undefined } })
        }
        if (indexContractKey !== 0) {
            getIndexContract({ variables: { locationKey: locationKey, indexContractKey: indexContractKey, pricingDate: pricingDate, salesAgreementKey: salesAgreementKey !== 0 ? salesAgreementKey : undefined } })
        }
        if (prePurchaseAgreementKey !== 0) {
            getPrePurchase({ variables: { locationKey: locationKey, prePurchaseAgreementKey: prePurchaseAgreementKey, pricingDate: pricingDate, salesAgreementKey: salesAgreementKey !== 0 ? salesAgreementKey : undefined } })
        }
        if (salesAgreementKey !== 0) {
            getSalesAgreement({ variables: { locationKey: locationKey, salesAgreementKey: salesAgreementKey, pricingDate: pricingDate } })
        }
    }, [locationKey, postingKey, getPosting, indexContractKey, getIndexContract, prePurchaseAgreementKey, getPrePurchase, salesAgreementKey, getSalesAgreement, pricingDate])

    useEffect(() => {
        if (fileNumberData && fileNumberData.fileNumberGetAll && fileNumberData.fileNumberGetAll.length > 0 && fileNumber === undefined) {
            setFileNumber(fileNumberData.fileNumberGetAll.some(x => x.isDefault) ? fileNumberData.fileNumberGetAll.filter(x => x.isDefault)[0].fileNumberKey : fileNumberData.fileNumberGetAll[0].fileNumberKey)
        }
    }, [fileNumberData, fileNumber, setFileNumber])

    const navigateToHome = () => {
        navigate(`/`)
    }

    const { data: locationData, loading: locationLoading, error: locationError } = useGetLocationSingleQuery({ variables: { locationKey: locationKey } })

    const [createOrderMutation] = useCreateOrderMutation()
    const submit = (variables: CreateOrderMutationVariables) => {
        variables.order.customerKey = customerKey
        if (postingData) {
            variables.order.patCost = postingData.pricingPostingGet.patCost
            variables.order.orderType = OrderType.Posting
            variables.order.postingKey = postingKey
        }
        if (icData) {
            variables.order.patCost = icData.pricingIndexContractGet.patCost
            variables.order.orderType = OrderType.IndexContract
            variables.order.indexContractKey = indexContractKey
        }
        if (ppaData) {
            variables.order.patCost = ppaData.pricingPrePurchaseAgreementGet.patCost
            variables.order.orderType = OrderType.PrePurchaseAgreement
            variables.order.prePurchaseAgreementKey = prePurchaseAgreementKey
        }
        if (saData) {
            variables.order.patCost = saData.pricingSalesAgreementGet.patCost
            variables.order.orderType = OrderType.SalesAgreement
            variables.order.salesAgreementKey = salesAgreementKey
        }

        variables.order.spotContract = variables.order.termContractKey != null ? false : true

        variables.order.tolls = toll ? toll : 0
        variables.order.status = OrderStatus.Open
        variables.order.readyForInvoicing = false

        variables.order.assessment = Number(variables.order.perc) + Number(variables.order.stateAssessment) + Number(variables.order.stateTax)

        createOrderMutation({ variables })
            .then(_ => navigateToHome())
    }

    const validationSchema = yup.object().shape({
        order: yup.object().shape({
            locationKey: yup.number().required(),
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required(),
            prePurchaseAgreementKey: yup.number().notRequired(),
            salesAgreementKey: yup.number().notRequired(),
            termContractKey: yup.number().notRequired(),

            fileNumberKey: yup.number().notRequired(),
            carrierKey: yup.number().required(),
            deliveryTimeKey: yup.number().required(),
            deliveryDate: yup.date().required(),
            gallons: yup.number().required(),
            miles: yup.number().required(),
            specialInstructions: yup.string(),

            product: yup.number().required(),
            markup: yup.number().required(),
            freight: yup.number().required(),
            misc: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            otherCharge: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            otherCost: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            fOB: yup.number().required(),
            perc: yup.number().required(),
            stateAssessment: yup.number().required(),
            stateTax: yup.number().required(),
            delivered: yup.number().required(),
            costDescription: yup.string(),
            patCost: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            patCostTotal: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            quotedPrice: yup.boolean().required(),

            actualGallons: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            poNumber: yup.string().notRequired(),
            sglInvoiceNumber: yup.string().notRequired(),
            manifest: yup.string().notRequired()
        })
    })

    useEffect(() => {
        if (terminalKey !== 0 && supplierKey !== 0) {
            getFileNumber({ variables: { filter: { terminalKey: { eq: tKey }, supplierKey: { eq: sKey }, isArchived: { eq: false } } } })
        }
    }, [tKey, sKey, supplierKey, terminalKey, getFileNumber])

    if (!freightLoading && !freightError && !freightData) {
        if (freight === undefined && carrierKey !== undefined && mileage !== undefined && mileage > 0 && terminalKey !== undefined && locationKey !== undefined) {
            const freightVariables: GetFreightForOrderQueryVariables = { carrierKey, pricingDate, mileage, terminalKey, locationKey }
            getFreight({ variables: freightVariables })
        }
    }
    else if (freightData && (marketCarrierRate === undefined || marketCarrierRate !== freightData.freightForOrderGet)) {
        setMarketCarrierRate(freightData.freightForOrderGet)
    }

    useEffect(() => {
        if (freightData && (freight === undefined || toll === undefined)) {
            setFreight(freightData.freightForOrderGet.ratePerGallon)
            setToll(freightData.freightForOrderGet.toll)
        }
    }, [freight, freightData, setFreight, toll, setToll, setMarketCarrierRate, locationData])

    useEffect(() => {
        if (locationData) {
            if (locationData.locationGetSingle.isFobCustomer && !fobCustomer) {
                setFobCustomer(true)
                if (!freight) {
                    setFreight(0)
                }
            }
        }
    }, [locationData, setFobCustomer, setFreight, freight, fobCustomer])

    let pricing: pricing = {
        product: 0,
        markup: 0,
        freight: 0,
        misc: 0,
        fob: 0,
        delivered: 0,
        // quoted: 0,
        miles: 0,
        patCost: 0,
        perc: 0,
        stateAssessment: 0,
        stateTax: 0
    }


    const { register, handleSubmit, errors, setValue } = useForm<CreateOrderMutationVariables>({ validationSchema })
    if (locationLoading || postingLoading || icLoading || ppaLoading || saLoading) {
        return <FormPage></FormPage>
    }
    if (locationError || postingError || icError || ppaError || saError) {
        return <p>There was a problem finding pricing</p>
    }




    if (postingData) {
        if (!postingData.pricingPostingGet) {
            return <p>There was a problem retrieving the posting</p>
        }

        const price = postingData.pricingPostingGet
        pricing.miles = price.mileage
        pricing.product = price.product
        pricing.freight = price.primaryCarrierRate
        pricing.markup = price.markup
        pricing.perc = price.perc
        pricing.stateAssessment = price.stateAssessment
        pricing.stateTax = price.stateTax
        pricing.fob = price.product + price.markup + price.perc + price.stateAssessment + price.stateTax
        pricing.delivered = pricing.fob + pricing.freight + price.patCost
        supplierKey = price.supplierKey
        if (terminalKey === undefined) {
            setTerminalKey(price.terminalKey)
        }
        termContractKey = price.termContractKey
        if (carrierKey === undefined) {
            setCarrierKey(price.primaryCarrierKey)
        }
        pricing.patCost = price.patCost
    }
    else if (icData) {
        const price = icData.pricingIndexContractGet
        pricing.miles = price.mileage
        pricing.product = price.product
        pricing.freight = price.primaryCarrierRate
        pricing.markup = price.markup
        pricing.perc = price.perc
        pricing.stateAssessment = price.stateAssessment
        pricing.stateTax = price.stateTax
        pricing.fob = price.product + price.markup + price.perc + price.stateAssessment + price.stateTax
        pricing.delivered = pricing.fob + pricing.freight + price.patCost
        supplierKey = price.supplierKey
        if (terminalKey === undefined) {
            setTerminalKey(price.terminalKey)
        }
        termContractKey = price.termContractKey
        if (carrierKey === undefined) {
            setCarrierKey(price.primaryCarrierKey)
        }
        pricing.patCost = price.patCost
    }
    else if (ppaData) {
        const price = ppaData.pricingPrePurchaseAgreementGet
        pricing.miles = price.mileage
        pricing.product = price.product
        pricing.freight = price.primaryCarrierRate
        pricing.markup = price.markup
        pricing.perc = price.perc
        pricing.stateAssessment = price.stateAssessment
        pricing.stateTax = price.stateTax
        pricing.fob = price.product + price.markup + price.perc + price.stateAssessment + price.stateTax
        pricing.delivered = pricing.fob + pricing.freight + price.patCost
        supplierKey = price.supplierKey
        if (terminalKey === undefined) {
            setTerminalKey(price.terminalKey)
        }
        termContractKey = price.termContractKey
        if (carrierKey === undefined) {
            setCarrierKey(price.primaryCarrierKey)
        }
        pricing.patCost = price.patCost
    }
    else if (saData) {
        const price = saData.pricingSalesAgreementGet
        pricing.miles = price.mileage
        pricing.product = price.product
        pricing.freight = price.primaryCarrierRate
        pricing.markup = price.markup
        pricing.perc = price.perc
        pricing.stateAssessment = price.stateAssessment
        pricing.stateTax = price.stateTax
        pricing.fob = price.product + price.markup + price.perc + price.stateAssessment + price.stateTax
        pricing.delivered = pricing.fob + pricing.freight + price.patCost
        supplierKey = price.supplierKey
        if (terminalKey === undefined) {
            setTerminalKey(price.terminalKey)
        }
        termContractKey = price.termContractKey
        if (carrierKey === undefined) {
            setCarrierKey(price.primaryCarrierKey)
        }
        pricing.patCost = price.patCost
    }

    if (supplierKey !== sKey) {
        setSKey(supplierKey)
    }
    if (terminalKey !== tKey) {
        setTKey(terminalKey)
    }

    if (termContractKey !== 0 && !shouldBeTermContract) {
        setShouldBeTermContract(true)
    }

    const onLocationSelect = (location: BasicLocationFragment) => {
        setCustomerKey(location ? location.customer.customerKey : undefined)
        setLocationKey(location ? location.locationKey : 0)
    }

    const onCarrierSelect = (carrier: AllCarrierListingFragment) => {
        if (carrier && carrier.carrierKey !== carrierKey) {
            setCarrierKey(carrier.carrierKey)
            getUpdatedFreight(null, null, carrier.carrierKey)
        }
    }

    const onTerminalSelect = (terminal) => {
        if (terminal && terminal.locationTerminals && terminal.locationTerminals.some(x => x.locationKey === locationKey)) {
            const miles = terminal.locationTerminals.filter(x => x.locationKey === locationKey)[0].mileage
            if (miles !== mileage) {
                setMileage(miles)
                getUpdatedFreight(miles, terminal.terminalKey)
            }
        }
        else {
            setMileage(0)
        }
    }

    const mileageChange = (miles: string) => {
        const milesNumber = Number(miles)

        if (!isNaN(milesNumber)) {
            setMileage(milesNumber)
            getUpdatedFreight(milesNumber)
        }
    }

    const getUpdatedFreight = (newMiles: number = null, newTerminal: number = null, newCarrier: number = null) => {
        const freightVariables: GetFreightForOrderQueryVariables = { carrierKey: newCarrier ?? carrierKey, pricingDate: pricingDate, mileage: newMiles ?? mileage, terminalKey: newTerminal ?? terminalKey, locationKey }
        getFreight({ variables: freightVariables })
    }

    const getStateTax = (type: StateTaxType) => {
        if (!stateTaxData) {
            return 0
        }
        var taxes = stateTaxData.stateTaxGetForLocationOnDate.filter(st => st.stateTaxType === type)
        if (taxes.length === 0) {
            return 0
        }
        return taxes.map(st => st.amount).reduce((a, b) => a + b)
    }

    const onSubmit = handleSubmit(submit)

    const commonProps = { register, errors }

    const cancel = () => {
        navigate("/orders")
    }

    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success} isDisabled={freightError != null && !fobCustomer}>Create Order</Button>)
    const footerLeftContent = (<Button clickAction={cancel} buttonType={ButtonType.Transparent}>Cancel</Button>)

    return (
        <FormPage title="Create Order" footerRightContent={footerRightContent} footerLeftContent={footerLeftContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <SalesAgreementSearch
                                label="Sales Agreement"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.salesAgreementKey)}
                                prefilledSalesAgreementKey={salesAgreementKey !== 0 ? salesAgreementKey : undefined}
                                locationKey={locationKey}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <PrePurchaseAgreementSearch
                                label="Pre Purchase Agreement"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.prePurchaseAgreementKey)}
                                prefilledPrePurchaseAgreementKey={prePurchaseAgreementKey !== 0 ? prePurchaseAgreementKey : undefined}
                                terminalKey={terminalKey}
                                supplierKey={supplierKey}
                                activeDate={pricingDate}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <LocationSearch
                                label="Location"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.locationKey)}
                                prefilledLocationKey={locationKey}
                                {...commonProps}
                                onSelect={onLocationSelect}
                                showCustomer={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.supplierKey)}
                                prefilledSupplierKey={supplierKey}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <TerminalSearch
                                label="Terminal"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.terminalKey)}
                                prefilledTerminalKey={terminalKey}
                                onSelect={onTerminalSelect}
                                locationKey={locationKey}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <FileNumberSearch
                                label="File #"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.fileNumberKey)}
                                prefilledFileNumberKey={fileNumber}
                                terminalKey={tKey}
                                supplierKey={sKey}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <TermContractSearch
                                label="Term Contract"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.termContractKey)}
                                clearOnSelect={false}
                                locationKey={locationKey}
                                prefilledTermContractKey={termContractKey}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Primary Carrier"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.carrierKey)}
                                prefilledCarrierKey={carrierKey}
                                onSelect={onCarrierSelect}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <DeliveryTimeSearch
                                label="Delivery Time"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.deliveryTimeKey)}
                                {...commonProps}
                                prefilledDeliveryTimeKey={locationData.locationGetSingle.customer.deliveryTimeKey}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedDateTime
                                label="Delivery Date"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.deliveryDate)}
                                setValue={setValue}
                                defaultValue={new Date(moment(pricingDate).format('LL'))}
                                {...commonProps}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.gallons)}
                                defaultValue="9500"
                                onChange={_ => setGallons(_.target.value)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Miles"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.miles)}
                                onChange={_ => mileageChange(_.target.value)}
                                value={mileage ? mileage.toString() : ""}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Trip Number"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.tripNumber)}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Special Instructions"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.specialInstructions)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <PricingTextBoxes
                    product={pricing.product}
                    productProp={propName<CreateOrderMutationVariables>(o => o.order.product)}
                    markup={pricing.markup}
                    markupProp={propName<CreateOrderMutationVariables>(o => o.order.markup)}
                    freight={freight}
                    freightProp={propName<CreateOrderMutationVariables>(o => o.order.freight)}
                    toll={toll}
                    tollProp={propName<CreateOrderMutationVariables>(o => o.order.tolls)}
                    marketCarrierRate={marketCarrierRate}
                    perc={pricing.perc}
                    percProp={propName<CreateOrderMutationVariables>(o => o.order.perc)}
                    misc={pricing.misc}
                    miscProp={propName<CreateOrderMutationVariables>(o => o.order.misc)}
                    fob={pricing.fob}
                    fobProp={propName<CreateOrderMutationVariables>(o => o.order.fOB)}
                    delivered={pricing.delivered}
                    deliveredProp={propName<CreateOrderMutationVariables>(o => o.order.delivered)}
                    otherChargeProp={propName<CreateOrderMutationVariables>(o => o.order.otherCharge)}
                    otherCostProp={propName<CreateOrderMutationVariables>(o => o.order.otherCost)}
                    costDescriptionProp={propName<CreateOrderMutationVariables>(o => o.order.costDescription)}
                    patCost={pricing.patCost}
                    patCostTotalProp={propName<CreateOrderMutationVariables>(o => o.order.patCostTotal)}
                    gallons={gallons}
                    quotedPriceProp={propName<CreateOrderMutationVariables>(o => o.order.quotedPrice)}
                    quotedPrice={salesAgreementKey !== 0 ? true : false}
                    commonProps={commonProps}
                    freightError={freightError != null && !fobCustomer}
                    stateAssessmentProp={propName<CreateOrderMutationVariables>(o => o.order.stateAssessment)}
                    stateTaxProp={propName<CreateOrderMutationVariables>(o => o.order.stateTax)}
                    stateAssessment={getStateTax(StateTaxType.Assessment)}
                    stateTax={getStateTax(StateTaxType.Tax)}
                />
                <FormTile>
                    <div className="row">
                        <div className="col-md-3">
                            <HookedTextInput
                                label="Actual Gallons"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.actualGallons)}
                                onChange={_ => setGallons(_.target.value)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="PO Number"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.poNumber)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="SGL Invoice Number"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.sglInvoiceNumber)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="Manifest"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.manifest)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}