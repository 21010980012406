import React, { ReactElement } from 'react'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import ellipsish from '../../../icons/ellipsis-h.svg'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import styles from '../MultiSearch/MultiSearch.module.scss'
import { AllTermContractQueryVariables, useAllTermContractQuery, InnerTermContractFragment, SortEnumType } from '../../../generated/graphql'
import TermContractSearch from './TermContractSearch'

interface Props {
    removeTermContract(termContract: any)
    addTermContract(termContract: any)
    data: any[]
}

export default function MultiTermContractSearch(props: Props): ReactElement {
    const variables: AllTermContractQueryVariables = {
        filter: { termContractKey: { in: props.data } },
        sort: { name: SortEnumType.Asc },
        cursor: "LTE=",
        // changed the number of page size due to errors of getting a lower max value that is allowed than the value 
        // we want to be maxed allowed.
        pageSize: 50
    }

    const { data, loading, error } = useAllTermContractQuery({ variables })

    const validationSchema = yup.object().shape({
        termContractKey: yup.number().required()
    })

    const removeTermContract = (termContract: any) => {
        props.removeTermContract(termContract.termContractKey)
    }

    const termContractGridOptions: GridOptions<InnerTermContractFragment> = {
        columnDefs: [
            {
                headerName: "Term Contract",
                dataTransform: t => `${t.name}`,
                field: "name"
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.termContractGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeTermContract }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onTermContractSelect = (termContract: any) => {
        if (termContract) {
            props.addTermContract(termContract.termContractKey)
        }
    }

    const header = (
        <TermContractSearch
            label="Term Contract"
            propertyKey={propName<string>(o => o)}
            onSelect={onTermContractSelect}
            clearOnSelect={true}
            onAdd={onTermContractSelect}
            {...commonProps} />
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={termContractGridOptions} />}
                </div>
            </div>
        </div>
    )
}