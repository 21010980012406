import { ApolloError } from '@apollo/client/errors'
import React from 'react'
import styles from './SearchDisplay.module.scss'

interface SearchListing {
    object: any
    key: number
    display: string
}

interface SearchGroup {
    title: string
    results: SearchListing[]
}

export interface InnerSearchLogicProps<T> {
    searchTerm: string
    onSelect(entity: T, entityDisplay: string)
    customerKey?: number
    locationKey?: number
    terminalKey?: number
    supplierKey?: number
    showCustomer?: boolean
    date?: Date
    onAdd?(name: string)
}

export interface SearchDisplayProps<T> {
    onSelect(selection: T, display: string)
    onAdd?(entityDisplay: string)
    groups: SearchGroup[]
    isLoading: boolean
    error: ApolloError
    searchTerm: string
}
const SearchDisplay = <T extends any>(props: SearchDisplayProps<T>) => {

    if (props.isLoading) {
        return <ul className={styles.dropdown}>
            <li className={styles.listLabel}>Searching for '{props.searchTerm}'</li>
        </ul>
    }

    if (props.error) {
        return <div className={styles.dropdown}>
            <li className={styles.listLabel}>Error Loading Content</li>
        </div>
    }

    const totalResults = props.groups.reduce((total, current) => total + current.results.length, 0)
    if (totalResults === 0) {
        return <ul className={styles.dropdown}>
            {props.onAdd === undefined && <li className={styles.listLabel}>No Results</li>}
            {(props.onAdd !== undefined && props.searchTerm !== "") && <li className={styles.listLabel} onClick={() => { props.onAdd(props.searchTerm); props.onSelect(undefined, undefined) }}>{`Add: ${props.searchTerm}`}</li>}
        </ul>
    }

    return <ul className={styles.dropdown}>
        {props.groups.map(group => {
            const title = group.results.length > 0 && <li key={group.title} className={styles.listLabel}>{group.title}</li>
            const items = group.results.map(result => <li onClick={() => props.onSelect(result.object, result.display)} className={styles.listItem} key={result.key}>{result.display}</li>)
            const add = (props.onAdd !== undefined && !group.results.some(x => x.display === props.searchTerm) && props.searchTerm !== "") && <li key="add" className={styles.listLabel} onClick={() => { props.onAdd(props.searchTerm); props.onSelect(undefined, undefined) }}>{`Add: ${props.searchTerm}`}</li>
            return [title, ...items, add]
        })}
    </ul>
}

export default SearchDisplay