
import gql from 'graphql-tag'
import { CreateAndEndActivePostingMutationVariables, useCreateAndEndActivePostingMutation } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from "../../helpers/propName"
import * as yup from "yup"
import React, { useState } from 'react'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'

import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const CreatePosting = gql`
    mutation CreatePosting($posting: PostingInput!) {
        postingCreate(posting: $posting) {
            postingKey
        }
    }
`

interface Props {
}

const PostingCreate = (props: Props) => {
    const navigate = useNavigate()

    const [createPostingMutation] = useCreateAndEndActivePostingMutation()

    const [startDate, setStartDate] = useState<Date>(moment().startOf('day').toDate())
    let oneYearFromStart = moment().startOf('day').add(1, 'year').toDate()

    const [endDate, setEndDate] = useState<Date>(oneYearFromStart)

    const [searchParams,] = useSearchParams()
    const supplierKey = searchParams.get("supplierKey") ? parseInt(searchParams.get("supplierKey")) : 0

    const navigateToList = () => {
        navigate("/settings/postings")
    }

    const navigateToPosting = (id: number) => {
        navigate(`/settings/postings/edit/${id}`)
    }

    const submit = (variables: CreateAndEndActivePostingMutationVariables) => {
        createPostingMutation({ variables })
            .then(r => {
                navigateToPosting(r.data.postingCreateAndEndActive.postingKey)
            })
    }

    const startDateChanged = (key, val) => {
        setStartDate(val)
        //when creating a new index contract, I figure it would be nice for the end date to update based on a change to the start date. End date can then be adjusted manually.
        setEndDate(moment(val).add(1, 'year').subtract(1, 'day').toDate())
        setValue(key, val)
    }

    const endDateChanged = (key, val) => {
        setEndDate(val)
        setValue(key, val)
    }

    const validationSchema = yup.object().shape({
        posting: yup.object().shape({
            cost: yup.number().required(),
            effectiveDate: yup.date().required(),
            effectiveEndDate: yup.date().required().min(yup.ref('effectiveDate'), 'End date must be after start date'),

            supplierKey: yup.number().required(),
            terminalKey: yup.number().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateAndEndActivePostingMutationVariables>({ validationSchema })

    const onSubmit = handleSubmit(submit)
    const commonProps = { register, errors, setValue }

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Posting</Button>)

    return <FormPage title="Create Posting" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
        <form onSubmit={onSubmit}>
            <FormTile>
                <div className="row">
                    <div className="col-md-6">
                        <SupplierSearch
                            label="Supplier"
                            propertyKey={propName<CreateAndEndActivePostingMutationVariables>(o => o.posting.supplierKey)}
                            prefilledSupplierKey={supplierKey !== 0 ? supplierKey : undefined}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <TerminalSearch
                            label="Terminal"
                            propertyKey={propName<CreateAndEndActivePostingMutationVariables>(o => o.posting.terminalKey)}
                            {...commonProps}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <HookedTextInput
                            label="Cost"
                            propertyKey={propName<CreateAndEndActivePostingMutationVariables>(o => o.posting.cost)}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedDateTime
                            label="Effective Date"
                            propertyKey={propName<CreateAndEndActivePostingMutationVariables>(o => o.posting.effectiveDate)}
                            defaultValue={startDate}
                            setValue={startDateChanged}
                            showTimeSelect={true}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedDateTime
                            label="Effective End Date"
                            propertyKey={propName<CreateAndEndActivePostingMutationVariables>(o => o.posting.effectiveEndDate)}
                            defaultValue={endDate}
                            setValue={endDateChanged}
                            showTimeSelect={true}
                            {...commonProps}
                        />
                    </div>
                </div>
            </FormTile>
        </form>
    </FormPage>
}
export default PostingCreate