import gql from "graphql-tag"
import { CarrierListingFragment, useAllCarrierQuery, useDeleteCarrierMutation, CarrierSortInput, CarrierFilterInput, AllCarrierQueryVariables, SortEnumType } from "../../generated/graphql"
import GeneralPage from "../../components/General/GeneralPage/GeneralPage"
import React, { Component } from "react"
import GridBase, { GridOptions } from "../../components/General/GridBase/GridBase"
import ellipsish from '../../icons/ellipsis-h.svg'
import Button from "../../components/General/Button/Button"
import Tile from "../../components/General/Tile/Tile"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import DeleteEntityModal from "../../components/AppliedModals/DeleteEntity/DeleteEntityModal"
import { NavigateFunction, useNavigate } from "react-router-dom"

export const CarrierFragment = gql`
   fragment carrierListing on Carrier {
      carrierKey
      name
      address1
      address2
      city
      state
      zipCode
      phone
      fax
      email
      carrierType
      isArchived
   }

   fragment allCarrierListing on Carrier {
      carrierKey
      name
      address1
      address2
      city
      state
      zipCode
      phone
      fax
      email
      carrierType
      isArchived
      carrierRates {
         endDate
         startDate
         basePercent
         carrierRateBands {
            mileageFrom
            mileageTo
            ratePerGallon
         }
      }
   }
`

export const AllCarrierQuery = gql`
   query AllCarrier($sortObject: [CarrierSortInput!], $filterObject: CarrierFilterInput) {
      carrierGetAll(order: $sortObject, where: $filterObject) {
         ...carrierListing
      }
   }
`

export const DeleteCarrierMutation = gql`
   mutation DeleteCarrier($id: Int!) {
      carrierDelete(id: $id)
   }
`

interface CarrierDataWrapperProps {
   currentSort: CarrierSortInput
   updateSort(newSort: CarrierSortInput)

   currentFilter: CarrierFilterInput
   updateFilter(newFilter: CarrierFilterInput)

   editCarrier(carrier: CarrierListingFragment)
   deleteCarrier(carrier: CarrierListingFragment)
   currentlyDeletingCarrier?: CarrierListingFragment
}

const CarrierDataWrapper = (props: CarrierDataWrapperProps): JSX.Element => {
   const variables: AllCarrierQueryVariables = {
      sortObject: props.currentSort,
      ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
   }

   const { data, loading, error } = useAllCarrierQuery({ variables })

   const [deleteMutation] = useDeleteCarrierMutation()

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => props.deleteCarrier(null))
   }

   const cancelDelete = () => {
      props.deleteCarrier(null)
   }

   const gridOptions: GridOptions<CarrierListingFragment> = {
      columnDefs: [
         { headerName: "Name", field: "name", sortable: true, searchable: true },
         { headerName: "Address 1", field: "address1" },
         { headerName: "Address 2", field: "address2" },
         { headerName: "City", field: "city", sortable: true, searchable: true },
         { headerName: "State", field: "state", sortable: true, searchable: true },
         { headerName: "Zip Code", field: "zipCode" },
         { headerName: "Phone", field: "phone" },
         { headerName: "Fax", field: "fax" }
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: (loading || error) ? [] : data.carrierGetAll,
      rowActions: [
         {
            icon: ellipsish, items: [
               { displayName: "Edit", action: props.editCarrier },
               { displayName: "Delete", action: props.deleteCarrier }
            ]
         }
      ],
      dataKeyColumn: "carrierKey",
      linkToPath: "/carriers/show/:key",
      currentSort: props.currentSort,
      sortAction: props.updateSort,
      currentFilter: props.currentFilter,
      filterAction: props.updateFilter
   }

   return (
      <div>
         <GridBase<CarrierListingFragment> gridOptions={gridOptions} />

         {!!props.currentlyDeletingCarrier && <DeleteEntityModal
            cancel={cancelDelete}
            confirm={confirmDelete}
            entityKey={props.currentlyDeletingCarrier.carrierKey}
            entityName="carrier"
            orderFilter={{ carrierKey: { eq: props.currentlyDeletingCarrier.carrierKey } }}
            locationFilter={{ or: [{ primaryCarrierKey: { eq: props.currentlyDeletingCarrier.carrierKey } }, { secondaryCarrierKey: { eq: props.currentlyDeletingCarrier.carrierKey } }] }}
         />}
      </div>
   )
}

const CarrierList = () => {
   const navigate = useNavigate()

   return (
      <CarrierListInnerWrapper navigate={navigate} />
   )
}

export default CarrierList

interface Props {
   navigate: NavigateFunction
}
interface State {
   currentSort: CarrierSortInput
   currentFilter: CarrierFilterInput
   currentlyDeletingCarrier?: CarrierListingFragment
}

class CarrierListInnerWrapper extends Component<Props, State> {
   constructor(props) {
      super(props)

      const currentSort: CarrierSortInput = { name: SortEnumType.Asc }
      const currentFilter: CarrierFilterInput = { isArchived: { eq: false } }

      this.state = {
         currentSort,
         currentFilter
      }
   }

   sortClick(currentSort: CarrierSortInput) {
      this.setState({ currentSort })
   }

   filterClick(currentFilter: CarrierFilterInput) {
      currentFilter.isArchived = this.state.currentFilter.isArchived
      this.setState({ currentFilter })
   }

   navigateToNewCarrier() {
      this.props.navigate('/carriers/new')
   }

   editCarrier(carrier: CarrierListingFragment) {
      this.props.navigate(`/carriers/edit/${carrier.carrierKey}`)
   }

   deleteCarriers(carrier: CarrierListingFragment) {
      this.setState({ currentlyDeletingCarrier: carrier })
   }

   setShowArchived(showArchived: boolean) {
      let newFilter = Object.assign({}, this.state.currentFilter)
      newFilter.isArchived = { eq: showArchived }

      this.setState({ currentFilter: newFilter })
   }

   render() {
      return (
         <GeneralPage title="Carriers" headerContent={
            <>
               <HookedCheckbox
                  label="Show Archived"
                  defaultValue={false}
                  propertyKey={"showArchived"}
                  errors={{}}
                  register={React.createRef()}
                  onChange={(event) => this.setShowArchived(event.target.checked)}
                  inline={true}
               />
               <Button clickAction={this.navigateToNewCarrier.bind(this)}>New Carrier</Button>
            </>
         }>
            <Tile>
               <CarrierDataWrapper
                  currentSort={this.state.currentSort}
                  updateSort={this.sortClick.bind(this)}
                  currentFilter={this.state.currentFilter}
                  updateFilter={this.filterClick.bind(this)}
                  currentlyDeletingCarrier={this.state.currentlyDeletingCarrier}
                  deleteCarrier={this.deleteCarriers.bind(this)}
                  editCarrier={this.editCarrier.bind(this)} />
            </Tile>
         </GeneralPage>
      )
   }
}