import React, { Component } from 'react'
import InputWrapper from './InputWrapper'
import styles from './Input.module.scss'
import textAreaStyles from './HookedTextArea.module.scss'

export interface DefaultInputProps<T> {
    propertyKey: string
    label?: string
    register: any
    errors: any
    value?: T
    defaultValue?: T
    placeholder?: string
    onClick?(event: any)
    className?: string
    alignCenter?: boolean
    onChange?(event: any)
    shortMobile?: boolean
    narrowMobile?: boolean
}

interface Props extends DefaultInputProps<string> { }

export default class HookedTextArea extends Component<Props> {
    render() {

        const currentError = this.props.errors[this.props.propertyKey]

        return (
            <InputWrapper<string> {...this.props} className={`${this.props.className} ${this.props.shortMobile ? styles.formGroup__noPadBottom : ''}`}>
                <textarea
                    className={`${styles.input} ${currentError ? styles.input__error : ''} ${this.props.shortMobile ? textAreaStyles.hookedTextArea__shortMobile : ''} ${this.props.narrowMobile ? textAreaStyles.hookedTextArea__narrowMobile : ''}`}
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    placeholder={this.props.placeholder}
                    ref={this.props.register}
                    onChange={this.props.onChange}
                    name={this.props.propertyKey}
                ></textarea>
            </InputWrapper>
        )
    }
}
