import gql from 'graphql-tag'
import React, { ReactElement, useState } from 'react'
import useForm from 'react-hook-form'
import { useGetSingleFileNumberQuery, useEditFileNumberMutation, useDeleteFileNumberMutation, EditFileNumberMutationVariables } from '../../generated/graphql'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import * as yup from "yup"
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import Modal from '../../components/Modal/Modal'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditFileNumberMutation = gql`
    mutation EditFileNumber($fileNumber: FileNumberInput!, $id: Int!) {
        fileNumberUpdate(fileNumberKey: $id, fileNumber: $fileNumber) {
            fileNumberKey
        }
    }
`

export const DeleteFileNumberMutation = gql`
    mutation DeleteFileNumber($fileNumberKey: Int!) {
        fileNumberDelete(fileNumberKey: $fileNumberKey)
    }
`

export const SingleFileNumberQuery = gql`
    fragment innerFileNumber on FileNumber {
        fileNumberKey
        number
        supplierKey
        terminalKey
        isArchived
        isDefault
    }

    query GetSingleFileNumber($key: Int!) {
        fileNumberGetSingle(fileNumberKey: $key) {
            ...innerFileNumber
        }
    }
`

interface MatchParams extends Params {
    fileNumberKey: string
}

interface Props {
}


export default function FileNumberEdit(props: Props): ReactElement {
    const navigate = useNavigate()

    const { fileNumberKey: key } = useParams() as MatchParams

    const fileNumberKey = parseInt(key)
    const [currentlyDeletingFileNumber, setCurrentlyDeleting] = useState(false)

    const validationSchema = yup.object().shape({
        fileNumber: yup.object().shape({
            number: yup.string().required(),
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required(),
            isDefault: yup.boolean().required(),
            isArchived: yup.boolean().required()
        })
    })

    const { data, loading, error } = useGetSingleFileNumberQuery({ variables: { key: fileNumberKey } })

    const [editFileNumber] = useEditFileNumberMutation()
    const [deleteFileNumber] = useDeleteFileNumberMutation()

    const { register, handleSubmit, errors } = useForm<EditFileNumberMutationVariables>({ validationSchema })

    if (loading) return null
    if (error) return <div>Error loading file number</div>

    const navigateToList = () => {
        navigate('/settings/file-number')
    }

    const submit = (variables: EditFileNumberMutationVariables) => {
        variables.id = fileNumberKey
        editFileNumber({ variables })
            .then(navigateToList)
    }

    const confirmDelete = (fileNumberKey: number) => {
        deleteFileNumber({ variables: { fileNumberKey } })
            .then(navigateToList)
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteModal = () => {
        setCurrentlyDeleting(true)
    }

    const commonProps = { register, errors }

    const footerLeftContent = (<>
        <Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteModal} buttonType={ButtonType.Tertiary}>Delete</Button>
    </>)

    const onSubmit = handleSubmit(submit)

    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update File Number</Button>)

    const deleteModalFooterLeftContent = (<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const deleteModalFooterRightContent = (<Button clickAction={() => confirmDelete(fileNumberKey)} buttonType={ButtonType.Danger}>Delete File Number</Button>)

    return (<FormPage title="Update File Number" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
        <form onSubmit={onSubmit}>
            <FormTile>
                <div className="row">
                    <div className="col-md-12">
                        <HookedTextInput
                            label="File Number"
                            propertyKey={propName<EditFileNumberMutationVariables>(o => o.fileNumber.number)}
                            defaultValue={data.fileNumberGetSingle.number}
                            {...commonProps}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TerminalSearch
                            label="Terminal"
                            propertyKey={propName<EditFileNumberMutationVariables>(o => o.fileNumber.terminalKey)}
                            prefilledTerminalKey={data.fileNumberGetSingle.terminalKey}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <SupplierSearch
                            label="Supplier"
                            propertyKey={propName<EditFileNumberMutationVariables>(o => o.fileNumber.supplierKey)}
                            prefilledSupplierKey={data.fileNumberGetSingle.supplierKey}
                            {...commonProps}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <HookedCheckbox
                            label="Is Default"
                            propertyKey={propName<EditFileNumberMutationVariables>(o => o.fileNumber.isDefault)}
                            defaultValue={data.fileNumberGetSingle.isDefault}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedCheckbox
                            label="Archived"
                            propertyKey={propName<EditFileNumberMutationVariables>(o => o.fileNumber.isArchived)}
                            defaultValue={data.fileNumberGetSingle.isArchived}
                            {...commonProps}
                        />
                    </div>
                </div>
            </FormTile>
        </form>

        {currentlyDeletingFileNumber && <Modal headerText="Delete?" footerLeftContent={deleteModalFooterLeftContent} footerRightContent={deleteModalFooterRightContent}>
            <p>Once you delete this File Number, it will not be able to be reversed.</p>
        </Modal>}
    </FormPage>
    )
}