import gql from "graphql-tag"
import GeneralPage from "../../components/General/GeneralPage/GeneralPage"
import { useState } from "react"
import React from "react"
import { useNavigate } from "react-router-dom"
import GridBase, { GridOptions } from "../../components/General/GridBase/GridBase"
import ellipsish from '../../icons/ellipsis-h.svg'
import Button from "../../components/General/Button/Button"
import { AllSupplierListingFragment, useDeleteSupplierMutation, useAllSupplierQuery, AllSupplierQueryVariables, SupplierFilterInput, SortEnumType, SupplierSortInput } from "../../generated/graphql"
import Tile from "../../components/General/Tile/Tile"
import BulkUpdateSupplierPostingsModal from "../../components/AppliedModals/BulkUpdateSupplierPostingsModal/BulkUpdateSupplierPostingsModal"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import DeleteEntityModal from "../../components/AppliedModals/DeleteEntity/DeleteEntityModal"

export const SupplierFragment = gql`
   fragment allSupplierListing on Supplier {
      name
      address1
      address2
      city
      directions
      fax
      phone
      state
      supplierKey
      zipCode
      notes
      isArchived
   }
`

export const AllSupplierQuery = gql`
   query AllSupplier ($sortObject: [SupplierSortInput!], $filterObject: SupplierFilterInput){
      supplierGetAll(order: $sortObject, where: $filterObject) {
         ...allSupplierListing
      }
   }
`

export const DeleteSupplierMutation = gql`
   mutation DeleteSupplier($id: Int!) {
      supplierDelete(id: $id)
   }
`

interface SupplierDataWrapperProps {
   currentSort: SupplierSortInput
   updateSort(newSort: SupplierSortInput)

   currentFilter: SupplierFilterInput
   updateFilter(newFilter: SupplierFilterInput)

   editSupplier(supplier: AllSupplierListingFragment)
   deleteSupplier(supplier: AllSupplierListingFragment)
   currentlyDeletingSupplier?: AllSupplierListingFragment
}

const SupplierDataWrapper = (props: SupplierDataWrapperProps): JSX.Element => {

   const variables: AllSupplierQueryVariables = {
      sortObject: props.currentSort,

      ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
   }

   const { data, loading, error } = useAllSupplierQuery({ variables })
   const [isShowingBulkUpdatePostingsModal, setShowingBulkUpdatePostingsModal] = useState(false)
   const [currentlyUpdatingPostingsKey, setCurrentlyUpdatingPostingsKey] = useState(null)

   const [deleteMutation] = useDeleteSupplierMutation()

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => props.deleteSupplier(null))
   }

   const cancelDelete = () => {
      props.deleteSupplier(null)
   }

   const openBulkUpdateSupplierPostingsModal = (supplier: AllSupplierListingFragment) => {
      setCurrentlyUpdatingPostingsKey(supplier.supplierKey)
      setShowingBulkUpdatePostingsModal(true)

   }
   const closeBulkUpdateSupplierPostingsModal = () => {
      setShowingBulkUpdatePostingsModal(false)
      setCurrentlyUpdatingPostingsKey(null)
   }

   const gridOptions: GridOptions<AllSupplierListingFragment> = {
      columnDefs: [
         {
            headerName: "Name", field: "name",
            sortable: true,
            searchable: true
         },
         { headerName: "City", field: "city" },
         { headerName: "State", field: "state" }
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: (loading || error) ? [] : data.supplierGetAll,
      rowActions: [
         {
            icon: ellipsish, items: [
               { displayName: "Edit", action: props.editSupplier },
               { displayName: "Update Postings", action: openBulkUpdateSupplierPostingsModal },
               { displayName: "Delete", action: props.deleteSupplier }
            ]
         }
      ],
      dataKeyColumn: "supplierKey",
      linkToPath: "/suppliers/show/:key",
      currentSort: props.currentSort,
      sortAction: props.updateSort,
      currentFilter: props.currentFilter,
      filterAction: props.updateFilter
   }
   return (
      <div>
         <GridBase gridOptions={gridOptions} />
         {isShowingBulkUpdatePostingsModal && <BulkUpdateSupplierPostingsModal closeModal={closeBulkUpdateSupplierPostingsModal} supplierKey={currentlyUpdatingPostingsKey} />}

         {!!props.currentlyDeletingSupplier && <DeleteEntityModal
            cancel={cancelDelete}
            confirm={confirmDelete}
            entityKey={props.currentlyDeletingSupplier.supplierKey}
            entityName="supplier"
            orderFilter={{ supplierKey: { eq: props.currentlyDeletingSupplier.supplierKey } }}
            indexContractFilter={{ supplierKey: { eq: props.currentlyDeletingSupplier.supplierKey } }}
            postingFilter={{ supplierKey: { eq: props.currentlyDeletingSupplier.supplierKey } }}
            prepurchaseFilter={{ supplierKey: { eq: props.currentlyDeletingSupplier.supplierKey } }}
            salesAgreementFilter={{ supplierKey: { eq: props.currentlyDeletingSupplier.supplierKey } }}
         />}
      </div>
   )
}


type Props = {}

const SupplierList = (props: Props) => {
   const navigate = useNavigate()

   const [currentSort, setCurrentSort] = useState<SupplierSortInput>({ name: SortEnumType.Asc })
   const [currentFilter, setCurrentFilter] = useState<SupplierFilterInput>({ isArchived: { eq: false } })
   const [currentlyDeletingSupplier, setCurrentlyDeletingSupplier] = useState<AllSupplierListingFragment>()

   const sortClick = (currentSort: SupplierSortInput) => {
      setCurrentSort(currentSort)
   }

   const filterClick = (_currentFilter: SupplierFilterInput) => {
      _currentFilter.isArchived = currentFilter.isArchived

      setCurrentFilter(_currentFilter)
   }
   const navigateToNewSupplier = () => {
      navigate('/suppliers/new')
   }

   const editSupplier = (supplier: AllSupplierListingFragment) => {
      navigate(`/suppliers/edit/${supplier.supplierKey}`)
   }

   const deleteSuppliers = (supplier: AllSupplierListingFragment) => {
      setCurrentlyDeletingSupplier(supplier)
   }

   const setShowArchived = (showArchived: boolean) => {
      let newFilter = Object.assign({}, currentFilter)
      newFilter.isArchived = { eq: showArchived }

      setCurrentFilter(newFilter)
   }

   return (
      <GeneralPage title="Suppliers" headerContent={
         <>
            <HookedCheckbox
               label="Show Archived"
               defaultValue={false}
               propertyKey={"showArchived"}
               errors={{}}
               register={React.createRef()}
               onChange={(event) => setShowArchived(event.target.checked)}
               inline={true}
            />
            <Button clickAction={navigateToNewSupplier}>New Supplier</Button>
         </>
      }>
         <Tile>
            <SupplierDataWrapper
               currentSort={currentSort}
               updateSort={sortClick}
               currentFilter={currentFilter}
               updateFilter={filterClick}
               currentlyDeletingSupplier={currentlyDeletingSupplier}
               deleteSupplier={deleteSuppliers}
               editSupplier={editSupplier} />
         </Tile>
      </GeneralPage>
   )
}

export default SupplierList