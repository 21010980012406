import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import { useAllTerminalQuery, InnerTerminalFragment, useDeleteTerminalMutation, AllTerminalQueryVariables, TerminalFilterInput, useArchiveTerminalMutation, TerminalSortInput, SortEnumType } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { useNavigate } from 'react-router-dom'

export const TerminalFragment = gql`
   fragment innerTerminal on Terminal {
      terminalKey
      name
      city
      state
      zipCode
      notes
   }

   fragment allTerminalListing on TerminalGetAllConnection {
      edges {
         cursor
         ...innerTerminalListing
      }
      totalCount
   }

   fragment innerTerminalListing on TerminalGetAllEdge {
      node {
         ...innerTerminal
      }
   }
`

export const AllTerminalQuery = gql`
   query AllTerminal ($sortObject: [TerminalSortInput!], $filterObject: TerminalFilterInput, $cursor: String!, $pageSize: Int!) {
      terminalGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
         ...allTerminalListing
      }
   }
`

export const DeleteTerminalMutation = gql`
   mutation DeleteTerminal($id: Int!) {
      terminalDelete(id: $id)
   }
`

export const ArchiveTerminal = gql`
    mutation ArchiveTerminal($id: Int!) {
        terminalArchive(id: $id) {
            terminalKey
        }
    }
`

interface TerminalDataWrapperProps {
   currentSort: TerminalSortInput
   updateSort(newSort: TerminalSortInput)

   currentFilter: TerminalFilterInput
   updateFilter(newFilter: TerminalFilterInput)

   editTerminal(terminal: InnerTerminalFragment)
   deleteTerminal(terminal: InnerTerminalFragment)
   currentlyDeletingTerminal: InnerTerminalFragment

   archiveTerminal(terminal: InnerTerminalFragment)
   currentlyArchivingTerminal?: InnerTerminalFragment
}

const TerminalDataWrapper = (props: TerminalDataWrapperProps): JSX.Element => {
   const pageSize = 25

   const [cursor, setCursor] = useState("LTE=")

   useEffect(() => {
      setCursor("LTE=")
   }, [props.currentFilter, setCursor])

   const variables: AllTerminalQueryVariables = {
      sortObject: props.currentSort,
      cursor: cursor,
      pageSize: pageSize,

      ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
   }

   const { data, loading, error, refetch } = useAllTerminalQuery({ variables })

   const [deleteMutation] = useDeleteTerminalMutation()

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => refetch())
         .then(() => props.deleteTerminal(null))
   }

   const cancelDelete = () => {
      props.deleteTerminal(null)
   }

   const cancelArchive = () => {
      props.archiveTerminal(null)
   }

   const [archiveTerminal] = useArchiveTerminalMutation()

   const confirmArchive = (id: number) => {
      archiveTerminal({ variables: { id } })
         .then(() => refetch())
         .then(() => props.archiveTerminal(null))
   }

   const pageChange = (currentCursor: string) => {
      setCursor(currentCursor)
   }

   const gridOptions: GridOptions<InnerTerminalFragment> = {
      columnDefs: [
         { headerName: "Name", field: "name", dataTransform: o => o.name, sortable: true, searchable: true },
         { headerName: "City", field: "city", dataTransform: o => o.city, sortable: true, searchable: true },
         { headerName: "State", field: "state", dataTransform: o => o.state, sortable: true, searchable: true },
         { headerName: "Notes", dataTransform: o => o.notes },
      ],
      isErrored: !!error,
      isLoading: loading,
      rowData: ((loading || error) && !data) ? [] : data.terminalGetAll.edges.map(x => x.node),
      rowActions: [
         {
            icon: ellipsish, items: [
               { displayName: "Edit", action: props.editTerminal },
               { displayName: "Archive", action: props.archiveTerminal },
               { displayName: "Delete", action: props.deleteTerminal }
            ]
         }
      ],
      dataKeyColumn: "terminalKey",
      linkToPath: "/terminals/show/:key",
      currentSort: props.currentSort,
      sortAction: props.updateSort,
      currentFilter: props.currentFilter,
      filterAction: props.updateFilter
   }
   return (
      <div>
         <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.terminalGetAll.totalCount : 0}
            changeCursor={pageChange}
         >
            <GridBase<InnerTerminalFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingTerminal && <DeleteEntityModal
               cancel={cancelDelete}
               confirm={confirmDelete}
               entityKey={props.currentlyDeletingTerminal.terminalKey}
               entityName="terminal"
               orderFilter={{ terminalKey: { eq: props.currentlyDeletingTerminal.terminalKey } }}
               indexContractFilter={{ terminalKey: { eq: props.currentlyDeletingTerminal.terminalKey } }}
               postingFilter={{ terminalKey: { eq: props.currentlyDeletingTerminal.terminalKey } }}
               prepurchaseFilter={{ terminalKey: { eq: props.currentlyDeletingTerminal.terminalKey } }}
               salesAgreementFilter={{ terminalKey: { eq: props.currentlyDeletingTerminal.terminalKey } }}
            />}
            {!!props.currentlyArchivingTerminal && <Modal headerText="Archive?">
               <p>Are you sure you want archive this terminal?</p>
               <button onClick={cancelArchive}>Cancel</button> <button onClick={() => confirmArchive(props.currentlyArchivingTerminal.terminalKey)}>Archive</button>
            </Modal>}
         </PaginationWrapper>
      </div>
   )
}
interface Props {
}


const TerminalList = (props: Props) => {
   const navigate = useNavigate()

   const [currentSort, setCurrentSort] = useState<TerminalSortInput>({ name: SortEnumType.Asc })
   const [currentFilter, setCurrentFilter] = useState<TerminalFilterInput>({ isArchived: { eq: false } })
   const [currentlyArchivingTerminal, setCurrentlyArchivingTerminal] = useState<InnerTerminalFragment>()
   const [currentlyDeletingTerminal, setCurrentlyDeletingTerminal] = useState<InnerTerminalFragment>()

   const sortClick = (currentSort: TerminalSortInput) => {
      setCurrentSort(currentSort)
   }

   const filterClick = (_currentFilter: TerminalFilterInput) => {
      _currentFilter.isArchived = currentFilter.isArchived

      setCurrentFilter(_currentFilter)
   }

   const navigateToNewTerminal = () => {
      navigate('/terminals/new')
   }

   const editTerminal = (terminal: InnerTerminalFragment) => {
      navigate(`/terminals/edit/${terminal.terminalKey}`)
   }

   const archiveTerminal = (terminal: InnerTerminalFragment) => {
      setCurrentlyArchivingTerminal(terminal)
   }

   const deleteTerminals = (terminal: InnerTerminalFragment) => {
      setCurrentlyDeletingTerminal(terminal)
   }

   const setShowArchived = (showArchived: boolean) => {
      let newFilter = Object.assign({}, currentFilter)
      newFilter.isArchived = { eq: showArchived }

      setCurrentFilter(newFilter)
   }

   return (
      <GeneralPage title="Terminals" headerContent={
         <div>
            <HookedCheckbox
               label="Show Archived"
               defaultValue={false}
               propertyKey={"showArchived"}
               errors={{}}
               register={React.createRef()}
               onChange={(event) => setShowArchived(event.target.checked)}
               inline={true}
            />
            <Button clickAction={navigateToNewTerminal}>New Terminal</Button>
         </div>
      }>
         <Tile>
            <TerminalDataWrapper
               currentSort={currentSort}
               currentlyArchivingTerminal={currentlyArchivingTerminal}
               updateSort={sortClick}
               currentFilter={currentFilter}
               updateFilter={filterClick}
               currentlyDeletingTerminal={currentlyDeletingTerminal}
               deleteTerminal={deleteTerminals}
               editTerminal={editTerminal}
               archiveTerminal={archiveTerminal} />
         </Tile>
      </GeneralPage>
   )
}

export default TerminalList