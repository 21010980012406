import React, { ReactElement } from 'react'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { CustomerGetAllEdge, useGetSearchCustomersQuery, CustomerSortInput, useGetSingleCustomerQuery, SortEnumType } from '../../../generated/graphql'

interface Props extends DefaultInputProps<CustomerGetAllEdge> {
    onSelect(customer: CustomerGetAllEdge)
    prefilledCustomerKey?: number
}

export default function CustomerSearch(props: Props): ReactElement {
    const { data, loading } = useGetSingleCustomerQuery({ variables: { id: props.prefilledCustomerKey }, skip: !props.prefilledCustomerKey })

    const onSelectCustomer = (customer: CustomerGetAllEdge) => {
        if (props.onSelect) props.onSelect(customer)
    }

    const prefilledData = data && data.customerGetSingle ? { node: { ...data.customerGetSingle } } as CustomerGetAllEdge : undefined
    const prefilledDataDisplay = data && `${data.customerGetSingle.dbaName && data.customerGetSingle.dbaName !== "" ? data.customerGetSingle.dbaName : data.customerGetSingle.name}`

    return <TextSearchDropdown<CustomerGetAllEdge>
        onSelect={onSelectCustomer}
        mapEntityToKey={e => parseInt(e.node.customerKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading && props.prefilledCustomerKey !== undefined}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}

        {...props}
    />
}

export const getCustomers = gql`
    fragment basicCustomer on Customer {
        customerKey
        name
        dbaName
    }

    query GetSearchCustomers($searchTerm: String, $sort: [CustomerSortInput!]) {
        customerGetAll(where: 
        { or: 
            [
                { name: {contains: $searchTerm }},
                { dbaName: {contains: $searchTerm }}
            ],
            and : {isArchived: {eq: false}}
        }, 
        order: $sort) {
            edges {
                node {
                    ...basicCustomer
                }
            }
        }
    }
`

const Inner = (props: InnerSearchLogicProps<CustomerGetAllEdge>) => {
    const sort: CustomerSortInput = { name: SortEnumType.Asc }

    const { data, loading, error } = useGetSearchCustomersQuery({ variables: { searchTerm: props.searchTerm, sort } })

    const showData = !loading && !error

    const results = showData && data.customerGetAll.edges.map(customer => {
        const display = `${customer.node.dbaName && customer.node.dbaName !== "" ? customer.node.dbaName : customer.node.name}`
        return { key: customer.node.customerKey, object: customer, display }
    })

    const displayProps: SearchDisplayProps<CustomerGetAllEdge> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Customers", results }
        ],
        searchTerm: props.searchTerm,
        isLoading: loading,
        error
    }

    return <SearchDisplay {...displayProps} />
}