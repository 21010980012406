
import gql from 'graphql-tag'
import { useMassMarkupMutation, MassMarkupMutationVariables } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'
import * as yup from 'yup'
import React, { ReactElement } from 'react'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { useNavigate } from 'react-router-dom'

export const MassMarkupMutation = gql`
    mutation MassMarkup($markupAmount: Float!, $currentMarkup: Float!) {
        massMarkup(markupAmount: $markupAmount, currentMarkup: $currentMarkup) 
    }
`

interface Props {
}

export default function MassMarkup(props: Props): ReactElement {
    const navigate = useNavigate()

    const [massMarkup] = useMassMarkupMutation()

    const navigateToHome = () => {
        navigate("/")
    }

    const validationSchema = yup.object().shape({
        markupAmount: yup.number().required(),
        currentMarkup: yup.number().required()
    })

    const submit = (variables: MassMarkupMutationVariables) => {
        massMarkup({ variables })
            .then(navigateToHome)
    }

    const { register, handleSubmit, errors } = useForm<MassMarkupMutationVariables>({ validationSchema })

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = (<Button clickAction={navigateToHome} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Mass Markup</Button>)

    return (
        <FormPage title="Mass Markup" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Current Markup"
                                propertyKey={propName<MassMarkupMutationVariables>(o => o.currentMarkup)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="New Markup"
                                propertyKey={propName<MassMarkupMutationVariables>(o => o.markupAmount)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}