import React, { ReactElement } from 'react'
import { AllPrePurchaseAgreementQueryVariables, useAllPrePurchaseAgreementQuery, InnerPrePurchaseAgreementFragment } from '../../../generated/graphql'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import ellipsish from '../../../icons/ellipsis-h.svg'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import PrePurchaseAgreementSearch from './PrePurchaseAgreementSearch'
import styles from '../MultiSearch/MultiSearch.module.scss'

interface Props {
    removePrePurchase(prePurchase: any)
    addPrePurchase(prePurchase: any)
    locationKey?: any
    data: any[]
}

export default function MultiPrePurchaseAgreementSearch(props: Props): ReactElement {
    const variables: AllPrePurchaseAgreementQueryVariables = {
        sortObject: {},
        cursor: "LTE=",
        filterObject: { prePurchaseAgreementKey: { in: props.data } },
        pageSize: 25
    }
    const { data, loading, error } = useAllPrePurchaseAgreementQuery({ variables })

    const validationSchema = yup.object().shape({
        prePurchaseAgreement: yup.object().shape({
            prePurchaseAgreementKey: yup.number().required()
        })
    })

    const removePrePurchase = (prePurchase: any) => {
        props.removePrePurchase(prePurchase.prePurchaseAgreementKey)
    }

    const prePurchaseGridOptions: GridOptions<InnerPrePurchaseAgreementFragment> = {
        columnDefs: [
            {
                headerName: "Agreement Name",
                dataTransform: o => o.supplierAgreement
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.prePurchaseAgreementGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removePrePurchase }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onSelect = (prePurchase: InnerPrePurchaseAgreementFragment) => {
        if (prePurchase) {
            props.addPrePurchase(prePurchase.prePurchaseAgreementKey)
        }
    }

    const header = (
        <div>
            <PrePurchaseAgreementSearch
                label="Pre Purchase Agreements"
                propertyKey={propName<string>(o => o)}
                onSelect={onSelect}
                clearOnSelect={true}
                {...commonProps} />
        </div>
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={prePurchaseGridOptions} />}
                </div>
            </div>
        </div>
    )
}