import React, { useState, useEffect } from 'react'
import styles from './PaginationWrapper.module.scss'
import Button, { ButtonType } from '../Button/Button'

interface Props {
    beforeChildren?: React.ReactNode
    afterChildren?: React.ReactNode

    changeCursor(cursor: string)

    pageSize: number
    totalCount: number

    children: React.ReactNode
}

const PaginationWrapper: React.FC<Props> = ({ beforeChildren, afterChildren, children, pageSize, totalCount, changeCursor }) => {
    const [currentPage, setCurrentPage] = useState(1)

    const [tc, setTC] = useState(0)

    const changePage = (page: number) => {
        setCurrentPage(page)
        changeCursor(btoa((pageSize * (page - 1) - 1).toString()))
        // changeCursor(btoa(`{"__totalCount":${totalCount.toString()},"__position":${(pageSize * (page - 1) - 1).toString()}}`))
    }

    useEffect(() => {
        //if row counts don't match, and there should be rows (not in a loading state of 0), reset it to page 1
        if (tc !== totalCount && totalCount > 0) {
            setTC(totalCount)
            setCurrentPage(1)
        }
    }, [tc, setTC, totalCount, setCurrentPage])

    const start = Math.max(0, currentPage - 2)
    const end = () => {
        if (start > 0) {
            return Math.min(start + 4, Math.ceil(totalCount / pageSize))
        }
        return Math.min(start + 5, Math.ceil(totalCount / pageSize))
    }

    return (
        <div className={styles.PaginationWrapper}>
            {beforeChildren}

            <div className={styles.PaginationWrapper__gridSection}>
                {children}
            </div>

            <div className={styles.PaginationWrapper__buttonSection}>
                {Math.ceil(totalCount / pageSize) > 1 && currentPage > 1 ?
                    <div>
                        <Button clickAction={() => changePage(1)} buttonType={ButtonType.Secondary}>{'<<'}</Button>
                        <Button clickAction={() => changePage(currentPage - 1)} buttonType={ButtonType.Secondary}>{'<'}</Button>
                    </div>
                    : null}
                {[...Array.from(Array(Math.ceil(totalCount / pageSize) + 1).keys())].splice(1).map(page => {
                    if (page < start || page > end()) {
                        return (null)
                    }

                    return (
                        <Button key={page} clickAction={() => changePage(page)}
                            buttonType={currentPage !== page ? ButtonType.Secondary : ButtonType.Primary} >{page}</Button>)
                })}
                {Math.ceil(totalCount / pageSize) > 1 && currentPage < Math.ceil(totalCount / pageSize) ?
                    <div>
                        <Button clickAction={() => changePage(currentPage + 1)} buttonType={ButtonType.Secondary}>{'>'}</Button>
                        <Button clickAction={() => changePage(Math.ceil(totalCount / pageSize))} buttonType={ButtonType.Secondary}>{'>>'}</Button>
                    </div>
                    : null}
            </div>

            {afterChildren}
        </div>
    )
}
export default PaginationWrapper