import React from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { useUpdatePointToPointCarrierRateMutation, UpdatePointToPointCarrierRateMutationVariables, useGetSingleCarrierPointToPointRateQuery } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from "yup"
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../General/Button/Button'
import StateSearch from '../../Search/StateSearch/StateSearch'

interface Props {
    closeModal(): void
    callback?(): Promise<any>
    carrierRateKey?: number
    delete(key): void
}

export const updatePointToPointCarrierRate = gql`
	mutation UpdatePointToPointCarrierRate($carrierPointToPointRate: CarrierPointToPointRateInput!, $carrierPointToPointRateKey: Int!) {
		carrierPointToPointRateUpdate(carrierPointToPointRate: $carrierPointToPointRate, id: $carrierPointToPointRateKey) {
			carrierPointToPointRateKey
		}
	}
`

export const GetSinglePointToPointCarrierRate = gql`
    fragment singlePointToPointCarrierRate on CarrierPointToPointRate {
        carrierPointToPointRateKey
         rate
         originCityKey
         originCity {
            name
            cityKey
            stateKey
            state{
               stateKey
               name
            }
         }
         destinationCityKey
         destinationCity {
            name
            cityKey
            stateKey
            state{
               stateKey
               name
            }
         }
         miles
         tolls
         carrierPointToPointRateDateSpanKey
    }

    query GetSingleCarrierPointToPointRate($key: Int!) {
        carrierPointToPointRateGetSingle(id: $key) {
            ...singlePointToPointCarrierRate
        }
    }
`

const UpdatePointToPointCarrierRateModal = (props: Props) => {
    const [updatePointToPointCarrierRateMutation, { loading: updating }] = useUpdatePointToPointCarrierRateMutation()
    const { data, loading, error } = useGetSingleCarrierPointToPointRateQuery({ variables: { key: props.carrierRateKey } })

    const onUpdate = (variables: UpdatePointToPointCarrierRateMutationVariables) => {

        variables.carrierPointToPointRateKey = props.carrierRateKey
        variables.carrierPointToPointRate.carrierPointToPointRateDateSpanKey = data.carrierPointToPointRateGetSingle.carrierPointToPointRateDateSpanKey
        variables.carrierPointToPointRate.destinationCity.cityKey = data.carrierPointToPointRateGetSingle.destinationCity.cityKey
        variables.carrierPointToPointRate.originCity.cityKey = data.carrierPointToPointRateGetSingle.originCity.cityKey
        variables.carrierPointToPointRate.originCityKey = data.carrierPointToPointRateGetSingle.originCity.cityKey
        variables.carrierPointToPointRate.destinationCityKey = data.carrierPointToPointRateGetSingle.destinationCity.cityKey
        updatePointToPointCarrierRateMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const validationSchema = yup.object().shape({
        carrierPointToPointRate: yup.object().shape({
            originCity: yup.object().shape({
                name: yup.string().required().trim(),
                stateKey: yup.number().required(),
            }),
            destinationCity: yup.object().shape({
                name: yup.string().required().trim(),
                stateKey: yup.number().required()
            }),
            miles: yup.number().required(),
            tolls: yup.number().required(),
            rate: yup.number().required(),
        })
    })

    const { register, handleSubmit, errors } = useForm<UpdatePointToPointCarrierRateMutationVariables>({ validationSchema })

    const commonProps = { register, errors }

    const onSubmit = handleSubmit(onUpdate)

    if (loading || error) {
        return <div></div>
    }

    const footerLeftContent = <><Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button><Button buttonType={ButtonType.Tertiary} clickAction={_ => props.delete(props.carrierRateKey)}>Delete</Button></>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit} isDisabled={updating}>Update</Button>

    return (
        <Modal headerText="Update Point to Point Rate" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll>
            <form onSubmit={onSubmit}>

                <div className="row">
                    <div className="col-xl-4 col-lg-8">
                        <HookedTextInput
                            register={register}
                            label="Origin City"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.originCity.name)}
                            defaultValue={data.carrierPointToPointRateGetSingle.originCity.name}
                            errors={errors}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                        <StateSearch
                            label="Origin State"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.originCity.stateKey)}
                            prefilledStateKey={data.carrierPointToPointRateGetSingle.originCity.stateKey}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-8">
                        <HookedTextInput
                            register={register}
                            label="Destination City"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.destinationCity.name)}
                            defaultValue={data.carrierPointToPointRateGetSingle.destinationCity.name}
                            errors={errors}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                        <StateSearch
                            label="Destination State"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.destinationCity.stateKey)}
                            prefilledStateKey={data.carrierPointToPointRateGetSingle.destinationCity.stateKey}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Miles"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.miles)}
                            defaultValue={data.carrierPointToPointRateGetSingle.miles.toString()}
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Tolls"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.tolls)}
                            errors={errors}
                            defaultValue={data.carrierPointToPointRateGetSingle.tolls.toString()}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            label="Rate"
                            propertyKey={propName<UpdatePointToPointCarrierRateMutationVariables>(o => o.carrierPointToPointRate.rate)}
                            errors={errors}
                            defaultValue={data.carrierPointToPointRateGetSingle.rate.toFixed(4).toString()}
                        />
                    </div>
                </div>
            </form>

        </Modal>
    )
}
export default UpdatePointToPointCarrierRateModal 