import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { useCreateCustomerMutation, CreateCustomerMutationVariables, BusinessType, useCreateCustomerTagMutation, CustomerTagInput } from '../../generated/graphql'
import useForm from 'react-hook-form'
import * as yup from "yup"
import propName from '../../helpers/propName'
import FormPage from '../../components/General/FormPage/FormPage'

import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import DeliveryTimeSearch from '../../components/Search/DeliveryTimeSearch/DeliveryTimeSearch'
import MultiTagSearch from '../../components/Search/TagSearch/MultiTagSearch'
import { useNavigate } from 'react-router-dom'

export const CreateCustomerQuery = gql`
    mutation CreateCustomer($customer: CustomerInput!) {
        customerCreate(customer: $customer) {
            customerKey
        }
    }
`

interface Props {

}


export default function CreateCustomer(props: Props): ReactElement {
    const navigate = useNavigate()

    const [createCustomerMutation] = useCreateCustomerMutation()
    const [tagKeys, setTagKeys] = useState<number[]>(undefined)

    const [createCustomerTag] = useCreateCustomerTagMutation()

    const navigateToList = () => {
        navigate("/customers")
    }

    const navigateToCustomer = (id: number) => {
        navigate(`/customers/show/${id}`)
    }

    const submit = (variables: CreateCustomerMutationVariables) => {
        variables.customer.isArchived = false

        createCustomerMutation({ variables })
            .then(_ => {
                if (tagKeys) {
                    tagKeys.forEach(key => {
                        const ctInput: CustomerTagInput = { customerKey: _.data.customerCreate.customerKey, tagKey: key }
                        createCustomerTag({ variables: { customerTag: ctInput } })
                    })
                }
                navigateToCustomer(_.data.customerCreate.customerKey)
            })
    }

    const validationSchema = yup.object().shape({
        customer: yup.object().shape({
            name: yup.string().required().trim(),
            quickBooksName: yup.string().trim(),
            businessType: yup.string().required(),
            creditLimit: yup.number(),
            creditNotes: yup.string().trim(),
            deliveryTimeKey: yup.number().required(),
            address1: yup.string().notRequired().trim(),
            address2: yup.string().notRequired().trim(),
            city: yup.string().notRequired().trim(),
            state: yup.string().notRequired().trim(),
            zipCode: yup.string().notRequired().trim(),
            directions: yup.string().trim()
        })
    })

    const businessTypes: SelectOption<BusinessType>[] = Object.keys(BusinessType).map(c => {
        return { key: BusinessType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const addTag = (tag) => {
        if (tagKeys === undefined) {
            setTagKeys([tag.tagKey])
        }
        else if (!tagKeys.some(x => x === tag.tagKey)) {
            setTagKeys([...tagKeys, tag.tagKey])
        }
    }

    const removeTag = (tag) => {
        setTagKeys([...tagKeys.filter(x => x !== tag)])
    }

    const defaultBusinessType = businessTypes[0]

    const { register, handleSubmit, errors, setValue } = useForm<CreateCustomerMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)
    const commonProps = { register, errors, setValue }

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Customer</Button>)

    return (
        <FormPage title="Create Customer" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.name)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="DBA Name"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.dbaName)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="QuickBooks Name"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.quickBooksName)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-8">
                            <HookedSelectOptions
                                options={businessTypes}
                                label="Business Type"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.businessType)}
                                setDefaultValue={defaultBusinessType}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <DeliveryTimeSearch
                                label="Delivery Time"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.deliveryTimeKey)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Description"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.description)}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 1"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.address1)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 2"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.address2)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="City"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.city)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.state)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Zipcode"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.zipCode)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Directions"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.directions)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Credit Limit"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.creditLimit)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Credit Notes"
                                propertyKey={propName<CreateCustomerMutationVariables>(o => o.customer.creditNotes)}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <MultiTagSearch
                                removeTag={removeTag}
                                addTag={addTag}
                                data={tagKeys}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>

        </FormPage>
    )
}