import React, { ReactElement, useEffect } from 'react'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { useGetSearchLocationsQuery, BasicLocationFragment, useGetSingleLocationQuery, LocationSortInput, SortEnumType } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'

interface Props extends DefaultInputProps<BasicLocationFragment> {
    onSelect?(location: BasicLocationFragment)
    prefilledLocationKey?: number
    clearOnSelect?: boolean
    customerKey?: number
    prefillIfOnlyLocation?: boolean
    showCustomer?: boolean
    requireCustomerKey?: boolean
}

export default function LocationSearch(props: Props): ReactElement {
    const sort: LocationSortInput = { name: SortEnumType.Asc }

    const { customerKey, requireCustomerKey, prefillIfOnlyLocation, onSelect } = props

    const { data: singleLocationData, loading: singleLocationLoading } = useGetSingleLocationQuery({ variables: { locationKey: props.prefilledLocationKey }, skip: !props.prefilledLocationKey })
    const { data: allLocationData, loading: locationsLoading } = useGetSearchLocationsQuery({ variables: { customerKey: props.customerKey, searchTerm: "", sort }, skip: !(customerKey && prefillIfOnlyLocation) })

    useEffect(() => {
        //if it's trying to load the locations, wait, skip this digest
        if (locationsLoading) { }

        // otherwise if there's no customer key when there should be a customer key, clear out a location that may be selected
        else if (!customerKey && requireCustomerKey) {
            onSelect(undefined)
        }

        // otherwise I have locations for a specific customer and should select it if there is only one location
        else if (allLocationData != null && (customerKey && prefillIfOnlyLocation) && allLocationData.locationGetByCustomer.length === 1) {
            // setLocationKey(allLocationData.locationGetByCustomer[0].locationKey)
            if (onSelect) onSelect(allLocationData.locationGetByCustomer[0])
        }
    }, [allLocationData, customerKey, prefillIfOnlyLocation, locationsLoading, requireCustomerKey, onSelect])

    const loc = singleLocationData ? singleLocationData.locationGetSingle : null
    const prefilledData = loc ? loc : undefined
    const prefilledDataDisplay = singleLocationData && `${props.showCustomer ? `${loc.customer.name} - ` : ""}${loc.name ? loc.name : `${loc.city}, ${loc.state}`}`

    return <TextSearchDropdown<BasicLocationFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e ? e.locationKey.toString() : '0')}
        searchWrapper={<Inner searchTerm="" customerKey={props.customerKey} onSelect={() => null} showCustomer={props.showCustomer} />}
        isLoadingPrefilled={singleLocationLoading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}
        disabled={requireCustomerKey && !customerKey}
        {...props}
    />
}

export const getLocations = gql`
    fragment BasicLocation on Location {
        locationKey
        name
        city
        state

        customer {
            customerKey
            name
        }
    }

    query GetSearchLocations($searchTerm: String, $customerKey: Int, $sort: [LocationSortInput!]) {
        locationGetByCustomer(customerKey: $customerKey, order: $sort, search: $searchTerm) {
            ...innerLocation 
        }
    }
`

const Inner = (props: InnerSearchLogicProps<BasicLocationFragment>) => {
    const sort: LocationSortInput = { name: SortEnumType.Asc }
    const { data, loading, error } = useGetSearchLocationsQuery({ variables: { customerKey: props.customerKey, searchTerm: props.searchTerm, sort } })

    const showData = !loading && !error

    const results = showData && data.locationGetByCustomer.map(location => {
        const display = `${props.showCustomer ? `${location.customer.name} - ` : ""} ${location.name ? location.name : `${location.city}, ${location.state}`}`
        return { key: location.locationKey, object: location, display }
    })

    const displayProps: SearchDisplayProps<BasicLocationFragment> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Locations", results }
        ],
        searchTerm: props.searchTerm,
        isLoading: loading,
        error
    }

    return <SearchDisplay {...displayProps} />

}
