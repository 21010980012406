import React, { FC } from 'react'
import Tile, { TileColor } from '../../General/Tile/Tile'
import styles from './FormTile.module.scss'

interface Props {
    title?: string
    tileColor?: TileColor

    children: React.ReactNode
}

const FormTile: FC<Props> = ({ title, tileColor, children }) => {
    return (
        <div className={styles.tileWrapper}>
            <Tile title={title} tileColor={tileColor}>
                {children}
            </Tile>
        </div>
    )
}

export default FormTile