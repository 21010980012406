import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { EditSalesAgreementMutationVariables, useEditSalesAgreementMutation, useCreateSalesAgreementLocationMutation, useDeleteSalesAgreementMutation, useDeleteSalesAgreementLocationMutation, SalesAgreementLocationInput, CreateSalesAgreementLocationMutationVariables, useGetSingleSalesAgreementQuery, useCreateLinkedSalesAgreementMutation, useDeleteLinkedSalesAgreementsMutation, LinkedSalesAgreementInput, useDeletePrePurchaseAgreementSalesAgreementMutation, useCreatePrePurchaseAgreementSalesAgreementMutation, PrePurchaseAgreementSalesAgreementInput, useDeleteTermContractSalesAgreementsMutation, useCreateTermContractSalesAgreementMutation, TermContractSalesAgreementInput, useAllStateTaxLazyQuery, StateTaxType } from '../../generated/graphql'
import useForm from 'react-hook-form'

import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import propName from '../../helpers/propName'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import MultiLocationSearch from '../../components/Search/LocationSearch/MultiLocationSearch'
import Modal from '../../components/Modal/Modal'
import MultiPrePurchaseAgreementSearch from '../../components/Search/PrePurchaseAgreementSearch/MultiPrePurchaseAgreementSearch'
import MultiSalesAgreementSearch from '../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch'
import GallonsGrid from '../../components/GallonsGrid/GallonsGrid'
import styles from '../../components/GallonsGrid/GallonsGrid.module.scss'
import moment from 'moment'
import MultiTermContractSearch from '../../components/Search/TermContractSearch/MultiTermContractSearch'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditSalesAgreementMutation = gql`
    mutation EditSalesAgreement($salesAgreement: SalesAgreementInput!, $id: Int!) {
        salesAgreementUpdate(salesAgreement: $salesAgreement, id: $id) {
            salesAgreementKey
        }
    }
`

export const DeleteSalesAgreementLocationMutation = gql`
    mutation DeleteSalesAgreementLocation($id: Int!) {
        salesAgreementLocationDeleteBySalesAgreementKey(salesAgreementKey: $id)
    }
`

export const DeleteSalesAgreementMutation = gql`
    mutation DeleteSalesAgreement($id: Int!) {
        salesAgreementDelete(id: $id)
    }
`

export const DeleteLinkedSalesAgreementMutation = gql`
    mutation DeleteLinkedSalesAgreements($id: Int!) {
        linkedSalesAgreementDelete(id: $id)
    }
`

export const CreateLinkedSalesAgreementMutation = gql`
    mutation CreateLinkedSalesAgreement($linkedSalesAgreement: LinkedSalesAgreementInput!) {
        linkedSalesAgreementCreate(linkedSalesAgreement: $linkedSalesAgreement) {
            relatedToSalesAgreementKey
            relatedFromSalesAgreementKey
        }
    }
`

export const DeletePrePurchaseAgreementSalesAgreementMutation = gql`
    mutation DeletePrePurchaseAgreementSalesAgreement($id: Int!) {
        prePurchaseAgreementSalesAgreementDelete(id: $id)
    }
`

export const CreatePrePurchaseAgreementSalesAgreementMutation = gql`
    mutation CreatePrePurchaseAgreementSalesAgreement($ppasa: PrePurchaseAgreementSalesAgreementInput!) {
        prePurchaseAgreementSalesAgreementCreate(prePurchaseAgreementSalesAgreement: $ppasa) {
            prePurchaseAgreementKey
            salesAgreementKey
        }
    }
`

export const CreateTermContractSalesAgreementQuery = gql`
    mutation CreateTermContractSalesAgreement($tcsa: TermContractSalesAgreementInput!) {
        termContractSalesAgreementCreate(termContractSalesAgreement: $tcsa) {
            termContractKey
            salesAgreementKey
        }
    }
`

export const DeleteTermContractSalesAgreementMutation = gql`
    mutation DeleteTermContractSalesAgreements($salesAgreementKey: Int!) {
        termContractSalesAgreementDelete(salesAgreementKey: $salesAgreementKey)
    }
`

export const GetSalesAgreement = gql`
    fragment salesAgreementListing on SalesAgreement {
        salesAgreementKey
        agreementName
        prePaid
        gallons
        deposit
        assessment
        startShipDate
        endShipDate
        notes
        gallonsToDate
        loadsToDate
        quoted
        quotedFOB
        misc
        supplierKey
        terminalKey
        aprEst
        mayEst
        junEst
        julEst
        augEst
        sepEst
        octEst
        novEst
        decEst
        janEst
        febEst
        marEst
        aprPI
        mayPI
        junPI
        julPI
        augPI
        sepPI
        octPI
        novPI
        decPI
        janPI
        febPI
        marPI
        supplier{
            name
        }
        terminal{
            name
        }
        salesAgreementLocations {
            locationKey
            location {
                state
            }
        }
        relatedFromSalesAgreements {
            relatedFromSalesAgreementKey
        }
        prePurchaseAgreementSalesAgreements {
            prePurchaseAgreementKey
        }
        termContractSalesAgreements {
            termContractKey
        }
    }

    query GetSingleSalesAgreement($id: Int!) {
        salesAgreementGetSingle(id: $id) {
            ...salesAgreementListing
        }
    }
`

interface MatchParams extends Params {
    salesAgreementKey: string
}

interface Props {
}


export default function EditSalesAgreement(props: Props): ReactElement {
    const navigate = useNavigate()
    const { salesAgreementKey: key } = useParams() as MatchParams

    const salesAgreementKey = parseInt(key)
    const [isSetup, setIsSetup] = useState(false)
    const [locationKeys, setLocationKeys] = useState<number[]>(undefined)
    const [prePurchaseKeys, setPrePurchaseKeys] = useState<number[]>(undefined)
    const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)
    const [termContractKeys, setTermContractKeys] = useState<number[]>(undefined)
    const [currentlyDeletingSalesAgreement, setCurrentlyDeleting] = useState(false)

    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)

    const [executeGetStateTaxes, { data: stateTaxData }] = useAllStateTaxLazyQuery()

    const validationSchema = yup.object().shape({
        salesAgreement: yup.object().shape({
            agreementName: yup.string().required().trim(),
            prePaid: yup.number().required(),
            gallons: yup.number().required(),
            deposit: yup.number().required(),
            assessment: yup.number().required(),
            startShipDate: yup.date().required(),
            endShipDate: yup.date().required(),
            notes: yup.string().trim(),
            gallonsToDate: yup.number().required(),
            loadsToDate: yup.number().required(),
            quoted: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            quotedFOB: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            misc: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            supplierKey: yup.number().notRequired(),
            terminalKey: yup.number().notRequired(),

            aprEst: yup.number().required(),
            mayEst: yup.number().required(),
            junEst: yup.number().required(),
            julEst: yup.number().required(),
            augEst: yup.number().required(),
            sepEst: yup.number().required(),
            octEst: yup.number().required(),
            novEst: yup.number().required(),
            decEst: yup.number().required(),
            janEst: yup.number().required(),
            febEst: yup.number().required(),
            marEst: yup.number().required(),

            aprPI: yup.number().required(),
            mayPI: yup.number().required(),
            junPI: yup.number().required(),
            julPI: yup.number().required(),
            augPI: yup.number().required(),
            sepPI: yup.number().required(),
            octPI: yup.number().required(),
            novPI: yup.number().required(),
            decPI: yup.number().required(),
            janPI: yup.number().required(),
            febPI: yup.number().required(),
            marPI: yup.number().required(),
        })
    })

    const { data, loading, error } = useGetSingleSalesAgreementQuery({ variables: { id: salesAgreementKey } })

    const { register, handleSubmit, errors, setValue } = useForm<EditSalesAgreementMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const navigateToList = () => {
        navigate('/pricing/sales-agreements/')
    }

    const [EditSalesAgreement] = useEditSalesAgreementMutation()
    const [DeleteSalesAgreement] = useDeleteSalesAgreementMutation()
    const [CreateSalesAgreementLocation] = useCreateSalesAgreementLocationMutation()
    const [DeleteSalesAgreementLocations] = useDeleteSalesAgreementLocationMutation()
    const [CreateLinkedSalesAgreements] = useCreateLinkedSalesAgreementMutation()
    const [DeleteLinkedSalesAgreements] = useDeleteLinkedSalesAgreementsMutation()
    const [CreatePrePurchaseAgreementSalesAgreement] = useCreatePrePurchaseAgreementSalesAgreementMutation()
    const [DeletePrePurchaseAgreementSalesAgreement] = useDeletePrePurchaseAgreementSalesAgreementMutation()
    const [deleteTermContractSalesAgreement] = useDeleteTermContractSalesAgreementsMutation()
    const [createTermContractSalesAgreement] = useCreateTermContractSalesAgreementMutation()

    if (loading) {
        return <FormPage></FormPage>
    }
    if (error) {
        return <p>There was a problem loading the sales agreement</p>
    }

    if (data.salesAgreementGetSingle.salesAgreementLocations &&
        data.salesAgreementGetSingle.salesAgreementLocations.length > 0 &&
        locationKeys === undefined) {
        setLocationKeys(data.salesAgreementGetSingle.salesAgreementLocations.map(x => x.locationKey))

        if (!isSetup) {
            executeGetStateTaxes({
                variables: {
                    filterObject: {
                        stateTaxType: { eq: StateTaxType.Tax },
                        "or":
                            [
                                //starts before the SA ends and never ends
                                {
                                    startDate: { lte: data.salesAgreementGetSingle.endShipDate },
                                    endDate: { eq: null }
                                },
                                //check for overlap
                                {
                                    startDate: { lte: data.salesAgreementGetSingle.endShipDate },
                                    endDate: { gte: data.salesAgreementGetSingle.startShipDate }
                                }
                            ]
                    },
                    sortObject: {}
                }
            })
            setIsSetup(true)
        }
    }
    if (data.salesAgreementGetSingle.relatedFromSalesAgreements &&
        data.salesAgreementGetSingle.relatedFromSalesAgreements.length > 0 &&
        salesAgreementKeys === undefined) {
        setSalesAgreementKeys(data.salesAgreementGetSingle.relatedFromSalesAgreements.map(x => x.relatedFromSalesAgreementKey))
    }
    if (data.salesAgreementGetSingle.prePurchaseAgreementSalesAgreements &&
        data.salesAgreementGetSingle.prePurchaseAgreementSalesAgreements.length > 0 &&
        prePurchaseKeys === undefined) {
        setPrePurchaseKeys(data.salesAgreementGetSingle.prePurchaseAgreementSalesAgreements.map(x => x.prePurchaseAgreementKey))
    }
    if (data.salesAgreementGetSingle.termContractSalesAgreements &&
        data.salesAgreementGetSingle.termContractSalesAgreements.length > 0 &&
        termContractKeys === undefined) {
        setTermContractKeys(data.salesAgreementGetSingle.termContractSalesAgreements.map(x => x.termContractKey))
    }
    if (startDate === undefined) {
        setStartDate(new Date(moment.utc(data.salesAgreementGetSingle.startShipDate).format('LL')))
    }
    if (endDate === undefined) {
        setEndDate(new Date(moment.utc(data.salesAgreementGetSingle.endShipDate).format('LL')))
    }

    const submit = (variables: EditSalesAgreementMutationVariables) => {
        variables.id = salesAgreementKey
        EditSalesAgreement({ variables })
            .then(r => {
                DeleteSalesAgreementLocations({ variables: { id: salesAgreementKey } })
                    .then(x => {
                        // nest so we make sure the delete promise resolves
                        if (locationKeys) {
                            locationKeys.forEach(key => {
                                const salInput: SalesAgreementLocationInput = { salesAgreementKey: salesAgreementKey, locationKey: key }
                                const salVariables: CreateSalesAgreementLocationMutationVariables = { salesAgreementLocation: salInput }
                                CreateSalesAgreementLocation({ variables: salVariables })
                            })
                        }
                    })
                    .then(x => {
                        DeleteLinkedSalesAgreements({ variables: { id: salesAgreementKey } })
                            .then(x => {
                                if (salesAgreementKeys) {
                                    salesAgreementKeys.forEach(key => {
                                        const saInput: LinkedSalesAgreementInput = { relatedFromSalesAgreementKey: key, relatedToSalesAgreementKey: salesAgreementKey }
                                        CreateLinkedSalesAgreements({ variables: { linkedSalesAgreement: saInput } })
                                    })
                                }
                            })
                            .then(x => {
                                DeletePrePurchaseAgreementSalesAgreement({ variables: { id: salesAgreementKey } })
                                    .then(x => {
                                        if (prePurchaseKeys) {
                                            prePurchaseKeys.forEach(key => {
                                                const ppInput: PrePurchaseAgreementSalesAgreementInput = { salesAgreementKey: salesAgreementKey, prePurchaseAgreementKey: key }
                                                CreatePrePurchaseAgreementSalesAgreement({ variables: { ppasa: ppInput } })
                                            })
                                        }
                                    })
                                    .then(navigateToList)
                            })
                            .then(x => {
                                deleteTermContractSalesAgreement({ variables: { salesAgreementKey: salesAgreementKey } })
                                    .then(x => {
                                        if (termContractKeys) {
                                            termContractKeys.forEach(key => {
                                                const tcsaInput: TermContractSalesAgreementInput = { salesAgreementKey: salesAgreementKey, termContractKey: key }
                                                createTermContractSalesAgreement({ variables: { tcsa: tcsaInput } })
                                            })
                                        }
                                    })
                            })
                    })
            })
    }

    const addLocation = (num: number) => {
        if (locationKeys === undefined) {
            setLocationKeys([num])
        }
        else if (!locationKeys.some(x => x === num)) {
            setLocationKeys([...locationKeys, num])
        }
    }

    const removeLocation = (num: number) => {
        setLocationKeys(locationKeys.filter(x => x !== num))
    }

    const addPrePurchase = (num: number) => {
        if (prePurchaseKeys === undefined) {
            setPrePurchaseKeys([num])
        }
        else if (!prePurchaseKeys.some(x => x === num)) {
            setPrePurchaseKeys([...prePurchaseKeys, num])
        }
    }

    const removePrePurchase = (num: number) => {
        setPrePurchaseKeys(prePurchaseKeys.filter(x => x !== num))
    }

    const addSalesAgreement = (num: number) => {
        if (salesAgreementKeys === undefined) {
            setSalesAgreementKeys([num])
        }
        else if (!salesAgreementKeys.some(x => x === num)) {
            setSalesAgreementKeys([...salesAgreementKeys, num])
        }
    }

    const removeSalesAgreement = (num: number) => {
        setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
    }

    const confirmDelete = () => {
        DeleteSalesAgreement({ variables: { id: salesAgreementKey } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const addTermContract = (num: number) => {
        if (termContractKeys === undefined) {
            setTermContractKeys([num])
        }
        else if (!termContractKeys.some(x => x === num)) {
            setTermContractKeys([...termContractKeys, num])
        }
    }

    const removeTermContract = (num: number) => {
        setTermContractKeys(termContractKeys.filter(x => x !== num))
    }

    const updateStateTaxes = (startDateParam, endDateParam) => {
        executeGetStateTaxes({
            variables: {
                filterObject: {
                    stateTaxType: { eq: StateTaxType.Tax },
                    "or":
                        [
                            //starts before the SA ends and never ends
                            {
                                startDate: { lte: endDateParam },
                                endDate: { eq: null }
                            },
                            //check for overlap
                            {
                                startDate: { lte: endDateParam },
                                endDate: { gte: startDateParam }
                            }
                        ]
                },
                sortObject: {}
            }
        })
    }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
            updateStateTaxes(date, endDate)
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
            updateStateTaxes(startDate, date)
        }
    }

    const onSubmit = handleSubmit(submit)

    const currentStates = data.salesAgreementGetSingle.salesAgreementLocations.map(sal => sal.location.state.toLowerCase())
    //since we don't have an easy way to filter states with the query, we just filter afterward. Will likely always be a small amount of data
    const filteredStateTaxes = stateTaxData ? stateTaxData.stateTaxGetAll
        .filter(st => {
            return currentStates.indexOf(st.state.name.toLowerCase()) > -1
        }) : []

    const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>
    )
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Sales Agreement</Button>)

    return (
        <FormPage title="Update Sales Agreement" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} >
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Agreement Name"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.agreementName)}
                                defaultValue={data.salesAgreementGetSingle.agreementName}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.supplierKey)}
                                prefilledSupplierKey={data.salesAgreementGetSingle.supplierKey}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <TerminalSearch
                                label="Terminal"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.terminalKey)}
                                prefilledTerminalKey={data.salesAgreementGetSingle.terminalKey}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiLocationSearch
                                removeLocation={removeLocation}
                                addLocation={addLocation}
                                data={locationKeys}
                                showCustomer={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiTermContractSearch
                                removeTermContract={removeTermContract}
                                addTermContract={addTermContract}
                                data={termContractKeys} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiPrePurchaseAgreementSearch
                                removePrePurchase={removePrePurchase}
                                addPrePurchase={addPrePurchase}
                                data={prePurchaseKeys} />
                        </div>
                        <div className="col-md-6">
                            <MultiSalesAgreementSearch
                                removeSalesAgreement={removeSalesAgreement}
                                addSalesAgreement={addSalesAgreement}
                                data={salesAgreementKeys} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Details">
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Pre Paid Dollars"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.prePaid)}
                                defaultValue={data.salesAgreementGetSingle.prePaid.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.gallons)}
                                defaultValue={data.salesAgreementGetSingle.gallons.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Deposit $/Gal"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.deposit)}
                                defaultValue={data.salesAgreementGetSingle.deposit.toFixed(4)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Quoted"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.quoted)}
                                defaultValue={data.salesAgreementGetSingle.quoted ? data.salesAgreementGetSingle.quoted.toFixed(4) : undefined}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons to Date"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.gallonsToDate)}
                                defaultValue={data.salesAgreementGetSingle.gallonsToDate.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Loads to Date"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.loadsToDate)}
                                defaultValue={data.salesAgreementGetSingle.loadsToDate.toString()}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Quoted FOB"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.quotedFOB)}
                                defaultValue={data.salesAgreementGetSingle.quotedFOB ? data.salesAgreementGetSingle.quotedFOB.toFixed(4) : undefined}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Assessment"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.assessment)}
                                defaultValue={data.salesAgreementGetSingle.assessment.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Misc Cost"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.misc)}
                                defaultValue={data.salesAgreementGetSingle.misc ? data.salesAgreementGetSingle.misc.toFixed(4) : undefined}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            State Taxes
                            {filteredStateTaxes.length > 0 ?
                                stateTaxData.stateTaxGetAll
                                    .filter(st => {
                                        return currentStates.indexOf(st.state.name.toLowerCase()) > -1
                                    })
                                    .map(st => {
                                        return <div key={st.stateTaxKey}>{moment(st.startDate).format('LL')}: {st.amount.toFixed(4)}</div>
                                    })
                                : <p>No State Taxes</p>
                            }

                        </div>
                        <div className="col-md-8">
                            <HookedTextArea
                                label="Notes"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.notes)}
                                defaultValue={data.salesAgreementGetSingle.notes}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Dates">
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Ship Date"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.startShipDate)}
                                defaultValue={startDate}
                                Change={onStartDateChange}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Ship Date"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.endShipDate)}
                                defaultValue={endDate}
                                Change={onEndDateChange}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className={styles.gallonsGrid__title}>Estimated</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.aprEst)}
                                defaultValue={data.salesAgreementGetSingle.aprEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.mayEst)}
                                defaultValue={data.salesAgreementGetSingle.mayEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.junEst)}
                                defaultValue={data.salesAgreementGetSingle.junEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.julEst)}
                                defaultValue={data.salesAgreementGetSingle.julEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.augEst)}
                                defaultValue={data.salesAgreementGetSingle.augEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.sepEst)}
                                defaultValue={data.salesAgreementGetSingle.sepEst.toString()}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.octEst)}
                                defaultValue={data.salesAgreementGetSingle.octEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.novEst)}
                                defaultValue={data.salesAgreementGetSingle.novEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.decEst)}
                                defaultValue={data.salesAgreementGetSingle.decEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.janEst)}
                                defaultValue={data.salesAgreementGetSingle.janEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.febEst)}
                                defaultValue={data.salesAgreementGetSingle.febEst.toString()}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.marEst)}
                                defaultValue={data.salesAgreementGetSingle.marEst.toString()}
                                {...commonProps} />
                        </div>
                    </div>
                    <GallonsGrid
                        salesAgreementKey={salesAgreementKey} />
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className={styles.gallonsGrid__title}>Price Increment</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.aprPI)}
                                defaultValue={data.salesAgreementGetSingle.aprPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.mayPI)}
                                defaultValue={data.salesAgreementGetSingle.mayPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.junPI)}
                                defaultValue={data.salesAgreementGetSingle.junPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.julPI)}
                                defaultValue={data.salesAgreementGetSingle.julPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.augPI)}
                                defaultValue={data.salesAgreementGetSingle.augPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.sepPI)}
                                defaultValue={data.salesAgreementGetSingle.sepPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.octPI)}
                                defaultValue={data.salesAgreementGetSingle.octPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.novPI)}
                                defaultValue={data.salesAgreementGetSingle.novPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.decPI)}
                                defaultValue={data.salesAgreementGetSingle.decPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.janPI)}
                                defaultValue={data.salesAgreementGetSingle.janPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.febPI)}
                                defaultValue={data.salesAgreementGetSingle.febPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<EditSalesAgreementMutationVariables>(o => o.salesAgreement.marPI)}
                                defaultValue={data.salesAgreementGetSingle.marPI.toFixed(4)}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
            </form>
            {currentlyDeletingSalesAgreement && <Modal headerText="Delete?">
                <p>Once you delete this Sales Agreement, it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Tertiary}>Cancel</Button> <Button clickAction={confirmDelete} buttonType={ButtonType.Primary}>Delete</Button>
            </Modal>}
        </FormPage>
    )
}