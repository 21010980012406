import React, { ReactElement } from 'react'
import Modal from '../../Modal/Modal'
import gql from 'graphql-tag'
import { CreateLocationTerminalMutationResult, CreateLocationTerminalMutationVariables, useCreateLocationTerminalMutation } from '../../../generated/graphql'
import TerminalSearch from '../../Search/TerminalSearch/TerminalSearch'
import propName from '../../../helpers/propName'
import * as yup from "yup"
import useForm from 'react-hook-form'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../General/Button/Button'

interface Props {
    locationKey: number
    callback(payload: CreateLocationTerminalMutationResult): Promise<any>
    closeModal()
}

export const CreateLocationTerminal = gql`
    mutation CreateLocationTerminal($locationTerminal: LocationTerminalInput!) {
        locationTerminalCreate(locationTerminal: $locationTerminal) {
            locationKey
            terminalKey
        }
    }
`

export default function AddTerminalModal({ locationKey, callback, closeModal }: Props): ReactElement {

    const [locationTerminalCreate] = useCreateLocationTerminalMutation()
    const submit = (variables: CreateLocationTerminalMutationVariables) => {
        variables.locationTerminal.freight = 0
        variables.locationTerminal.billingFreight = 0
        locationTerminalCreate({ variables: { locationTerminal: { ...variables.locationTerminal, locationKey } } })
            .then(callback)
            .then(closeModal)
    }

    const validationSchema = yup.object().shape({
        locationTerminal: yup.object().shape({
            terminalKey: yup.number().required(),
            mileage: yup.number().required()
        })
    })

    const { register, handleSubmit, errors } = useForm<CreateLocationTerminalMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const onSubmit = handleSubmit(submit)
    const onSearch = (terminal) => { }

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>

    return (
        <Modal headerText="Add Terminal to Location" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <TerminalSearch
                    onSelect={onSearch}
                    propertyKey={propName<CreateLocationTerminalMutationVariables>(o => o.locationTerminal.terminalKey)}
                    label="Terminal"
                    {...commonProps}
                />

                <HookedTextInput
                    propertyKey={propName<CreateLocationTerminalMutationVariables>(o => o.locationTerminal.mileage)}
                    label="Mileage"
                    {...commonProps}
                />
            </form>
        </Modal>
    )
}
