import gql from 'graphql-tag'
import * as yup from 'yup'
import { useUpdateCarrierRateBandMutation, UpdateCarrierRateBandMutationVariables, useDeleteCarrierRateBandMutation } from '../../generated/graphql'
import useForm from 'react-hook-form'
import React, { useState } from 'react'
import HookedTextInput from '../General/Inputs/HookedTextInput'
import propName from '../../helpers/propName'
import Button, { ButtonType } from '../General/Button/Button'
import { Save, Trash } from 'react-feather'
import styles from './CarrierRateBand.module.scss'
import Modal from '../Modal/Modal'

interface CarrierRateBandProps {
    carrierRateBandKey: number
    carrierRateKey: number
    mileageFrom: number
    mileageTo: number
    rate: number
    callback?(): void
}

export const UpdateCarrierRateBand = gql`
    mutation UpdateCarrierRateBand($carrierRateBand: CarrierRateBandInput!, $key: Int!) {
        carrierRateBandUpdate(carrierRateBand: $carrierRateBand, id: $key) {
            carrierRateBandKey
        }
    }
`

export const DeleteCarrierRateBandMutation = gql`
   mutation DeleteCarrierRateBand($id: Int!) {
      carrierRateBandDelete(id: $id)
   }
`

const CarrierRateBandUpdate = (props: CarrierRateBandProps) => {

    const validationSchema = yup.object().shape({
        carrierRateBand: yup.object().shape({
            mileageFrom: yup.number().required(),
            mileageTo: yup.number().required(),
            ratePerGallon: yup.number().required()
        })
    })

    const [currentlyDeletingCarrierRate, setCurrentlyDeleting] = useState(false)
    const [updateCarrierRateBand] = useUpdateCarrierRateBandMutation()
    const [deleteMutation] = useDeleteCarrierRateBandMutation()


    const submitUpdateRateBandTask = (variables: UpdateCarrierRateBandMutationVariables) => {
        variables.carrierRateBand.carrierRateKey = props.carrierRateKey
        variables.key = props.carrierRateBandKey
        updateCarrierRateBand({ variables })
    }

    const updateCarrierRateBandSubmit = useForm<UpdateCarrierRateBandMutationVariables>({ validationSchema })
    const updateCommonProps = { register: updateCarrierRateBandSubmit.register, errors: updateCarrierRateBandSubmit.errors, setValue: updateCarrierRateBandSubmit.setValue }
    const updateCarrierRate = updateCarrierRateBandSubmit.handleSubmit(submitUpdateRateBandTask)

    const confirmDelete = () => {
        deleteMutation({ variables: { id: props.carrierRateBandKey } })
            .then(() => {
                setCurrentlyDeleting(false)
                props.callback()
            })
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    return (
        <form id="update-carrier-rate-band-form" onSubmit={updateCarrierRate}>
            <div className="row">
                <div className="col-md-4">
                    <HookedTextInput
                        propertyKey={propName<UpdateCarrierRateBandMutationVariables>(o => o.carrierRateBand.mileageFrom)}
                        label="Mileage From"
                        defaultValue={props.mileageFrom.toString()}
                        {...updateCommonProps}
                    />
                </div>
                <div className="col-md-4">
                    <HookedTextInput
                        propertyKey={propName<UpdateCarrierRateBandMutationVariables>(o => o.carrierRateBand.mileageTo)}
                        label="Mileage To"
                        defaultValue={props.mileageTo.toString()}
                        {...updateCommonProps}
                    />
                </div>
                <div className="col-md-2">
                    <HookedTextInput
                        propertyKey={propName<UpdateCarrierRateBandMutationVariables>(o => o.carrierRateBand.ratePerGallon)}
                        label="Rate"
                        defaultValue={props.rate.toFixed(4)}
                        {...updateCommonProps}
                    />
                </div>
                <div className="col-md-2">
                    <div className={styles.carrierRateBand__button}>
                        <Button buttonType={ButtonType.TightPrimary} clickAction={updateCarrierRate} ><Save /></Button>
                        <Button buttonType={ButtonType.TightSecondary} clickAction={openDeleteConfirmation}><Trash /></Button>
                    </div>
                </div>
            </div>
            {!!currentlyDeletingCarrierRate && <Modal headerText="Delete?" footerLeftContent={<Button buttonType={ButtonType.Secondary} clickAction={cancelDelete}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={confirmDelete}>Delete</Button>}>
                <p>Once you delete this Carrier Rate, it will not be able to be reversed.</p>
            </Modal>}
        </form>
    )

}

export default CarrierRateBandUpdate