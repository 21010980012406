import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import { useAllTermContractQuery, InnerTermContractFragment, AllTermContractQueryVariables, TermContractFilterInput, useDeleteTermContractMutation, TermContractSortInput, SortEnumType } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export const TermContractFragment = gql`
    fragment innerTermContract on TermContract {
        termContractKey
        name
        termContractSuppliers {
            supplier {
                name
            }
        }
        termContractTerminals {
            terminal {
                name
            }
        }
        startDate
        endDate
        notes
    }

    fragment innerTermContractListing on TermContractGetAllEdge {
        node {
            ...innerTermContract
        }
    }

    fragment allTermContractListing on TermContractGetAllConnection {
        edges {
            cursor
            ...innerTermContractListing
        }
        totalCount
    }
`

export const AllTermContractQuery = gql`
    query AllTermContract($sort: [TermContractSortInput!], $filter: TermContractFilterInput, $cursor: String!, $pageSize: Int!) {
        termContractGetAll(order: $sort, where: $filter, first: $pageSize, after: $cursor) {
            ...allTermContractListing
        }
    }
`

export const DeleteTermContractMutation = gql`
    mutation DeleteTermContract($key: Int!) {
        termContractDelete(termContractKey: $key)
    }
`

interface TermContractDataWrapperProps {
    currentSort: TermContractSortInput
    updateSort(newSort: TermContractSortInput)

    currentFilter: TermContractFilterInput
    updateFilter(newFilter: TermContractFilterInput)

    editTermContract(termContract: InnerTermContractFragment)
    deleteTermContract(termContract: InnerTermContractFragment)
    currentlyDeletingTermContract: InnerTermContractFragment
}

const TermContractDataWrapper = (props: TermContractDataWrapperProps): JSX.Element => {
    const pageSize = 25
    const [cursor, setCursor] = useState("LTE=")

    const [deleteMutation] = useDeleteTermContractMutation()

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllTermContractQueryVariables = {
        sort: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllTermContractQuery({ variables })

    const confirmDelete = (key: number) => {
        deleteMutation({ variables: { key } })
            .then(() => refetch())
            .then(() => props.deleteTermContract(null))
    }

    const cancelDelete = () => {
        props.deleteTermContract(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerTermContractFragment> = {
        columnDefs: [
            { headerName: "Name", field: "name", dataTransform: o => o.name, sortable: true, searchable: true },
            { headerName: "Suppliers", field: "supplier", dataTransform: o => o.termContractSuppliers.map(s => s.supplier.name).join(" | ") },
            {
                headerName: "Start Date",
                field: "startDate",
                dataTransform: o => moment.utc(o.startDate).format('LL'),
                sortable: true
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.termContractGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editTermContract },
                    { displayName: "Delete", action: props.deleteTermContract }
                ]
            }
        ],
        dataKeyColumn: "termContractKey",
        linkToPath: "/pricing/term-contract/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    if (props.currentFilter.endDate && props.currentFilter.endDate.lt) {
        gridOptions.columnDefs.push({
            headerName: "End Date", field: "endDate", dataTransform: o => moment.utc(o.endDate).format('LL')
        })
    }

    return (
        <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.termContractGetAll.totalCount : 0}
            changeCursor={pageChange}
        >
            <GridBase<InnerTermContractFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingTermContract && <Modal headerText="Delete?"
                footerLeftContent={<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>}
                footerRightContent={<Button clickAction={() => confirmDelete(props.currentlyDeletingTermContract.termContractKey)} buttonType={ButtonType.Danger}>Delete</Button>}>
                <p>Once you delete this term contract, it will not be able to be reversed.</p>
                <p>Term Contract: {props.currentlyDeletingTermContract.name}</p>
            </Modal>}
        </PaginationWrapper>
    )
}

interface Props {
}

const TermContracts = (props: Props) => {
    const navigate = useNavigate()
    const today = moment().startOf('day')

    const [currentSort, setCurrentSort] = useState<TermContractSortInput>({ name: SortEnumType.Asc })
    const [currentFilter, setCurrentFilter] = useState<TermContractFilterInput>({ endDate: { gte: today } })
    const [currentlyDeletingTermContract, setCurrentlyDeletingTermContract] = useState<InnerTermContractFragment>()

    const sortClick = (currentSort: TermContractSortInput) => {
        setCurrentSort(currentSort)
    }

    const filterClick = (_currentFilter: TermContractFilterInput) => {
        _currentFilter.endDate = currentFilter.endDate
        setCurrentFilter(_currentFilter)
    }

    const navigateToNewTermContract = () => {
        navigate('/pricing/term-contract/new')
    }

    const editTermContract = (termContract: InnerTermContractFragment) => {
        navigate(`/pricing/term-contract/edit/${termContract.termContractKey}`)
    }

    const deleteTermContract = (termContract: InnerTermContractFragment) => {
        setCurrentlyDeletingTermContract(termContract)
    }

    const setShowExpired = (showExpired: boolean) => {
        let newFilter = Object.assign({}, currentFilter)

        const today = moment().startOf('day')

        if (showExpired) {
            newFilter.endDate = { lt: today }
        }
        else {
            newFilter.endDate = { gte: today }
        }

        setCurrentFilter(newFilter)
    }

    return (
        <GeneralPage title="Term Contracts" headerContent={
            <div>
                <HookedCheckbox
                    label="Show Expired"
                    defaultValue={false}
                    propertyKey={"showExpired"}
                    errors={{}}
                    register={React.createRef()}
                    onChange={(event) => setShowExpired(event.target.checked)}
                    inline={true}
                />
                <Button clickAction={navigateToNewTermContract}>New Term Contract</Button>
            </div>
        }>
            <Tile>
                <TermContractDataWrapper
                    currentSort={currentSort}
                    updateSort={sortClick}
                    currentFilter={currentFilter}
                    updateFilter={filterClick}
                    editTermContract={editTermContract}
                    deleteTermContract={deleteTermContract}
                    currentlyDeletingTermContract={currentlyDeletingTermContract} />
            </Tile>
        </GeneralPage>
    )
}

export default TermContracts