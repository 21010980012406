import gql from 'graphql-tag'
import React, { ReactElement, useState } from 'react'
import useForm from 'react-hook-form'
import { GetSingleBasisTypeQueryVariables, useGetSingleBasisTypeQuery, useEditBasisTypeMutation, EditBasisTypeMutationVariables, useDeleteBasisTypeMutation } from '../../generated/graphql'
import propName from '../../helpers/propName'

import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import * as yup from "yup"
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import Modal from '../../components/Modal/Modal'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditBasisTypeQuery = gql`
   mutation EditBasisType($basisType: BasisTypeInput! $id: Int!) {
      basisTypeUpdate(basisType: $basisType, id: $id) {
         basisTypeKey
      }
   }
`

interface MatchParams extends Params {
   basisTypeKey: string
}

interface Props {
}

export default function EditBasisType(props: Props): ReactElement {
   const navigate = useNavigate()

   const { basisTypeKey: key } = useParams() as MatchParams

   const basisTypeKey = parseInt(key)
   const [currentlyDeletingBasisType, setCurrentlyDeleting] = useState(false)

   const validationSchema = yup.object().shape({
      basisType: yup.object().shape({
         name: yup.string().required().trim(),
         description: yup.string().required().trim(),
      })
   })

   const getVariables: GetSingleBasisTypeQueryVariables = { id: basisTypeKey }
   const { data: d, loading: l, error: e } = useGetSingleBasisTypeQuery({ variables: getVariables })

   const [editBasisTypeMutation] = useEditBasisTypeMutation()
   const [deleteMutation] = useDeleteBasisTypeMutation()

   const { register, handleSubmit, errors } = useForm<EditBasisTypeMutationVariables>({ validationSchema })

   if (l) return <div>Loading</div>
   if (e) return <div>Error Loading Record</div>

   const navigateToList = () => {
      navigate("/pricing/basis-types")
   }

   const onSubmit = handleSubmit(
      (variables: EditBasisTypeMutationVariables) =>
         editBasisTypeMutation({ variables: { ...variables, ...{ id: basisTypeKey } } })
            .then(_ => navigateToList()))

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Basis Type</Button>)

   return (
      <FormPage title="Update Basis Type" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.basisTypeGetSingle.name}
                        register={register}
                        label="Name"
                        propertyKey={propName<EditBasisTypeMutationVariables>(o => o.basisType.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextArea
                        defaultValue={d.basisTypeGetSingle.description}
                        register={register}
                        label="Description"
                        propertyKey={propName<EditBasisTypeMutationVariables>(o => o.basisType.description)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedCheckbox
                        label="Archived"
                        register={register}
                        propertyKey={propName<EditBasisTypeMutationVariables>(o => o.basisType.isArchived)}
                        defaultValue={d.basisTypeGetSingle.isArchived}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingBasisType && <Modal headerText="Delete?">
            <p>Once you delete this basis type, it will not be able to be reversed.</p>
            <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(basisTypeKey)}>Delete</button>
         </Modal>}
      </FormPage>
   )
}