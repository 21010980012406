import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useAllBasisTypesQuery, CreateBasisTypePriceMutationVariables, useCreateBasisTypePriceMutation, SortEnumType } from '../../../generated/graphql'
import Modal from '../../Modal/Modal'
import Button, { ButtonType } from '../../General/Button/Button'
import styles from '../BulkUpdateSupplierPostingsModal/BulkUpdateSupplierPostingsModal.module.scss'
import inputStyles from '../../General/Inputs/Input.module.scss'
import DatePicker from 'react-datepicker'

interface Props {
    closeModal(): void
    callback?(): void
}

interface BasisTypeUpdate {
    key: number
    price: string
    date: Date
}

const BasisTypeUpdatePricesModal = (props: Props) => {

    const { data, loading, error } = useAllBasisTypesQuery({ variables: { sortObject: { name: SortEnumType.Asc } } })
    const [date, setDate] = useState<Date>(new Date(moment().format('LL')))
    const [basisTypes, setBasisTypes] = useState<BasisTypeUpdate[]>([])

    const [createBasisTypePrice] = useCreateBasisTypePriceMutation()

    useEffect(() => {
        if (data) {
            const arr: BasisTypeUpdate[] = data.basisTypeGetAll.map(p => {
                const transformedVal: BasisTypeUpdate = { key: p.basisTypeKey, price: p.currentPrice.price.toFixed(4), date: p.currentPrice.effectiveDate }
                return transformedVal
            })
            setBasisTypes(arr)
        }
    }, [setBasisTypes, data])


    if (loading) return <Modal></Modal>
    if (error) return <Modal><h2>Error</h2></Modal>

    function handleChange(i, event) {
        let newPrices: BasisTypeUpdate[] = Object.assign(basisTypes)
        newPrices[i].price = event.target.value
        setBasisTypes(newPrices)
    }

    const updateBasisTypes = (variables: CreateBasisTypePriceMutationVariables) => {
        return createBasisTypePrice({ variables })
    }

    const submit = () => {
        let promises = []

        basisTypes.forEach(c => {
            let btInt = Number(c.price)
            if (!isNaN(btInt)) {
                const bt = data.basisTypeGetAll.find(bt => bt.basisTypeKey === c.key)
                if (bt) {
                    if (bt.currentPrice.price === btInt) {
                        return
                    }

                    let btUpdate: CreateBasisTypePriceMutationVariables = {
                        basisTypePrice: {
                            price: btInt,
                            basisTypeKey: c.key,
                            effectiveDate: date
                        }
                    }

                    promises.push(updateBasisTypes(btUpdate))
                }
            }
        })

        Promise.all(promises).then(props.callback).then(props.closeModal)
    }

    const footerLeftContent = (<Button clickAction={props.closeModal} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={submit} buttonType={ButtonType.Success}>Update Basis Types</Button>)

    const onDateChange = (date: Date, e) => {
        setDate(date)
    }

    return (
        <Modal headerText="Update All Basis Types" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <div className={`row ${styles.tableHeading}`}>
                <div className="col-md-3">Basis Type</div>
                <div className="col-md-3">Date</div>
                <div className="col-md-3">Price</div>
                <div className="col-md-3">New Price</div>
            </div>

            <div className={styles.tableContainer}>
                {data.basisTypeGetAll.map((bt, index) => {
                    return (<div className={`row ${styles.tableRow}`} key={index}>
                        <div className={`col-md-3 ${styles.tableRowColumn}`}>{bt.name}</div>
                        <div className={`col-md-3 ${styles.tableRowColumn}`}>{moment.utc(bt.currentPrice.effectiveDate).format('LL')}</div>
                        <div className={`col-md-3 ${styles.tableRowColumn}`}>{bt.currentPrice.price.toFixed(4)}</div>
                        <div className="col-md-3">
                            <input
                                type="text"
                                placeholder="New Price"
                                onChange={e => handleChange(index, e)}
                                className={inputStyles.input}
                                defaultValue={bt.currentPrice.price.toFixed(4)}
                            />
                        </div>
                    </div>)
                })}
            </div>
            <div className="row">
                <div className="col-md-4">
                    <label className={inputStyles.input__wrapper}>Date</label>
                    <DatePicker
                        className={inputStyles.input}
                        selected={date}
                        onChange={onDateChange}
                        showTimeSelect={false}
                        dateFormat="MMMM d, yyyy"
                        maxDate={new Date()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default BasisTypeUpdatePricesModal