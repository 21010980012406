import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { useGetSingleIndexContractQuery, useEditIndexContractMutation, EditIndexContractMutationVariables, SupplierType, ContractPeriod, ContractType, useDeleteIndexContractMutation, useContractVolumeForIndexContractQuery } from '../../../generated/graphql'

import useForm from 'react-hook-form'
import HookedTextInput from '../../../components/General/Inputs/HookedTextInput'
import propName from '../../../helpers/propName'
import TerminalSearch from '../../../components/Search/TerminalSearch/TerminalSearch'
import SupplierSearch from '../../../components/Search/SupplierSearch/SupplierSearch'
import HookedDateTime from '../../../components/General/Inputs/HookedDateTime'
import BasisTypeSearch from '../../../components/Search/BasisTypeSearch/BasisTypeSearch'
import styles from './EditIndexContract.module.scss'
import { SelectOption } from '../../../components/General/SelectOptions/SelectOptions'
import HookedTextArea from '../../../components/General/Inputs/HookedTextArea'
import HookedSelectOptions from '../../../components/General/Inputs/HookedSelectOptions'
import HookedCheckbox from '../../../components/General/Inputs/HookedCheckbox'
import FormPage from '../../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../../components/General/Button/Button'
import FormTile from '../../../components/Tiles/FormTile/FormTile'
import Modal from '../../../components/Modal/Modal'
import GallonsGrid from '../../../components/GallonsGrid/GallonsGrid'
import moment from 'moment'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const IndexContractFragment = gql`
   fragment allIndexContract on IndexContract {
       terminalKey
       terminal {
           name
       }
       supplierKey
       supplier {
           name
       }
       basisTypeKey
       basisType {
           basisTypePrices {
               effectiveDate
               price
           }
           name
       }
       effectiveDate
       effectiveEndDate
       accountInfo
       supplierType
       contractPeriod
       isIndication
       minimumLoads
       maximumLoads
       minimumGallons
       maximumGallons
       januaryIndex
       februaryIndex
       marchIndex
       aprilIndex
       mayIndex
       juneIndex
       julyIndex
       augustIndex
       septemberIndex
       octoberIndex
       novemberIndex
       decemberIndex
       dayPrior
   }
`


export const EditIndexContractQuery = gql`
   mutation EditIndexContract($indexContract: IndexContractInput! $id: Int!) {
       indexContractUpdate(id: $id, indexContract: $indexContract) {
           indexContractKey
       }
   }
`

export const GetSingleIndexContractQuery = gql`
   fragment singleIndexContract on IndexContract {
       ...allIndexContract
   }
   query GetSingleIndexContract($id: Int!) {
       indexContractGetSingle(indexContractKey: $id) {
           ...singleIndexContract
       }
   }
`

interface MatchParams extends Params {
    indexContractKey: string
}

interface Props {
}

export default function EditIndexContract(props: Props): ReactElement {
    const navigate = useNavigate()

    const { indexContractKey: key } = useParams() as MatchParams

    const indexContractKey = parseInt(key)
    const [currentlyDeletingIndexContract, setCurrentlyDeleting] = useState(false)

    const { data, loading, error } = useGetSingleIndexContractQuery({ variables: { id: indexContractKey } })
    const { data: contractVolumeData } = useContractVolumeForIndexContractQuery({ variables: { id: indexContractKey } })

    const [editIndexContractMutation] = useEditIndexContractMutation()
    const [deleteMutation] = useDeleteIndexContractMutation()

    const navigateToList = () => {
        navigate("/pricing/index-contracts")
    }

    const submit = (variables: EditIndexContractMutationVariables) => {

        variables.indexContract.contractType = ContractType.DayOf

        editIndexContractMutation({ variables: { ...variables, ...{ id: indexContractKey } } })
            .then(navigateToList)
    }

    const validationSchema = yup.object().shape({
        indexContract: yup.object().shape({
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required(),
            basisTypeKey: yup.number().required(),

            effectiveDate: yup.date().required(),
            effectiveEndDate: yup.date().required().min(yup.ref('effectiveDate'), 'End date must be after start date'),

            accountInfo: yup.string().notRequired(),

            januaryIndex: yup.number().required(),
            februaryIndex: yup.number().required(),
            marchIndex: yup.number().required(),
            aprilIndex: yup.number().required(),
            mayIndex: yup.number().required(),
            juneIndex: yup.number().required(),
            julyIndex: yup.number().required(),
            augustIndex: yup.number().required(),
            septemberIndex: yup.number().required(),
            octoberIndex: yup.number().required(),
            novemberIndex: yup.number().required(),
            decemberIndex: yup.number().required(),

            dayPrior: yup.boolean().required(),

            contractPeriod: yup.string().required(),
            supplierType: yup.string().required(),

            maximumGallons: yup.number().notRequired(),
            maximumLoads: yup.number().notRequired(),

            minimumGallons: yup.number().notRequired(),
            minimumLoads: yup.number().notRequired(),

            isIndication: yup.boolean().required()
        })
    })



    const { register, handleSubmit, errors, setValue } = useForm<EditIndexContractMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)

    if (loading) return <FormPage></FormPage>
    if (error) return <div>Error Loading Record</div>

    const commonProps = { register, errors, setValue }

    const supplierTypes: SelectOption<SupplierType>[] = Object.keys(SupplierType).map(c => {
        return { key: SupplierType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const contractPeriods: SelectOption<ContractPeriod>[] = Object.keys(ContractPeriod).map(c => {
        return { key: ContractPeriod[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const defaultSupplierType = supplierTypes.find(st => st.key === data.indexContractGetSingle.supplierType)
    const defaultContractPeriod = contractPeriods.find(cp => cp.key === data.indexContractGetSingle.contractPeriod)

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Index Contract</Button>)

    return (
        <FormPage title="Update Index Contract" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <div className={styles.wrapper}>
                    <div>
                        <FormTile>
                            <div className="row">
                                <div className="col-md-6">
                                    <TerminalSearch
                                        label="Terminal"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.terminalKey)}
                                        prefilledTerminalKey={data.indexContractGetSingle.terminalKey}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SupplierSearch
                                        label="Supplier"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.supplierKey)}
                                        prefilledSupplierKey={data.indexContractGetSingle.supplierKey}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <BasisTypeSearch
                                        label="Basis Type"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.basisTypeKey)}
                                        prefilledBasisTypeKey={data.indexContractGetSingle.basisTypeKey}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <HookedDateTime
                                        label="Effective Start Date"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.effectiveDate)}
                                        defaultValue={new Date(moment.utc(data.indexContractGetSingle.effectiveDate).format('LL'))}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-4">

                                    <HookedDateTime
                                        label="Effective End Date"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.effectiveEndDate)}
                                        defaultValue={new Date(moment.utc(data.indexContractGetSingle.effectiveEndDate).format('LL'))}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <HookedTextArea
                                        label="Account Info"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.accountInfo)}
                                        defaultValue={data.indexContractGetSingle.accountInfo}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </FormTile>
                        <FormTile>
                            <div className="row">
                                <div className="col-md-12">
                                    <HookedSelectOptions
                                        options={supplierTypes}
                                        label="Supplier Type"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.supplierType)}
                                        setDefaultValue={defaultSupplierType}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <HookedSelectOptions
                                        options={contractPeriods}
                                        label="Period"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.contractPeriod)}
                                        setDefaultValue={defaultContractPeriod}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedCheckbox
                                        label="Indication"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.isIndication)}
                                        defaultValue={data.indexContractGetSingle.isIndication}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedCheckbox
                                        label="Day Prior"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.dayPrior)}
                                        defaultValue={data.indexContractGetSingle.dayPrior}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Minimum Loads"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.minimumLoads)}
                                        defaultValue={data.indexContractGetSingle.minimumLoads.toString()}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Maximum Loads"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.maximumLoads)}
                                        defaultValue={data.indexContractGetSingle.maximumLoads.toString()}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Minimum Gallons"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.minimumGallons)}
                                        defaultValue={data.indexContractGetSingle.minimumGallons.toString()}
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <HookedTextInput
                                        label="Maximum Gallons"
                                        propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.maximumGallons)}
                                        defaultValue={data.indexContractGetSingle.maximumGallons.toString()}
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </FormTile>
                        <FormTile title="Contract Volume">
                            {contractVolumeData && !contractVolumeData.contractVolumeGetByIndexContract && <h5>No Contract Volume Found</h5>}
                            {contractVolumeData && contractVolumeData.contractVolumeGetByIndexContract &&
                                <GallonsGrid
                                    contractVolumeKey={contractVolumeData.contractVolumeGetByIndexContract.contractVolumeKey}
                                    showEstimatedGallons={true} />}
                        </FormTile>
                    </div>

                    <div className={styles.pricingInputs}>
                        <HookedTextInput
                            label="Jan"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.januaryIndex)}
                            defaultValue={data.indexContractGetSingle.januaryIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Feb"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.februaryIndex)}
                            defaultValue={data.indexContractGetSingle.februaryIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Mar"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.marchIndex)}
                            defaultValue={data.indexContractGetSingle.marchIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Apr"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.aprilIndex)}
                            defaultValue={data.indexContractGetSingle.aprilIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="May"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.mayIndex)}
                            defaultValue={data.indexContractGetSingle.mayIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Jun"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.juneIndex)}
                            defaultValue={data.indexContractGetSingle.juneIndex.toString()}
                            {...commonProps}
                        />
                        <HookedTextInput
                            label="Jul"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.julyIndex)}
                            defaultValue={data.indexContractGetSingle.julyIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Aug"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.augustIndex)}
                            defaultValue={data.indexContractGetSingle.augustIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Sep"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.septemberIndex)}
                            defaultValue={data.indexContractGetSingle.septemberIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Oct"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.octoberIndex)}
                            defaultValue={data.indexContractGetSingle.octoberIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Nov"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.novemberIndex)}
                            defaultValue={data.indexContractGetSingle.novemberIndex.toString()}
                            {...commonProps}
                        />

                        <HookedTextInput
                            label="Dec"
                            propertyKey={propName<EditIndexContractMutationVariables>(o => o.indexContract.decemberIndex)}
                            defaultValue={data.indexContractGetSingle.decemberIndex.toString()}
                            {...commonProps}
                        />
                    </div>
                </div>
            </form>

            {currentlyDeletingIndexContract && <Modal headerText="Delete?">
                <p>Once you delete this index contract, it will not be able to be reversed.</p>
                <p>Index Contract: Supplier - {data.indexContractGetSingle.supplier.name}, Terminal - {data.indexContractGetSingle.terminal.name}</p>
                <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(indexContractKey)}>Delete</button>
            </Modal>}
        </FormPage>
    )
}