import React, { Component, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { useAllFlatSalesAgreementQuery, AllFlatSalesAgreementQueryVariables, FlatSalesAgreementFilterInput, InnerFlatSalesAgreementFragment, useDeleteSalesAgreementMutation, SortEnumType, FlatSalesAgreementSortInput } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import Modal from '../../components/Modal/Modal'

import Tile from '../../components/General/Tile/Tile'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import moment from 'moment'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const SalesAgreementFragment = gql`
     fragment innerSalesAgreement on SalesAgreement {
         salesAgreementKey
         agreementName
         supplierKey
         terminalKey
         endShipDate
         supplier {
             name
         }
         terminal {
             name
         }
     }

     fragment innerSalesAgreementListing on SalesAgreementGetAllEdge {
         node {
             ...innerSalesAgreement
         }
     }

     fragment allSalesAgreementListing on SalesAgreementGetAllConnection {
         edges {
             cursor
             ...innerSalesAgreementListing
         }
         totalCount
     }

     fragment innerFlatSalesAgreement on FlatSalesAgreement {
         salesAgreementKey
         agreementName
         supplier
         terminal
         endShipDate
     }

     fragment innerFlatSalesAgreementListing on FlatSalesAgreementGetAllEdge {
         node {
             ...innerFlatSalesAgreement
         }
     }

     fragment allFlatSalesAgreementListing on FlatSalesAgreementGetAllConnection {
         edges {
             cursor
             ...innerFlatSalesAgreementListing
         }
         totalCount
     }
 `

export const AllSalesAgreementQuery = gql`
    query AllSalesAgreement($sortObject: [SalesAgreementSortInput!], $filterObject: SalesAgreementFilterInput, $cursor: String!, $pageSize: Int!) {
        salesAgreementGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) {
            ...allSalesAgreementListing
        }
    }

    query AllFlatSalesAgreement($sort: [FlatSalesAgreementSortInput!], $filter: FlatSalesAgreementFilterInput, $cursor: String!, $pageSize: Int!) {
        flatSalesAgreementGetAll(order: $sort, where: $filter, first: $pageSize, after: $cursor) {
            ...allFlatSalesAgreementListing
        }
    }
`

interface SalesAgreementWrapperProps {
    currentSort: FlatSalesAgreementSortInput
    updateSort(newSort: FlatSalesAgreementSortInput)

    currentFilter: FlatSalesAgreementFilterInput
    updateFilter(newFilter: FlatSalesAgreementFilterInput)

    editSalesAgreement(salesAgreement: InnerFlatSalesAgreementFragment)
    deleteSalesAgreement(salesAgreement: InnerFlatSalesAgreementFragment)
    currentlyDeleteingSalesAgreement?: InnerFlatSalesAgreementFragment
}

const SalesAgreementWrapper = (props: SalesAgreementWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllFlatSalesAgreementQueryVariables = {
        sort: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllFlatSalesAgreementQuery({ variables })

    const [deleteMutation] = useDeleteSalesAgreementMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => props.deleteSalesAgreement(null))
            .then(refetch)
    }

    const cancelDelete = () => {
        props.deleteSalesAgreement(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerFlatSalesAgreementFragment> = {
        columnDefs: [
            {
                headerName: "Name",
                field: "agreementName",
                dataTransform: o => o.agreementName,
                sortable: true,
                searchable: true
            },
            {
                headerName: "Supplier",
                dataTransform: o => o.supplier ? o.supplier : "",
                field: "supplier",
                sortable: true,
                searchable: true
            },
            {
                headerName: "Terminal",
                dataTransform: o => o.terminal ? o.terminal : "",
                field: "terminal",
                sortable: true,
                searchable: true
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.flatSalesAgreementGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editSalesAgreement },
                    { displayName: "Delete", action: props.deleteSalesAgreement }
                ]
            }
        ],
        dataKeyColumn: "salesAgreementKey",
        linkToPath: "/pricing/sales-agreements/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    if (props.currentFilter.endShipDate.lt) {
        gridOptions.columnDefs.push({
            headerName: "End Ship Date", field: "endShipDate", dataTransform: o => moment.utc(o.endShipDate).format('LL')
        })
    }

    return (
        <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.flatSalesAgreementGetAll.totalCount : 0}
            changeCursor={pageChange} >
            <GridBase<InnerFlatSalesAgreementFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeleteingSalesAgreement && <Modal headerText="Delete?">
                <p>Once you delete this sales agreement, it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button> <Button clickAction={() => confirmDelete(props.currentlyDeleteingSalesAgreement.salesAgreementKey)}>Delete</Button>
            </Modal>}
        </PaginationWrapper>
    )
}


const SalesAgreements = () => {
    const navigate = useNavigate()

    return (
        <SalesAgreementsInnerWrapper navigate={navigate} />
    )
}

export default SalesAgreements

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentSort: FlatSalesAgreementSortInput
    currentFilter: FlatSalesAgreementFilterInput
    currentlyDeleteingSalesAgreement?: InnerFlatSalesAgreementFragment
}

class SalesAgreementsInnerWrapper extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: FlatSalesAgreementSortInput = { agreementName: SortEnumType.Desc }

        const today = moment().startOf('day')

        const currentFilter: FlatSalesAgreementFilterInput = {
            endShipDate: { gte: today }
        }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: FlatSalesAgreementSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: FlatSalesAgreementFilterInput) {
        let cf = this.state.currentFilter
        currentFilter.endShipDate = cf.endShipDate
        currentFilter.startShipDate = cf.startShipDate
        this.setState({ currentFilter })
    }

    navigateToNewSalesAgreement() {
        this.props.navigate("/pricing/sales-agreements/new")
    }

    editSalesAgreement(salesAgreement: InnerFlatSalesAgreementFragment) {
        // this.navigate(`/pricing/sales-agreements/edit/${salesAgreement.salesAgreementKey}`)
    }

    deleteSalesAgreement(salesAgreement: InnerFlatSalesAgreementFragment) {
        this.setState({ currentlyDeleteingSalesAgreement: salesAgreement })
    }

    setShowExpired(showExpired: boolean) {
        let currentFilter: FlatSalesAgreementFilterInput = Object.assign({}, this.state.currentFilter)

        const today = moment().startOf('day')

        if (showExpired) {
            currentFilter.endShipDate = { lt: today }
        }
        else {
            currentFilter.endShipDate = { gte: today }
        }

        this.setState({ currentFilter: currentFilter })
    }

    render() {
        return (
            <GeneralPage
                title="Sales Agreements"
                headerContent={
                    <div>
                        <HookedCheckbox
                            label="Show Expired"
                            defaultValue={false}
                            propertyKey={"showExpired"}
                            errors={{}}
                            register={React.createRef()}
                            onChange={(event) => this.setShowExpired(event.target.checked)}
                            inline={true}
                        />
                        <Button clickAction={this.navigateToNewSalesAgreement.bind(this)} buttonType={ButtonType.Primary}>New Sales Agreement</Button>
                    </div>
                }>
                <Tile>
                    <SalesAgreementWrapper
                        currentlyDeleteingSalesAgreement={this.state.currentlyDeleteingSalesAgreement}
                        deleteSalesAgreement={this.deleteSalesAgreement.bind(this)}
                        editSalesAgreement={this.editSalesAgreement.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}