import React, { ReactElement, useEffect, useState } from 'react'
import { DefaultInputProps } from './HookedTextArea'
import SelectOptions, { SelectOption } from '../SelectOptions/SelectOptions'
import InputWrapper from './InputWrapper'

interface Props<T> extends DefaultInputProps<T> {
    setValue(valueName: string, value: any)
    options: SelectOption<T>[]
    setDefaultValue?: SelectOption<T>
    onChange?(option: T)
    disabled?: boolean
}

export default function HookedSelectOptions<T>(props: Props<T>): ReactElement {
    const [hasRegistered, setHasRegistered] = useState(false)
    const [hasSetDefault, setHasSetDefault] = useState(false)

    const { register, propertyKey, setDefaultValue, setValue, options, disabled } = props

    useEffect(() => {
        register({ name: propertyKey, required: true })
        setHasRegistered(true)
        if (setDefaultValue && !hasSetDefault) {
            setValue(propertyKey, setDefaultValue.key)
            setHasSetDefault(true)
        }
    }, [register, propertyKey, setDefaultValue, setValue, hasSetDefault, setHasSetDefault])

    const onChange = (option: T) => {
        if (!hasRegistered) {
            register({ name: propertyKey, required: true })
            setHasRegistered(true)
        }
        setValue(propertyKey, option)
        if (props.onChange) {
            props.onChange(option)
        }
    }

    return (
        <InputWrapper<T> {...props}>
            <SelectOptions options={options} setOption={onChange} setDefaultValue={setDefaultValue} disabled={disabled} />
        </InputWrapper>
    )
}
