import React, { ReactElement } from 'react'
import Modal from '../../Modal/Modal'
import Button, { ButtonType } from '../../General/Button/Button'
import { useCreateTankMutation, CreateTankMutationVariables, CreateTankMutationResult } from '../../../generated/graphql'
import * as yup from 'yup'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import propName from '../../../helpers/propName'

interface Props {
    locationKey: number
    callback(payload: CreateTankMutationResult): Promise<any>
    closeModal()
}

export const CreateTank = gql`
    mutation CreateTank($tank: TankInput!) {
        tankCreate(tank: $tank) {
            tankKey
        }
    }
`

export default function AddTankModal({locationKey, callback, closeModal }: Props): ReactElement {

    const [createTank] = useCreateTankMutation()

    const submit = (variables: CreateTankMutationVariables) => {
        variables.tank.locationKey = locationKey

        createTank({ variables })
            .then(callback)
            .then(closeModal)
    }

    const validationSchema = yup.object().shape({
        tank: yup.object().shape({
            size: yup.number().required()
        })
    })

    const { register, handleSubmit, errors } = useForm<CreateTankMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Submit</Button>

    return (
        <Modal headerText="Add Tank" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <HookedTextInput
                    propertyKey={propName<CreateTankMutationVariables>(t => t.tank.size)}
                    label="Size"
                    {...commonProps}
                />
            </form>
        </Modal>
    )
}