import gql from "graphql-tag"
import * as yup from "yup"
import { GetSingleCarrierQueryVariables, useGetSingleCarrierQuery, EditCarrierMutationVariables, useEditCarrierMutation, useDeleteCarrierMutation, CarrierType } from "../../generated/graphql"
import React, { ReactElement, useState } from "react"
import useForm from "react-hook-form"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import propName from "../../helpers/propName"
import { Params, useNavigate, useParams } from "react-router-dom"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormPage from "../../components/General/FormPage/FormPage"
import FormTile from "../../components/Tiles/FormTile/FormTile"
import HookedSelectOptions from "../../components/General/Inputs/HookedSelectOptions"
import { SelectOption } from "../../components/General/SelectOptions/SelectOptions"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import DeleteEntityModal from "../../components/AppliedModals/DeleteEntity/DeleteEntityModal"

export const EditCarrierQuery = gql`
   mutation EditCarrier($carrier: CarrierInput! $id: Int!){
      carrierUpdate(carrier: $carrier, id: $id) {
         carrierKey
      }
   }
`

export default function EditCarrier(props: Props): ReactElement {
   const navigate = useNavigate()

   const { carrierKey: key } = useParams() as MatchParams

   const carrierKey = parseInt(key)

   const [currentlyDeletingCarrier, setCurrentlyDeleting] = useState(false)
   const [deleteMutation] = useDeleteCarrierMutation()

   const validationSchema = yup.object().shape({
      carrier: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().required().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().required().trim(),
         state: yup.string().required().trim(),
         zipCode: yup.string().required().trim(),
         phone: yup.string().required().trim(),
         fax: yup.string().notRequired().trim(),
         email: yup.string().notRequired().trim(),
         carrierType: yup.string().required()
      })
   })

   const getVariables: GetSingleCarrierQueryVariables = { id: carrierKey }
   const { data: d, loading: l, error: e } = useGetSingleCarrierQuery({ variables: getVariables })

   const [editCarrierMutation] = useEditCarrierMutation()
   const { register, handleSubmit, errors, setValue } = useForm<EditCarrierMutationVariables>({ validationSchema })

   if (l) return <div>Loading</div>
   if (e) return <div>Error Loading Record</div>

   const onSubmit = handleSubmit(
      (variables: EditCarrierMutationVariables) => {
         editCarrierMutation({ variables: { ...variables, ...{ id: carrierKey } } })
            .then(r => navigateToCarrier(carrierKey))
      }
   )

   const carrierTypes: SelectOption<CarrierType>[] = Object.keys(CarrierType).map(c => {
      return { key: CarrierType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
   })

   const defaultCarrierType = carrierTypes.find(ct => ct.key === d.carrierGetSingle.carrierType)


   const navigateToList = () => {
      navigate("/carriers")
   }

   const navigateToCarrier = (carrierKey: number) => {
      navigate(`/carriers/show/${carrierKey}`)
   }

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Carrier</Button>)

   return (
      <FormPage title="Update Carrier" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.name}
                        register={register}
                        label="Name"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.address1}
                        register={register}
                        label="Address 1"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.address2}
                        register={register}
                        label="Address 2"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.city}
                        register={register}
                        label="City"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.city)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.state}
                        register={register}
                        label="State"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.zipCode}
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>

               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.phone}
                        register={register}
                        label="Phone"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.fax}
                        register={register}
                        label="Fax"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.fax)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.carrierGetSingle.email}
                        register={register}
                        label="Email"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.email)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedSelectOptions
                        options={carrierTypes}
                        label="Carrier Type"
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.carrierType)}
                        setDefaultValue={defaultCarrierType}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedCheckbox
                        label="Archived"
                        register={register}
                        propertyKey={propName<EditCarrierMutationVariables>(o => o.carrier.isArchived)}
                        defaultValue={d.carrierGetSingle.isArchived}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingCarrier && <DeleteEntityModal
            cancel={cancelDelete}
            confirm={confirmDelete}
            entityKey={carrierKey}
            entityName="carrier"
            orderFilter={{ carrierKey: { eq: carrierKey } }}
            locationFilter={{ or: [{ primaryCarrierKey: { eq: carrierKey } }, { secondaryCarrierKey: { eq: carrierKey } }] }}
         />}
      </FormPage>
   )
}
interface MatchParams extends Params {
   carrierKey: string
}
interface Props {
}