import React from 'react'
import Tile from '../components/General/Tile/Tile'
import GeneralPage from '../components/General/GeneralPage/GeneralPage'
import TileList from '../components/General/Tile/TileList'
import gql from 'graphql-tag'
import { useGetHomeScreenTasksQuery, DisplayTaskFragment, GetHomeScreenTasksQueryVariables } from '../generated/graphql'
import GridBase, { GridOptions } from '../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../components/General/Button/Button'
import { useNavigate } from 'react-router'
import propName from '../helpers/propName'
import moment from 'moment'

interface Props {
}

export const AllTasksQuery = gql`
    fragment DisplayTask on Task {
        taskKey
        taskDate
        contactKey
        contact {
            firstName
            lastName
            phone
        }
        location {
            name
            customer {
                name
            }
            city
            state
        }
        user {
            email
            name
        }
    }

    query GetHomeScreenTasks($endOfDayDate: DateTime!) {
        taskGetAll(where: { and: [{ isComplete: {eq: false }}, { taskDate: {lt: $endOfDayDate }}]}, order: [{ taskDate: ASC }]) {
            ...DisplayTask
        }
    }
`

interface activityTableProps {
    taskNavigation(taskKey)
}

const ActivityTable = (props: activityTableProps) => {

    const endOfDayDate = moment().startOf("day").add(1, "day")

    var beginningOfDayDate = new Date()
    beginningOfDayDate.setHours(0, 0, 0, 0)

    const variables: GetHomeScreenTasksQueryVariables = { endOfDayDate: moment(endOfDayDate).format('L') }
    const { data, loading, error } = useGetHomeScreenTasksQuery({ variables })

    const gridOptions: GridOptions<DisplayTaskFragment> = {

        columnDefs: [
            // This is DateTime don't use UTC
            { headerName: "Date/Time", dataTransform: entity => moment(entity.taskDate).format("M/D/YY h:mm A") },
            { headerName: "Customer", dataTransform: o => `${o.location.customer.name} - ${o.location.name ? o.location.name : `${o.location.city}, ${o.location.state}`}` },
            { headerName: "Contact Person", dataTransform: entity => `${entity.contact.firstName} ${entity.contact.lastName}` },
            { headerName: "Assigned To", dataTransform: o => o.user.name },
            { headerName: "Phone #", dataTransform: o => o.contact.phone },
        ],

        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.taskGetAll,
        // rowActions: [
        //     { customComponent: updateButton }
        // ],

        cellStyleRules: [{
            // Highlight with red if the task is due before today
            ruleFn: (task: DisplayTaskFragment) => new Date(task.taskDate) < beginningOfDayDate,
            cssProperties: {
                backgroundColor: "rgba(255,0,0,.2)"
            }
        }],

        dataKeyColumn: propName<DisplayTaskFragment>(o => o.taskKey),
        linkToPath: "/tasks/show/:key"
    }

    return <GridBase<DisplayTaskFragment> gridOptions={gridOptions} />
}


const Home = (props: Props) => {
    const navigate = useNavigate()

    const navigateToNewActivity = () => {
        navigate('/tasks/show/0')
    }

    const navigateToScheduleActivity = () => {
        navigate('/tasks/new')
    }

    const navigateToTask = (key) => {
        navigate(`/tasks/show/${key}`)
    }

    return (
        <GeneralPage
            title="Home Page"
        >
            <TileList>
                <Tile isFullWidth={true} title="Today's Planned Activity" multiHeaderButtons={<>
                    <Button clickAction={navigateToScheduleActivity} buttonType={ButtonType.Primary}>Schedule Future Activity</Button>
                    <Button clickAction={navigateToNewActivity} buttonType={ButtonType.Success}>Call Now</Button>
                </>}>
                    <ActivityTable taskNavigation={navigateToTask} />
                </Tile>

                {/* <UpdateBasisTile />
                    <GaugeDisplayTile />
                    <Tile title="Agreement Statuses">
                        <TileButton buttonText="View All" buttonAction={() => { }} buttonType={ButtonType.Secondary}>
                            <p></p>
                        </TileButton>
                    </Tile> */}
            </TileList>

        </GeneralPage>
    )
}

export default Home