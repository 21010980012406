import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { useCreateUserMutation, CreateUserMutationVariables, useGetCurrentUserQuery } from '../../generated/graphql'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import propName from '../../helpers/propName'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'

import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiCarrierSearch from '../../components/Search/CarrierSearch/MultiCarrierSearch'
import { useNavigate } from 'react-router-dom'

export const CreateUserMutation = gql`
    fragment SingleUserFragment on User {
        email
        name
    }

    mutation CreateUser($user: UserInput!, $password: String!, $carriers: [Int!]) {
        userCreate(user: $user, password: $password, carriers: $carriers) {
            ...SingleUserFragment
        }
    }
`

interface Props {

}

export default function UserCreate(props: Props): ReactElement {
    const navigate = useNavigate()

    const [carrierKeys, setCarrierKeys] = useState<number[]>(undefined)

    const { data: userD } = useGetCurrentUserQuery()

    const [showPasswordError, setShowPasswordError] = useState(false)

    const validationSchema = yup.object().shape({
        user: yup.object().shape({
            email: yup.string().required().trim(),
            name: yup.string().required().trim(),
            phoneNumber: yup.string().notRequired().trim(),
            passwordHash: yup.string().required(),
            marketingUser: yup.boolean().required(),
            preferPrefixSearch: yup.boolean().required()
        })
    })

    const { register, handleSubmit, errors } = useForm<CreateUserMutationVariables>({ validationSchema })
    const commonProps = { register, errors }

    const [CreateUser] = useCreateUserMutation()

    const navigateToList = () => {
        navigate('/settings/users')
    }

    const submit = (variables: CreateUserMutationVariables) => {
        const password = variables.user.passwordHash
        if (password !== "") {
            var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")
            const matches = mediumRegex.test(password)
            if (!matches) {
                setShowPasswordError(true)
                return null
            }
        }
        variables.user.userName = variables.user.email
        variables.user.lockoutEnabled = false
        variables.user.passwordHash = undefined
        variables.carriers = carrierKeys

        CreateUser({ variables: { user: { ...variables.user }, password: password, carriers: variables.carriers } })
            .then(navigateToList)
    }

    const onSubmit = handleSubmit(submit)

    const addCarrier = (num: number) => {
        if (carrierKeys === undefined) {
            setCarrierKeys([num])
        }
        else if (!carrierKeys.some(x => x === num)) {
            setCarrierKeys([...carrierKeys, num])
        }
    }

    const removeCarrier = (num: number) => {
        setCarrierKeys([...carrierKeys.filter(x => x !== num)])
    }

    if (!userD) {
        return <div>Loading...</div>
    }
    const currentUserName = userD.currentUser.userName.toLowerCase()
    if (currentUserName !== "admin@bulb.digital" && currentUserName !== "steve" && currentUserName !== "steve@smithgas.com") {
        return <div>You do not have access to user management.</div>
    }

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create User</Button>)

    return (
        <FormPage title="Create User" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.name)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Email"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.email)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Phone Number"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.phoneNumber)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Password"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.passwordHash)}
                                isPassword={true}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiCarrierSearch
                                removeCarrier={removeCarrier}
                                addCarrier={addCarrier}
                                data={carrierKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedCheckbox
                                label="Marketing User"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.marketingUser)}
                                defaultValue={false}
                                {...commonProps}
                            />
                            <HookedCheckbox
                                label="Prefer Prefix Search"
                                propertyKey={propName<CreateUserMutationVariables>(o => o.user.preferPrefixSearch)}
                                defaultValue={false}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            {showPasswordError && <p>Password must contain at least 8 characters, lowercase, uppercase and numbers</p>}
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}