import React, { Component } from 'react'
import gql from 'graphql-tag'
import { FlatIndexContractListingFragment, useGetAllFlatIndexContractsQuery, GetAllFlatIndexContractsQueryVariables, useDeleteIndexContractMutation, FlatIndexContractSortInput, FlatIndexContractFilterInput, SortEnumType } from '../../generated/graphql'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import moment from 'moment'
import propName from '../../helpers/propName'
import Button from '../../components/General/Button/Button'

import ellipsisHorizontal from '../../icons/ellipsis-h.svg'
import Tile from '../../components/General/Tile/Tile'
import Modal from '../../components/Modal/Modal'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const GetAllIndexContracts = gql`
    fragment IndexContractListing on IndexContract {
        indexContractKey

        supplier {
            name
        }

        terminal {
            name
        }

        accountInfo
        effectiveDate
        effectiveEndDate
    }

    fragment FlatIndexContractListing on FlatIndexContract {
        indexContractKey
        supplier
        supplierKey
        terminal
        accountInfo
        effectiveDate
        effectiveEndDate
    }

    query GetAllIndexContracts($sortObject: [IndexContractSortInput!], $filterObject: IndexContractFilterInput) {
        indexContractsGetAll(order: $sortObject, where: $filterObject) {
            ...IndexContractListing
        }
    }

    query GetAllFlatIndexContracts($sort: [FlatIndexContractSortInput!], $filter: FlatIndexContractFilterInput) {
        flatIndexContractGetAll(order: $sort, where: $filter) {
            ...FlatIndexContractListing
        }
    }
`

export const DeleteIndexContractMutation = gql`
   mutation DeleteIndexContract($id: Int!) {
      indexContractDelete(id: $id)
   }
`

interface IndexContractDataWrapperProps {
    editIndexContract(indexContract: FlatIndexContractListingFragment)
    deleteIndexContract(indexContract: FlatIndexContractListingFragment)
    currentlyDeletingIndexContract: FlatIndexContractListingFragment
    currentSort: FlatIndexContractSortInput
    updateSort(sort: FlatIndexContractSortInput)
    currentFilter: FlatIndexContractFilterInput
    updateFilter(filter: FlatIndexContractFilterInput)
}

const IndexContractDataWrapper = (props: IndexContractDataWrapperProps): JSX.Element => {

    const variables: GetAllFlatIndexContractsQueryVariables = {
        sort: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }
    const { data, loading, error, refetch } = useGetAllFlatIndexContractsQuery({ variables })

    const [deleteMutation] = useDeleteIndexContractMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => refetch())
            .then(() => props.deleteIndexContract(null))
    }

    const cancelDelete = () => {
        props.deleteIndexContract(null)
    }

    const gridOptions: GridOptions<FlatIndexContractListingFragment> = {
        columnDefs: [
            { headerName: "Supplier", dataTransform: (entity) => entity.supplier, field: "supplier", sortable: true, searchable: true },
            { headerName: "Terminal", dataTransform: (entity) => entity.terminal, field: "terminal", sortable: true, searchable: true },
            { headerName: "Account Info", dataTransform: (entity) => entity.accountInfo },
            { headerName: "Effective Date", dataTransform: (entity) => moment.utc(entity.effectiveDate).format("L"), field: "effectiveDate", sortable: true, searchable: true, date: true },
            { headerName: "Effective End Date", dataTransform: (entity) => moment.utc(entity.effectiveEndDate).format("L"), field: "effectiveDate", sortable: true, searchable: true, date: true },
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.flatIndexContractGetAll,
        dataKeyColumn: propName<FlatIndexContractListingFragment>(o => o.indexContractKey),
        linkToPath: "/pricing/index-contracts/edit/:key",
        rowActions: [
            {
                icon: ellipsisHorizontal, items: [
                    { displayName: "Edit", action: props.editIndexContract },
                    { displayName: "Delete", action: props.deleteIndexContract }
                ]
            }
        ],
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter,
    }

    return (
        <div>
            <GridBase<FlatIndexContractListingFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingIndexContract && <Modal headerText="Delete?">
                <p>Once you delete this index contract, it will not be able to be reversed.</p>
                <p>Index Contract: Supplier - {props.currentlyDeletingIndexContract.supplier}, Terminal - {props.currentlyDeletingIndexContract.terminal}</p>
                <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(props.currentlyDeletingIndexContract.indexContractKey)}>Delete</button>
            </Modal>}
        </div>
    )
}

const IndexContracts = () => {
    const navigate = useNavigate()

    return (
        <IndexContractsInnerWrapper navigate={navigate} />
    )
}

export default IndexContracts

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentlyDeletingIndexContract?: FlatIndexContractListingFragment
    currentFilter: FlatIndexContractFilterInput
    currentSort: FlatIndexContractSortInput
}

class IndexContractsInnerWrapper extends Component<Props, State>  {
    constructor(props) {
        super(props)
        const today = moment().startOf('day')

        const currentSort: FlatIndexContractSortInput = { effectiveDate: SortEnumType.Desc }
        const currentFilter: FlatIndexContractFilterInput = { effectiveEndDate: { gte: today } }

        this.state = {
            currentSort,
            currentFilter,
        }
    }

    navigateToNewIndexContract() {
        this.props.navigate('/pricing/index-contracts/new')
    }

    editIndexContract(indexContract: FlatIndexContractListingFragment) {
        this.props.navigate(`/pricing/index-contracts/edit/${indexContract.indexContractKey}`)
    }

    deleteAction(indexContract: FlatIndexContractListingFragment) {
        this.setState({ currentlyDeletingIndexContract: indexContract })
    }

    sortClick(currentSort: FlatIndexContractSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: FlatIndexContractFilterInput) {
        currentFilter.effectiveEndDate = this.state.currentFilter.effectiveEndDate
        this.setState({ currentFilter })
    }

    setShowExpired(showExpired: boolean) {
        let currentFilter: FlatIndexContractFilterInput = Object.assign({}, this.state.currentFilter)

        const today = moment().startOf('day')

        if (showExpired) {
            currentFilter.effectiveEndDate = { lt: today }
        }
        else {
            currentFilter.effectiveEndDate = { gte: today }
        }

        this.setState({ currentFilter: currentFilter })
    }

    render() {
        return (
            <GeneralPage title="Index Contracts"
                headerContent={
                    <div>
                        <HookedCheckbox
                            label="Show Expired"
                            defaultValue={false}
                            propertyKey={"showExpired"}
                            errors={{}}
                            register={React.createRef()}
                            onChange={(event) => this.setShowExpired(event.target.checked)}
                            inline={true}
                        />
                        <Button clickAction={this.navigateToNewIndexContract.bind(this)}>New Index Contract</Button>
                    </div>
                }
            >
                <Tile>
                    <IndexContractDataWrapper
                        currentlyDeletingIndexContract={this.state.currentlyDeletingIndexContract}
                        deleteIndexContract={this.deleteAction.bind(this)}
                        editIndexContract={this.editIndexContract.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}