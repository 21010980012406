import React, { Component, useState } from 'react'
import gql from 'graphql-tag'
import { StateTaxFilterInput, InnerStateTaxFragment, AllStateTaxQueryVariables, useAllStateTaxQuery, useDeleteStateTaxMutation, SortEnumType, StateTaxSortInput } from '../../generated/graphql'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import CreateStateTaxModal from '../../components/AppliedModals/StateTaxModal/CreateStateTaxModal'
import UpdateStateTaxModal from '../../components/AppliedModals/StateTaxModal/UpdateStateTaxModal'
import Tile from '../../components/General/Tile/Tile'
import moment from 'moment'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'

export const StateTaxFragment = gql`
    fragment innerStateTax on StateTax {
        stateTaxKey
        startDate
        endDate
        stateKey
        state {
            ...innerState
        }
        amount
        stateTaxType
    }
`
export const AllStateTaxQuery = gql`
    query AllStateTax ($sortObject: [StateTaxSortInput!], $filterObject: StateTaxFilterInput) {
        stateTaxGetAll(order: $sortObject, where: $filterObject) {
            ...innerStateTax
        }
    }
`

export const DeleteStateTaxMutation = gql`
    mutation DeleteStateTax($key: Int!) {
        stateTaxDelete(id: $key)
    }
`


interface StateTaxWrapperProps {
    currentSort: StateTaxSortInput
    updateSort(newSort: StateTaxSortInput)

    currentFilter: StateTaxFilterInput
    updateFilter(newfilter: StateTaxFilterInput)

    deleteStateTax(StateTax: InnerStateTaxFragment)
    currentlyDeletingStateTax: InnerStateTaxFragment

    createStateTax(create: Boolean)
    currentlyCreatingStateTax: Boolean
}

const StateTaxWrapper = (props: StateTaxWrapperProps): JSX.Element => {
    const [updateStateTaxKey, setUpdateStateTaxKey] = useState<number>()
    const variables: AllStateTaxQueryVariables = {
        sortObject: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllStateTaxQuery({ variables })

    const [deleteMutation] = useDeleteStateTaxMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { key: id } })
            .then(() => refetch())
            .then(() => props.deleteStateTax(null))
    }

    const cancelDelete = () => {
        props.deleteStateTax(null)
    }

    const cancelCreate = () => {
        props.createStateTax(null)
    }

    const closeUpdateStateTaxModal = () => {
        setUpdateStateTaxKey(undefined)
    }

    const refetchStateTaxes = () => {
        return refetch(variables)
    }

    const openUpdateStateTaxModal = (line) => {
        setUpdateStateTaxKey(line["stateTaxKey"])
    }

    const gridOptions: GridOptions<InnerStateTaxFragment> = {
        columnDefs: [
            { headerName: "State", dataTransform: o => o.state.name },
            { headerName: "Tax Type", dataTransform: o => o.stateTaxType },
            { headerName: "Start Date", dataTransform: o => moment.utc(o.startDate).format('LL'), field: "startDate", sortable: true },
            { headerName: "End Date", dataTransform: o => o.endDate ? moment.utc(o.endDate).format('LL') : "None", field: "startDate", sortable: true },
            { headerName: "Amount", dataTransform: o => o.amount.toFixed(4) },
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.stateTaxGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Delete", action: props.deleteStateTax }
                ]
            }
        ],
        dataKeyColumn: "patCostKey",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter,
        clickAction: openUpdateStateTaxModal
    }

    return (
        <div>
            <GridBase<InnerStateTaxFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingStateTax && <Modal noScroll headerText="Delete?"
                footerLeftContent={<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmDelete(props.currentlyDeletingStateTax.stateTaxKey)}>Delete</Button>}>
                <p>Once you delete this State Tax it will not be able to be reversed.</p>

            </Modal>}
            {!!props.currentlyCreatingStateTax && <CreateStateTaxModal
                closeModal={cancelCreate} callback={refetchStateTaxes} />}
            {updateStateTaxKey && <UpdateStateTaxModal closeModal={closeUpdateStateTaxModal} delete={props.deleteStateTax} stateTaxKey={updateStateTaxKey} callback={refetchStateTaxes} />}
        </div>
    )
}

interface Props {
}

interface State {
    currentSort: StateTaxSortInput
    currentFilter: StateTaxFilterInput
    currentlyDeletingStateTax?: InnerStateTaxFragment
    currentlyCreatingStateTax?: Boolean
}

export default class StateTaxes extends Component<Props, State> {
    constructor(props) {
        super(props)
        const today = moment().startOf('day')

        const currentSort: StateTaxSortInput = { startDate: SortEnumType.Desc }
        const currentFilter: StateTaxFilterInput = { "or": [{ endDate: { gte: today } }, { endDate: { eq: null } }] }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: StateTaxSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: StateTaxFilterInput) {
        this.setState({ currentFilter })
    }

    deleteStateTax(stateTax: InnerStateTaxFragment) {
        this.setState({ currentlyDeletingStateTax: stateTax })
    }

    createStateTax(create: boolean) {
        this.setState({ currentlyCreatingStateTax: create })
    }

    setShowExpired(showExpired: boolean) {
        let currentFilter: StateTaxFilterInput = Object.assign({}, this.state.currentFilter)

        const today = moment().startOf('day')

        if (showExpired) {
            currentFilter = { endDate: { lt: today } }
        }
        else {
            currentFilter = { "or": [{ endDate: { gte: today } }, { endDate: { eq: null } }] }
        }

        this.setState({ currentFilter: currentFilter })
    }

    render() {
        return (
            <GeneralPage title="State Taxes" headerContent={
                <div>
                    <HookedCheckbox
                        label="Show Expired"
                        defaultValue={false}
                        propertyKey={"showExpired"}
                        errors={{}}
                        register={React.createRef()}
                        onChange={(event) => this.setShowExpired(event.target.checked)}
                        inline={true}
                    />
                    <Button clickAction={() => this.createStateTax(true)}>Create State Tax</Button>
                </div>
            }>
                <Tile>
                    <StateTaxWrapper
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                        currentlyDeletingStateTax={this.state.currentlyDeletingStateTax}
                        deleteStateTax={this.deleteStateTax.bind(this)}
                        currentlyCreatingStateTax={this.state.currentlyCreatingStateTax}
                        createStateTax={this.createStateTax.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}