import React, { useState } from 'react'
import gql from 'graphql-tag'
import Modal from '../../Modal/Modal'
import useForm from 'react-hook-form'
import { CreateStateTaxMutationResult, useCreateStateTaxMutation, CreateStateTaxMutationVariables, StateTaxType } from '../../../generated/graphql'
import propName from '../../../helpers/propName'
import * as yup from 'yup'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import Button, { ButtonType } from '../../General/Button/Button'
import StateSearch from '../../Search/StateSearch/StateSearch'
import HookedSelectOptions from '../../General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../General/SelectOptions/SelectOptions'
import moment from 'moment'

export const CreateStateTaxMutation = gql`
    mutation CreateStateTax($stateTax: StateTaxInput!) {
        stateTaxCreate(stateTax: $stateTax) {
            stateTaxKey
        }
    }
`
interface Props {
    closeModal(): void
    callback(result: CreateStateTaxMutationResult): Promise<any>
}

const CreateStateTaxModal = (props: Props) => {
    const [createStateTaxMutation, { error: createError }] = useCreateStateTaxMutation()
    const today = moment().startOf('day')
    const [startDate, setStartDate] = useState(new Date(today.format("LL")))

    const submit = (variables: CreateStateTaxMutationVariables) => {
        createStateTaxMutation({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const stateTaxTypes: SelectOption<StateTaxType>[] = Object.keys(StateTaxType).map(c => {
        return { key: StateTaxType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const defaultStateTaxType = stateTaxTypes[0]

    const validationSchema = yup.object().shape({
        stateTax: yup.object().shape({
            startDate: yup.date().required(),
            amount: yup.number().required(),
            stateKey: yup.number().required()
        })
    })

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
        }
    }

    const { register, handleSubmit, errors, setValue } = useForm<CreateStateTaxMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
    const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create State Tax</Button>

    return (
        <Modal headerText="Create State Tax" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} noScroll narrow>
            <form onSubmit={onSubmit}>
                {createError && <div className="row">
                    <div className="col-md-12">
                        <h4 style={{ color: "red" }}>{createError.message}</h4>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-md-4">
                        <StateSearch
                            label="State"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.stateKey)}
                            {...commonProps}
                        />
                    </div>

                    <div className="col-md-4">
                        <HookedTextInput
                            label="Amount"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.amount)}
                            defaultValue=".002"
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-4">
                        <HookedSelectOptions
                            options={stateTaxTypes}
                            label="Tax Type"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.stateTaxType)}
                            setDefaultValue={defaultStateTaxType}
                            {...commonProps}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedDateTime
                            label="Start Date"
                            propertyKey={propName<CreateStateTaxMutationVariables>(o => o.stateTax.startDate)}
                            defaultValue={startDate}
                            Change={onStartDateChange}
                            {...commonProps}
                        />
                    </div>


                </div>
            </form>
        </Modal>
    )
}
export default CreateStateTaxModal