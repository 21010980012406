import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { CreateContractVolumeMutationVariables, useCreateContractVolumeMutation, ContractVolumeType, ContractVolumeTerminalInput, CreateContractVolumeTerminalMutationVariables, useCreateContractVolumeTerminalMutation } from '../../generated/graphql'
import propName from '../../helpers/propName'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'

import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import MultiTerminalSearch from '../../components/Search/TerminalSearch/MultiTerminalSearch'
import { useNavigate } from 'react-router-dom'

export const CreateContractVolume = gql`
    mutation CreateContractVolume($contractVolume: ContractVolumeInput!) {
        contractVolumeCreate(contractVolume: $contractVolume) {
            contractVolumeKey
        }
    }
`

export const CreateContractVolumeTerminal = gql`
    mutation CreateContractVolumeTerminal($contractVolumeTerminal: ContractVolumeTerminalInput!) {
        contractVolumeTerminalCreate(contractVolumeTerminal: $contractVolumeTerminal) {
            contractVolumeKey
        }
    }
`

interface Props {

}

export default function CreateContractVolumes(props: Props): ReactElement {
    const navigate = useNavigate()

    const [terminalKeys, setTerminalKeys] = useState<Array<number>>(undefined)

    const [CreateContractVolumeTerminal] = useCreateContractVolumeTerminalMutation()
    const [createContractVolume] = useCreateContractVolumeMutation()

    const navigateToList = () => {
        navigate('/settings/contract-volumes')
    }

    const submit = (variables: CreateContractVolumeMutationVariables) => {

        createContractVolume({ variables })
            .then(_ => {
                if (terminalKeys) {
                    terminalKeys.forEach(key => {
                        const cvtInput: ContractVolumeTerminalInput = { contractVolumeKey: _.data.contractVolumeCreate.contractVolumeKey, terminalKey: key }
                        const cvtVariables: CreateContractVolumeTerminalMutationVariables = { contractVolumeTerminal: cvtInput }
                        CreateContractVolumeTerminal({ variables: cvtVariables })
                    })
                }
            })
            .then(navigateToList)
    }

    const addTerminal = (num: number) => {
        if (terminalKeys === undefined) {
            setTerminalKeys([num])
        }
        else if (!terminalKeys.some(x => x === num)) {
            setTerminalKeys([...terminalKeys, num])
        }
    }

    const removeTerminal = (num: number) => {
        setTerminalKeys(terminalKeys.filter(x => x !== num))
    }

    const validationSchema = yup.object().shape({
        contractVolume: yup.object().shape({
            startDate: yup.date().required(),
            contractVolumeType: yup.string().required(),
            january: yup.number().required(),
            february: yup.number().required(),
            march: yup.number().required(),
            april: yup.number().required(),
            may: yup.number().required(),
            june: yup.number().required(),
            july: yup.number().required(),
            august: yup.number().required(),
            september: yup.number().required(),
            october: yup.number().required(),
            november: yup.number().required(),
            december: yup.number().required(),
            supplierKey: yup.number().required()
        })
    })

    const contractVolumeTypes: SelectOption<ContractVolumeType>[] = Object.keys(ContractVolumeType).map(c => {
        return { key: ContractVolumeType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateContractVolumeMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    const leftFooterContent = (<Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit} >Create Contract Volume</Button>)

    return (
        <FormPage title="Create Contract Volume" footerLeftContent={leftFooterContent} footerRightContent={rightFooterContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiTerminalSearch
                                removeTerminal={removeTerminal}
                                addTerminal={addTerminal}
                                data={terminalKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.supplierKey)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.startDate)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedSelectOptions
                                options={contractVolumeTypes}
                                label="Contract Volume Type"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.contractVolumeType)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.january)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.february)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.march)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.april)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.may)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.june)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.july)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.august)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.september)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.october)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.november)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<CreateContractVolumeMutationVariables>(o => o.contractVolume.december)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}