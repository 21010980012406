import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import SearchDisplay, { InnerSearchLogicProps, SearchDisplayProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'
import { AllCarrierListingFragment, useGetSearchCarrierQuery, useGetSingleCarrierLazyQuery, CarrierSearchSortInput, SortEnumType } from '../../../generated/graphql'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'

interface Props extends DefaultInputProps<AllCarrierListingFragment> {
    onSelect?(carrier: AllCarrierListingFragment)
    prefilledCarrierKey?: number
    clearOnSelect?: boolean
    date?: Date
}

export default function CarrierSearch(props: Props): ReactElement {

    const [executeGetCarrier, { data, loading }] = useGetSingleCarrierLazyQuery({ variables: { id: props.prefilledCarrierKey } })
    const { prefilledCarrierKey } = props

    useEffect(() => {
        if (prefilledCarrierKey) {
            executeGetCarrier()
        }
    }, [prefilledCarrierKey, executeGetCarrier])

    const prefilledData = data && data.carrierGetSingle
    const prefilledDataDisplay = data && `${data.carrierGetSingle.name} ${data.carrierGetSingle.city ? `- ${data.carrierGetSingle.city} - ${data.carrierGetSingle.state}` : ''}`

    return <TextSearchDropdown<AllCarrierListingFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => e.carrierKey}
        searchWrapper={<Inner searchTerm="" date={props.date} onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        clearOnSelect={props.clearOnSelect}

        {...props}
    />
}

export const getCarrier = gql`
    fragment carrierSearchListing on CarrierSearch {
        carrierKey
        name
        address1
        address2
        city
        state
        zipCode
        phone
        fax
        email
        carrierRates {
            endDate
            startDate
            basePercent
            carrierRateBands {
                mileageFrom
                mileageTo
                ratePerGallon
            }
        }
    }

    query GetSearchCarrier($searchTerm: String, $sort: [CarrierSearchSortInput!], $date: DateTime) {
        carrierSearch(search: $searchTerm, order: $sort, date: $date) {
            ...carrierSearchListing
        }
    }
`


const Inner = (props: InnerSearchLogicProps<AllCarrierListingFragment>) => {
    const sort: CarrierSearchSortInput = { name: SortEnumType.Asc }

    const { data, loading, error } = useGetSearchCarrierQuery({ variables: { searchTerm: props.searchTerm, sort, date: props.date } })

    const showData = !loading && !error

    const results = showData && data.carrierSearch.map(carrier => {
        const display = `${carrier.name} ${carrier.city ? `- ${carrier.city} - ${carrier.state}` : ''}`
        return { key: carrier.carrierKey, object: carrier, display }
    })

    const displayProps: SearchDisplayProps<AllCarrierListingFragment> = {
        onSelect: props.onSelect,
        groups: [
            { title: "Carriers", results }
        ],
        searchTerm: props.searchTerm,
        isLoading: loading,
        error
    }

    return <SearchDisplay {...displayProps} />
}