import React, { Component, ReactElement } from 'react'
import Button, { ButtonType } from '../../Button/Button'
import styles from './EmptyState.module.scss'

interface Props {
    text: string
    buttonText?: string
    buttonAction?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    data: any[]
    icon?: ReactElement

    children?: React.ReactNode
}

export default class EmptyState extends Component<Props> {
    state = {}

    render() {
        const hasData = (): boolean => {
            return !!(this.props.data && this.props.data.length)
        }

        return (<>
            {!hasData() && <div className={styles.container}>
                <div className={styles.icon}>
                    {this.props.icon && this.props.icon}
                </div>
                <p>{this.props.text}</p>
                {this.props.buttonAction && <Button buttonType={ButtonType.Primary} clickAction={this.props.buttonAction}>{this.props.buttonText}</Button>}
            </div>}
            {hasData() && this.props.children}
        </>
        )
    }
}
