import React, { Component } from 'react'

import { useDeleteFileNumberMutation, useGetAllFlatFileNumbersQuery, GetAllFlatFileNumbersQueryVariables, FlatFileNumberListingFragment, FlatFileNumberSortInput, FlatFileNumberFilterInput, SortEnumType } from '../../generated/graphql'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import gql from 'graphql-tag'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import Tile from '../../components/General/Tile/Tile'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const FileNumberFragment = gql`
    fragment allFileNumberListing on FileNumber {
        fileNumberKey
        number
        isDefault
        terminal {
            name
        }
        supplier {
            name
        }
        isArchived
    }
`

export const FileNumbersQuery = gql`
    query AllFileNumbers($filter: FileNumberFilterInput, $sort: [FileNumberSortInput!]) {
        fileNumberGetAll(where: $filter, order: $sort) {
            ...allFileNumberListing
        }
    }
`

export const GetAllFileNumbers = gql`
    
    fragment FlatFileNumberListing on FlatFileNumber {
        fileNumberKey
        number
        isDefault
        terminal
        supplier
        isArchived
    }

    query GetAllFlatFileNumbers($sort: [FlatFileNumberSortInput!], $filter: FlatFileNumberFilterInput) {
        flatFileNumberGetAll(order: $sort, where: $filter) {
            ...FlatFileNumberListing
        }
    }
`

interface FileNumbersDataWrapperProps {
    currentSort: FlatFileNumberSortInput[]
    updateSort(newSort: FlatFileNumberSortInput)

    currentFilter: FlatFileNumberFilterInput
    updateFilter(newFilter: FlatFileNumberFilterInput)

    editFileNumber(fileNumber: FlatFileNumberListingFragment)
    deleteFileNumber(fileNumber: FlatFileNumberListingFragment)
    currentlyDeletingFileNumber?: FlatFileNumberListingFragment
}

const FileNumbersDataWrapper = (props: FileNumbersDataWrapperProps): JSX.Element => {

    const variables: GetAllFlatFileNumbersQueryVariables = {
        sort: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filter: props.currentFilter })
    }
    const { data, loading, error, refetch } = useGetAllFlatFileNumbersQuery({ variables })

    const [deleteFileNumber] = useDeleteFileNumberMutation()

    const confirmDelete = (fileNumberKey: number) => {
        deleteFileNumber({ variables: { fileNumberKey } })
            .then(_ => props.deleteFileNumber(null))
            .then(refetch)
    }

    const cancelDelete = () => {
        props.deleteFileNumber(null)
    }

    const deleteModalFooterLeftContent = (<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const deleteModalFooterRightContent = (<Button clickAction={() => confirmDelete(props.currentlyDeletingFileNumber.fileNumberKey)} buttonType={ButtonType.Danger}>Delete File Number</Button>)

    const gridOptions: GridOptions<FlatFileNumberListingFragment> = {
        columnDefs: [
            { headerName: "Supplier", field: "supplier", dataTransform: o => o.supplier, searchable: true, sortable: true },
            { headerName: "Terminal", field: "terminal", dataTransform: o => o.terminal, searchable: true, sortable: true },
            { headerName: "File Number", field: "number", dataTransform: o => o.number, searchable: true, sortable: true },
            { headerName: "Default", field: "markup", dataTransform: o => o.isDefault ? "Yes" : "No" },
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) && !data ? [] : data.flatFileNumberGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editFileNumber },
                    { displayName: "Delete", action: props.deleteFileNumber }
                ]
            }
        ],
        dataKeyColumn: "fileNumberKey",
        linkToPath: "/settings/file-number/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,

        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    return (
        <>
            <GridBase<FlatFileNumberListingFragment> gridOptions={gridOptions} />
            {props.currentlyDeletingFileNumber && <Modal headerText="Delete?" footerLeftContent={deleteModalFooterLeftContent} footerRightContent={deleteModalFooterRightContent}>
                <p>Once you delete this file number, it will not be able to be reversed.</p>
            </Modal>}
        </>
    )
}

const FileNumbers = () => {
    const navigate = useNavigate()

    return (
        <FileNumbersInnerWrapper navigate={navigate} />
    )
}

export default FileNumbers

interface Props {
    navigate: NavigateFunction
}

interface State {
    currentSort: FlatFileNumberSortInput[]
    currentFilter: FlatFileNumberFilterInput
    currentlyDeletingFileNumber?: FlatFileNumberListingFragment
}

class FileNumbersInnerWrapper extends Component<Props, State> {
    constructor(props) {
        super(props)

        // const currentSort: FlatFileNumberSortInput = { number: SortEnumType.Asc }
        const currentSort: FlatFileNumberSortInput[] = [{ supplier: SortEnumType.Asc }, { terminal: SortEnumType.Asc }, { isDefault: SortEnumType.Desc }]
        const currentFilter: FlatFileNumberFilterInput = { isArchived: { eq: false } }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(newSort: FlatFileNumberSortInput) {
        this.setState({ currentSort: [newSort] })
    }

    filterClick(currentFilter: FlatFileNumberFilterInput) {
        currentFilter.isArchived = this.state.currentFilter.isArchived
        this.setState({ currentFilter })
    }

    navigateToNewFileNumber() {
        this.props.navigate('/settings/file-number/new')
    }

    editFileNumber(fileNumber: FlatFileNumberListingFragment) {
        this.props.navigate(`/settings/file-number/edit/${fileNumber.fileNumberKey}`)
    }

    deleteAction(fileNumber: FlatFileNumberListingFragment) {
        this.setState({ currentlyDeletingFileNumber: fileNumber })
    }

    setShowArchived(showArchived: boolean) {
        let newFilter = Object.assign({}, this.state.currentFilter)
        newFilter.isArchived = { eq: showArchived }

        this.setState({ currentFilter: newFilter })
    }

    render() {
        return (
            <GeneralPage title="File Numbers"
                headerContent={
                    <div>
                        <HookedCheckbox
                            label="Show Archived"
                            defaultValue={false}
                            propertyKey={"showArchived"}
                            errors={{}}
                            register={React.createRef()}
                            onChange={(event) => this.setShowArchived(event.target.checked)}
                            inline={true}
                        />
                        <Button clickAction={this.navigateToNewFileNumber.bind(this)}>New File Number</Button>
                    </div>
                }>
                <Tile>
                    <FileNumbersDataWrapper
                        currentlyDeletingFileNumber={this.state.currentlyDeletingFileNumber}
                        deleteFileNumber={this.deleteAction.bind(this)}
                        editFileNumber={this.editFileNumber.bind(this)}
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}