import React, { Component } from 'react'
import gql from 'graphql-tag'
import { useDeletePatCostMutation, useAllPatCostQuery, PatCostFilterInput, InnerPatCostFragment, AllPatCostQueryVariables, PatCostSortInput, SortEnumType } from '../../generated/graphql'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Button, { ButtonType } from '../../components/General/Button/Button'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'

import ellipsish from '../../icons/ellipsis-h.svg'
import Modal from '../../components/Modal/Modal'
import CreatePatCostModal from '../../components/AppliedModals/PatCostModal/CreatePatCostModal'
import Tile from '../../components/General/Tile/Tile'
import moment from 'moment'

export const PatCostFragment = gql`
    fragment innerPatCost on PatCost {
        patCostKey
        effectiveDate
        cost
        state
    }

    query AllPatCost ($sortObject: [PatCostSortInput!], $filterObject: PatCostFilterInput) {
        patCostGetAll(order: $sortObject, where: $filterObject) {
            ...innerPatCost
        }
    }
`

export const DeletePatCostMutation = gql`
    mutation DeletePatCost($id: Int!) {
        patCostDelete(id: $id)
    }
`

export const CreatePatCostMutation = gql`
    mutation CreatePatCost($patCost: PatCostInput!) {
        patCostCreate(patCost: $patCost) {
            patCostKey
        }
    }
`

interface PatCostWrapperProps {
    currentSort: PatCostSortInput
    updateSort(newSort: PatCostSortInput)

    currentFilter: PatCostFilterInput
    updateFilter(newfilter: PatCostFilterInput)

    deletePatCost(patCost: InnerPatCostFragment)
    currentlyDeleteingPatcost: InnerPatCostFragment

    createPatCost(create: Boolean)
    currentlyCreatingPatCost: Boolean
}

const PatCostWrapper = (props: PatCostWrapperProps): JSX.Element => {
    const variables: AllPatCostQueryVariables = {
        sortObject: props.currentSort,
        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllPatCostQuery({ variables })

    const [deleteMutation] = useDeletePatCostMutation()

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => refetch())
            .then(() => props.deletePatCost(null))
    }

    const cancelDelete = () => {
        props.deletePatCost(null)
    }

    const cancelCreate = () => {
        props.createPatCost(null)
    }

    const refetchPatCosts = () => {
        return refetch(variables)
    }

    const gridOptions: GridOptions<InnerPatCostFragment> = {
        columnDefs: [
            { headerName: "Effective Date", dataTransform: o => moment.utc(o.effectiveDate).format('LL'), field: "effectiveDate", sortable: true },
            { headerName: "Cost", dataTransform: o => o.cost.toFixed(4), field: "cost", sortable: true },
            { headerName: "State", dataTransform: o => o.state, field: "state", sortable: true, searchable: true }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.patCostGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Delete", action: props.deletePatCost }
                ]
            }
        ],
        dataKeyColumn: "patCostKey",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    return (
        <div>
            <GridBase<InnerPatCostFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeleteingPatcost && <Modal headerText="Delete?">
                <p>Once you delete this Pat Cost it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button> <Button clickAction={() => confirmDelete(props.currentlyDeleteingPatcost.patCostKey)}>Delete</Button>
            </Modal>}
            {!!props.currentlyCreatingPatCost && <CreatePatCostModal
                closeModal={cancelCreate} callback={refetchPatCosts} />}
        </div>
    )
}

interface Props {
}

interface State {
    currentSort: PatCostSortInput
    currentFilter: PatCostFilterInput
    currentlyDeletingPatCost?: InnerPatCostFragment
    currentlyCreatingPatCost?: Boolean
}

export default class PatCosts extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: PatCostSortInput = { effectiveDate: SortEnumType.Desc }
        const currentFilter: PatCostFilterInput = {}

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: PatCostSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: PatCostFilterInput) {
        this.setState({ currentFilter })
    }

    deletePatCost(patCost: InnerPatCostFragment) {
        this.setState({ currentlyDeletingPatCost: patCost })
    }

    createPatCost(create: boolean) {
        this.setState({ currentlyCreatingPatCost: create })
    }

    render() {
        return (
            <GeneralPage title="Pat Costs" headerContent={
                <Button clickAction={() => this.createPatCost(true)}>Create Pat Cost</Button>
            }>
                <Tile>
                    <PatCostWrapper
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                        currentlyDeleteingPatcost={this.state.currentlyDeletingPatCost}
                        deletePatCost={this.deletePatCost.bind(this)}
                        currentlyCreatingPatCost={this.state.currentlyCreatingPatCost}
                        createPatCost={this.createPatCost.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}