import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'

import { useCreateLocationMutation, CreateLocationMutationVariables, LocationStatus, useGetSingleCustomerQuery, useCreateLocationTagMutation, LocationTagInput } from '../../generated/graphql'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import propName from '../../helpers/propName'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import CarrierSearch from '../../components/Search/CarrierSearch/CarrierSearch'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiTagSearch from '../../components/Search/TagSearch/MultiTagSearch'
import { Params, useNavigate, useParams } from 'react-router-dom'

interface Match extends Params {
    customerKey: string
}

interface Props {
}

export const CreateLocationMutation = gql`
    mutation CreateLocation($location: LocationInput!) {
        locationCreate(location: $location) {
            locationKey
        }
    }

    mutation CreateLocationTag($locationTag: LocationTagInput!) {
        locationTagCreate(locationTag: $locationTag) {
            locationKey
            tagKey
        }
    }

    mutation DeleteLocationTag($locationKey: Int!, $tagKey: Int!) {
        locationTagDelete(locationKey: $locationKey, tagKey: $tagKey )
    }
`

function CreateLocation(props: Props): ReactElement {
    const navigate = useNavigate()

    const { customerKey: key } = useParams() as Match

    const customerKey = parseInt(key)
    const navigateToCustomer = () => navigate(`/customers/show/${customerKey}`)
    const [tagKeys, setTagKeys] = useState<number[]>(undefined)
    const [mailToSame, setMailToSame] = useState(false)
    const [isFob, setIsFob] = useState(false)
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [directions, setDirections] = useState("")

    const [createLocationTag] = useCreateLocationTagMutation()

    const { data: d, loading: l, error: e } = useGetSingleCustomerQuery({ variables: { id: customerKey } })

    const [createLocationMutation] = useCreateLocationMutation()
    const submit = (variables: CreateLocationMutationVariables) => {
        variables.location.isFobCustomer = isFob
        // TODO: Input userKey
        createLocationMutation({ variables: { ...variables, location: { ...variables.location, customerKey, hasPercCharge: false, isAgExempt: false, isInternetCustomer: false, isTaxExempt: false } } })
            .then(_ => {
                if (tagKeys) {
                    tagKeys.forEach(key => {
                        const ltInput: LocationTagInput = { locationKey: _.data.locationCreate.locationKey, tagKey: key }
                        createLocationTag({ variables: { locationTag: ltInput } })
                    })
                }
                navigateToCustomer()
            })
    }

    const validationSchema = yup.object().shape({
        location: yup.object().shape({
            name: yup.string().notRequired().trim(),
            quickBooksName: yup.string().notRequired().trim(),

            address1: yup.string().notRequired().trim(),
            address2: yup.string().notRequired().trim(),

            city: yup.string().required().trim(),
            state: yup.string().required().trim(),
            zipCode: yup.string().notRequired().trim(),

            directions: yup.string().trim(),
            locationNotes: yup.string().trim(),
            lockMarkup: yup.boolean().required(),

            markupCost: yup.number(),

            primaryCarrierKey: yup.number().required(),
            secondaryCarrierKey: yup.number().notRequired(),

            locationStatus: yup.string().required(),
            percentOfSales: yup.number().required(),

            mailToAddress1: yup.string().notRequired().trim(),
            mailToAddress2: yup.string().notRequired().trim(),
            mailToCity: yup.string().notRequired().trim(),
            mailToDirections: yup.string().notRequired().trim(),
            mailToState: yup.string().notRequired().trim(),
            mailToZipCode: yup.string().notRequired().trim(),
            mailToSameAsCustomer: yup.boolean().required(),

            agId: yup.string().notRequired(),
            federalId: yup.string().notRequired(),
            stateId: yup.string().notRequired(),
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateLocationMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)

    const addTag = (tag) => {
        if (tagKeys === undefined) {
            setTagKeys([tag.tagKey])
        }
        else if (!tagKeys.some(x => x === tag.tagKey)) {
            setTagKeys([...tagKeys, tag.tagKey])
        }
    }

    const removeTag = (tag) => {
        setTagKeys([...tagKeys.filter(x => x !== tag)])
    }

    if (l) return (null)
    if (e) return <div>Error Loading Customer</div>

    const locationStatuses: SelectOption<LocationStatus>[] = Object.keys(LocationStatus).map(c => {
        return { key: LocationStatus[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })
    const defaultLocationStatus = locationStatuses[0]

    const percentages = [100, 50, 20]
    const percentOfBusiness: SelectOption<any>[] = Object.values(percentages).map(c => { return { key: c, value: `${c.toString()}%` } })
    const defaultPercentage = percentOfBusiness[0]

    const footerLeftContent = (<Button clickAction={navigateToCustomer} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Location</Button>)


    return (
        <FormPage title="Create Location" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.name)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="QuickBooks Name"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.quickBooksName)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 1"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.address1)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 2"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.address2)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="City"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.city)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.state)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Zipcode"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.zipCode)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <HookedSelectOptions
                                options={locationStatuses}
                                label="Location Status"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.locationStatus)}
                                setDefaultValue={defaultLocationStatus}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedSelectOptions
                                options={percentOfBusiness}
                                label="Percentage Of Sales"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.percentOfSales)}
                                setDefaultValue={defaultPercentage}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Directions"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.directions)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Primary Carrier"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.primaryCarrierKey)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Secondary Carrier"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.secondaryCarrierKey)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedCheckbox
                                label="Mail To Same As Customer Address"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToSameAsCustomer)}
                                register={register}
                                errors={errors}
                                onChange={_ => setMailToSame(_.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Address 1"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToAddress1)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.address1 || '') : address1}
                                onChange={_ => setAddress1(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Address 2"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToAddress2)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.address2 || '') : address2}
                                onChange={_ => setAddress2(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail City"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToCity)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.city || '') : city}
                                onChange={_ => setCity(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail State"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToState)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.state || '') : state}
                                onChange={_ => setState(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail Zip"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToZipCode)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.zipCode || '') : zip}
                                onChange={_ => setZip(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Directions"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.mailToDirections)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.customerGetSingle.directions || '') : directions}
                                onChange={_ => setDirections(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Location Notes"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.locationNotes)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Markup"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.markupCost)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-3">
                            <HookedCheckbox
                                label="Lock Markup"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.lockMarkup)}
                                inline={false}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-3">
                            <HookedCheckbox
                                label="FOB Customer"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.isFobCustomer)}
                                register={register}
                                inline={false}
                                errors={errors}
                                onChange={_ => setIsFob(_.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Ag ID"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.agId)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Federal ID"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.federalId)}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State ID"
                                propertyKey={propName<CreateLocationMutationVariables>(o => o.location.stateId)}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <MultiTagSearch
                                removeTag={removeTag}
                                addTag={addTag}
                                data={tagKeys}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}

export default CreateLocation
