import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import moment from 'moment'
import { EditSwapDealMutationVariables, GetSingleSwapDealQueryVariables, SwapDealSalesAgreementInput, useCreateSwapDealSalesAgreementMutation, useDeleteSwapDealSalesAgreementBySwapDealMutation, useEditSwapDealMutation, useGetSingleSwapDealQuery } from '../../generated/graphql'
import BasisTypeSearch from '../../components/Search/BasisTypeSearch/BasisTypeSearch'
import MultiSalesAgreementSearch from '../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditSwapDealMutation = gql`
    fragment singleSwapDeal on SwapDeal {
        swapDealKey
        basisType{
            basisTypeKey
        }
        swapDealSalesAgreements{
            salesAgreementKey
            salesAgreement{
                agreementName
            }
        }
        dealNumber
        sglCost
        effectiveStartDate
        effectiveEndDate
        monthlyVolume
    }

    query GetSingleSwapDeal($id: Int!) {
      swapDealGetSingle(id: $id) {
         ...singleSwapDeal
      }
   }

   mutation EditSwapDeal($swapDeal: SwapDealInput! $id: Int!) {
      swapDealUpdate(swapDeal: $swapDeal, swapDealKey: $id) {
         swapDealKey
      }
   }
`

export const DeleteSwapDealSalesAgreementBySwapDealMutation = gql`
    mutation DeleteSwapDealSalesAgreementBySwapDeal($id: Int!) {
        swapDealSalesAgreementDeleteBySwapDeal(id: $id)
    }
`

interface MatchParams extends Params {
    swapDealKey: string
}

interface Props {
}


export default function SwapDealEdit(props: Props): ReactElement {
    const navigate = useNavigate()

    const { swapDealKey: key } = useParams() as MatchParams

    const swapDealKey = parseInt(key)
    const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)

    const getVariables: GetSingleSwapDealQueryVariables = { id: swapDealKey }
    const { data, loading, error } = useGetSingleSwapDealQuery({ variables: getVariables })


    const [editSwapDealMutation] = useEditSwapDealMutation()
    const [CreateSwapDealSalesAgreement] = useCreateSwapDealSalesAgreementMutation()
    const [DeleteSwapDealSalesAgreement] = useDeleteSwapDealSalesAgreementBySwapDealMutation()

    const navigateToList = () => {
        navigate('/pricing/swap-deals')
    }

    const addSalesAgreement = (num: number) => {
        if (salesAgreementKeys === undefined) {
            setSalesAgreementKeys([num])
        }
        else if (!salesAgreementKeys.some(x => x === num)) {
            setSalesAgreementKeys([...salesAgreementKeys, num])
        }
    }

    const removeSalesAgreement = (num: number) => {
        setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
    }


    const validationSchema = yup.object().shape({
        swapDeal: yup.object().shape({
            basisTypeKey: yup.number().required(),
            dealNumber: yup.number().required(),
            sglCost: yup.number().required(),
            monthlyVolume: yup.number().required(),
            effectiveStartDate: yup.date().required(),
            effectiveEndDate: yup.date().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<EditSwapDealMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(
        (variables: EditSwapDealMutationVariables) => {
            editSwapDealMutation({ variables: { ...variables, ...{ id: swapDealKey } } })
                .then(r => {
                    DeleteSwapDealSalesAgreement({ variables: { id: swapDealKey } })
                        .then(x => {
                            if (salesAgreementKeys) {
                                salesAgreementKeys.forEach(key => {
                                    const sdInput: SwapDealSalesAgreementInput = { salesAgreementKey: key, swapDealKey: swapDealKey }
                                    CreateSwapDealSalesAgreement({ variables: { sdsa: sdInput } })
                                })
                            }
                        })
                })
                .then(navigateToList)
        })

    if (loading) return <div></div>
    if (error) return <div>Error Loading Record</div>

    if (data.swapDealGetSingle.swapDealSalesAgreements &&
        data.swapDealGetSingle.swapDealSalesAgreements.length > 0 &&
        salesAgreementKeys === undefined) {
        setSalesAgreementKeys(data.swapDealGetSingle.swapDealSalesAgreements.map(x => x.salesAgreementKey))
    }

    const leftFooterContent = (<Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update Swap Deal</Button>)

    return (
        <FormPage title="Create Swap Deal" footerLeftContent={leftFooterContent} footerRightContent={rightFooterContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="Deal #"
                                defaultValue={data.swapDealGetSingle.dealNumber.toString()}
                                propertyKey={propName<EditSwapDealMutationVariables>(t => t.swapDeal.dealNumber)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="SGL Cost"
                                defaultValue={data.swapDealGetSingle.sglCost.toString()}
                                propertyKey={propName<EditSwapDealMutationVariables>(t => t.swapDeal.sglCost)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <HookedTextInput
                                label="Monthly Volume"
                                propertyKey={propName<EditSwapDealMutationVariables>(t => t.swapDeal.monthlyVolume)}
                                defaultValue={data.swapDealGetSingle.monthlyVolume.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<EditSwapDealMutationVariables>(t => t.swapDeal.effectiveStartDate)}
                                defaultValue={new Date(moment.utc(data.swapDealGetSingle.effectiveStartDate).format('LL'))}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<EditSwapDealMutationVariables>(t => t.swapDeal.effectiveEndDate)}
                                defaultValue={new Date(moment.utc(data.swapDealGetSingle.effectiveEndDate).format('LL'))}
                                {...commonProps}
                            />
                        </div>

                        <div className="col-md-6">
                            <BasisTypeSearch
                                label="Basis Type"
                                prefilledBasisTypeKey={data.swapDealGetSingle.basisType.basisTypeKey}
                                propertyKey={propName<EditSwapDealMutationVariables>(o => o.swapDeal.basisTypeKey)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiSalesAgreementSearch
                                removeSalesAgreement={removeSalesAgreement}
                                addSalesAgreement={addSalesAgreement}
                                data={salesAgreementKeys} />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}