import React, { ReactElement } from 'react'
import Tile from '../General/Tile/Tile'
import styles from './GallonsGrid.module.scss';
import { useLifetimeTermContractGallonsQuery } from '../../generated/graphql'
import gql from 'graphql-tag'

export const TermContractGallonsQuery = gql`
    query LifetimeTermContractGallons($termContractKey: Int!) {
        lifetimeTermContractGallons(termContractKey: $termContractKey) {
            year
            jan
            feb
            mar
            apr
            may
            jun
            jul
            aug
            sep
            oct
            nov
            dec
        }
    }
`

interface Props {
    termContractKey: number
}

export default function TermContractGallonsGrid(props: Props): ReactElement {
    const { data, loading, error } = useLifetimeTermContractGallonsQuery({ variables: { termContractKey: props.termContractKey } })

    if (loading || error || data.lifetimeTermContractGallons.length === 0) {
        return <div></div>
    }

    const formatNum = (gallons: number) => {
        return gallons.toLocaleString()
    }

    let rows = []

    data.lifetimeTermContractGallons.forEach((x, index) => {
        const row = (
            <div className="row" key={index}>
                <div className="col">{x.year}</div>
                <div className="col">{formatNum(x.jan)}</div>
                <div className="col">{formatNum(x.feb)}</div>
                <div className="col">{formatNum(x.mar)}</div>
                <div className="col">{formatNum(x.apr)}</div>
                <div className="col">{formatNum(x.may)}</div>
                <div className="col">{formatNum(x.jun)}</div>
                <div className="col">{formatNum(x.jul)}</div>
                <div className="col">{formatNum(x.aug)}</div>
                <div className="col">{formatNum(x.sep)}</div>
                <div className="col">{formatNum(x.oct)}</div>
                <div className="col">{formatNum(x.nov)}</div>
                <div className="col">{formatNum(x.dec)}</div>
            </div>
        )
        rows.push(row)
    })

    return <Tile title="Gallons Purchased">
        <div className={styles.gallonsGrid__smallText}>
            <div className="row" key="Header">
                <div className="col">Year</div>
                <div className="col">Jan</div>
                <div className="col">Feb</div>
                <div className="col">Mar</div>
                <div className="col">Apr</div>
                <div className="col">May</div>
                <div className="col">Jun</div>
                <div className="col">Jul</div>
                <div className="col">Aug</div>
                <div className="col">Sep</div>
                <div className="col">Oct</div>
                <div className="col">Nov</div>
                <div className="col">Dec</div>
            </div>
            {rows}
        </div>
    </Tile>
}