import gql from "graphql-tag"
import useForm from "react-hook-form"
import React, { ReactElement } from "react"
import propName from "../../helpers/propName"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { CreateSupplierMutationVariables, useCreateSupplierMutation } from "../../generated/graphql"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import HookedTextArea from "../../components/General/Inputs/HookedTextArea"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormPage from "../../components/General/FormPage/FormPage"
import FormTile from "../../components/Tiles/FormTile/FormTile"

export const CreateSupplierQuery = gql`
   mutation CreateSupplier($supplier: SupplierInput!) {
      supplierCreate(supplier: $supplier) {
         supplierKey
      }
   }
`
interface Props {
}


export default function CreateSupplier(props: Props): ReactElement {
   const navigate = useNavigate()

   const [createSupplierMutation] = useCreateSupplierMutation()

   const navigateToList = () => {
      navigate("/suppliers")
   }

   const navigateToSupplier = (id: number) => {
      navigate(`/suppliers/show/${id}`)
   }

   const submit = (variables: CreateSupplierMutationVariables) => {
      variables.supplier.isArchived = false
      createSupplierMutation({ variables })
         .then(r => navigateToSupplier(r.data.supplierCreate.supplierKey))
   }

   const validationSchema = yup.object().shape({
      supplier: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().notRequired().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().notRequired().trim(),
         directions: yup.string().notRequired().trim(),
         fax: yup.string().notRequired().trim(),
         phone: yup.string().required().trim(),
         state: yup.string().notRequired().trim(),
         zipCode: yup.string().notRequired().trim(),
         notes: yup.string().notRequired().trim()
      })
   })

   const { register, handleSubmit, errors } = useForm<CreateSupplierMutationVariables>({ validationSchema })

   const onSubmit = handleSubmit(submit)

   const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Supplier</Button>)


   return (
      <FormPage title="Create Supplier" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Name"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 1"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        register={register}
                        label="Address 2"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="City"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.city)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="State"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        register={register}
                        label="Notes"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.notes)}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>

            <FormTile>
               <div className="row">
                  <div className="col-md-6">
                     <HookedTextInput
                        register={register}
                        label="Phone"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedTextInput
                        register={register}
                        label="Fax"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.fax)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        register={register}
                        label="Directions"
                        propertyKey={propName<CreateSupplierMutationVariables>(o => o.supplier.directions)}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>
      </FormPage>
   )
}
