import React, { ReactElement } from 'react'
import SupplierSearch from './SupplierSearch'
import propName from '../../../helpers/propName'
import styles from '../MultiSearch/MultiSearch.module.scss'
import GridBase, { GridOptions } from '../../General/GridBase/GridBase'
import { AllSupplierQueryVariables, useAllSupplierQuery, BasicSupplierFragment } from '../../../generated/graphql'
import * as yup from 'yup'
import ellipsish from '../../../icons/ellipsis-h.svg'
import useForm from 'react-hook-form'

interface Props {
    removeSupplier(supplier: any)
    addSupplier(supplier: any)
    data: any[]
}

export default function MultiSupplierSearch(props: Props): ReactElement {
    const variables: AllSupplierQueryVariables = {
        sortObject: {},
        filterObject: { supplierKey: { in: props.data } }
    }

    const { data, loading, error } = useAllSupplierQuery({ variables })

    const validationSchema = yup.object().shape({
        supplierKey: yup.number().required()
    })

    const removeSupplier = (supplier: any) => {
        props.removeSupplier(supplier.supplierKey)
    }

    const supplierGridOptions: GridOptions<BasicSupplierFragment> = {
        columnDefs: [
            {
                headerName: "Name",
                dataTransform: o => o.name,
                field: "name"
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error) ? [] : data.supplierGetAll,
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeSupplier }
                ]
            }
        ]
    }

    const { register, errors } = useForm<any>({ validationSchema })
    const commonProps = { register, errors }
    const onSupplierSelect = (supplier: BasicSupplierFragment) => {
        if (supplier) {
            props.addSupplier(supplier.supplierKey)
        }
    }

    const header = (
        <SupplierSearch
            label="Supplier"
            propertyKey={propName<any>(o => o.supplierKey)}
            onSelect={onSupplierSelect}
            clearOnSelect={true}
            {...commonProps}
        />
    )

    return (
        <div className={styles.multiSearch__container}>
            <div className="row">
                <div className="col-md-12">
                    {header}
                </div>
            </div>
            <div className={`row ${styles.multiSearch__grid}`}>
                <div className="col-md-12">
                    {props.data && props.data.length > 0 && <GridBase gridOptions={supplierGridOptions} />}
                </div>
            </div>
        </div>
    )
}