import React, { ReactElement, useState } from 'react'

import gql from 'graphql-tag'
import useForm from 'react-hook-form'
import { EditContractVolumeMutationVariables, useEditContractVolumeMutation, ContractVolumeType, useDeleteContractVolumeMutation, useSingleContractVolumeQuery, ContractVolumeTerminalInput, CreateContractVolumeTerminalMutationVariables, useCreateContractVolumeTerminalMutation, useDeleteContractVolumeTerminalMutation } from '../../generated/graphql'
import propName from '../../helpers/propName'
import * as yup from "yup"
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import FormPage from '../../components/General/FormPage/FormPage'
import Modal from '../../components/Modal/Modal'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import moment from 'moment'
import MultiTerminalSearch from '../../components/Search/TerminalSearch/MultiTerminalSearch'
import GallonsGrid from '../../components/GallonsGrid/GallonsGrid'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditContractVolumeMutation = gql`
    mutation EditContractVolume($contractVolume: ContractVolumeInput!, $id: Int!) {
        contractVolumeUpdate(contractVolume: $contractVolume, id: $id) {
            contractVolumeKey
        }
    }
`

export const SingleContractVolumeQuery = gql`
    query SingleContractVolume($id: Int!) {
        contractVolumeGetSingle(id: $id) {
            ...innerContractVolume
        }
    }
`

export const DeleteContractVolumeTerminal = gql`
    mutation DeleteContractVolumeTerminal($id: Int!) {
        contractVolumeTerminalDelete(contractVolumeKey: $id) 
    }
`


interface MatchParams extends Params {
    contractVolumeKey: string
}

interface Props {

}

export default function EditContractVolume(props: Props): ReactElement {
    const navigate = useNavigate()

    const { contractVolumeKey: key } = useParams() as MatchParams

    const contractVolumeKey = parseInt(key)
    const [currentlyDeletingContractVolume, setCurrentlyDeleting] = useState(false)
    const [terminalKeys, setTerminalKeys] = useState<Array<number>>(undefined)

    const [deleteMutation] = useDeleteContractVolumeMutation()
    const { data, loading, error } = useSingleContractVolumeQuery({ variables: { id: contractVolumeKey } })

    const navigateToList = () => {
        navigate('/settings/contract-volumes')
    }

    const confirmDelete = () => {
        deleteMutation({ variables: { id: contractVolumeKey } })
            .then(navigateToList)
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const [CreateContractVolumeTerminal] = useCreateContractVolumeTerminalMutation()
    const [DeleteContractVolumeTerminal] = useDeleteContractVolumeTerminalMutation()
    const [updateContractVolume] = useEditContractVolumeMutation()
    const submit = (variables: EditContractVolumeMutationVariables) => {
        updateContractVolume({ variables: { ...variables, ...{ id: contractVolumeKey } } })
            .then(_ => {
                DeleteContractVolumeTerminal({ variables: { id: contractVolumeKey } })
                    .then(x => {
                        if (terminalKeys) {
                            terminalKeys.forEach(key => {
                                const cvtInput: ContractVolumeTerminalInput = { contractVolumeKey, terminalKey: key }
                                const cvtVariables: CreateContractVolumeTerminalMutationVariables = { contractVolumeTerminal: cvtInput }
                                CreateContractVolumeTerminal({ variables: cvtVariables })
                            })
                        }
                    })
            })
            .then(navigateToList)
    }

    const addTerminal = (num: number) => {
        if (terminalKeys === undefined) {
            setTerminalKeys([num])
        }
        else if (!terminalKeys.some(x => x === num)) {
            setTerminalKeys([...terminalKeys, num])
        }
    }

    const removeTerminal = (num: number) => {
        setTerminalKeys(terminalKeys.filter(x => x !== num))
    }

    const validationSchema = yup.object().shape({
        contractVolume: yup.object().shape({
            startDate: yup.date().required(),
            contractVolumeType: yup.string().required(),
            january: yup.number().required(),
            february: yup.number().required(),
            march: yup.number().required(),
            april: yup.number().required(),
            may: yup.number().required(),
            june: yup.number().required(),
            july: yup.number().required(),
            august: yup.number().required(),
            september: yup.number().required(),
            october: yup.number().required(),
            november: yup.number().required(),
            december: yup.number().required(),
            supplierKey: yup.number().required()
        })
    })

    const contractVolumeTypes: SelectOption<ContractVolumeType>[] = Object.keys(ContractVolumeType).map(c => {
        return { key: ContractVolumeType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })
    const { register, handleSubmit, errors, setValue } = useForm<EditContractVolumeMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    if (loading) {
        return <FormPage></FormPage>
    }
    if (error) {
        return <p>There was an error loading the contract volume</p>
    }
    if (data.contractVolumeGetSingle.contractVolumeTerminals &&
        data.contractVolumeGetSingle.contractVolumeTerminals.length > 0 &&
        terminalKeys === undefined) {
        setTerminalKeys(data.contractVolumeGetSingle.contractVolumeTerminals.map(x => x.terminalKey))
    }

    const defaultContractVolumeType = contractVolumeTypes.find(cv => cv.key === data.contractVolumeGetSingle.contractVolumeType)

    const leftFooterContent = (<Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit} >Update Contract Volume</Button>)

    return (
        <FormPage title="Edit Contract Volume" footerRightContent={rightFooterContent} footerLeftContent={leftFooterContent}>
            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiTerminalSearch
                                removeTerminal={removeTerminal}
                                addTerminal={addTerminal}
                                data={terminalKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.supplierKey)}
                                prefilledSupplierKey={data.contractVolumeGetSingle.supplierKey}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.startDate)}
                                defaultValue={new Date(moment.utc(data.contractVolumeGetSingle.startDate).format('LL'))}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <HookedSelectOptions
                                options={contractVolumeTypes}
                                label="Contract Volume Type"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.contractVolumeType)}
                                setDefaultValue={defaultContractVolumeType}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <FormTile title='Gallons'>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.january)}
                                defaultValue={data.contractVolumeGetSingle.january.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.february)}
                                defaultValue={data.contractVolumeGetSingle.february.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.march)}
                                defaultValue={data.contractVolumeGetSingle.march.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.april)}
                                defaultValue={data.contractVolumeGetSingle.april.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.may)}
                                defaultValue={data.contractVolumeGetSingle.may.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.june)}
                                defaultValue={data.contractVolumeGetSingle.june.toString()}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.july)}
                                defaultValue={data.contractVolumeGetSingle.july.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.august)}
                                defaultValue={data.contractVolumeGetSingle.august.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.september)}
                                defaultValue={data.contractVolumeGetSingle.september.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.october)}
                                defaultValue={data.contractVolumeGetSingle.october.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.november)}
                                defaultValue={data.contractVolumeGetSingle.november.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<EditContractVolumeMutationVariables>(o => o.contractVolume.december)}
                                defaultValue={data.contractVolumeGetSingle.december.toString()}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <GallonsGrid contractVolumeKey={contractVolumeKey} />
                </FormTile>
            </form>
            {currentlyDeletingContractVolume && <Modal headerText="Delete?">
                <p>Once you delete this contract volume, it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Tertiary}>Cancel</Button> <Button clickAction={confirmDelete} buttonType={ButtonType.Primary}>Delete</Button>
            </Modal>}
        </FormPage>
    )
}