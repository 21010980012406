import React from 'react'
import styles from './Tile.module.scss'

interface Props {
    children: React.ReactNode
}

const TileList: React.FC<Props> = ({ children }) => {
    return (
        <div className={styles.Tile__list}>
            {children}
        </div>
    )
}

export default TileList
