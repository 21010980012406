import React from 'react'
import Modal from '../../Modal/Modal'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import propName from '../../../helpers/propName'
import HookedDateTime from '../../General/Inputs/HookedDateTime'
import HookedTextInput from '../../General/Inputs/HookedTextInput'
import gql from 'graphql-tag'
import Button, { ButtonType } from '../../General/Button/Button'
import { useUpdateFuelSurchargeMutation, UpdateFuelSurchargeMutationVariables, useGetSingleFuelSurchargeQuery } from '../../../generated/graphql'
import moment from 'moment'

interface Props {
    closeModal(): void
    callback(): Promise<any>
    fuelSurchargeKey: number
    delete(key): void
}

export const GetSingleFuelSurcharge = gql`
    fragment singleFuelSurcharge on FuelSurcharge {
        fuelSurchargeKey
        carrierKey
        rate
        startDate
        endDate
    }

    query GetSingleFuelSurcharge($key: Int!) {
        fuelSurchargeGetSingle(id: $key) {
            ...singleFuelSurcharge
        }
    }
`

export const updateFuelSurcharge = gql`
    mutation UpdateFuelSurcharge($fuelSurcharge: FuelSurchargeInput!, $fuelSurchargeKey: Int!) {
        fuelSurchargeUpdate(fuelSurcharge: $fuelSurcharge, id: $fuelSurchargeKey) {
            fuelSurchargeKey
        }
    }
`

const UpdateFuelSurchargeModal = (props: Props) => {
    const [updateFuelSurcharge] = useUpdateFuelSurchargeMutation()
    const { data, loading, error } = useGetSingleFuelSurchargeQuery({ variables: { key: props.fuelSurchargeKey } })

    const submit = (variables: UpdateFuelSurchargeMutationVariables) => {
        variables.fuelSurchargeKey = props.fuelSurchargeKey
        variables.fuelSurcharge.carrierKey = data.fuelSurchargeGetSingle.carrierKey

        updateFuelSurcharge({ variables })
            .then(props.callback)
            .then(() => props.closeModal())
    }

    const validationSchema = yup.object().shape({
        fuelSurcharge: yup.object().shape({
            startDate: yup.date().required(),
            endDate: yup.date().required(),
            rate: yup.number().required(),
        })
    })

    const { register, setValue, handleSubmit, errors } = useForm<UpdateFuelSurchargeMutationVariables>({ validationSchema })

    const onSubmit = handleSubmit(submit)
    if (loading || error) {
        return <div></div>
    }

    const footerLeftContent = <><Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button><Button buttonType={ButtonType.Tertiary} clickAction={_ => props.delete(props.fuelSurchargeKey)}>Delete</Button></>
    const footerRightContent = <><Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update Fuel Surcharge</Button></>

    return (
        <Modal headerText="Update Fuel Surcharge" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <HookedDateTime
                            setValue={setValue}
                            register={register}
                            propertyKey={propName<UpdateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.startDate)}
                            defaultValue={new Date(moment.utc(data.fuelSurchargeGetSingle.startDate).format('LL'))}
                            label="Start Date"
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <HookedDateTime
                            setValue={setValue}
                            register={register}
                            propertyKey={propName<UpdateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.endDate)}
                            defaultValue={new Date(moment.utc(data.fuelSurchargeGetSingle.endDate).format('LL'))}
                            label="End Date"
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <HookedTextInput
                            register={register}
                            propertyKey={propName<UpdateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.rate)}
                            defaultValue={data.fuelSurchargeGetSingle.rate.toString()}
                            label="Rate"
                            errors={errors}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    )
}
export default UpdateFuelSurchargeModal