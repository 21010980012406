import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { CreateSalesAgreementMutationVariables, useCreateSalesAgreementMutation, useCreateSalesAgreementLocationMutation, CreateSalesAgreementLocationMutationVariables, SalesAgreementLocationInput, PrePurchaseAgreementSalesAgreementInput, LinkedSalesAgreementInput, useCreateLinkedSalesAgreementMutation, useCreatePrePurchaseAgreementSalesAgreementMutation, TermContractSalesAgreementInput, useCreateTermContractSalesAgreementMutation, useAllStateTaxLazyQuery, BasicLocationFragment, StateTaxType } from '../../generated/graphql'
import useForm from 'react-hook-form'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import propName from '../../helpers/propName'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import MultiLocationSearch from '../../components/Search/LocationSearch/MultiLocationSearch'
import MultiPrePurchaseAgreementSearch from '../../components/Search/PrePurchaseAgreementSearch/MultiPrePurchaseAgreementSearch'
import MultiSalesAgreementSearch from '../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch'
import moment from 'moment'
import MultiTermContractSearch from '../../components/Search/TermContractSearch/MultiTermContractSearch'
import { useNavigate } from 'react-router-dom'

export const CreateSalesAgreementQuery = gql`
    mutation CreateSalesAgreement($salesAgreement: SalesAgreementInput!) {
        salesAgreementCreate(salesAgreement: $salesAgreement) {
            salesAgreementKey
        }
    }
`

export const CreateSalesAgreementLocationQuery = gql`
    mutation CreateSalesAgreementLocation($salesAgreementLocation: SalesAgreementLocationInput!) {
        salesAgreementLocationCreate(salesAgreementLocation: $salesAgreementLocation) {
            salesAgreementKey
            locationKey
        }
    }
`

interface Props {

}

export default function SalesAgreementCreate(props: Props): ReactElement {
    const navigate = useNavigate()

    const [locationKeys, setLocationKeys] = useState<number[]>(undefined)
    const [prePurchaseKeys, setPrePurchaseKeys] = useState<number[]>(undefined)
    const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)
    const [termContractKeys, setTermContractKeys] = useState<number[]>(undefined)

    const [locations, setLocations] = useState<BasicLocationFragment[]>([])

    const [startDate, setStartDate] = useState(new Date(moment().format('LL')))
    const [endDate, setEndDate] = useState(undefined)

    const [executeGetStateTaxes, { data: stateTaxData }] = useAllStateTaxLazyQuery()

    const validationSchema = yup.object().shape({
        salesAgreement: yup.object().shape({
            agreementName: yup.string().required().trim(),
            prePaid: yup.number().required(),
            gallons: yup.number().required(),
            deposit: yup.number().required(),
            assessment: yup.number().required(),
            startShipDate: yup.date().required(),
            endShipDate: yup.date().required(),
            notes: yup.string().trim(),
            gallonsToDate: yup.number().required(),
            loadsToDate: yup.number().required(),
            quoted: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            quotedFOB: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            misc: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            supplierKey: yup.number().notRequired(),
            terminalKey: yup.number().notRequired(),

            aprEst: yup.number().required(),
            mayEst: yup.number().required(),
            junEst: yup.number().required(),
            julEst: yup.number().required(),
            augEst: yup.number().required(),
            sepEst: yup.number().required(),
            octEst: yup.number().required(),
            novEst: yup.number().required(),
            decEst: yup.number().required(),
            janEst: yup.number().required(),
            febEst: yup.number().required(),
            marEst: yup.number().required(),

            aprPI: yup.number().required(),
            mayPI: yup.number().required(),
            junPI: yup.number().required(),
            julPI: yup.number().required(),
            augPI: yup.number().required(),
            sepPI: yup.number().required(),
            octPI: yup.number().required(),
            novPI: yup.number().required(),
            decPI: yup.number().required(),
            janPI: yup.number().required(),
            febPI: yup.number().required(),
            marPI: yup.number().required(),
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateSalesAgreementMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const [CreateSalesAgreement] = useCreateSalesAgreementMutation()
    const [CreateSalesAgreementLocation] = useCreateSalesAgreementLocationMutation()
    const [CreateLinkedSalesAgreements] = useCreateLinkedSalesAgreementMutation()
    const [CreatePrePurchaseAgreementSalesAgreement] = useCreatePrePurchaseAgreementSalesAgreementMutation()
    const [createTermContractSalesAgreement] = useCreateTermContractSalesAgreementMutation()

    const navigateToList = () => {
        navigate('/pricing/sales-agreements/')
    }

    const navigateToSalesAgreement = (id: number) => {
        navigate(`/pricing/sales-agreements/edit/${id}`)
    }

    const submit = (variables: CreateSalesAgreementMutationVariables) => {
        CreateSalesAgreement({ variables })
            .then(r => {
                const salesAgreementKey = r.data.salesAgreementCreate.salesAgreementKey
                if (locationKeys) {
                    locationKeys.forEach(key => {
                        const salInput: SalesAgreementLocationInput = { salesAgreementKey: salesAgreementKey, locationKey: key }
                        const salVariables: CreateSalesAgreementLocationMutationVariables = { salesAgreementLocation: salInput }
                        CreateSalesAgreementLocation({ variables: salVariables })
                    })
                }
                if (salesAgreementKeys) {
                    salesAgreementKeys.forEach(key => {
                        const saInput: LinkedSalesAgreementInput = { relatedFromSalesAgreementKey: key, relatedToSalesAgreementKey: salesAgreementKey }
                        CreateLinkedSalesAgreements({ variables: { linkedSalesAgreement: saInput } })
                    })
                }
                if (prePurchaseKeys) {
                    prePurchaseKeys.forEach(key => {
                        const ppInput: PrePurchaseAgreementSalesAgreementInput = { salesAgreementKey: salesAgreementKey, prePurchaseAgreementKey: key }
                        CreatePrePurchaseAgreementSalesAgreement({ variables: { ppasa: ppInput } })
                    })
                }
                if (termContractKeys) {
                    termContractKeys.forEach(key => {
                        const tcsaInput: TermContractSalesAgreementInput = { salesAgreementKey: salesAgreementKey, termContractKey: key }
                        createTermContractSalesAgreement({ variables: { tcsa: tcsaInput } })
                    })
                }
                navigateToSalesAgreement(r.data.salesAgreementCreate.salesAgreementKey)
            })
    }


    const addLocation = (num: number) => {
        if (locationKeys === undefined) {
            setLocationKeys([num])
        }
        else if (!locationKeys.some(x => x === num)) {
            setLocationKeys([...locationKeys, num])
        }
    }

    const addLocationEntity = (location: BasicLocationFragment) => {
        let newLocations = Object.assign([], locations)
        newLocations.push(location)
        setLocations(newLocations)
    }

    const removeLocation = (num: number) => {
        setLocationKeys([...locationKeys.filter(x => x !== num)])
        setLocations([...locations.filter(x => x.locationKey !== num)])
    }

    const addPrePurchase = (num: number) => {
        if (prePurchaseKeys === undefined) {
            setPrePurchaseKeys([num])
        }
        else if (!prePurchaseKeys.some(x => x === num)) {
            setPrePurchaseKeys([...prePurchaseKeys, num])
        }
    }

    const removePrePurchase = (num: number) => {
        setPrePurchaseKeys(prePurchaseKeys.filter(x => x !== num))
    }

    const addSalesAgreement = (num: number) => {
        if (salesAgreementKeys === undefined) {
            setSalesAgreementKeys([num])
        }
        else if (!salesAgreementKeys.some(x => x === num)) {
            setSalesAgreementKeys([...salesAgreementKeys, num])
        }
    }

    const removeSalesAgreement = (num: number) => {
        setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
    }

    const addTermContract = (num: number) => {
        if (termContractKeys === undefined) {
            setTermContractKeys([num])
        }
        else if (!termContractKeys.some(x => x === num)) {
            setTermContractKeys([...termContractKeys, num])
        }
    }

    const removeTermContract = (num: number) => {
        setTermContractKeys(termContractKeys.filter(x => x !== num))
    }

    const updateStateTaxes = (startDateParam, endDateParam) => {
        if (startDateParam && endDateParam) {
            executeGetStateTaxes({
                variables: {
                    filterObject: {
                        stateTaxType: { eq: StateTaxType.Tax },
                        "or":
                            [
                                //starts before the SA ends and never ends
                                {
                                    startDate: { lte: endDateParam },
                                    endDate: { eq: null }
                                },
                                //check for overlap
                                {
                                    startDate: { lte: endDateParam },
                                    endDate: { gte: startDateParam }
                                }
                            ]
                    },
                    sortObject: {}
                }
            })
        }
    }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
            updateStateTaxes(date, endDate)
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
            updateStateTaxes(startDate, date)
        }
    }

    const onSubmit = handleSubmit(submit)

    const currentStates = locations.map(l => l.state.toLowerCase())
    //since we don't have an easy way to filter states with the query, we just filter afterward. Will likely always be a small amount of data
    const filteredStateTaxes = stateTaxData ? stateTaxData.stateTaxGetAll
        .filter(st => {
            return currentStates.indexOf(st.state.name.toLowerCase()) > -1
        }) : []

    const footerLeftContent = (<Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Create Sales Agreement</Button>)

    return (
        <FormPage title="Create Sales Agreement" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Agreement Name"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.agreementName)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.supplierKey)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <TerminalSearch
                                label="Terminal"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.terminalKey)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiLocationSearch
                                removeLocation={removeLocation}
                                addLocation={addLocation}
                                addLocationEntity={addLocationEntity}
                                data={locationKeys}
                                showCustomer={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiTermContractSearch
                                removeTermContract={removeTermContract}
                                addTermContract={addTermContract}
                                data={termContractKeys} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiPrePurchaseAgreementSearch
                                removePrePurchase={removePrePurchase}
                                addPrePurchase={addPrePurchase}
                                data={prePurchaseKeys} />
                        </div>
                        <div className="col-md-6">
                            <MultiSalesAgreementSearch
                                removeSalesAgreement={removeSalesAgreement}
                                addSalesAgreement={addSalesAgreement}
                                data={salesAgreementKeys} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Details">
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Pre Paid Dollars"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.prePaid)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.gallons)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Deposit $/Gal"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.deposit)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Quoted"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.quoted)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons to Date"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.gallonsToDate)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Loads to Date"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.loadsToDate)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Quoted FOB"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.quotedFOB)}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Assessment"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.assessment)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Misc Cost"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.misc)}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            State Taxes
                            {filteredStateTaxes.length > 0 ?
                                stateTaxData.stateTaxGetAll
                                    .filter(st => {
                                        return currentStates.indexOf(st.state.name.toLowerCase()) > -1
                                    })
                                    .map(st => {
                                        return <div key={st.stateTaxKey}>{moment(st.startDate).format('LL')}: {st.amount.toFixed(4)}</div>
                                    })
                                : <p>No State Taxes</p>
                            }
                        </div>
                        <div className="col-md-8">
                            <HookedTextArea
                                label="Notes"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.notes)}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Dates">
                    <div className="row">
                        <div className="col-md-6">
                            <HookedDateTime
                                label="Start Ship Date"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.startShipDate)}
                                defaultValue={startDate}
                                Change={onStartDateChange}
                                {...commonProps} />
                        </div>
                        <div className="col-md-6">
                            <HookedDateTime
                                label="End Ship Date"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.endShipDate)}
                                Change={onEndDateChange}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Estimated">
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.aprEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.mayEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.junEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.julEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.augEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.sepEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.octEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.novEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.decEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.janEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.febEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.marEst)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
                <FormTile title="Price Increment">
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.aprPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.mayPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.junPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.julPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.augPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.sepPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.octPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.novPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.decPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.janPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.febPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<CreateSalesAgreementMutationVariables>(o => o.salesAgreement.marPI)}
                                defaultValue={"0"}
                                {...commonProps} />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}