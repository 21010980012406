import React, { Component } from 'react'
import styles from './NavBar.module.scss'
import { NavLink } from 'react-router-dom'

import dollar from "../../icons/dollar.svg"
import clipboard from "../../icons/clipboard.svg"
import flame from "../../icons/flame.svg"
import gear from "../../icons/gear.svg"
import person from "../../icons/person.svg"
// import location from "../../icons/location.svg";
import piechart from "../../icons/piechart.svg"
import tanker from "../../icons/tanker.svg"
import logo from '../../icons/logov2.svg'
import tank from '../../icons/terminal-icon.svg'

export interface HamburgerState {
    setMainMenuState(desiredState: boolean)
    setSubMenuState(desiredState: boolean)
    triggerLightHamburger()
    mainMenuState: boolean
    subMenuState: boolean
    lightHamburgerState: boolean
}

interface Props {
    hamburgerState: HamburgerState
}
interface State {
    navItems: NavItem[]
    mobileNavIsOpen: boolean
}

interface NavItem {
    title: string
    icon: string
    url: string
}

class NavBar extends Component<Props, State> {
    state = {
        navItems: [
            { title: "Orders", icon: clipboard, url: "/orders" },
            { title: "Customers", icon: person, url: "/customers" },
            // { title: "Locations", icon: location, url: "/locations" },
            { title: "Carriers", icon: tanker, url: "/carriers" },
            { title: "Suppliers", icon: flame, url: "/suppliers" },
            { title: "Terminals", icon: tank, url: "/terminals" },
            { title: "Reports", icon: piechart, url: "/reports" },
            { title: "Pricing", icon: dollar, url: "/pricing" },
        ],
        mobileNavIsOpen: false
    }

    closeMobileMenu() {
        this.setState({ mobileNavIsOpen: false })
    }

    toggleMobileNav() {
        this.props.hamburgerState.setMainMenuState(!this.props.hamburgerState.mainMenuState)
    }

    openLink() {
        this.props.hamburgerState.setMainMenuState(false)
        this.props.hamburgerState.setSubMenuState(true)
    }

    renderNavItems() {
        return this.state.navItems.map(
            (navItem, i) =>

                <li className={styles.NavBar__listItem} key={`${navItem.title}-${i}`}>
                    <NavLink
                        onClick={this.openLink.bind(this)}
                        className={({ isActive }) => isActive ? `${styles.NavBar__link__active} ${styles.NavBar__link}` : styles.NavBar__link}
                        to={navItem.url}
                    >
                        <img className={styles.NavBar__icon} src={navItem.icon} alt={`${navItem.title} icon`} />
                        {navItem.title}
                    </NavLink>
                </li>

        )
    }


    render() {
        return (
            <div className={styles.NavBar__wrapper}>
                <div className={`${styles.NavBar} ${this.props.hamburgerState.mainMenuState && styles.NavBar__mobileOpen}`}>
                    <ul className={styles.NavBar__list}>

                        <li className={`${styles.NavBar__listItem} ${styles.NavBar__listItem__logo}`}>
                            <NavLink onClick={this.openLink.bind(this)} to="/">
                                <img className={styles.NavBar__logo} src={logo} alt="Logo" />
                            </NavLink>
                        </li>

                        <div className={styles.NavBar__listItemWrapper}>
                            {this.renderNavItems()}
                        </div>

                        <li className={`${styles.NavBar__listItem} ${styles.NavBar__listItem__lower}`}>
                            <NavLink
                                onClick={this.openLink.bind(this)}
                                className={({ isActive }) => isActive ? `${styles.NavBar__link__active} ${styles.NavBar__link}` : styles.NavBar__link}
                                to="/settings"
                            >
                                <img className={styles.NavBar__icon} src={gear} alt="Settings icon" />
                                Settings
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div onClick={this.toggleMobileNav.bind(this)}
                    className={`${styles.NavBar__mobileTrigger} 
                                ${this.props.hamburgerState.mainMenuState && styles.NavBar__mobileTrigger__close} 
                                ${this.props.hamburgerState.lightHamburgerState && styles.NavBar__mobileTrigger__light}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

            </div>
        )
    }
}

export default NavBar
