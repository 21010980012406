import gql from "graphql-tag"
import { GetSinglePrePurchaseAgreementQueryVariables, useGetSinglePrePurchaseAgreementQuery, useEditPrePurchaseAgreementMutation, EditPrePurchaseAgreementMutationVariables, useDeletePrePurchaseAgreementMutation, PrePurchaseAgreementSalesAgreementInput, useCreatePrePurchaseAgreementSalesAgreementMutation, useDeletePrePurchaseAgreementSalesAgreementByPrePurchaseMutation } from "../../generated/graphql"
import useForm from "react-hook-form"
import propName from "../../helpers/propName"
import React, { ReactElement, useState } from "react"
import { Params, useNavigate, useParams } from "react-router-dom"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import HookedTextArea from "../../components/General/Inputs/HookedTextArea"
import * as yup from "yup"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormPage from "../../components/General/FormPage/FormPage"
import Modal from "../../components/Modal/Modal"
import FormTile from "../../components/Tiles/FormTile/FormTile"
import HookedDateTime from "../../components/General/Inputs/HookedDateTime"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import TerminalSearch from "../../components/Search/TerminalSearch/TerminalSearch"
import SupplierSearch from "../../components/Search/SupplierSearch/SupplierSearch"
import GallonsGrid from "../../components/GallonsGrid/GallonsGrid"
import MultiSalesAgreementSearch from "../../components/Search/SalesAgreementSearch/MultiSalesAgreementSearch"
import styles from '../../components/GallonsGrid/GallonsGrid.module.scss'
import moment from 'moment'

export const DeletePrePurchaseAgreementSalesAgreementByPrePurchaseMutation = gql`
    mutation DeletePrePurchaseAgreementSalesAgreementByPrePurchase($id: Int!) {
        prePurchaseAgreementSalesAgreementDeleteByPrePurchase(id: $id)
    }
`

export const EditPrePurchaseAgreementQuery = gql`
   mutation EditPrePurchaseAgreement($prePurchaseAgreement: PrePurchaseAgreementInput! $id: Int!) {
      prePurchaseAgreementUpdate(prePurchaseAgreement: $prePurchaseAgreement, id: $id) {
         prePurchaseAgreementKey
      }
   }
`

export const GetSinglePrePurchaseAgreement = gql`
   fragment singlePrePurchaseAgreement on PrePurchaseAgreement {
      prePurchaseAgreementKey
      supplierAgreement
      prePaidDollars
      gallons
      toDateGallons
      productCost
      assessmentCost
      otherCosts
      units
      freightCost
      miscCost
      costDescription
      startShipDate
      endShipDate
      includeInTermContractVolume
      includeFreight
      chargeTariff
      showOnPricing
      terminal {
         terminalKey
         name
      }
      supplier {
         supplierKey
         name
      }
      prePurchaseAgreementSalesAgreements {
         salesAgreementKey
      }
      description

      aprilEstimate
      mayEstimate
      juneEstimate
      julyEstimate
      augustEstimate
      septemberEstimate
      octoberEstimate
      novemberEstimate
      decemberEstimate
      januaryEstimate
      februaryEstimate
      marchEstimate

      aprilPriceIncrement
      mayPriceIncrement
      junePriceIncrement
      julyPriceIncrement
      augustPriceIncrement
      septemberPriceIncrement
      octoberPriceIncrement
      novemberPriceIncrement
      decemberPriceIncrement
      januaryPriceIncrement
      februaryPriceIncrement
      marchPriceIncrement

      aprilSellBack
      maySellBack
      juneSellBack
      julySellBack
      augustSellBack
      septemberSellBack
      octoberSellBack
      novemberSellBack
      decemberSellBack
      januarySellBack
      februarySellBack
      marchSellBack
   }
   query GetSinglePrePurchaseAgreement($prePurchaseAgreementKey: Int!) {
      prePurchaseAgreementGetSingle(prePurchaseAgreementKey: $prePurchaseAgreementKey) {
         ...singlePrePurchaseAgreement
      }
   }
`

export default function PrePurchaseAgreementEdit(props: Props): ReactElement {
   const navigate = useNavigate()
   const { prePurchaseAgreementKey: key } = useParams() as MatchParams

   const prePurchaseAgreementKey = parseInt(key)
   const getVariables: GetSinglePrePurchaseAgreementQueryVariables = { prePurchaseAgreementKey: prePurchaseAgreementKey }
   const [salesAgreementKeys, setSalesAgreementKeys] = useState<number[]>(undefined)
   const [toDateGallons, setToDateGallons] = useState(0)

   const { data: d, loading: l, error: e } = useGetSinglePrePurchaseAgreementQuery({ variables: getVariables })

   const [currentlyDeletingPrePurchaseAgreement, setCurrentlyDeleting] = useState(false)

   const [editPrePurchaseAgreementMutation] = useEditPrePurchaseAgreementMutation()
   const [deleteMutation] = useDeletePrePurchaseAgreementMutation()

   const validationSchema = yup.object().shape({
      prePurchaseAgreement: yup.object().shape({
         supplierAgreement: yup.string().required().trim(),
         prePaidDollars: yup.number().required(),
         gallons: yup.number().required(),
         toDateGallons: yup.number().required(),
         productCost: yup.number().required(),
         assessmentCost: yup.number().required(),
         otherCosts: yup.number().required(),
         units: yup.number().required(),
         freightCost: yup.number().required(),
         miscCost: yup.number().required(),
         supplierKey: yup.number().required(),
         terminalKey: yup.number().required(),

         startShipDate: yup.date().required(),
         endShipDate: yup.date().required(),

         aprilEstimate: yup.number().required(),
         mayEstimate: yup.number().required(),
         juneEstimate: yup.number().required(),
         julyEstimate: yup.number().required(),
         augustEstimate: yup.number().required(),
         septemberEstimate: yup.number().required(),
         octoberEstimate: yup.number().required(),
         novemberEstimate: yup.number().required(),
         decemberEstimate: yup.number().required(),
         januaryEstimate: yup.number().required(),
         februaryEstimate: yup.number().required(),
         marchEstimate: yup.number().required(),

         aprilPriceIncrement: yup.number().required(),
         mayPriceIncrement: yup.number().required(),
         junePriceIncrement: yup.number().required(),
         julyPriceIncrement: yup.number().required(),
         augustPriceIncrement: yup.number().required(),
         septemberPriceIncrement: yup.number().required(),
         octoberPriceIncrement: yup.number().required(),
         novemberPriceIncrement: yup.number().required(),
         decemberPriceIncrement: yup.number().required(),
         januaryPriceIncrement: yup.number().required(),
         februaryPriceIncrement: yup.number().required(),
         marchPriceIncrement: yup.number().required(),

         aprilSellBack: yup.number().required(),
         maySellBack: yup.number().required(),
         juneSellBack: yup.number().required(),
         julySellBack: yup.number().required(),
         augustSellBack: yup.number().required(),
         septemberSellBack: yup.number().required(),
         octoberSellBack: yup.number().required(),
         novemberSellBack: yup.number().required(),
         decemberSellBack: yup.number().required(),
         januarySellBack: yup.number().required(),
         februarySellBack: yup.number().required(),
         marchSellBack: yup.number().required(),
      })
   })


   const { register, handleSubmit, errors, setValue } = useForm<EditPrePurchaseAgreementMutationVariables>({ validationSchema })

   const commonProps = { register, errors, setValue }

   const [CreatePrePurchaseAgreementSalesAgreement] = useCreatePrePurchaseAgreementSalesAgreementMutation()
   const [DeletePrePurchaseAgreementSalesAgreement] = useDeletePrePurchaseAgreementSalesAgreementByPrePurchaseMutation()

   if (l) return <FormPage></FormPage>
   if (e) return <div>Error Loading Record</div>

   if (d.prePurchaseAgreementGetSingle.prePurchaseAgreementSalesAgreements &&
      d.prePurchaseAgreementGetSingle.prePurchaseAgreementSalesAgreements.length > 0 &&
      salesAgreementKeys === undefined) {
      setSalesAgreementKeys(d.prePurchaseAgreementGetSingle.prePurchaseAgreementSalesAgreements.map(x => x.salesAgreementKey))
   }

   const navigateToList = () => {
      navigate("/pricing/pre-purchase-agreements")
   }

   const submit = (variables: EditPrePurchaseAgreementMutationVariables) => {
      editPrePurchaseAgreementMutation({ variables: { ...variables, ...{ id: prePurchaseAgreementKey } } })
         .then(r => {
            DeletePrePurchaseAgreementSalesAgreement({ variables: { id: prePurchaseAgreementKey } })
               .then(x => {
                  if (salesAgreementKeys) {
                     salesAgreementKeys.forEach(key => {
                        const ppInput: PrePurchaseAgreementSalesAgreementInput = { salesAgreementKey: key, prePurchaseAgreementKey: prePurchaseAgreementKey }
                        CreatePrePurchaseAgreementSalesAgreement({ variables: { ppasa: ppInput } })
                     })
                  }
               })
         })
         .then(navigateToList)
   }

   const onSubmit = handleSubmit(submit)

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const addSalesAgreement = (num: number) => {
      if (salesAgreementKeys === undefined) {
         setSalesAgreementKeys([num])
      }
      else if (!salesAgreementKeys.some(x => x === num)) {
         setSalesAgreementKeys([...salesAgreementKeys, num])
      }
   }

   const removeSalesAgreement = (num: number) => {
      setSalesAgreementKeys(salesAgreementKeys.filter(x => x !== num))
   }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Pre Purchase Agreement</Button>)


   return (
      <FormPage title="Update Pre Purchase Agreement" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        label="Supplier Agreement"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.supplierAgreement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.supplierAgreement}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Pre Paid Dollars"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.prePaidDollars)}
                        defaultValue={d.prePurchaseAgreementGetSingle.prePaidDollars.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Gallons"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.gallons)}
                        defaultValue={d.prePurchaseAgreementGetSingle.gallons.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="To Date Gallons"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.toDateGallons)}
                        value={toDateGallons}
                        disabled
                        {...commonProps}
                     />
                  </div>

               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Product Cost"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.productCost)}
                        defaultValue={d.prePurchaseAgreementGetSingle.productCost.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Perc"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.assessmentCost)}
                        defaultValue={d.prePurchaseAgreementGetSingle.assessmentCost.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Other Costs"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.otherCosts)}
                        defaultValue={d.prePurchaseAgreementGetSingle.otherCosts.toString()}
                        {...commonProps}
                     />
                  </div>

               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Units"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.units)}
                        defaultValue={d.prePurchaseAgreementGetSingle.units.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Freight Cost"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.freightCost)}
                        defaultValue={d.prePurchaseAgreementGetSingle.freightCost.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        label="Misc Cost"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.miscCost)}
                        defaultValue={d.prePurchaseAgreementGetSingle.miscCost.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        label="Cost Description"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.costDescription)}
                        defaultValue={d.prePurchaseAgreementGetSingle.costDescription}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <HookedDateTime
                        label="Start Ship Date"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.startShipDate, 0)}
                        defaultValue={new Date(moment.utc(d.prePurchaseAgreementGetSingle.startShipDate).format('LL'))}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedDateTime
                        label="End Ship Date"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.endShipDate, 0)}
                        defaultValue={new Date(moment.utc(d.prePurchaseAgreementGetSingle.endShipDate).format('LL'))}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Include in Term Contract Vol"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.includeInTermContractVolume)}
                        defaultValue={d.prePurchaseAgreementGetSingle.includeInTermContractVolume}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Include Freight"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.includeFreight)}
                        defaultValue={d.prePurchaseAgreementGetSingle.includeFreight}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Charge Tariff"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.chargeTariff)}
                        defaultValue={d.prePurchaseAgreementGetSingle.chargeTariff}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Show on Pricing"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.showOnPricing)}
                        defaultValue={d.prePurchaseAgreementGetSingle.showOnPricing}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">

                     <TerminalSearch
                        label="Terminal"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.terminalKey)}
                        prefilledTerminalKey={d.prePurchaseAgreementGetSingle.terminal.terminalKey}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">

                     <SupplierSearch
                        label="Supplier"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.supplierKey)}
                        prefilledSupplierKey={d.prePurchaseAgreementGetSingle.supplier.supplierKey}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <MultiSalesAgreementSearch
                        removeSalesAgreement={removeSalesAgreement}
                        addSalesAgreement={addSalesAgreement}
                        data={salesAgreementKeys} />
                  </div>
               </div>
               <div>
                  <div className="col-md-12">
                     <HookedTextArea
                        label="Description"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.description)}
                        defaultValue={d.prePurchaseAgreementGetSingle.description}
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <h6 className={styles.gallonsGrid__title}>Est</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.aprilEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.mayEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.mayEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.juneEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.juneEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julyEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.julyEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.augustEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.septemberEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.octoberEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.novemberEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.decemberEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januaryEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.januaryEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februaryEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.februaryEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchEstimate)}
                        defaultValue={d.prePurchaseAgreementGetSingle.marchEstimate.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
               <GallonsGrid
                  prePurchaseAgreementKey={prePurchaseAgreementKey}
                  toDateGallonsCallback={setToDateGallons} />
               <div className="row">
                  <div className="col-md-12">
                     <h6 className={styles.gallonsGrid__title}>Price Increment</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.aprilPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.mayPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.mayPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.junePriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.junePriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julyPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.julyPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.augustPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.septemberPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.octoberPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.novemberPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.decemberPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januaryPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.januaryPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februaryPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.februaryPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchPriceIncrement)}
                        defaultValue={d.prePurchaseAgreementGetSingle.marchPriceIncrement.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>


               <div className="row">
                  <div className="col-md-12">
                     <h6 className={styles.gallonsGrid__title}>Sell Back</h6>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Apr"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.aprilSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.aprilSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="May"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.maySellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.maySellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jun"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.juneSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.juneSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jul"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.julySellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.julySellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Aug"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.augustSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.augustSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Sept"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.septemberSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.septemberSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Oct"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.octoberSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.octoberSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Nov"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.novemberSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.novemberSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Dec"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.decemberSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.decemberSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Jan"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.januarySellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.januarySellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Feb"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.februarySellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.februarySellBack.toString()}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-2">
                     <HookedTextInput
                        label="Mar"
                        propertyKey={propName<EditPrePurchaseAgreementMutationVariables>(o => o.prePurchaseAgreement.marchSellBack)}
                        defaultValue={d.prePurchaseAgreementGetSingle.marchSellBack.toString()}
                        {...commonProps}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingPrePurchaseAgreement && <Modal headerText="Delete?">
            <p>Once you delete this Pre Purchase Agreement, it will not be able to be reversed.</p>
            <button onClick={cancelDelete}>Cancel</button> <button onClick={() => confirmDelete(prePurchaseAgreementKey)}>Delete</button>
         </Modal>}
      </FormPage>
   )
}

interface MatchParams extends Params {
   prePurchaseAgreementKey: string
}

interface Props {
}