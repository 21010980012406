import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import Button, { ButtonType } from '../../components/General/Button/Button'
import { useAllUsersQuery, UserFilterInput, AllUsersQueryVariables, useDeleteUserMutation, InnerUserFragment, useGetCurrentUserQuery, UserSortInput, SortEnumType } from '../../generated/graphql'
import ellipsish from '../../icons/ellipsis-h.svg'

import Tile from '../../components/General/Tile/Tile'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import Modal from '../../components/Modal/Modal'
import PaginationWrapper from '../../components/General/Pagination/PaginationWrapper'
import { useNavigate } from 'react-router'

export const UserFragment = gql`
    fragment innerUser on User {
        id
        name
        email
        phoneNumber
        marketingUser
        preferPrefixSearch
        userCarriers {
            carrierKey
        }
    }

    fragment innerUserListing on UsersGetAllEdge {
        node {
            ...innerUser
        }
    }

    fragment allUserListing on UsersGetAllConnection {
        edges {
            cursor
            ...innerUserListing
        }
        totalCount
    }

    query AllUsers($sortObject: [UserSortInput!], $filterObject: UserFilterInput, $cursor: String!, $pageSize: Int!) {
        usersGetAll(order: $sortObject, where: $filterObject, first: $pageSize, after: $cursor) { 
            ...allUserListing
        }
    }
`

export const DeleteUserMutation = gql`
    mutation DeleteUser($email: String!) {
        userDelete(email: $email)
    }
`

interface UserWrapperProps {
    currentSort: UserSortInput
    updateSort(newSort: UserSortInput)

    currentFilter: UserFilterInput
    updateFilter(newFilter: UserFilterInput)

    editUser(user: InnerUserFragment)
    deleteUser(user: InnerUserFragment)
    currentlyDeletingUser?: InnerUserFragment
}

const UserWrapper = (props: UserWrapperProps): JSX.Element => {
    const pageSize = 25

    const [cursor, setCursor] = useState("LTE=")

    const { data: userD, loading: userL, error: userE } = useGetCurrentUserQuery()

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: AllUsersQueryVariables = {
        sortObject: props.currentSort,
        cursor: cursor,
        pageSize: pageSize,

        ...(Object.keys(props.currentFilter).length && { filterObject: props.currentFilter })
    }

    const { data, loading, error, refetch } = useAllUsersQuery({ variables })

    const [deleteMutation] = useDeleteUserMutation()

    const confirmDelete = (email: string) => {
        deleteMutation({ variables: { email } })
            .then(() => props.deleteUser(null))
            .then(refetch)
    }

    const cancelDelete = () => {
        props.deleteUser(null)
    }

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const gridOptions: GridOptions<InnerUserFragment> = {
        columnDefs: [
            { headerName: "Name", dataTransform: o => o.name },
            { headerName: "Email", dataTransform: o => o.email }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error || userL || userE) && !data) ? [] : data.usersGetAll.edges.map(x => x.node),
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: props.editUser },
                    { displayName: "Delete", action: props.deleteUser }
                ]
            }
        ],
        dataKeyColumn: "id",
        linkToPath: "/settings/users/edit/:key",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter
    }

    if (!userD) {
        return <div>Loading...</div>
    }
    const currentUserName = userD.currentUser.userName.toLowerCase()
    if (currentUserName !== "admin@bulb.digital" && currentUserName !== "steve" && currentUserName !== "steve@smithgas.com") {
        return <div>You do not have access to user management.</div>
    }

    return (
        <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.usersGetAll.totalCount : 0}
            changeCursor={pageChange}>
            <GridBase<InnerUserFragment> gridOptions={gridOptions} />
            {!!props.currentlyDeletingUser && <Modal headerText="Delete?">
                <p>Once you delete this user, it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button> <Button clickAction={() => confirmDelete(props.currentlyDeletingUser.email)}>Delete</Button>
            </Modal>}
        </PaginationWrapper>
    )
}

interface Props {
}

const Users = (props: Props) => {
    const navigate = useNavigate()

    const [currentSort, setCurrentSort] = useState<UserSortInput>({ name: SortEnumType.Asc })
    const [currentFilter, setCurrentFilter] = useState<UserFilterInput>({})
    const [currentlyDeletingUser, setCurrentlyDeletingUser] = useState<InnerUserFragment>()

    const sortClick = (currentSort: UserSortInput) => {
        setCurrentSort(currentSort)
    }

    const filterClick = (currentFilter: UserFilterInput) => {
        setCurrentFilter(currentFilter)
    }

    const navigateToNewUser = () => {
        navigate("/settings/users/new")
    }

    const editUser = (user: InnerUserFragment) => {
        navigate(`/settings/users/edit/${user.id}`)
    }

    const deleteUser = (user: InnerUserFragment) => {
        setCurrentlyDeletingUser(user)
    }

    return (
        <GeneralPage
            title="Users"
            headerContent={
                <Button clickAction={navigateToNewUser} buttonType={ButtonType.Primary}>New User</Button>
            }>
            <Tile>
                <UserWrapper
                    currentlyDeletingUser={currentlyDeletingUser}
                    deleteUser={deleteUser}
                    editUser={editUser}
                    currentSort={currentSort}
                    updateSort={sortClick}
                    currentFilter={currentFilter}
                    updateFilter={filterClick}
                />
            </Tile>
        </GeneralPage>
    )
}

export default Users