import React, { ReactElement, useState } from 'react'
import { Activity } from 'react-feather'
import { Task, useGetTasksCompletedAfterQuery } from '../../../generated/graphql'
import moment from 'moment'
import Button, { ButtonType } from '../../General/Button/Button'
import TaskTile from '../TaskTile/TaskTile'
import Tile from '../../General/Tile/Tile'
import gql from 'graphql-tag'
import EmptyState from '../../General/Tile/EmptyState/EmptyState'

export const GetTaskQuery = gql`
    fragment TaskShowCompletedAfter on Task {
        contact {
            contactKey
            firstName
            lastName
        }
        location {
            customerKey
            locationKey
            name
        }
        user {
            id
            email
            name
        }
        taskKey
        isComplete
        taskDate
        callDetails
        contactMethod
        completedDate
        locationKey
        
    }

    query GetTasksCompletedAfter($date: DateTime!, $locationKey: Int!, $pageSize: Int!) {
        taskGetCompletedAfterDate(date: $date, where: { locationKey: {eq: $locationKey }}, order: [{ taskDate: DESC}], first: $pageSize) {
            edges {
                cursor
                node {
                    ...TaskShow
                }
            }
            totalCount
        }
    }
`

interface Props {
    locationKey: number
    showDelete?: boolean
    limitHeight?: boolean
}

export default function TaskHistoryTile(props: Props): ReactElement {
    const [showAll, setShowAll] = useState(false)

    const monthAgo = moment().add(-1, 'month').startOf('day')
    const fiveYearsAgo = moment().add(-5, 'year').startOf('day')

    const dateForQuery = showAll ? fiveYearsAgo : monthAgo

    const { data, loading, error, refetch } = useGetTasksCompletedAfterQuery({ variables: { date: dateForQuery, locationKey: props.locationKey, pageSize: showAll ? 1000 : 5 } })

    const showAllTasks = () => {
        setShowAll(true)
    }

    const removeTaskCallback = () => {
        refetch()
    }

    if (loading || error) {
        return <Tile title="Past Activity"></Tile>
    }

    return <Tile title="Activity" scrollable={showAll && props.limitHeight} maxHeight={512}>
        {data.taskGetCompletedAfterDate.edges.map(t => t.node as Task).map(t => {
            return <TaskTile task={t} showDelete={props.showDelete} removeCallback={removeTaskCallback} key={t.taskKey}></TaskTile>
        })}
        {!showAll && <Button buttonType={ButtonType.Secondary} clickAction={showAllTasks}>Show All</Button>}
        {data && <EmptyState text="There is no past activity" icon={<Activity size="48" />}
            data={data.taskGetCompletedAfterDate.edges.map(x => x.node)}></EmptyState>}
    </ Tile>
}
