import React, { Component, useState } from 'react'
import gql from 'graphql-tag'
import { useGetPricingQuery, GetPricingQueryVariables, PricingListingFragment, PricingSortInput, PricingType, OrderType } from '../../generated/graphql'

import Tile from '../General/Tile/Tile'
import GridBase, { GridOptions, GridSize } from '../General/GridBase/GridBase'
import moment from 'moment'
import HookedDateTime from '../General/Inputs/HookedDateTime'
import Button, { ButtonType } from '../General/Button/Button'

export const getPricing = gql`
    fragment pricingListing on Pricing {
        pricingType
        effectiveDate
        supplierName
        supplierKey
        terminalName
        terminalKey
        product
        markup
        patCost
        perc
        stateTax
        stateAssessment
        fob
        mileage
        primaryCarrier
        secondaryCarrier
        primaryCarrierRate
        secondaryCarrierRate
        primaryCarrierDelivered
        secondaryCarrierDelivered
        primaryCarrierKey
        secondaryCarrierKey
        locationKey
        queryString
        contractVolumeExhausted
        pricingName
        termContractKey
        hasErrors
        pricingKey
    }

    query GetPricing($locationKey: Int!, $sort: [PricingSortInput!], $pricingDate: DateTime!) {
        pricingsGet(locationKey: $locationKey, order: $sort, pricingDate: $pricingDate) {
            ...pricingListing
        }
    }
`

export const getLocationSingle = gql`
    fragment LocationCustomerSupplier on Location {
        locationTerminals {
            terminalKey
            mileage
            terminal {
                name
                indexContracts {
                    indexContractKey
                    supplier {
                        name
                    }
                    basisType {
                        basisTypeKey
                        name
                        # basisTypePrices {
                        #     price
                        #     effectiveDate
                        # }
                    }
                    effectiveDate
                    januaryIndex
                    februaryIndex
                    marchIndex
                    aprilIndex
                    mayIndex
                    juneIndex
                    julyIndex
                    augustIndex
                    septemberIndex
                    octoberIndex
                    novemberIndex
                    decemberIndex
                }
                prePurchaseAgreements {
                    prePurchaseAgreementKey
                    supplierAgreement
                    supplier {
                        name
                    }
                    toDateGallons
                    gallons
                    productCost
                    assessmentCost
                    freightCost
                    miscCost
                    endShipDate
                    startShipDate
                }
            }
        }
        locationKey
        primaryCarrierKey
        primaryCarrier {
            name
            carrierRates {
                startDate
                endDate
                basePercent
                carrierRateBands {
                    mileageFrom
                    mileageTo
                    ratePerGallon
                }
            }
        }
        secondaryCarrierKey
        secondaryCarrier {
            name
            carrierRates {
                startDate
                endDate
                basePercent
                carrierRateBands {
                    mileageFrom
                    mileageTo
                    ratePerGallon
                }
            }
        }
        markupCost
        city
        state
        customer {
            customerKey
            name
            deliveryTimeKey
        }
        isFobCustomer
    }

    query GetLocationSingle($locationKey: Int!) {
        locationGetSingle(locationKey: $locationKey) {
            ...LocationCustomerSupplier
        }
    }
`

export const TerminalPosting = gql`
    fragment InnerTerminalPosting on Posting {
        postingKey
        terminalKey
        supplierKey
        terminal {
            name
        }
        supplier {
            name
        }
        cost
        effectiveDate
    }

    query GetPostingsByLocation($locationKey: Int!) {
        postingsByLocationKey(locationKey: $locationKey) {
            ...InnerTerminalPosting
        }
    }
`

interface PricingGridProps {
    locationKey: number

    sortPricing(sort: PricingSortInput)
    newSort: PricingSortInput

    click(key)
    salesAgreementSelected: Boolean
    clearSalesAgreement()

    defaultDate?: Date
    orderType?: OrderType
    currentPricingKey?: number
    postingKey?: number
    indexContractKey?: number
    prePurchaseAgreementKey?: number
}

const PricingGridWrapper = (props: PricingGridProps): JSX.Element => {
    const [pricingDate, setPricingDate] = useState(props.defaultDate ? props.defaultDate : new Date(moment().add(1, 'day').format('LL')))

    const variables: GetPricingQueryVariables = {
        locationKey: props.locationKey,
        sort: props.newSort,
        pricingDate: pricingDate
    }

    const { data, loading, error } = useGetPricingQuery({ variables })

    const dateChanged = (name, val) => {
        if (val !== pricingDate) {
            setPricingDate(val)
        }
    }

    if (error) {
        return <p>There was an issue fetching Pricing.  Please contact an administrator.</p>
    }

    const darken: React.CSSProperties = { filter: "saturate(200%) brightness(0.95)", fontWeight: 700 }
    const alignRight: React.CSSProperties = { textAlign: "right" }
    const justifyFlexEnd: React.CSSProperties = { justifyContent: "flex-end" }

    const sortPrimary: boolean = variables.sort ? (!Array.isArray(variables.sort) ? variables.sort.primaryCarrierDelivered ? true : false : false) : false

    const gridOptions: GridOptions<PricingListingFragment> = {
        columnDefs: [
            {
                headerName: "Type",
                dataTransform: o => `${o.pricingType}${o.pricingName ? ` - ${o.pricingName}` : ''}`
            },
            {
                headerName: "Eff Date",
                dataTransform: o => moment.utc(o.effectiveDate).format('MM/DD/YY')
            },
            {
                headerName: "Supplier",
                dataTransform: o => o.supplierName
            },
            {
                headerName: "Terminal",
                dataTransform: o => o.terminalName
            },
            {
                headerName: "Product",
                dataTransform: o => o.product.toFixed(4),
                styles: alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Markup",
                dataTransform: o => (o.markup + o.patCost + o.stateTax).toFixed(4),
                styles: alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "FOB",
                dataTransform: o => o.fob.toFixed(4),
                styles: alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Mileage",
                dataTransform: o => o.mileage.toString(),
                styles: alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Sec Rate",
                dataTransform: o => (o.secondaryCarrierRate && o.secondaryCarrierRate !== 0) ? o.secondaryCarrierRate.toFixed(4) : "-",
                styles: !sortPrimary ? { ...darken, ...alignRight } : alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Pri Rate",
                dataTransform: o => (o.primaryCarrierRate && o.primaryCarrierRate !== 0) ? o.primaryCarrierRate.toFixed(4) : "-",
                styles: sortPrimary ? { ...darken, ...alignRight } : alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Sec Del",
                dataTransform: o => (o.secondaryCarrierRate && o.secondaryCarrierRate !== 0) ? o.secondaryCarrierDelivered.toFixed(4) : "-",
                field: "secondaryCarrierDelivered",
                sortable: true,
                styles: !sortPrimary ? { ...darken, ...alignRight } : alignRight,
                headerStyles: justifyFlexEnd
            },
            {
                headerName: "Pri Del",
                dataTransform: o => (o.primaryCarrierRate && o.primaryCarrierRate !== 0) ? o.primaryCarrierDelivered.toFixed(4) : "-",
                field: "primaryCarrierDelivered",
                sortable: true,
                styles: sortPrimary ? { ...darken, ...alignRight } : alignRight,
                headerStyles: justifyFlexEnd
            }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: (loading || error || !data || (data && !data.pricingsGet)) ? [] : data.pricingsGet,
        sortAction: props.sortPricing,
        currentSort: props.newSort,
        dataKeyColumn: "queryString",
        clickAction: props.click,
        responsiveLarge: true,
        monospace: true,
        cellStyleRules: [{
            // Highlight with green for pre purchase agreements
            ruleFn: (row: PricingListingFragment) => row.pricingType === PricingType.Ppa,
            cssProperties: {
                backgroundColor: "#D1EEE7",
                color: "#0E3E33",
                borderBottom: "1px solid #B1E2D6"
            }
        },
        {
            // Highlight with blue for postings
            ruleFn: (row: PricingListingFragment) => row.pricingType === PricingType.P,
            cssProperties: {
                backgroundColor: "#DDE5F2",
                color: "#21365A",
                borderBottom: "1px solid #B2C4E1"
            }
        },
        {
            // Highlight with purple for sales agreements
            ruleFn: (row: PricingListingFragment) => row.pricingType === PricingType.Sa,
            cssProperties: {
                backgroundColor: "#ECE2F2",
                color: "#422654",
                borderBottom: "1px solid #CFB5DE"
            }
        },
        {
            // Highlight with light gray for index contracts
            ruleFn: (row: PricingListingFragment) => row.pricingType === PricingType.Ic,
            cssProperties: {
                backgroundColor: "#fafafa",
                color: "#333",
                borderBottom: "1px solid #efefef"
            }
        },
        {
            // Gray out row if the contract volume is exhausted
            ruleFn: (row: PricingListingFragment) => row.contractVolumeExhausted === true,
            cssProperties: {
                backgroundColor: "#E5E5E5",
                color: "#5F6163"
            }
        },
        // {
        //     // Highlight red if there may be some invalid freight costs
        //     ruleFn: (row: PricingListingFragment) => row.hasErrors === true,
        //     cssProperties: {
        //         backgroundColor: "#F4DFDF",
        //         color: "#880707",
        //         borderBottom: "1px solid #F4DFDF"
        //     }
        // },
        {
            ruleFn: (row: PricingListingFragment) => {
                //check to see if the order's pricing type matches the row's pricing type
                if ((row.pricingType === PricingType.Ic && props.orderType === OrderType.IndexContract)
                    || (row.pricingType === PricingType.P && props.orderType === OrderType.Posting)
                    || (row.pricingType === PricingType.Ppa && props.orderType === OrderType.PrePurchaseAgreement)) {

                    return row.pricingKey === props.currentPricingKey
                }
                // if the order is of type sales agreement, we want to highlight the row that was selected to be related to, not the sales agreement. Kind of confusing, but they pick two rows in the instance they pick a sales agreement
                else if (props.orderType === OrderType.SalesAgreement) {
                    //if the order we have has an index contract key, let's see if it matches this row
                    if (props.indexContractKey && row.pricingType === PricingType.Ic) {
                        return props.indexContractKey === row.pricingKey
                    }
                    //if the order we have has an posting key, let's see if it matches this row
                    else if (props.postingKey && row.pricingType === PricingType.P) {
                        return props.postingKey === row.pricingKey
                    }
                    //if the order we have has an pre purchase key, let's see if it matches this row
                    else if (props.prePurchaseAgreementKey && row.pricingType === PricingType.Ppa) {
                        return props.prePurchaseAgreementKey === row.pricingKey
                    }
                }

                return false
            },
            cssProperties: {
                borderBottom: "3px solid black",
                borderTop: "3px solid black"
            }
        }
        ],
        size: GridSize.small
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <HookedDateTime
                        label="Pricing Date"
                        propertyKey="PricingDate"
                        defaultValue={pricingDate}
                        register={React.createRef}
                        errors={{}}
                        setValue={dateChanged}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    {data && data.pricingsGet && data.pricingsGet.length > 0 ? <h5><strong>Assess: </strong>{data.pricingsGet[0].perc + data.pricingsGet[0].stateAssessment + data.pricingsGet[0].stateTax}</h5> : null}
                </div>
                <div className="col-md-5">
                    {data && data.pricingsGet && data.pricingsGet.length > 0 ? <h5><strong>Primary: </strong>{data.pricingsGet[0].primaryCarrier}</h5> : null}
                </div>
                <div className="col-md-5">
                    {data && data.pricingsGet && data.pricingsGet.length > 0 ? <h5><strong>Secondary: </strong>{data.pricingsGet[0].secondaryCarrier}</h5> : null}
                </div>
            </div>
            {props.salesAgreementSelected && <div className="row">
                <div className="col-md-12">
                    Sales Agreement Selected <Button clickAction={props.clearSalesAgreement} buttonType={ButtonType.Primary}>Clear</Button>
                </div>
            </div>}
            <GridBase<PricingListingFragment> gridOptions={gridOptions} />
        </div>
    )
}


interface Props {
    locationKey: number

    sortPricing(sort: PricingSortInput)
    newSort: PricingSortInput

    click(key)
    salesAgreementSelected: Boolean
    clearSalesAgreement()

    defaultDate?: Date

    orderType?: OrderType
    currentPricingKey?: number

    postingKey?: number
    indexContractKey?: number
    prePurchaseAgreementKey?: number
}


export default class PricingGrid extends Component<Props> {
    render() {
        return (
            <Tile isFullWidth={true}>
                <PricingGridWrapper
                    {...this.props}
                />
            </Tile>
        )
    }
}