import React, { Component } from 'react'
import { Mail, Phone, Edit, X, Smartphone, Home, Star } from 'react-feather'
import styles from './ContactTile.module.scss'
import Button, { ButtonType } from '../../General/Button/Button'

interface Props {
    firstName: string
    lastName?: string
    email?: string
    phone?: string
    cellPhone?: string
    homePhone?: string
    editAction?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    removeAction?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    default?: boolean
}

export default class ContactTile extends Component<Props> {
    state = {}

    render() {
        return (
            <div className={styles.contact}>
                <h5 className={styles.contact__name}>{this.props.default && <span><Star /> </span>}{this.props.firstName} {this.props.lastName}</h5>
                <p>{this.props.email && <span className={styles.contact__method}><Mail /> {this.props.email}</span>}{this.props.phone && <span className={styles.contact__method}><Phone /> {this.props.phone}</span>}{this.props.cellPhone && <span className={styles.contact__method}><Smartphone /> {this.props.cellPhone}</span>}{this.props.homePhone && <span className={styles.contact__method}><Home /> {this.props.homePhone}</span>}</p>
                <div className={styles.contact__actionButtons}>
                    {this.props.editAction && <div className={styles.contact__actionButton}><Button buttonType={ButtonType.TightSecondary} clickAction={this.props.editAction}><Edit /></Button></div>}
                    {this.props.removeAction && <div className={styles.contact__actionButton}><Button buttonType={ButtonType.TightSecondary} clickAction={this.props.removeAction}><X /></Button></div>}
                </div>
            </div>
        )
    }
}
