import React, { ReactElement, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { UpdateOrderMutationVariables, useUpdateOrderMutation, useGetSingleOrderQuery, useDeleteOrderMutation, useCreateOrderMutation, CreateOrderMutationVariables, AllCarrierListingFragment, OrderStatus, BasicLocationFragment, PricingSortInput, useRefreshOrderPricingMutation, RefreshOrderPricingMutationVariables, useSendBackToDispatchedOrderMutation, Pricing, GetFreightForOrderQueryVariables, useGetFreightForOrderLazyQuery, FlatCarrierRate, BasicTerminalFragment, BasicSupplierFragment, useAllFileNumbersLazyQuery, useGetLocationSingleLazyQuery, OrderType, SortEnumType } from '../../generated/graphql'
import * as yup from 'yup'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import LocationSearch from '../../components/Search/LocationSearch/LocationSearch'

import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import propName from '../../helpers/propName'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import CarrierSearch from '../../components/Search/CarrierSearch/CarrierSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'
import useForm from 'react-hook-form'
import Modal from '../../components/Modal/Modal'
import PricingTextBoxes from '../../components/Pricing/PricingTextBoxes'
import SalesAgreementSearch from '../../components/Search/SalesAgreementSearch/SalesAgreementSearch'
import PrePurchaseAgreementSearch from '../../components/Search/PrePurchaseAgreementSearch/PrePurchaseAgreementSearch'
import { Copy } from 'react-feather'
import DeliveryTimeSearch from '../../components/Search/DeliveryTimeSearch/DeliveryTimeSearch'
import PricingGrid from '../../components/Pricing/PricingGrid'
import moment from 'moment'
import FileNumberSearch from '../../components/Search/FileNumber/FileNumberSearch'
import Spacer from '../../components/General/Utility/Spacer'
import TermContractSearch from '../../components/Search/TermContractSearch/TermContractSearch'
import { Params, useNavigate, useParams, useLocation } from 'react-router-dom'

export const UpdateOrderMutation = gql`
    mutation UpdateOrder($order: OrderInput!, $id: Int!) {
        orderUpdate(order: $order, id: $id) {
            orderKey
        }
    }
`

export const DeleteOrderMutation = gql`
    mutation DeleteOrder($id: Int!) {
        orderDelete(id: $id)
    }
`

export const DispatchOrderMutation = gql`
    mutation DispatchOrder($id: Int!) {
        orderDispatch(id: $id, sendEmail: false) {
            ...singleOrder
        }
    }
`

export const ReadyForInvoicingOrderMutation = gql`
    mutation ReadyForInvoicingOrder($orderKey: Int!) {
        orderReadyForInvoicing(orderKey: $orderKey)
    }
`

export const CloseOrderMutation = gql`
    mutation CloseOrder($id: Int!, $quickbooks: Boolean!) {
        orderClose(id: $id, sendToQuickbooks: $quickbooks) {
            ...singleOrder
        }
    }
`

export const SendBackToDispatchedMutation = gql`
    mutation SendBackToDispatchedOrder($id: Int!) {
        orderSendBackToDispatch(id: $id)
    }
`

export const GetOrder = gql`
    fragment singleOrder on Order {
        locationKey
        location {
            state
            locationTerminals {
                mileage
            }
        }
        customerKey
        customer {
            quickBooksName
        }
        supplierKey
        terminalKey
        carrierKey
        termContractKey
        deliveryTimeKey
        status
        fileNumberKey
        deliveryDate
        gallons
        miles
        tripNumber
        specialInstructions
        product
        markup
        freight
        tolls
        assessment
        misc
        fOB
        delivered
        actualGallons
        poNumber
        sglInvoiceNumber
        manifest
        otherCharge
        otherCost
        costDescription
        patCost
        patCostTotal
        prePurchaseAgreementKey
        prePurchaseAgreement {
            supplierAgreement
        }
        postingKey
        indexContractKey
        salesAgreementKey
        createdByUserId
        createdDate
        carrier{
            email
        }
        createdByUser {
            name
        }
        modifiedByUser {
            name
        }
        orderType
        quotedPrice
        readyForInvoicing
        tripNotes
        tripsActualGallons
        tripManifest
        carrierPaid
        supplierPaid
        spotContract
        stateAssessment
        stateTax
        perc
    }

    query GetSingleOrder($id: Int!) {
        orderGetSingle(id: $id) {
            ...singleOrder
        }
    }
`

export const OrderPricingRefreshMutation = gql`
    mutation RefreshOrderPricing($orderKey: Int!, $date: DateTime!, $pKey: Int, $icKey: Int, $ppaKey: Int, $saKey: Int, $termContractKey: Int) {
        orderRedoPricing(orderKey: $orderKey, date: $date, postingKey: $pKey, icKey: $icKey, ppaKey: $ppaKey, saKey: $saKey, termContractKey: $termContractKey)
    }
`


interface MatchParams extends Params {
    orderKey: string
}

interface Props {

}

export default function EditOrder(props: Props): ReactElement {
    const navigate = useNavigate()
    let location = useLocation()

    const { orderKey: key } = useParams() as MatchParams
    const orderKey = parseInt(key)

    const [customerKey, setCustomerKey] = useState(undefined)
    const [locationKey, setLocationKey] = useState(undefined)
    const [toll, setToll] = useState<number>(undefined)
    const [marketCarrierRate, setMarketCarrierRate] = useState<FlatCarrierRate>(undefined)

    const [currentlyDeletingOrder, setCurrentlyDeleting] = useState(false)
    const [mileage, setMileage] = useState(undefined)
    const [carrierKey, setCarrierKey] = useState(undefined)
    const [salesAgreement, setSalesAgreement] = useState(undefined)
    const [salesAgreementModal, setSalesAgreementModal] = useState(undefined)
    const today = moment().endOf('day').toDate()
    const [terminalKey, setTerminalKey] = useState(undefined)
    const [supplierKey, setSupplierKey] = useState(undefined)
    const [fileNumberKey, setFileNumberKey] = useState(undefined)
    const [pricingSort, setPricingSort] = useState({ primaryCarrierDelivered: SortEnumType.Asc } as PricingSortInput)
    const [showPricingGrid, setShowPricingGrid] = useState(false)
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)
    const [PricingAdjustment, setPricingAdjustment] = useState(undefined)
    const [terminalLoaded, setTerminalLoaded] = useState(false)
    const [gallons, setGallons] = useState(0)
    const [deliveryDate, setDeliveryDate] = useState(today)
    const [fobCustomer, setFobCustomer] = useState(false)

    let readyForInvoicing = false

    const { data, loading, error } = useGetSingleOrderQuery({ variables: { id: orderKey } })

    const [getFreight, { data: freightData, loading: freightLoading, error: freightError }] = useGetFreightForOrderLazyQuery()
    const [getLocation, { data: locationData }] = useGetLocationSingleLazyQuery()
    const [getFileNumber, { data: fileNumberData, loading: fileNumberLoading }] = useAllFileNumbersLazyQuery()

    const validationSchema = yup.object().shape({
        order: yup.object().shape({
            locationKey: yup.number().required(),
            supplierKey: yup.number().required(),
            terminalKey: yup.number().required(),
            prePurchaseAgreementKey: yup.number().notRequired(),
            salesAgreementKey: yup.number().notRequired(),
            termContractKey: yup.number().notRequired(),

            fileNumberKey: yup.number().notRequired(),
            carrierKey: yup.number().required(),
            deliveryTimeKey: yup.number().required(),
            deliveryDate: yup.date().required(),
            gallons: yup.number().required(),
            miles: yup.number().required(),
            tripNumber: yup.string().notRequired(),
            specialInstructions: yup.string(),

            product: yup.number().required(),
            markup: yup.number().required(),
            freight: yup.number().required(),
            misc: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            otherCharge: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            otherCost: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            fOB: yup.number().required(),
            perc: yup.number().required(),
            stateAssessment: yup.number().required(),
            stateTax: yup.number().required(),
            delivered: yup.number().required(),
            costDescription: yup.string(),
            patCost: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            patCostTotal: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            quotedPrice: yup.boolean().required(),

            actualGallons: yup.number().transform(n => { return isNaN(n) ? undefined : n }).notRequired(),
            poNumber: yup.string().notRequired(),
            sglInvoiceNumber: yup.string().notRequired(),
            manifest: yup.string().notRequired(),
            spotContract: yup.bool().notRequired()
        })
    })

    const [updateOrderMutation] = useUpdateOrderMutation()
    // const [dispatchOrderMutation] = useDispatchOrderMutation()
    // const [closeOrderMutation] = useCloseOrderMutation()
    const [createOrderMutation] = useCreateOrderMutation()
    const [redoPricing] = useRefreshOrderPricingMutation()
    const [sendBackToDispatched] = useSendBackToDispatchedOrderMutation()

    if (deliveryDate && !freightLoading && !freightError && !freightData) {
        if (marketCarrierRate === undefined && carrierKey !== undefined && mileage !== undefined && mileage > 0 && terminalKey !== undefined && locationKey !== undefined) {
            const freightVariables: GetFreightForOrderQueryVariables = { carrierKey, pricingDate: deliveryDate, mileage, terminalKey, locationKey }
            getFreight({ variables: freightVariables })
        }
    }
    else if (freightData && (marketCarrierRate === undefined || marketCarrierRate !== freightData.freightForOrderGet)) {
        setMarketCarrierRate(freightData.freightForOrderGet)
    }

    const navigateToList = () => {
        navigate(`/orders${location.search}`)
    }

    useEffect(() => {
        if (data) {
            setMileage(data.orderGetSingle.miles)
            setLocationKey(data.orderGetSingle.locationKey)
            setCarrierKey(data.orderGetSingle.carrierKey)
            setDeliveryDate(data.orderGetSingle.deliveryDate)
            setToll(data.orderGetSingle.tolls)

            getLocation({ variables: { locationKey: data.orderGetSingle.locationKey } })
        }
    }, [setMileage, setLocationKey, setCarrierKey, data, getLocation])

    useEffect(() => {
        if (terminalKey && supplierKey && fileNumberKey === undefined) {
            getFileNumber({ variables: { filter: { terminalKey: terminalKey, supplierKey: supplierKey, isArchived: { eq: false } } } })
        }
    }, [terminalKey, supplierKey, fileNumberKey, getFileNumber])

    useEffect(() => {
        if (locationData) {
            if (locationData.locationGetSingle.isFobCustomer && !fobCustomer) {
                setFobCustomer(true)
            }
        }
    }, [fobCustomer, locationData, setFobCustomer])

    const setTollToMarketToll = () => {
        setToll(marketCarrierRate.toll)
    }

    const onLocationSelect = (location: BasicLocationFragment) => {
        setCustomerKey(location ? location.customer.customerKey : undefined)
        setLocationKey(location ? location.locationKey : undefined)

        if (location) {
            getUpdatedFreight(null, null, null, location.locationKey)
        }
    }

    const pricingUpdateClick = (line: Pricing) => {
        let params = new URLSearchParams(line.queryString)

        const postingKey = params.get("posting") ? parseInt(params.get("posting")) : null
        const indexContractKey = params.get("indexContract") ? parseInt(params.get("indexContract")) : null
        const prePurchaseAgreementKey = params.get("prePurchaseAgreement") ? parseInt(params.get("prePurchaseAgreement")) : null
        const salesAgreementKey = params.get("salesAgreement") ? parseInt(params.get("salesAgreement")) : null

        if (line.queryString.includes("salesAgreement") && line.supplierName === "Pick your supply") {
            setSalesAgreement(salesAgreementKey)
            setSalesAgreementModal(true)
            return
        }

        const dateParam = params.get("date") ? params.get("date") : null
        const date = dateParam ? new Date(moment(dateParam).format('LL')) : null

        let redoVariables: RefreshOrderPricingMutationVariables = { orderKey, date }
        if (line.queryString.includes("posting")) {
            redoVariables.pKey = postingKey
        }
        else if (line.queryString.includes("indexContract")) {
            redoVariables.icKey = indexContractKey
        }
        else if (line.queryString.includes("prePurchaseAgreement")) {
            redoVariables.ppaKey = prePurchaseAgreementKey
        }
        if (line.queryString.includes("salesAgreement")) {
            redoVariables.saKey = salesAgreementKey
        }
        if (salesAgreement !== undefined) {
            redoVariables.saKey = salesAgreement
        }

        if (line.termContractKey != null) {
            redoVariables.termContractKey = line.termContractKey
        }

        setPricingAdjustment(redoVariables)
    }

    const updatePricing = () => {
        setIsUpdatingOrder(true)
        // I'm using the history.go(0) to refresh the page instead of refetching the data so
        // we dont need to change all the use effects to refresh properly
        redoPricing({ variables: PricingAdjustment })
            .then(_ => navigate(0))
    }

    useEffect(() => {
        if (data) {
            if (terminalKey === undefined && supplierKey === undefined && fileNumberKey === undefined && gallons === 0) {
                setTerminalKey(data.orderGetSingle.terminalKey)
                setSupplierKey(data.orderGetSingle.supplierKey)
                setFileNumberKey(data.orderGetSingle.fileNumberKey)
                setGallons(data.orderGetSingle.actualGallons ? data.orderGetSingle.actualGallons : data.orderGetSingle.gallons)
            }
        }
    }, [data, terminalKey, supplierKey, setTerminalKey, setSupplierKey, gallons, setGallons, fileNumberKey, fileNumberLoading])

    useEffect(() => {
        if (fileNumberData && fileNumberData.fileNumberGetAll && fileNumberData.fileNumberGetAll.length > 0) {
            setFileNumberKey(fileNumberData.fileNumberGetAll.some(x => x.isDefault) ? fileNumberData.fileNumberGetAll.filter(x => x.isDefault)[0].fileNumberKey : fileNumberData.fileNumberGetAll[0].fileNumberKey)
        }
    }, [fileNumberData, setFileNumberKey])

    const submit = (variables: UpdateOrderMutationVariables) => {
        setIsUpdatingOrder(true)
        variables.order.patCost = data ? data.orderGetSingle.patCost : 0
        variables.order.status = data ? data.orderGetSingle.status : OrderStatus.Open
        variables.order.orderType = data.orderGetSingle.orderType
        variables.order.carrierPaid = data.orderGetSingle.carrierPaid
        variables.order.supplierPaid = data.orderGetSingle.supplierPaid
        // If readyForInvoicing is false the ready for invoicing button has not been clicked so we want to use the data that was loaded
        // if readyForInvoicing is true then the ready for invoicing button has been clicked and we want to use it to set the variables
        variables.order.readyForInvoicing = !readyForInvoicing ? data.orderGetSingle.readyForInvoicing : readyForInvoicing
        variables.order.tripNotes = data.orderGetSingle.tripNotes
        variables.order.tripManifest = data.orderGetSingle.tripManifest
        variables.order.tripsActualGallons = data.orderGetSingle.tripsActualGallons
        if (data && data.orderGetSingle.createdByUserId) {
            variables.order.createdByUserId = data.orderGetSingle.createdByUserId
        }
        if (data && data.orderGetSingle.createdDate) {
            variables.order.createdDate = data.orderGetSingle.createdDate
        }
        if (data && data.orderGetSingle.postingKey) {
            variables.order.postingKey = data.orderGetSingle.postingKey
        }
        if (data && data.orderGetSingle.indexContractKey) {
            variables.order.indexContractKey = data.orderGetSingle.indexContractKey
        }
        if (data && data.orderGetSingle.prePurchaseAgreementKey) {
            variables.order.prePurchaseAgreementKey = data.orderGetSingle.prePurchaseAgreementKey
        }
        if (data && data.orderGetSingle.salesAgreementKey) {
            variables.order.salesAgreementKey = data.orderGetSingle.salesAgreementKey
        }

        variables.order.tolls = toll ? toll : 0
        variables.order.spotContract = variables.order.termContractKey != null ? false : true

        if (!customerKey) {
            setCustomerKey(data.orderGetSingle.customerKey)
            variables.order.customerKey = data.orderGetSingle.customerKey
        }
        else {
            variables.order.customerKey = customerKey
        }

        variables.order.assessment = Number(variables.order.perc) + Number(variables.order.stateAssessment) + Number(variables.order.stateTax)

        updateOrderMutation({ variables: { ...variables, ...{ id: orderKey } } })
            .then(r => navigateToList())
    }

    const copy = (variables: UpdateOrderMutationVariables) => {
        setIsUpdatingOrder(true)
        variables.order.patCost = data ? data.orderGetSingle.patCost : 0
        variables.order.status = data ? data.orderGetSingle.status : OrderStatus.Open
        variables.order.orderType = data.orderGetSingle.orderType
        variables.order.readyForInvoicing = data ? data.orderGetSingle.readyForInvoicing : false

        variables.order.carrierPaid = data.orderGetSingle.carrierPaid
        variables.order.supplierPaid = data.orderGetSingle.supplierPaid

        if (data && data.orderGetSingle.createdByUserId) {
            variables.order.createdByUserId = data.orderGetSingle.createdByUserId
        }
        if (data && data.orderGetSingle.createdDate) {
            variables.order.createdDate = data.orderGetSingle.createdDate
        }
        if (data && data.orderGetSingle.postingKey) {
            variables.order.postingKey = data.orderGetSingle.postingKey
        }
        if (data && data.orderGetSingle.indexContractKey) {
            variables.order.indexContractKey = data.orderGetSingle.indexContractKey
        }
        if (data && data.orderGetSingle.prePurchaseAgreementKey) {
            variables.order.prePurchaseAgreementKey = data.orderGetSingle.prePurchaseAgreementKey
        }
        if (data && data.orderGetSingle.salesAgreementKey) {
            variables.order.salesAgreementKey = data.orderGetSingle.salesAgreementKey
        }

        if (data && data.orderGetSingle.termContractKey) {
            variables.order.termContractKey = data.orderGetSingle.termContractKey
        }

        variables.order.assessment = data.orderGetSingle.assessment
        variables.order.perc = data.orderGetSingle.perc
        variables.order.stateAssessment = data.orderGetSingle.stateAssessment
        variables.order.stateTax = data.orderGetSingle.stateTax

        variables.order.tolls = toll
        variables.order.spotContract = variables.order.termContractKey != null ? false : true

        if (!customerKey) {
            setCustomerKey(data.orderGetSingle.customerKey)
            variables.order.customerKey = data.orderGetSingle.customerKey
        }
        else {
            variables.order.customerKey = customerKey
        }
        const createVariables: CreateOrderMutationVariables = {
            order: variables.order,
            copy: true
        }

        createOrderMutation({ variables: createVariables })
            .then(r => {
                setIsUpdatingOrder(false)
                navigate(`/orders/edit/${r.data.orderCreate.orderKey}`)
            })
    }

    const onCarrierSelect = (carrier: AllCarrierListingFragment) => {
        if (carrier) {
            setCarrierKey(carrier.carrierKey)
            getUpdatedFreight(null, null, carrier.carrierKey)
        }
    }

    const onSupplierSelect = (supplier: BasicSupplierFragment) => {
        setSupplierKey(supplier ? supplier.supplierKey : undefined)
        if (!supplier) {
            setFileNumberKey(undefined)
        }
    }

    const onTerminalSelect = (terminal: BasicTerminalFragment) => {
        setTerminalKey(terminal ? terminal.terminalKey : undefined)
        if (!terminal) {
            setFileNumberKey(undefined)
        }
        if (terminal && terminal.locationTerminals && terminal.locationTerminals.some(x => x.locationKey === locationKey)) {
            const miles = terminal.locationTerminals.filter(x => x.locationKey === locationKey)[0].mileage
            if (miles !== mileage && terminalLoaded) {
                setMileage(miles)
                getUpdatedFreight(miles, terminal.terminalKey)
            }
            setTerminalLoaded(true)
        }
        else if (terminalLoaded) {
            setMileage(0)
        }
    }

    const mileageChange = (miles: number) => {
        const milesNumber = Number(miles)

        if (!isNaN(milesNumber)) {
            setMileage(milesNumber)
            getUpdatedFreight(milesNumber)
        }
    }

    const [deleteOrderMutation] = useDeleteOrderMutation()
    const { register, handleSubmit, errors, setValue } = useForm<UpdateOrderMutationVariables>({ validationSchema })
    const onSubmit = handleSubmit(submit)
    const copySubmit = handleSubmit(copy)

    if (loading) return <FormPage />
    if (error) return <div>Error Loading Order</div>

    const commonProps = { register, errors, setValue }

    const confirmDelete = () => {
        deleteOrderMutation({ variables: { id: orderKey } })
            .then(() => navigateToList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const dateChange = (name, val) => {
        if (val !== deliveryDate) {
            setDeliveryDate(val)
        }
    }

    const revertToDispatched = () => {
        setIsUpdatingOrder(true)

        sendBackToDispatched({ variables: { id: orderKey } })
            .then(navigateToList)
    }

    const clearSalesAgreement = () => {
        setSalesAgreement(undefined)
    }

    const getUpdatedFreight = (newMiles: number = null, newTerminal: number = null, newCarrier: number = null, newLocation: number = null) => {
        const freightVariables: GetFreightForOrderQueryVariables = { carrierKey: newCarrier ?? carrierKey, pricingDate: deliveryDate, mileage: newMiles ?? mileage, terminalKey: newTerminal ?? terminalKey, locationKey: newLocation ?? locationKey }
        getFreight({ variables: freightVariables })
    }

    const order = data.orderGetSingle
    const currentPricingKey: number = order.orderType === OrderType.IndexContract ? order.indexContractKey
        : order.orderType === OrderType.Posting ? order.postingKey
            : order.orderType === OrderType.PrePurchaseAgreement ? order.prePurchaseAgreementKey
                : order.orderType === OrderType.SalesAgreement ? order.salesAgreementKey
                    : null

    const footerLeftContent = (<div><Button clickAction={navigateToList} isDisabled={isUpdatingOrder} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} isDisabled={isUpdatingOrder} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
    const footerRightContent = (<div>
        <Button clickAction={copySubmit} isDisabled={isUpdatingOrder} buttonType={ButtonType.Secondary}><Copy size={18} /> Copy Order</Button>

        {data.orderGetSingle.status === OrderStatus.Closed ?
            <Button buttonType={ButtonType.Secondary} clickAction={revertToDispatched}>Send Back to Dispatched</Button> : undefined}

        {!data.orderGetSingle.readyForInvoicing ? <Button buttonType={ButtonType.Primary} isDisabled={isUpdatingOrder} clickAction={_ => { readyForInvoicing = true; onSubmit(_) }}>Ready for Invoicing</Button> : undefined}

        {/* {data.orderGetSingle.status === OrderStatus.Dispatched ? (<Button buttonType={ButtonType.Primary} isDisabled={isUpdatingOrder} clickAction={closeOrder}>Close Order</Button>) : undefined} */}

        <Button clickAction={onSubmit} isDisabled={isUpdatingOrder} buttonType={ButtonType.Success}>Update Order</Button>
    </div>)

    return (
        <FormPage title="Update Order" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent} fullWidth={showPricingGrid}>
            <form>
                <FormTile>
                    <div className="row">
                        {data.orderGetSingle.createdByUser &&
                            <div className="col-md-6">
                                Created By: {data.orderGetSingle.createdByUser.name}
                            </div>}
                        {data.orderGetSingle.modifiedByUser &&
                            <div className="col-md-6">
                                Modified By: {data.orderGetSingle.modifiedByUser.name}
                            </div>}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {data.orderGetSingle.status}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SalesAgreementSearch
                                label="Sales Agreement"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.salesAgreementKey)}
                                prefilledSalesAgreementKey={data.orderGetSingle.salesAgreementKey}
                                locationKey={locationKey}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <PrePurchaseAgreementSearch
                                label="Pre Purchase Agreement"
                                propertyKey={propName<CreateOrderMutationVariables>(o => o.order.prePurchaseAgreementKey)}
                                prefilledPrePurchaseAgreementKey={data.orderGetSingle.prePurchaseAgreementKey}
                                terminalKey={terminalKey}
                                supplierKey={supplierKey}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <LocationSearch
                                label="Location"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.locationKey)}
                                {...commonProps}
                                prefilledLocationKey={data.orderGetSingle.locationKey}
                                customerKey={data.orderGetSingle.customerKey}
                                onSelect={onLocationSelect}
                                showCustomer={true}
                                requireCustomerKey={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <SupplierSearch
                                label="Supplier"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.supplierKey)}
                                prefilledSupplierKey={data.orderGetSingle.supplierKey}
                                onSelect={onSupplierSelect}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <TerminalSearch
                                label="Terminal"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.terminalKey)}
                                prefilledTerminalKey={data.orderGetSingle.terminalKey}
                                onSelect={onTerminalSelect}
                                locationKey={locationKey}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <FileNumberSearch
                                label="File #"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.fileNumberKey)}
                                prefilledFileNumberKey={fileNumberKey}
                                supplierKey={supplierKey}
                                terminalKey={terminalKey}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <TermContractSearch
                                label="Term Contract"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.termContractKey)}
                                prefilledTermContractKey={data.orderGetSingle.termContractKey}
                                clearOnSelect={false}
                                locationKey={locationKey}
                                {...commonProps}
                            />
                            {data.orderGetSingle.spotContract &&
                                <>
                                    <input
                                        type="checkbox"
                                        checked={true}
                                        disabled={true}
                                        style={{ marginRight: 10 }}
                                    />
                                    <label>Spot</label>
                                </>}
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Primary Carrier"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.carrierKey)}
                                prefilledCarrierKey={data.orderGetSingle.carrierKey}
                                onSelect={onCarrierSelect}
                                date={deliveryDate}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <DeliveryTimeSearch
                                label="Delivery Time"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.deliveryTimeKey)}
                                prefilledDeliveryTimeKey={data.orderGetSingle.deliveryTimeKey}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedDateTime
                                label="Delivery Date"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.deliveryDate)}
                                defaultValue={new Date(moment.utc(deliveryDate).format('LL'))}
                                Change={dateChange}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Gallons"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.gallons)}
                                defaultValue={data.orderGetSingle.gallons.toString()}
                                onChange={_ => setGallons(_.currentTarget.value)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Miles"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.miles)}
                                onChange={_ => mileageChange(_.target.value)}
                                value={mileage ? mileage.toString() : ""}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Trip Number"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.tripNumber)}
                                defaultValue={data.orderGetSingle.tripNumber}
                                disabled={true}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Special Instructions"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.specialInstructions)}
                                defaultValue={data.orderGetSingle.specialInstructions}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <PricingTextBoxes
                    productProp={propName<UpdateOrderMutationVariables>(o => o.order.product)}
                    product={data.orderGetSingle.product}
                    markupProp={propName<UpdateOrderMutationVariables>(o => o.order.markup)}
                    markup={data.orderGetSingle.markup}
                    freightProp={propName<UpdateOrderMutationVariables>(o => o.order.freight)}
                    freight={data.orderGetSingle.freight}
                    toll={data.orderGetSingle.tolls}
                    tollProp={propName<UpdateOrderMutationVariables>(o => o.order.tolls)}
                    marketCarrierRate={marketCarrierRate}
                    percProp={propName<UpdateOrderMutationVariables>(o => o.order.perc)}
                    perc={data.orderGetSingle.perc}
                    miscProp={propName<UpdateOrderMutationVariables>(o => o.order.misc)}
                    misc={data.orderGetSingle.misc}
                    fobProp={propName<UpdateOrderMutationVariables>(o => o.order.fOB)}
                    fob={data.orderGetSingle.fOB}
                    deliveredProp={propName<UpdateOrderMutationVariables>(o => o.order.delivered)}
                    delivered={data.orderGetSingle.delivered}
                    otherCharge={data.orderGetSingle.otherCharge}
                    otherChargeProp={propName<UpdateOrderMutationVariables>(o => o.order.otherCharge)}
                    otherCost={data.orderGetSingle.otherCost}
                    otherCostProp={propName<UpdateOrderMutationVariables>(o => o.order.otherCost)}
                    costDescription={data.orderGetSingle.costDescription}
                    costDescriptionProp={propName<UpdateOrderMutationVariables>(o => o.order.costDescription)}
                    patCost={data.orderGetSingle.patCost}
                    patCostTotalProp={propName<UpdateOrderMutationVariables>(o => o.order.patCostTotal)}
                    patCostTotal={data.orderGetSingle.patCostTotal}
                    quotedPriceProp={propName<UpdateOrderMutationVariables>(o => o.order.quotedPrice)}
                    quotedPrice={data.orderGetSingle.quotedPrice}
                    commonProps={commonProps}
                    gallons={gallons}
                    updateFreightCallback={setTollToMarketToll}
                    freightError={freightError != null && !fobCustomer}
                    orderStatus={data.orderGetSingle.status}
                    stateAssessmentProp={propName<UpdateOrderMutationVariables>(o => o.order.stateAssessment)}
                    stateTaxProp={propName<UpdateOrderMutationVariables>(o => o.order.stateTax)}
                    stateAssessment={data.orderGetSingle.stateAssessment}
                    stateTax={data.orderGetSingle.stateTax}
                />
                <FormTile>
                    <div className="row">
                        <div className="col-md-3">
                            <HookedTextInput
                                label="Actual Gallons"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.actualGallons)}
                                defaultValue={data.orderGetSingle.actualGallons ? data.orderGetSingle.actualGallons.toString() : ""}
                                onChange={_ => setGallons(_.currentTarget.value)}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="PO Number"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.poNumber)}
                                defaultValue={data.orderGetSingle.poNumber}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="SGL Invoice Number"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.sglInvoiceNumber)}
                                defaultValue={data.orderGetSingle.sglInvoiceNumber}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-3">
                            <HookedTextInput
                                label="Manifest"
                                propertyKey={propName<UpdateOrderMutationVariables>(o => o.order.manifest)}
                                defaultValue={data.orderGetSingle.manifest}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Trip Notes <p>{data.orderGetSingle.tripNotes ? data.orderGetSingle.tripNotes : "No Trip Notes Yet"}</p>
                        </div>
                    </div>
                </FormTile>
            </form>
            {/* {isShowingExportModal && <OrderDispatchExportModal orderKey={orderKey} closeModal={closeExportModal} />} */}
            {currentlyDeletingOrder && <Modal headerText="Delete?">
                <p>Once you delete this order, it will not be able to be reversed.</p>
                <Button clickAction={cancelDelete} buttonType={ButtonType.Tertiary}>Cancel</Button> <Button clickAction={confirmDelete} buttonType={ButtonType.Primary}>Delete</Button>
            </Modal>}
            {PricingAdjustment && <Modal headerText="Change Pricing?" footerLeftContent={<Button clickAction={_ => setPricingAdjustment(undefined)} buttonType={ButtonType.Tertiary}>Cancel</Button>} footerRightContent={<Button clickAction={updatePricing} isDisabled={isUpdatingOrder} buttonType={ButtonType.Primary}>Update Order</Button>}>
                <p>Are you sure you want to change the pricing for this order?</p>
            </Modal>}
            <Button buttonType={ButtonType.Secondary} clickAction={_ => setShowPricingGrid(!showPricingGrid)}>{!showPricingGrid ? "Reselect Pricing" : "Hide Pricing Grid"}</Button>
            {showPricingGrid &&
                <PricingGrid
                    locationKey={locationKey}
                    newSort={pricingSort}
                    sortPricing={setPricingSort}
                    click={pricingUpdateClick}
                    salesAgreementSelected={salesAgreement !== undefined}
                    clearSalesAgreement={clearSalesAgreement}
                    defaultDate={new Date(moment.utc(data.orderGetSingle.deliveryDate).format('LL'))}
                    orderType={data.orderGetSingle.orderType}
                    currentPricingKey={currentPricingKey}
                    postingKey={data.orderGetSingle.postingKey}
                    prePurchaseAgreementKey={data.orderGetSingle.prePurchaseAgreementKey}
                    indexContractKey={data.orderGetSingle.indexContractKey}
                />}
            {salesAgreementModal && <Modal footerLeftContent={<Button clickAction={_ => { setSalesAgreementModal(undefined) }} buttonType={ButtonType.Primary}>Ok</Button>}>
                Sales Agreement Selected
            </Modal>}

            <Spacer mobileOnly={true} spacerSize={120}></Spacer>

        </FormPage>
    )
}