import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { useGetSingleLocationQuery, useAddContactToLocationMutation, CreateContactMutationResult, CreateLocationTerminalMutationResult, Terminal, useDeleteContactMutation, useUnlinkContactFromLocationMutation, Contact, useRemoveTerminalFromLocationMutation, LocationStatus, CreateLocationContactMutationResult, useLatestOrderQuery, SingleLocationTerminalFragment, UpdateLocationTerminalMutationResult, EditContactMutationResult, useDefaultContactLocationMutation, Tank, useDeleteTankMutation, CreateTankMutationResult } from '../../generated/graphql'

import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import AddTerminalModal from '../../components/AppliedModals/AddTerminalModal/AddTerminalModal'
import EditLocationTerminalModal from '../../components/AppliedModals/EditLocationTerminalModal/EditLocationTerminalModal'
import CreateContactModal from '../../components/AppliedModals/ContactModal/CreateContactModal'
import Tile from '../../components/General/Tile/Tile'
import { Plus } from 'react-feather'
import EmptyState from '../../components/General/Tile/EmptyState/EmptyState'
import GridBase, { GridOptions } from '../../components/General/GridBase/GridBase'
import ellipsish from '../../icons/ellipsis-h.svg'
import ContactTile from '../../components/Tiles/ContactTile/ContactTile'
import Modal from '../../components/Modal/Modal'
import EditContactModal from '../../components/AppliedModals/ContactModal/EditContactModal'
import AddTankModal from '../../components/AppliedModals/AddTankModal/AddTankModal'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import LinkContactModal from '../../components/AppliedModals/ContactModal/LinkContactModal'
import LocationGallonGrid from '../../components/GallonsGrid/LocationGallonGrid'
import moment from 'moment'
import TaskHistoryTile from '../../components/Tiles/TaskHistoryTile/TaskHistoryTile'
import { Params, useNavigate, useParams } from 'react-router-dom'

interface Match extends Params {
    locationKey: string
}

interface Props {

}

export const RemoveTerminalFromLocation = gql`
    mutation RemoveTerminalFromLocation($locationKey: Int!, $terminalKey: Int!) {
        locationRemoveTerminal(locationKey: $locationKey, terminalKey: $terminalKey)
    }
`

export const GetSingleLocationQuery = gql`
    fragment SingleLocationTerminal on LocationTerminal {
        mileage
        terminalKey
        locationKey
        terminal {
            terminalKey
            name
            city
            state
            contacts {
                contactKey
            }
            isArchived
        } 
    }

    fragment SingleLocationFragment on Location {
        locationKey
        # activities
        address1
        address2
        agId
        city
        customerKey
        directions
        federalId
        hasPercCharge
        isAgExempt
        isFobCustomer
        isInternetCustomer
        isTaxExempt
        # locationContacts: [LocationContactInput]
        locationNotes
        markupCost
        lockMarkup
        name
        quickBooksName
        state
        stateId
        # tankSize
        # user
        # userKey
        zipCode
        locationStatus
        percentOfSales

        mailToAddress1
        mailToAddress2
        mailToCity
        mailToDirections
        mailToState
        mailToZipCode
        mailToSameAsCustomer

        tanks {
            tankKey
            size
        }

        primaryCarrierKey
        primaryCarrier {
            name
        }
        secondaryCarrierKey
        secondaryCarrier {
            name
        }
        locationContacts {
            contact {
                contactKey
                firstName
                lastName
                email
                phone
                notes
                default
                cellPhone
                homePhone
                deleted
            }
            default
        }
        customer {
            customerKey
            name
            address1
            address2
            city
            state
            zipCode
            directions
        }
        locationTerminals {
            ...SingleLocationTerminal 
        }
        tasks {
            contact {
                firstName
                lastName
            }
            callDetails
            taskDate
        }
        locationTags {
            locationKey
            tagKey
        }
    }
    query GetSingleLocation($locationKey: Int!) {
        locationGetSingle(locationKey: $locationKey) {
            ...SingleLocationFragment
        }
    }

    mutation AddContactToLocation($locationKey: Int!, $contactKey: Int!, $default: Boolean!) {
        addContactToLocation(contactKey: $contactKey, locationKey: $locationKey, defaultContact: $default)
    }

    mutation DefaultContactLocation($locationKey: Int!, $contactKey: Int!, $def: Boolean!) {
        defaultContactLocation(locationKey: $locationKey, contactKey: $contactKey, def: $def)
    }

    mutation UnlinkContactFromLocation($locationKey: Int!, $contactKey: Int!) {
        unlinkContactFromLocation(locationKey: $locationKey, contactKey: $contactKey)
    }

    query LatestOrder($locationKey: Int!) {
        orderGetAll(order: [{ deliveryDate: DESC }], where: { locationKey: { eq: $locationKey }}, after: "LTE=", first: 1) {
            ...orderGetAllListing
        }
    }

    mutation DeleteTank($tankKey: Int!) {
        tankDelete(tankKey: $tankKey)
    }
`

export default function LocationShow(props: Props): ReactElement {
    const navigate = useNavigate()
    const { locationKey: key } = useParams() as Match

    const locationKey = parseInt(key)

    const [isShowingLinkingModal, setShowingLinkingModal] = useState(false)
    const [isAddingTerminal, setIsAddingTerminal] = useState(false)
    const [isShowingCreateModal, setShowingCreateModal] = useState(false)
    const [isAddingTank, setAddingTank] = useState(false)
    const [currentlyDeletingContact, setCurrentlyDeletingContact] = useState<Contact>(null)
    const [currentlyRemovingTerminal, setCurrentlyRemovingTerminal] = useState<Terminal>(null)
    const [currentlyEditingContact, setCurrentlyEditingContact] = useState<Contact>()
    const [locationTerminal, setLocationTerminal] = useState<SingleLocationTerminalFragment>(undefined)
    const [defaultContact, setDefaultContact] = useState(false)
    const [currentlyRemovingTank, setCurrentlyRemovingTank] = useState<Tank>(null)

    const [addContactToLocation] = useAddContactToLocationMutation()
    const [defaultContactLocation] = useDefaultContactLocationMutation()
    const [deleteContactMutation] = useDeleteContactMutation()
    const [unlinkContactMutation] = useUnlinkContactFromLocationMutation()
    const [removeTerminalFromLocationMutation] = useRemoveTerminalFromLocationMutation()
    const [deleteTank] = useDeleteTankMutation()

    const createContactCallback = ((contact: CreateContactMutationResult) => {
        const contactKey = contact.data.contactCreate.contactKey
        return addContactToLocation({ variables: { contactKey, locationKey, default: contact.data.contactCreate.default } })
            .then(_ => refetch({ locationKey: locationKey }))
    })

    const linkContactCallback = ((contact: CreateLocationContactMutationResult) => {
        return refetch({ locationKey: locationKey })
    })

    const createContactNotLink = () => {
        setShowingLinkingModal(false)
        setShowingCreateModal(true)
    }


    const { data, loading, error, refetch } = useGetSingleLocationQuery({ variables: { locationKey } })
    const { data: latestOrderData } = useLatestOrderQuery({ variables: { locationKey } })

    const addTerminalCallback = (payload: CreateLocationTerminalMutationResult) => {
        return refetch({ locationKey: payload.data.locationTerminalCreate.locationKey })
    }

    const updateLocationTerminalCallback = (payload: UpdateLocationTerminalMutationResult) => {
        return refetch({ locationKey: payload.data.locationTerminalEdit.locationKey })
    }

    const navigateToEditLocation = (locationKey: number) => {
        navigate(`/locations/edit/${locationKey}`)
    }

    const navigateToCustomer = () => {
        navigate(`/customers/show/${data.locationGetSingle.customerKey}`)
    }

    const confirmContactDelete = (contactKey: number) => {
        deleteContactMutation({ variables: { id: contactKey } })
            .then(() => { refetch({ locationKey: locationKey }); setCurrentlyDeletingContact(null) })
    }

    const confirmTankDelete = (tankKey: number) => {
        deleteTank({ variables: { tankKey } })
            .then(() => { refetch({ locationKey }) })
            .then(_ => { setCurrentlyRemovingTank(null) })
    }

    const confirmContactUnlink = (contactKey: number) => {
        unlinkContactMutation({ variables: { locationKey: locationKey, contactKey: contactKey } })
            .then(() => { refetch({ locationKey: locationKey }); setCurrentlyDeletingContact(null) })
    }

    const confirmTerminalRemove = (terminalKey: number) => {
        removeTerminalFromLocationMutation({ variables: { locationKey, terminalKey } })
            .then(() => { refetch({ locationKey: locationKey }); setCurrentlyRemovingTerminal(null) })
    }

    const removeTerminalLink = (terminal: Terminal) => {
        setCurrentlyRemovingTerminal(terminal)
    }

    const removeTank = (tank: Tank) => {
        setCurrentlyRemovingTank(tank)
    }

    const navigateToCall = () => {
        navigate(`/tasks/show/0?location=${locationKey}&customer=${data.locationGetSingle.customerKey}`)
    }

    const contactUpdateCallback = ((contact: EditContactMutationResult) => {
        return defaultContactLocation({ variables: { contactKey: contact.data.contactUpdate.contactKey, locationKey, def: contact.data.contactUpdate.default } })
            .then(_ => refetch({ locationKey }))
    })

    const addTankCallback = ((tank: CreateTankMutationResult) => {
        return refetch({ locationKey })
    })

    if (loading) { return (null) }
    if (error) { return <p>Error</p> }

    const terminalGridOptions: GridOptions<SingleLocationTerminalFragment> = {
        columnDefs: [
            { headerName: "Name", field: "name", dataTransform: lt => lt.terminal.name },
            { headerName: "Mileage", field: "mileage" }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: data.locationGetSingle ? data.locationGetSingle.locationTerminals : [],
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Edit", action: setLocationTerminal },
                    { displayName: "Remove", action: removeTerminalLink }
                ]
            }
        ],
        dataKeyColumn: "terminalKey",
        linkToPath: "/terminals/show/:key"
    }

    const tankGridOptions: GridOptions<any> = {
        columnDefs: [
            { headerName: "Tank", field: "size", dataTransform: t => t.size }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: data.locationGetSingle ? data.locationGetSingle.tanks : [],
        rowActions: [
            {
                icon: ellipsish, items: [
                    { displayName: "Remove", action: removeTank }
                ]
            }
        ]
    }

    const closeEditModal = () => setCurrentlyEditingContact(null)

    const locationStatuses: SelectOption<LocationStatus>[] = Object.values(LocationStatus).map(c => { return { key: c, value: c.charAt(0).toUpperCase() + c.slice(1).toLowerCase() } })
    const defaultLocationStatus = locationStatuses.find(ls => ls.key === data.locationGetSingle.locationStatus)

    const sidebarContent: ReactElement = (
        <TaskHistoryTile locationKey={data.locationGetSingle.locationKey}></TaskHistoryTile>
    )

    const headerContent = (
        <>
            <Button buttonType={ButtonType.Secondary} clickAction={() => { navigateToCustomer() }}>Go To Customer</Button>
            <Button buttonType={ButtonType.Success} clickAction={navigateToCall}>Call</Button>
            <Button clickAction={() => { navigateToEditLocation(data.locationGetSingle.locationKey) }}>Edit Location</Button>
        </>
    )


    return (
        <GeneralPage title="Location" showSidebar={(data.locationGetSingle.tasks && data.locationGetSingle.tasks.length > 0)}
            sidebarContent={sidebarContent} headerContent={headerContent}>
            <div className="row">
                <div className="col-md-12">
                    <Tile>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>{data.locationGetSingle.name ? data.locationGetSingle.name : data.locationGetSingle.city}</h2>
                                    </div>
                                    {data.locationGetSingle.quickBooksName &&
                                        <div className="col-md-12">
                                            <h5><strong>QuickBooks Name:</strong> {data.locationGetSingle.quickBooksName ? data.locationGetSingle.quickBooksName : ""}</h5>
                                        </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6><strong>Address</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5>{data.locationGetSingle.address1}{data.locationGetSingle.address2 && <span><br />{data.locationGetSingle.address2}</span>}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5>{data.locationGetSingle.city}, {data.locationGetSingle.state} {data.locationGetSingle.zipCode}</h5>
                                            </div>
                                        </div>
                                        {data.locationGetSingle.directions &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5><strong>Directions:</strong> {data.locationGetSingle.directions}</h5>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6><strong>Mail To Address</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5>{data.locationGetSingle.mailToAddress1}{data.locationGetSingle.mailToAddress2 && <span><br />{data.locationGetSingle.mailToAddress2}</span>}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5>{data.locationGetSingle.mailToCity}, {data.locationGetSingle.mailToState} {data.locationGetSingle.mailToZipCode}</h5>
                                            </div>
                                        </div>
                                        {/* {data.locationGetSingle.mailToDirections &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5><strong>Directions:</strong> {data.locationGetSingle.mailToDirections}</h5>
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tile>

                    {data.locationGetSingle.primaryCarrierKey && <Tile>
                        <div className="row">
                            {data.locationGetSingle.primaryCarrierKey &&
                                <div className="col-md-6">
                                    <h5><strong>Primary Carrier:</strong> {data.locationGetSingle.primaryCarrier.name}</h5>
                                </div>}
                            {data.locationGetSingle.secondaryCarrierKey &&
                                <div className="col-md-6">
                                    <h5><strong>Secondary Carrier:</strong> {data.locationGetSingle.secondaryCarrier.name}</h5>
                                </div>}
                        </div>
                    </Tile>}
                    <Tile>
                        <div className="row">
                            <div className="col-md-12">
                                {data.locationGetSingle.locationNotes &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5><strong>Location Notes:</strong> {data.locationGetSingle.locationNotes}</h5>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {(latestOrderData && latestOrderData.orderGetAll.edges.length > 0) && <div className="row">
                            <div className="col-md-12">
                                <h5><strong>Last Order:</strong> {moment.utc(latestOrderData.orderGetAll.edges[0].node.deliveryDate).format('LL')}</h5>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    {data.locationGetSingle.markupCost !== undefined &&
                                        <div className="col-md-12">
                                            <h5><strong>Markup Cost:</strong> {data.locationGetSingle.markupCost}</h5>
                                        </div>
                                    }
                                </div>
                                {(data.locationGetSingle.federalId || data.locationGetSingle.stateId) &&
                                    <div className="row">
                                        {data.locationGetSingle.federalId &&
                                            <div className="col-md-6">
                                                <h5><strong>Federal ID:</strong> {data.locationGetSingle.federalId}</h5>
                                            </div>
                                        }
                                        {data.locationGetSingle.stateId &&
                                            <div className="col-md-6">
                                                <h5><strong>State ID:</strong> {data.locationGetSingle.stateId}</h5>
                                            </div>
                                        }
                                    </div>
                                }
                                {data.locationGetSingle.agId &&
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5><strong>Ag ID:</strong> {data.locationGetSingle.agId}</h5>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5><strong>Location Status:</strong> {defaultLocationStatus.value}</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <h5><strong>Percent of Sales:</strong> {data.locationGetSingle.percentOfSales}%</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tile>
                    <LocationGallonGrid locationKey={locationKey} />

                    <div className="row">
                        <div className="col-md-6">
                            <Tile title="Terminals" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setIsAddingTerminal(true)}><Plus /></Button>} headerButtonCondition={(data.locationGetSingle.locationTerminals && data.locationGetSingle.locationTerminals.length > 0)}>
                                <EmptyState text="You don't have any terminals linked to this location yet. Why don't you link one?"
                                    data={data.locationGetSingle.locationTerminals} buttonText="Add Terminal" buttonAction={() => setIsAddingTerminal(true)}></EmptyState>

                                {data.locationGetSingle.locationTerminals.length > 0 && <GridBase gridOptions={terminalGridOptions} />}
                            </Tile>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <Tile title="Tanks" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setAddingTank(true)}><Plus /></Button>} headerButtonCondition={(data.locationGetSingle.tanks && data.locationGetSingle.tanks.length > 0)}>
                                        <EmptyState text="You have not added any Tanks yet.  Why don't you add a tank?"
                                            data={data.locationGetSingle.tanks} buttonText="Add Tank" buttonAction={() => setAddingTank(true)}></EmptyState>

                                        {data.locationGetSingle.tanks.length > 0 && <GridBase gridOptions={tankGridOptions} />}
                                    </Tile>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Tile title="Contacts" headerButton={<Button buttonType={ButtonType.TightPrimary} clickAction={() => setShowingLinkingModal(true)}><Plus /></Button>} headerButtonCondition={(data.locationGetSingle.locationContacts && data.locationGetSingle.locationContacts.length > 0)}>
                                        <EmptyState text="You don't have any contacts linked to this carrier yet. Why don't you create or link one?"
                                            data={data.locationGetSingle.locationContacts} buttonText="Create Contact" buttonAction={() => setShowingLinkingModal(true)}></EmptyState>
                                        {data.locationGetSingle.locationContacts.map(locationContact => {
                                            return <ContactTile key={locationContact.contact.contactKey} firstName={locationContact.contact.firstName} lastName={locationContact.contact.lastName} email={locationContact.contact.email} phone={locationContact.contact.phone}
                                                default={locationContact.default} cellPhone={locationContact.contact.cellPhone} homePhone={locationContact.contact.homePhone} editAction={() => { setCurrentlyEditingContact(locationContact.contact); setDefaultContact(locationContact.default) }} removeAction={() => { setCurrentlyDeletingContact(locationContact.contact) }}></ContactTile>
                                        })}
                                    </Tile>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowingLinkingModal && <LinkContactModal closeModal={() => setShowingLinkingModal(false)} callback={linkContactCallback} createContact={createContactNotLink} customerKey={data.locationGetSingle.customerKey} locationKey={data.locationGetSingle.locationKey} />}
            {isShowingCreateModal && <CreateContactModal closeModal={() => setShowingCreateModal(false)} callback={createContactCallback} />}
            {isAddingTerminal && <AddTerminalModal closeModal={() => setIsAddingTerminal(false)} locationKey={locationKey} callback={addTerminalCallback} />}
            {locationTerminal && <EditLocationTerminalModal closeModal={() => setLocationTerminal(undefined)} callback={updateLocationTerminalCallback} lt={locationTerminal} />}
            {currentlyEditingContact && <EditContactModal closeModal={closeEditModal} callback={contactUpdateCallback} contact={currentlyEditingContact} default={defaultContact} />}
            {isAddingTank && <AddTankModal closeModal={() => setAddingTank(false)} locationKey={locationKey} callback={addTankCallback} />}

            {currentlyDeletingContact && <Modal headerText="Delete Contact?" footerLeftContent={<Button buttonType={ButtonType.Transparent} clickAction={() => setCurrentlyDeletingContact(null)}>Cancel</Button>}
                footerRightContent={<div>
                    <Button buttonType={ButtonType.Primary} clickAction={() => confirmContactUnlink(currentlyDeletingContact.contactKey)}>Unlink Contact</Button>
                    <Button buttonType={ButtonType.Danger} clickAction={() => confirmContactDelete(currentlyDeletingContact.contactKey)}>Delete From All Locations</Button>
                </div>}>
                <p>Once you delete this contact, it will not be able to be reversed.</p>

            </Modal>}

            {currentlyRemovingTank && <Modal headerText="Delete Tank?" footerLeftContent={<Button buttonType={ButtonType.Transparent} clickAction={() => setCurrentlyRemovingTank(null)}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Primary} clickAction={() => confirmTankDelete(currentlyRemovingTank.tankKey)}>Delete Tank</Button>}>
            </Modal>}

            {currentlyRemovingTerminal && <Modal headerText="Remove Terminal?" footerLeftContent={<Button buttonType={ButtonType.Transparent} clickAction={() => setCurrentlyRemovingTerminal(null)}>Cancel</Button>}
                footerRightContent={<Button buttonType={ButtonType.Danger} clickAction={() => confirmTerminalRemove(currentlyRemovingTerminal.terminalKey)}>Remove</Button>}>
                <p>Are you sure you want to remove this terminal from this location?</p>
            </Modal>}
        </GeneralPage>
    )
}
