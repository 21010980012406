import gql from "graphql-tag"
import { GetSingleTerminalQueryVariables, useGetSingleTerminalQuery, useEditTerminalMutation, EditTerminalMutationVariables, useDeleteTerminalMutation } from "../../generated/graphql"
import useForm from "react-hook-form"
import propName from "../../helpers/propName"
import React, { ReactElement, useState } from "react"
import { Params, useNavigate, useParams } from "react-router-dom"
import HookedTextInput from "../../components/General/Inputs/HookedTextInput"
import HookedTextArea from "../../components/General/Inputs/HookedTextArea"
import * as yup from "yup"
import Button, { ButtonType } from "../../components/General/Button/Button"
import FormPage from "../../components/General/FormPage/FormPage"
import FormTile from "../../components/Tiles/FormTile/FormTile"
import HookedCheckbox from "../../components/General/Inputs/HookedCheckbox"
import DeleteEntityModal from "../../components/AppliedModals/DeleteEntity/DeleteEntityModal"


export const EditTerminalQuery = gql`
   mutation EditTerminal($terminal: TerminalInput! $id: Int!) {
      terminalUpdate(terminal: $terminal, id: $id) {
         terminalKey
      }
   }
`

interface MatchParams extends Params {
   terminalKey: string
}

export default function EditTerminal(): ReactElement {
   const navigate = useNavigate()
   const { terminalKey: key } = useParams() as MatchParams

   const terminalKey = parseInt(key)
   const getVariables: GetSingleTerminalQueryVariables = { id: terminalKey }
   const { data: d, loading: l, error: e } = useGetSingleTerminalQuery({ variables: getVariables })

   const [currentlyDeletingTerminal, setCurrentlyDeleting] = useState(false)

   const [editTerminalMutation] = useEditTerminalMutation()
   const [deleteMutation] = useDeleteTerminalMutation()

   const validationSchema = yup.object().shape({
      terminal: yup.object().shape({
         name: yup.string().required().trim(),
         address1: yup.string().required().trim(),
         address2: yup.string().notRequired().trim(),
         city: yup.string().required().trim(),
         directions: yup.string().notRequired().trim(),
         email: yup.string().email().notRequired(),
         fax: yup.string().notRequired().trim(),
         phone: yup.string().required().trim(),
         state: yup.string().required().trim(),
         zipCode: yup.string().required().trim(),
         isArchived: yup.boolean().required(),
         notes: yup.string().notRequired()
      })
   })


   const { register, handleSubmit, errors } = useForm<EditTerminalMutationVariables>({ validationSchema })

   if (l) return <div>Loading</div>
   if (e) return <div>Error Loading Record</div>

   const onSubmit = handleSubmit(
      (variables: EditTerminalMutationVariables) =>
         editTerminalMutation({ variables: { ...variables, ...{ id: terminalKey } } })
            .then(r => navigateToShowTerminal(r.data.terminalUpdate.terminalKey)))

   const navigateToList = () => {
      navigate("/terminals")
   }

   const navigateToShowTerminal = (key: number) => {
      navigate(`/terminals/show/${key}`)
   }

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Terminal</Button>)


   return (
      <FormPage title="Update Terminal" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.name}
                        register={register}
                        label="Name"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.name)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.address1}
                        register={register}
                        label="Address 1"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.address1)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-12">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.address2}
                        register={register}
                        label="Address 2"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.address2)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.city}
                        register={register}
                        label="City"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.city)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.state}
                        register={register}
                        label="State"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.state)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.zipCode}
                        register={register}
                        label="Zip Code"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.zipCode)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        defaultValue={d.terminalGetSingle.notes}
                        register={register}
                        label="Notes"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.notes)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.phone}
                        register={register}
                        label="Phone"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.phone)}
                        errors={errors}
                     />
                  </div>
                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.fax}
                        register={register}
                        label="Fax"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.fax)}
                        errors={errors}
                     />
                  </div>

                  <div className="col-md-4">
                     <HookedTextInput
                        defaultValue={d.terminalGetSingle.email}
                        register={register}
                        label="Email Address"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.email)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedTextArea
                        defaultValue={d.terminalGetSingle.directions}
                        register={register}
                        label="Directions"
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.directions)}
                        errors={errors}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-12">
                     <HookedCheckbox
                        label="Archived"
                        register={register}
                        propertyKey={propName<EditTerminalMutationVariables>(o => o.terminal.isArchived)}
                        defaultValue={d.terminalGetSingle.isArchived}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingTerminal && <DeleteEntityModal
            cancel={cancelDelete}
            confirm={confirmDelete}
            entityKey={terminalKey}
            entityName="terminal"
            orderFilter={{ terminalKey: { eq: terminalKey } }}
            indexContractFilter={{ terminalKey: { eq: terminalKey } }}
            postingFilter={{ terminalKey: { eq: terminalKey } }}
            prepurchaseFilter={{ terminalKey: { eq: terminalKey } }}
            salesAgreementFilter={{ terminalKey: { eq: terminalKey } }}
         />}
      </FormPage>
   )
}

interface MatchParams extends Params {
   terminalKey: string
}