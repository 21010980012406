import React, { ReactElement, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useEditLocationMutation, EditLocationMutationVariables, GetSingleLocationQueryVariables, useGetSingleLocationQuery, useDeleteLocationMutation, LocationStatus, useCreateLocationTagMutation, useDeleteLocationTagMutation, LocationTagInput } from '../../generated/graphql'
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import * as yup from 'yup'
import CarrierSearch from '../../components/Search/CarrierSearch/CarrierSearch'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiTagSearch from '../../components/Search/TagSearch/MultiTagSearch'
import DeleteEntityModal from '../../components/AppliedModals/DeleteEntity/DeleteEntityModal'
import { Params, useNavigate, useParams } from 'react-router-dom'


export const EditLocationQuery = gql`
    mutation EditLocation($location: LocationInput! $id: Int!) {
        locationUpdate(location: $location, id: $id) {
            locationKey
        }
    }
`

interface MatchParams extends Params {
    locationKey: string
}

interface Props {
}

export default function EditLocation(props: Props): ReactElement {
    const navigate = useNavigate()

    const { locationKey: key } = useParams() as MatchParams

    const locationKey = parseInt(key)
    const [currentlyDeletingLocation, setCurrentlyDeleting] = useState(false)
    const [tagKeys, setTagKeys] = useState<number[]>(undefined)
    const [mailToSame, setMailToSame] = useState(false)
    const [isFob, setIsFob] = useState(false)
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [directions, setDirections] = useState("")

    const getVariables: GetSingleLocationQueryVariables = { locationKey: locationKey }
    const { data: d, loading: l, error: e } = useGetSingleLocationQuery({ variables: getVariables })

    const [editLocationMutation] = useEditLocationMutation()
    const [deleteMutation] = useDeleteLocationMutation()

    const [createLocationTag] = useCreateLocationTagMutation()
    const [deleteLocationTag] = useDeleteLocationTagMutation()

    const validationSchema = yup.object().shape({
        location: yup.object().shape({
            name: yup.string().notRequired().trim(),
            quickBooksName: yup.string().notRequired().trim(),

            address1: yup.string().notRequired().trim(),
            address2: yup.string().notRequired().trim(),

            city: yup.string().required().trim(),
            state: yup.string().required().trim(),
            zipCode: yup.string().notRequired().trim(),

            directions: yup.string().trim(),
            locationNotes: yup.string().trim(),
            lockMarkup: yup.boolean().required(),

            markupCost: yup.number(),

            primaryCarrierKey: yup.number().required(),
            secondaryCarrierKey: yup.number().notRequired(),

            locationStatus: yup.string(),

            mailToAddress1: yup.string().notRequired().trim(),
            mailToAddress2: yup.string().notRequired().trim(),
            mailToCity: yup.string().notRequired().trim(),
            mailToDirections: yup.string().notRequired().trim(),
            mailToState: yup.string().notRequired().trim(),
            mailToZipCode: yup.string().notRequired().trim(),
            mailToSameAsCustomer: yup.boolean().required(),

            agId: yup.string().notRequired(),
            federalId: yup.string().notRequired(),
            stateId: yup.string().notRequired()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<EditLocationMutationVariables>({ validationSchema })

    useEffect(() => {
        if (d !== undefined) {
            setMailToSame(d.locationGetSingle.mailToSameAsCustomer)
            setAddress1(d.locationGetSingle.mailToAddress1)
            setAddress2(d.locationGetSingle.mailToAddress2)
            setCity(d.locationGetSingle.mailToCity)
            setState(d.locationGetSingle.mailToState)
            setZip(d.locationGetSingle.mailToZipCode)
            setDirections(d.locationGetSingle.mailToDirections)
            setIsFob(d.locationGetSingle.isFobCustomer)
        }
    }, [d, setMailToSame, setAddress1, setAddress2, setCity, setState, setZip, setDirections])

    if (l) return (null)
    if (e) return <div>Error Loading Record</div>

    const submit = (variables: EditLocationMutationVariables) => {
        variables.location.customerKey = d.locationGetSingle.customerKey
        variables.location.hasPercCharge = false
        variables.location.isAgExempt = false
        variables.location.isFobCustomer = isFob
        variables.location.isInternetCustomer = false
        variables.location.isTaxExempt = false

        editLocationMutation({ variables: { ...variables, ...{ id: locationKey } } })
            .then(r => {
                if (tagKeys) {
                    tagKeys.forEach(key => {
                        if (d.locationGetSingle.locationTags.some(y => y.tagKey === key)) {
                            return
                        }
                        const ltInput: LocationTagInput = { locationKey, tagKey: key }
                        createLocationTag({ variables: { locationTag: ltInput } })
                    })
                }
                if (d.locationGetSingle.locationTags.length > 0) {
                    d.locationGetSingle.locationTags.forEach(x => {
                        if (!tagKeys.some(y => y === x.tagKey)) {
                            return deleteLocationTag({ variables: { locationKey, tagKey: x.tagKey } })

                        }
                    })
                }

                navigate(`/locations/show/${d.locationGetSingle.locationKey}`)
            })
    }

    if (d.locationGetSingle.locationTags && d.locationGetSingle.locationTags.length > 0 && tagKeys === undefined) {
        setTagKeys(d.locationGetSingle.locationTags.map(x => x.tagKey))
    }

    const onSubmit = handleSubmit(submit)

    const locationStatuses: SelectOption<LocationStatus>[] = Object.keys(LocationStatus).map(c => {
        return { key: LocationStatus[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })
    const defaultLocationStatus = locationStatuses.find(ls => ls.key === d.locationGetSingle.locationStatus)

    const percentages = [100, 50, 20]
    const percentOfBusiness: SelectOption<any>[] = Object.values(percentages).map(c => { return { key: c, value: `${c.toString()}%` } })
    const defaultPercentage = percentOfBusiness.find(pb => pb.key === d.locationGetSingle.percentOfSales)

    const navigateToShow = () => {
        navigate(`/locations/show/${d.locationGetSingle.locationKey}`)
    }

    const navigateToLocationList = () => {
        navigate(`/locations`)
    }

    const confirmDelete = (id: number) => {
        deleteMutation({ variables: { id } })
            .then(() => navigateToLocationList())
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const addTag = (tag) => {
        if (tagKeys === undefined) {
            setTagKeys([tag.tagKey])
        }
        else if (!tagKeys.some(x => x === tag.tagKey)) {
            setTagKeys([...tagKeys, tag.tagKey])
        }
    }

    const removeTag = (tag) => {
        setTagKeys([...tagKeys.filter(x => x !== tag)])
    }

    const footerLeftContent = (<div><Button clickAction={navigateToShow} buttonType={ButtonType.Transparent}>Cancel</Button>
        <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
    const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Location</Button>)

    return (
        <FormPage title="Update Location" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Friendly Name"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.name)}
                                defaultValue={d.locationGetSingle.name}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <HookedTextInput
                                label="QuickBooks Name"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.quickBooksName)}
                                defaultValue={d.locationGetSingle.quickBooksName}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 1"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.address1)}
                                register={register}
                                defaultValue={d.locationGetSingle.address1}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Address 2"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.address2)}
                                register={register}
                                defaultValue={d.locationGetSingle.address2}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="City"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.city)}
                                register={register}
                                defaultValue={d.locationGetSingle.city}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.state)}
                                register={register}
                                defaultValue={d.locationGetSingle.state}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Zipcode"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.zipCode)}
                                register={register}
                                defaultValue={d.locationGetSingle.zipCode}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <HookedSelectOptions
                                options={locationStatuses}
                                label="Status"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.locationStatus)}
                                setDefaultValue={defaultLocationStatus}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedSelectOptions
                                options={percentOfBusiness}
                                label="Percentage Of Sales"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.percentOfSales)}
                                setDefaultValue={defaultPercentage}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Directions"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.directions)}
                                register={register}
                                defaultValue={d.locationGetSingle.directions}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Primary Carrier"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.primaryCarrierKey)}
                                prefilledCarrierKey={d.locationGetSingle.primaryCarrierKey}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-6">
                            <CarrierSearch
                                label="Secondary Carrier"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.secondaryCarrierKey)}
                                prefilledCarrierKey={d.locationGetSingle.secondaryCarrierKey}
                                register={register}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedCheckbox
                                label="Mail To Same As Customer Address"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToSameAsCustomer)}
                                register={register}
                                errors={errors}
                                defaultValue={d.locationGetSingle.mailToSameAsCustomer}
                                onChange={_ => setMailToSame(_.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Address 1"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToAddress1)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.address1 || '') : address1}
                                onChange={_ => setAddress1(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Address 2"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToAddress2)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.address2 || '') : address2}
                                onChange={_ => setAddress2(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail City"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToCity)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.city || '') : city}
                                onChange={_ => setCity(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail State"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToState)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.state || '') : state}
                                onChange={_ => setState(_.target.value)}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Mail Zip"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToZipCode)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.zipCode || '') : zip}
                                onChange={_ => setZip(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Mail Directions"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.mailToDirections)}
                                register={register}
                                disabled={mailToSame}
                                value={mailToSame ? (d.locationGetSingle.customer.directions || '') : directions}
                                onChange={_ => setDirections(_.target.value)}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Location Notes"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.locationNotes)}
                                register={register}
                                defaultValue={d.locationGetSingle.locationNotes}
                                errors={errors} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HookedTextInput
                                label="Markup"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.markupCost)}
                                register={register}
                                defaultValue={d.locationGetSingle.markupCost.toString()}
                                errors={errors} />
                        </div>
                        <div className="col-md-3">
                            <HookedCheckbox
                                label="Lock Markup"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.lockMarkup)}
                                defaultValue={d.locationGetSingle.lockMarkup}
                                register={register}
                                errors={errors} />
                        </div>
                        <div className="col-md-3">
                            <HookedCheckbox
                                label="FOB Customer"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.isFobCustomer)}
                                register={register}
                                errors={errors}
                                defaultValue={d.locationGetSingle.isFobCustomer}
                                onChange={_ => setIsFob(_.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Ag ID"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.agId)}
                                register={register}
                                defaultValue={d.locationGetSingle.agId}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="Federal ID"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.federalId)}
                                register={register}
                                defaultValue={d.locationGetSingle.federalId}
                                errors={errors} />
                        </div>
                        <div className="col-md-4">
                            <HookedTextInput
                                label="State ID"
                                propertyKey={propName<EditLocationMutationVariables>(o => o.location.stateId)}
                                register={register}
                                defaultValue={d.locationGetSingle.stateId}
                                errors={errors} />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <MultiTagSearch
                                removeTag={removeTag}
                                addTag={addTag}
                                data={tagKeys}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>

            {currentlyDeletingLocation && <DeleteEntityModal
                cancel={cancelDelete}
                confirm={confirmDelete}
                entityKey={locationKey}
                entityName="location"
                orderFilter={{ locationKey: { eq: locationKey } }} />}
        </FormPage>
    )
}
