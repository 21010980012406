import React, { useState } from "react"
import { useMostRecentPostingsForSupplierQuery, useBulkUpdatePostingsMutation, BulkUpdatePostingInput } from "../../../generated/graphql"
import Modal from "../../Modal/Modal"
import styles from './BulkUpdateSupplierPostingsModal.module.scss'
import inputStyles from '../../General/Inputs/Input.module.scss'
import Button, { ButtonType } from "../../General/Button/Button"
import DatePicker from 'react-datepicker'
import gql from 'graphql-tag'
import moment from 'moment'

export const CreateAndEndActivePostingMutation = gql`
   mutation CreateAndEndActivePosting($posting: PostingInput!) {
      postingCreateAndEndActive(posting: $posting) {
         ...InnerPosting
      }
   }
`

export const BulkUpdatePostingsMutation = gql`
   mutation BulkUpdatePostings($postings: [BulkUpdatePostingInput]!) {
      postingBulkUpdate(postings: $postings)
   }
`

interface Props {
   supplierKey: number
   closeModal(): void
   callback?(): void
}

interface CostUpdate {
   id: number
   cost: string
}

const BulkUpdateSupplierPostingsModal = (props: Props) => {
   const { data, loading, error } = useMostRecentPostingsForSupplierQuery({ variables: { supplierKey: props.supplierKey } })

   const [date, setDate] = useState<Date>(new Date(moment().add(1, 'day').format('LL')))
   const [endDate, setEndDate] = useState<Date>(new Date(moment().add(1, 'year').format('LL')))
   const [costs, setCosts] = useState<CostUpdate[]>([])

   const [bulkUpdatePostings] = useBulkUpdatePostingsMutation()

   if (loading) return <Modal></Modal>
   if (error) return <Modal><h2>Error</h2></Modal>

   if (costs.length === 0) {
      if (data.postingsGetAllForSupplierMostRecent.length !== 0) {
         const emptyArray: CostUpdate[] = data.postingsGetAllForSupplierMostRecent.map(p => {
            const newVal: CostUpdate = { id: p.postingKey, cost: p.cost.toString() }
            return newVal
         })
         setCosts(emptyArray)
      }
   }

   function handleChange(i, event) {
      let newCosts: CostUpdate[] = Object.assign(costs)
      newCosts[i].cost = event.target.value
      setCosts(newCosts)
   }

   const submit = () => {
      let postings: BulkUpdatePostingInput[] = []

      costs.forEach(c => {
         let costInt = Number(c.cost)
         if (!isNaN(costInt)) {
            let filteredPostings = data.postingsGetAllForSupplierMostRecent.filter(p => p.postingKey === c.id)
            if (filteredPostings.length) {
               let existingPosting = filteredPostings[0]
               // If we are not adjusting the pricing we dont want to update the date either
               if (existingPosting.cost === costInt) {
                  return
               }
               let posting: BulkUpdatePostingInput = {
                  postingKey: existingPosting.postingKey,
                  cost: costInt,
                  effectiveDate: date,
                  effectiveEndDate: endDate,
                  supplierKey: existingPosting.supplierKey,
                  terminalKey: existingPosting.terminalKey
               }

               postings.push(posting)
            }
         }
      })

      bulkUpdatePostings({ variables: { postings } }).then(props.callback).then(props.closeModal)
   }

   const footerLeftContent = (<Button clickAction={props.closeModal} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const footerRightContent = (<Button clickAction={submit} buttonType={ButtonType.Success}>Update Postings</Button>)


   const onDateChange = (date: Date, e) => {
      setDate(date)
   }

   const onEndDateChange = (date: Date, e) => {
      setEndDate(date)
   }

   const getCostForPosting = (key: number) => {
      const filteredCosts = costs.filter(c => c.id === key)
      if (filteredCosts.length) {
         return filteredCosts[0]
      }
   }

   return (
      <Modal headerText="Bulk Update Supplier Postings" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>

         <div className={styles.tableHeading}>
            <div className="row">

               <div className="col-md-3">Terminal</div>
               <div className="col-md-3">Date</div>
               <div className="col-md-3">Cost</div>
               <div className="col-md-3">New Cost</div>
            </div>
         </div>

         <div className={styles.tableContainer}>
            {data.postingsGetAllForSupplierMostRecent.map((p, index) => {
               return (<div className={`row ${styles.tableRow}`} key={index} >
                  <div className={`col-md-3 ${styles.tableRowColumn}`}><span className={styles.mobileOnlyLabel}>Terminal: </span>{p.terminal.name}</div>
                  <div className={`col-md-3 ${styles.tableRowColumn}`}><span className={styles.mobileOnlyLabel}>Date: </span>{moment(p.effectiveDate).format('LLL')}</div>
                  <div className={`col-md-3 ${styles.tableRowColumn}`}><span className={styles.mobileOnlyLabel}>Cost: </span> {p.cost}</div>
                  <div className="col-md-3">
                     <label className={styles.mobileOnlyLabel}>New Cost</label>
                     <input
                        type="text"
                        placeholder="New Price"
                        onChange={e => handleChange(index, e)}
                        className={inputStyles.input}
                        defaultValue={getCostForPosting(p.postingKey) ? getCostForPosting(p.postingKey).cost : "0"}
                     />
                  </div>
               </div>)
            })}
         </div>
         <div className="row">
            <div className="col-md-4">
               <label className={inputStyles.input__wrapper}>Start Date</label>
               <DatePicker
                  className={inputStyles.input}
                  selected={date}
                  onChange={onDateChange}
                  showTimeSelect={true}
                  dateFormat="MMMM d, yyyy h:mm aa"
               />
            </div>
            <div className="col-md-4" style={{ "display": "none" }}>
               <label className={inputStyles.input__wrapper}>End Date</label>
               <DatePicker
                  className={inputStyles.input}
                  selected={endDate}
                  onChange={onEndDateChange}
                  showTimeSelect={true}
                  dateFormat="MMMM d, yyyy h:mm aa"
               />
            </div>
         </div>
      </Modal>
   )
}
export default BulkUpdateSupplierPostingsModal
