import React from 'react'
import styles from './Icon.module.scss'

interface Props {
    iconPath: string
    iconAlt?: string

    children?: React.ReactNode
}

const Icon: React.FC<Props> = ({ children, iconPath, iconAlt }) => {
    return (
        <figure tabIndex={1} className={styles.icon}>
            <img src={iconPath} alt={iconAlt} />
            {children &&
                <span className={styles.childrenWrapper}>
                    {children}
                </span>
            }
        </figure>
    )
}

export default Icon
