import React, { ReactElement } from 'react'
import moment from 'moment'
import Tile from '../../General/Tile/Tile'
import EmptyState from '../../General/Tile/EmptyState/EmptyState'
import { DashboardOrderQueryVariables, OrderStatus, useDashboardOrderQuery, InnerOrderFragment, OrderType, SortEnumType } from '../../../generated/graphql'
import GridBase, { GridOptions, GridSize } from '../../General/GridBase/GridBase'
import { SelectOption } from '../../General/SelectOptions/SelectOptions'

interface Props {
    locationKey: number
}

export default function OrderReferenceTile(props: Props): ReactElement {

    const orderVariables: DashboardOrderQueryVariables = {
        sortObject: { deliveryDate: SortEnumType.Desc },
        cursor: "LTE=",
        pageSize: 5,
        filterObject: { locationKey: { eq: props.locationKey } },
        status: OrderStatus.All
    }
    const { data, loading, error } = useDashboardOrderQuery({ variables: orderVariables })

    if (loading || error) {
        return <Tile title="Recent Orders" />
    }

    const orderTypes: SelectOption<OrderType>[] = Object.keys(OrderType).map(c => {
        return { key: OrderType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
    })

    const gridOptions: GridOptions<InnerOrderFragment> = {
        columnDefs: [
            { headerName: "Supplier", dataTransform: o => o.supplier.name },
            { headerName: "Terminal", dataTransform: o => o.terminal.name },
            { headerName: "Carrier", dataTransform: o => o.carrier.name },
            { headerName: "Type", dataTransform: o => orderTypes.filter(x => x.key === o.orderType)[0].value },
            { headerName: "Delivery Date", dataTransform: o => moment.utc(o.deliveryDate).format('l') },
            { headerName: "Delivered", dataTransform: o => o.delivered.toFixed(4).toString() }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: data.orderDashboard.edges.map(x => x.node),
        dataKeyColumn: "orderKey",
        size: GridSize.small
    }

    return <Tile title="Recent Orders">
        <EmptyState text="You dont have any orders for this location yet." data={data.orderDashboard.edges}></EmptyState>
        {data.orderDashboard.edges.length > 0 && <GridBase gridOptions={gridOptions} />}
    </Tile>
}