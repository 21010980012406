import * as yup from "yup"
import React from "react";
import Modal from "../../Modal/Modal";
import useForm from 'react-hook-form'
import propName from '../../../helpers/propName'
import { CreateFuelSurchargeMutationVariables, useCreateFuelSurchargeMutation } from "../../../generated/graphql";
import HookedDateTime from "../../General/Inputs/HookedDateTime";
import gql from "graphql-tag";
import HookedTextInput from "../../General/Inputs/HookedTextInput";
import Button, { ButtonType } from "../../General/Button/Button";
import moment from 'moment'

interface Props {
   closeModal(): void
   callback(): Promise<any>
   carrierKey: number
}

export const CreateFuelSurcharge = gql`
   mutation CreateFuelSurcharge($fuelSurcharge: FuelSurchargeInput!) {
      fuelSurchargeCreate(fuelSurcharge: $fuelSurcharge) {
         fuelSurchargeKey
      }
   }
`

const CreateFuelSurchargeModal = (props: Props) => {
   const today = moment().startOf('day')
   const endDate = new Date(moment().add(10, 'years').format('LL'))
   const [createFuelSurchargeMutation] = useCreateFuelSurchargeMutation()
   const onCreate = (variables: CreateFuelSurchargeMutationVariables) => {

      variables.fuelSurcharge.carrierKey = props.carrierKey

      createFuelSurchargeMutation({ variables })
         .then(props.callback)
         .then(() => props.closeModal());
   }

   const validationSchema = yup.object().shape({
      fuelSurcharge: yup.object().shape({
         startDate: yup.date().required(),
         endDate: yup.date().required(),
         rate: yup.number().required()
      })
   })
   const { register, setValue, handleSubmit, errors } = useForm<CreateFuelSurchargeMutationVariables>({ validationSchema })

   const onSubmit = handleSubmit(onCreate)

   const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
   const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create Fuel Surcharge</Button>

   return (
      <Modal headerText="Create Fuel Surcharge" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <div className="row">
               <div className="col-md-6">
                  <HookedDateTime
                     setValue={setValue}
                     register={register}
                     propertyKey={propName<CreateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.startDate)}
                     label="Start Date"
                     defaultValue={new Date(today.format('LL'))}
                     errors={errors}
                  />
               </div>
               <div className="col-md-6">
                  <HookedDateTime
                     setValue={setValue}
                     register={register}
                     propertyKey={propName<CreateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.endDate)}
                     label="End Date"
                     defaultValue={endDate}
                     errors={errors}
                  />
               </div>
            </div>

            <div className="row">
               <div className="col-md-4">
                  <HookedTextInput
                     defaultValue="100"
                     register={register}
                     propertyKey={propName<CreateFuelSurchargeMutationVariables>(o => o.fuelSurcharge.rate)}
                     label="Rate"
                     errors={errors}
                  />
               </div>
            </div>
         </form>
      </Modal >
   )
}
export default CreateFuelSurchargeModal