import gql from 'graphql-tag'
import React, { ReactElement, useState } from 'react'
import useForm from 'react-hook-form'
import { useEditDirectMarkupMutation, EditDirectMarkupMutationVariables, useDeleteDirectMarkupMutation, useGetSingleDirectMarkupQuery, GetSingleDirectMarkupQueryVariables, DirectMarkupType } from '../../generated/graphql'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import * as yup from "yup"
import FormPage from '../../components/General/FormPage/FormPage'
import Button, { ButtonType } from '../../components/General/Button/Button'
import Modal from '../../components/Modal/Modal'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import HookedSelectOptions from '../../components/General/Inputs/HookedSelectOptions'
import { SelectOption } from '../../components/General/SelectOptions/SelectOptions'
import SupplierSearch from '../../components/Search/SupplierSearch/SupplierSearch'
import LocationSearch from '../../components/Search/LocationSearch/LocationSearch'
import TerminalSearch from '../../components/Search/TerminalSearch/TerminalSearch'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditDirectMarkupQuery = gql`
   mutation EditDirectMarkup($directMarkup: DirectMarkupInput!, $id: Int!) {
      directMarkupUpdate(directMarkupKey: $id, directMarkup: $directMarkup) {
         directMarkupKey
      }
   }
`

export const SingleDirectMarkupQuery = gql`
   fragment innerDirectMarkup on DirectMarkup {
        directMarkupKey
        markup
        supplierKey
        locationKey
        terminalKey
        directMarkupType
        isArchived
    }

    query GetSingleDirectMarkup($id: Int!) {
        directMarkupGetSingle(id: $id) {
            ...innerDirectMarkup
        }
    }
`

interface MatchParams extends Params {
   directMarkupKey: string
}

interface Props {
}

export default function EditDirectMarkup(props: Props): ReactElement {
   const navigate = useNavigate()

   const { directMarkupKey: key } = useParams() as MatchParams

   const directMarkupKey = parseInt(key)
   const [currentlyDeletingDirectMarkup, setCurrentlyDeleting] = useState(false)

   const validationSchema = yup.object().shape({
      directMarkup: yup.object().shape({
         markup: yup.number().required(),
         directMarkupType: yup.string().trim(),
         supplierKey: yup.number().required(),
         locationKey: yup.number().required(),
         terminalKey: yup.number().required()
      })
   })

   const getVariables: GetSingleDirectMarkupQueryVariables = { id: directMarkupKey }
   const { data, loading, error } = useGetSingleDirectMarkupQuery({ variables: getVariables })

   const [editDirectMarkupMutation] = useEditDirectMarkupMutation()
   const [deleteMutation] = useDeleteDirectMarkupMutation()

   const { register, handleSubmit, errors, setValue } = useForm<EditDirectMarkupMutationVariables>({ validationSchema })

   if (loading) return null
   if (error) return <div>Error Loading Record</div>

   const onSubmit = handleSubmit(
      (variables: EditDirectMarkupMutationVariables) => {
         editDirectMarkupMutation({ variables: { ...variables, ...{ id: directMarkupKey } } })
            .then(navigateToList)
      })

   const navigateToList = () => {
      navigate("/pricing/direct-markup")
   }

   const confirmDelete = (id: number) => {
      deleteMutation({ variables: { id } })
         .then(() => navigateToList())
   }

   const cancelDelete = () => {
      setCurrentlyDeleting(false)
   }

   const openDeleteConfirmation = () => {
      setCurrentlyDeleting(true)
   }

   const markupTypes: SelectOption<DirectMarkupType>[] = Object.keys(DirectMarkupType).map(c => {
      return { key: DirectMarkupType[c], value: c.replace(/([a-z])([A-Z])/g, '$1 $2') }
   })
   const defaultMarkupType = markupTypes.find(mt => mt.key === data.directMarkupGetSingle.directMarkupType)

   const commonProps = { register, errors, setValue }

   const footerLeftContent = (<div><Button clickAction={navigateToList} buttonType={ButtonType.Transparent}>Cancel</Button>
      <Button clickAction={openDeleteConfirmation} buttonType={ButtonType.Tertiary}>Delete</Button></div>)
   const footerRightContent = (<Button clickAction={onSubmit} buttonType={ButtonType.Success}>Update Direct Markup</Button>)

   const deleteModalFooterLeftContent = (<Button clickAction={cancelDelete} buttonType={ButtonType.Transparent}>Cancel</Button>)
   const deleteModalFooterRightContent = (<Button clickAction={() => confirmDelete(directMarkupKey)} buttonType={ButtonType.Danger}>Delete Direct Markup</Button>)

   return (
      <FormPage title="Update Direct Markup" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <FormTile>
               <div className="row">
                  <div className="col-md-6">
                     <HookedTextInput
                        label="Markup"
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.markup)}
                        defaultValue={data.directMarkupGetSingle.markup.toFixed(4)}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedSelectOptions
                        options={markupTypes}
                        label="Markup Type"
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.directMarkupType)}
                        setDefaultValue={defaultMarkupType}
                        {...commonProps}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <SupplierSearch
                        label="Supplier"
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.supplierKey)}
                        prefilledSupplierKey={data.directMarkupGetSingle.supplierKey}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <LocationSearch
                        label="Location"
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.locationKey)}
                        prefilledLocationKey={data.directMarkupGetSingle.locationKey}
                        {...commonProps}
                        showCustomer={true}
                     // onSelect={onLocationSelect}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <TerminalSearch
                        label="Terminal"
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.terminalKey)}
                        prefilledTerminalKey={data.directMarkupGetSingle.terminalKey}
                        {...commonProps}
                     />
                  </div>
                  <div className="col-md-6">
                     <HookedCheckbox
                        label="Archived"
                        register={register}
                        propertyKey={propName<EditDirectMarkupMutationVariables>(o => o.directMarkup.isArchived)}
                        defaultValue={data.directMarkupGetSingle.isArchived}
                        errors={errors}
                     />
                  </div>
               </div>
            </FormTile>
         </form>

         {currentlyDeletingDirectMarkup && <Modal headerText="Delete?" footerLeftContent={deleteModalFooterLeftContent} footerRightContent={deleteModalFooterRightContent}>
            <p>Once you delete this direct markup, it will not be able to be reversed.</p>
         </Modal>}
      </FormPage>
   )
}