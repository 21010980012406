import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { EditTermContractMutationVariables, useCreateTermContractLocationMutation, useCreateTermContractSupplierMutation, useCreateTermContractTerminalMutation, useDeleteTermContractLocationMutation, useDeleteTermContractMutation, useDeleteTermContractSupplierMutation, useDeleteTermContractTerminalMutation, useEditTermContractMutation, useSingleTermContractQuery } from '../../generated/graphql'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiTerminalSearch from '../../components/Search/TerminalSearch/MultiTerminalSearch'
import MultiLocationSearch from '../../components/Search/LocationSearch/MultiLocationSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import moment from 'moment'
import Modal from '../../components/Modal/Modal'
import MultiSupplierSearch from '../../components/Search/SupplierSearch/MultiSupplierSearch'
import TermContractGallonsGrid from '../../components/GallonsGrid/TermContractGallonsGrid'
import { Params, useNavigate, useParams } from 'react-router-dom'

export const EditTermContractMutation = gql`
    mutation EditTermContract($termContract: TermContractInput!, $key: Int!) {
        termContractUpdate(termContract: $termContract, termContractKey: $key) {
            termContractKey
        }
    }
`

export const SingleTermContractQuery = gql`
    fragment AllTermContract on TermContract {
        termContractKey
        name
        startDate
        endDate
        notes
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
        posts
        indexContracts
        prePurchaseAgreements
        termContractSuppliers {
            supplierKey
        }
        termContractLocations {
            locationKey
        }
        termContractTerminals {
            terminalKey
        }
    }

    query SingleTermContract($key: Int!) {
        termContractGetSingle(termContractKey: $key) {
            ...AllTermContract
        }
    }

    query SingleSearchTermContract($key: Int!) {
        termContractGetSingle(termContractKey: $key) {
            ...innerTermContract
        }
    }
`

export const DeleteTermContractLocationMutation = gql`
    mutation DeleteTermContractLocation($key: Int!) {
        termContractLocationDelete(termContractKey: $key)
    }
`

export const DeleteTermContractTerminalMutation = gql`
    mutation DeleteTermContractTerminal($key: Int!) {
        termContractTerminalDelete(termContractKey: $key)
    }
`

export const DeleteTermContractSupplierMutation = gql`
    mutation DeleteTermContractSupplier($key: Int!) {
        termContractSupplierDelete(termContractKey: $key)
    }
`

interface MatchParams extends Params {
    termContractKey: string
}

interface Props {

}

export default function EditTermContract(props: Props): ReactElement {
    const navigate = useNavigate()

    const { termContractKey: key } = useParams() as MatchParams

    const termContractKey = parseInt(key)
    const [currentlyDeleteingTermContract, setCurrentlyDeleting] = useState(false)

    const [supplierKeys, setSupplierKeys] = useState<Array<number>>(undefined)
    const [terminalKeys, setTerminalKeys] = useState<Array<number>>(undefined)
    const [locationKeys, setLocationKeys] = useState<Array<number>>(undefined)

    const [startDate, setStartDate] = useState<Date>(undefined)
    const [endDate, setEndDate] = useState<Date>(undefined)

    const [locationNote, setLocationNote] = useState<string>(undefined)

    const [CreateTermContractLocation] = useCreateTermContractLocationMutation()
    const [DeleteAllTermContractLocations] = useDeleteTermContractLocationMutation()
    const [CreateTermContractTerminal] = useCreateTermContractTerminalMutation()
    const [DeleteAllTermContractTerminals] = useDeleteTermContractTerminalMutation()
    const [CreateTermContractSupplier] = useCreateTermContractSupplierMutation()
    const [DeleteAllTermContractSuppliers] = useDeleteTermContractSupplierMutation()
    const [UpdateTermContract] = useEditTermContractMutation()

    const [deleteMutation] = useDeleteTermContractMutation({ variables: { key: termContractKey } })
    const { data, loading, error } = useSingleTermContractQuery({ variables: { key: termContractKey } })

    const navigateToList = () => {
        navigate('/pricing/term-contract')
    }

    const confirmDelete = () => {
        deleteMutation()
            .then(navigateToList)
    }

    const cancelDelete = () => {
        setCurrentlyDeleting(false)
    }

    const openDeleteConfirmation = () => {
        setCurrentlyDeleting(true)
    }

    const submit = (variables: EditTermContractMutationVariables) => {
        if (locationKeys === undefined || locationKeys.length === 0) {
            setLocationNote("Please select at least one location.")
            return
        }
        else {
            setLocationNote(undefined)
        }

        variables.key = termContractKey

        UpdateTermContract({ variables })
            .then(_ => {
                DeleteAllTermContractLocations({ variables: { key: termContractKey } })
                    .then(x => {
                        locationKeys.forEach(l => {
                            CreateTermContractLocation({ variables: { termContractLocation: { termContractKey: termContractKey, locationKey: l } } })
                        })
                    })
                DeleteAllTermContractTerminals({ variables: { key: termContractKey } })
                    .then(x => {
                        if (terminalKeys) {
                            terminalKeys.forEach(t => {
                                CreateTermContractTerminal({ variables: { termContractTerminal: { termContractKey: termContractKey, terminalKey: t } } })
                            })
                        }
                    })
                DeleteAllTermContractSuppliers({ variables: { key: termContractKey } })
                    .then(x => {
                        if (supplierKeys) {
                            supplierKeys.forEach(s => {
                                CreateTermContractSupplier({ variables: { termContractSupplier: { termContractKey: termContractKey, supplierKey: s } } })
                            })
                        }
                    })
            })
            .then(navigateToList)
    }

    const addSupplier = (num: number) => {
        if (supplierKeys === undefined) {
            setSupplierKeys([num])
        }
        else if (!supplierKeys.some(x => x === num)) {
            setSupplierKeys([...supplierKeys, num])
        }
    }

    const removeSupplier = (num: number) => {
        setSupplierKeys(supplierKeys.filter(x => x !== num))
    }

    const addTerminal = (num: number) => {
        if (terminalKeys === undefined) {
            setTerminalKeys([num])
        }
        else if (!terminalKeys.some(x => x === num)) {
            setTerminalKeys([...terminalKeys, num])
        }
    }

    const removeTerminal = (num: number) => {
        setTerminalKeys(terminalKeys.filter(x => x !== num))
    }

    const addLocation = (num: number) => {
        if (locationKeys === undefined) {
            setLocationKeys([num])
        }
        else if (!locationKeys.some(x => x === num)) {
            setLocationKeys([...locationKeys, num])
        }
    }

    const removeLocation = (num: number) => {
        setLocationKeys(locationKeys.filter(x => x !== num))
    }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
        }
    }

    const validationSchema = yup.object().shape({
        termContract: yup.object().shape({
            name: yup.string().required().trim(),
            startDate: yup.date().required(),
            endDate: yup.date().required().min(yup.ref('startDate'), 'End date must be after start date'),
            posts: yup.bool().required(),
            indexContracts: yup.bool().required(),
            notes: yup.string().notRequired().trim(),
            january: yup.number().required(),
            february: yup.number().required(),
            march: yup.number().required(),
            april: yup.number().required(),
            may: yup.number().required(),
            june: yup.number().required(),
            july: yup.number().required(),
            august: yup.number().required(),
            september: yup.number().required(),
            october: yup.number().required(),
            november: yup.number().required(),
            december: yup.number().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<EditTermContractMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }

    const onSubmit = handleSubmit(submit)

    if (loading) {
        return <FormPage></FormPage>
    }
    if (error) {
        return <p>There was an error loading the term contract</p>
    }

    if (data.termContractGetSingle.termContractTerminals &&
        data.termContractGetSingle.termContractTerminals.length > 0 &&
        terminalKeys === undefined) {
        setTerminalKeys(data.termContractGetSingle.termContractTerminals.map(x => x.terminalKey))
    }
    if (data.termContractGetSingle.termContractLocations &&
        data.termContractGetSingle.termContractLocations.length > 0 &&
        locationKeys === undefined) {
        setLocationKeys(data.termContractGetSingle.termContractLocations.map(x => x.locationKey))
    }
    if (data.termContractGetSingle.termContractSuppliers &&
        data.termContractGetSingle.termContractSuppliers.length > 0 &&
        supplierKeys === undefined) {
        setSupplierKeys(data.termContractGetSingle.termContractSuppliers.map(x => x.supplierKey))
    }

    if (startDate === undefined || endDate === undefined) {
        setStartDate(new Date(moment(data.termContractGetSingle.startDate).format('LL')))
        setEndDate(new Date(moment(data.termContractGetSingle.endDate).format('LL')))
    }

    const leftFooterContent = (<div>
        <Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>
        <Button buttonType={ButtonType.Tertiary} clickAction={openDeleteConfirmation}>Delete</Button>
    </div>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit}>Update Term Contract</Button>)

    return (
        <FormPage title="Edit Term Contract" footerRightContent={rightFooterContent} footerLeftContent={leftFooterContent}>
            <form onSubmit={onSubmit}>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.name)}
                                defaultValue={data.termContractGetSingle.name}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.startDate)}
                                defaultValue={startDate}
                                Change={onStartDateChange}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.endDate)}
                                defaultValue={endDate}
                                Change={onEndDateChange}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedCheckbox
                                label="Posts"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.posts)}
                                defaultValue={data.termContractGetSingle.posts}
                                {...commonProps}
                            />
                            <HookedCheckbox
                                label="Index Contracts"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.indexContracts)}
                                defaultValue={data.termContractGetSingle.indexContracts}
                                {...commonProps}
                            />
                            <HookedCheckbox
                                label="Pre Purchase Agreements"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.prePurchaseAgreements)}
                                defaultValue={data.termContractGetSingle.prePurchaseAgreements}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiSupplierSearch
                                removeSupplier={removeSupplier}
                                addSupplier={addSupplier}
                                data={supplierKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiTerminalSearch
                                removeTerminal={removeTerminal}
                                addTerminal={addTerminal}
                                data={terminalKeys}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {locationNote && <p style={{ color: "red" }}>{locationNote}</p>}
                            <MultiLocationSearch
                                removeLocation={removeLocation}
                                addLocation={addLocation}
                                data={locationKeys}
                                showCustomer={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Notes"
                                propertyKey={propName<EditTermContractMutationVariables>(t => t.termContract.notes)}
                                defaultValue={data.termContractGetSingle.notes}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.january)}
                                defaultValue={data.termContractGetSingle.january.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.february)}
                                defaultValue={data.termContractGetSingle.february.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="March"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.march)}
                                defaultValue={data.termContractGetSingle.march.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.april)}
                                defaultValue={data.termContractGetSingle.april.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.may)}
                                defaultValue={data.termContractGetSingle.may.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.june)}
                                defaultValue={data.termContractGetSingle.june.toString()}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.july)}
                                defaultValue={data.termContractGetSingle.july.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.august)}
                                defaultValue={data.termContractGetSingle.august.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.september)}
                                defaultValue={data.termContractGetSingle.september.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.october)}
                                defaultValue={data.termContractGetSingle.october.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.november)}
                                defaultValue={data.termContractGetSingle.november.toString()}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<EditTermContractMutationVariables>(o => o.termContract.december)}
                                defaultValue={data.termContractGetSingle.december.toString()}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <TermContractGallonsGrid termContractKey={termContractKey}></TermContractGallonsGrid>
            </form>
            {currentlyDeleteingTermContract &&
                <Modal headerText="Delete?"
                    footerLeftContent={<Button clickAction={cancelDelete} buttonType={ButtonType.Tertiary}>Cancel</Button>}
                    footerRightContent={<Button clickAction={confirmDelete} buttonType={ButtonType.Danger}>Delete</Button>}>
                    <p>Once you delete this term contract, it will not be able to be reversed.</p>
                </Modal>}
        </FormPage>
    )
}