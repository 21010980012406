import React, { Component } from 'react'
import styles from './Modal.module.scss'

interface Props {
    headerText?: string
    footerLeftContent?: React.ReactNode
    footerRightContent?: React.ReactNode
    narrow?: boolean
    noScroll?: boolean
    children?: React.ReactNode
}

export default class Modal extends Component<Props> {
    render() {
        let footer = (this.props.footerLeftContent || this.props.footerRightContent) && (
            <div className={styles.Modal__footer}>
                <div>
                    {this.props.footerLeftContent}
                </div>
                <div>
                    {this.props.footerRightContent}
                </div>
            </div>
        )

        return (
            <div className={styles.Modal__wrapper}>
                <div className={`${styles.Modal} ${this.props.narrow && styles.Modal__narrow} ${this.props.noScroll && styles.Modal__noScroll}`}>
                    {this.props.headerText && <h4 className={styles.Modal__title}>{this.props.headerText}</h4>}
                    {this.props.children}
                    {footer}
                </div>
            </div>
        )
    }
}
