import React, { ReactElement, useEffect } from 'react'
import gql from 'graphql-tag'
import TextSearchDropdown from '../TextSearchDropdown/TextSearchDropdown'
import { useGetSingleStateLazyQuery, useStatesSearchQuery, InnerStateFragment } from '../../../generated/graphql'
import SearchDisplay, { SearchDisplayProps, InnerSearchLogicProps } from '../SearchDisplay/SearchDisplay'
import { DefaultInputProps } from '../../General/Inputs/HookedTextArea'


export const StatesSearchQuery = gql`
    fragment innerState on State {
        stateKey
        name
    }

    query StatesSearch($search: String) {
        stateGetAll(where: { name: {contains: $search}}, order: [{ name: ASC }]) {
            ...innerState
        }
    }

    query GetSingleState($key: Int!) {
        stateGetSingle(stateKey: $key) {
            ...innerState
        }
    }
`

interface Props extends DefaultInputProps<InnerStateFragment> {
    onSelect?(state: InnerStateFragment)
    prefilledStateKey?: number
    supplierKey?: number
    terminalKey?: number
}

export default function StateSearch(props: Props): ReactElement {

    const [executeGetSingleState, { data, loading }] = useGetSingleStateLazyQuery({ variables: { key: props.prefilledStateKey } })

    const { prefilledStateKey } = props

    useEffect(() => {
        if (prefilledStateKey) {
            executeGetSingleState()
        }
    }, [prefilledStateKey, executeGetSingleState])

    const prefilledData = data && data.stateGetSingle
    const prefilledDataDisplay = data && `${data.stateGetSingle.name}`

    return <TextSearchDropdown<InnerStateFragment>
        onSelect={props.onSelect}
        mapEntityToKey={e => parseInt(e.stateKey.toString())}
        searchWrapper={<Inner searchTerm="" onSelect={() => null} />}
        isLoadingPrefilled={loading}
        prefilledEntity={prefilledData}
        prefilledEntityDisplay={prefilledDataDisplay}
        {...props}
    />
}

const Inner = (props: InnerSearchLogicProps<InnerStateFragment>) => {

    const { data, loading, error } = useStatesSearchQuery({ variables: { search: props.searchTerm } })

    const showData = !loading && !error

    const results = showData &&
        data.stateGetAll.map(fn => {
            const display = fn.name
            return { key: fn.stateKey, object: fn, display }
        })

    const displayProps: SearchDisplayProps<InnerStateFragment> = {
        onSelect: props.onSelect,
        groups: [{ title: "States", results }],
        isLoading: loading,
        searchTerm: props.searchTerm,
        error
    }

    return <SearchDisplay {...displayProps} />
}