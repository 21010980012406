import React, { ReactElement, useState } from 'react'
import gql from 'graphql-tag'
import { CreateTermContractMutationVariables, useCreateTermContractMutation, useCreateTermContractLocationMutation, useCreateTermContractTerminalMutation, useCreateTermContractSupplierMutation } from '../../generated/graphql'
import * as yup from "yup"
import useForm from 'react-hook-form'
import propName from '../../helpers/propName'

import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import HookedTextArea from '../../components/General/Inputs/HookedTextArea'
import FormTile from '../../components/Tiles/FormTile/FormTile'
import Button, { ButtonType } from '../../components/General/Button/Button'
import FormPage from '../../components/General/FormPage/FormPage'
import HookedCheckbox from '../../components/General/Inputs/HookedCheckbox'
import MultiTerminalSearch from '../../components/Search/TerminalSearch/MultiTerminalSearch'
import MultiLocationSearch from '../../components/Search/LocationSearch/MultiLocationSearch'
import HookedDateTime from '../../components/General/Inputs/HookedDateTime'
import moment from 'moment'
import MultiSupplierSearch from '../../components/Search/SupplierSearch/MultiSupplierSearch'
import { useNavigate } from 'react-router-dom'

export const CreateTermContractMutation = gql`
    mutation CreateTermContract($termContract: TermContractInput!) {
        termContractCreate(termContract: $termContract) {
            termContractKey
        }
    }
`

export const CreateTermContractLocationMutation = gql`
    mutation CreateTermContractLocation($termContractLocation: TermContractLocationInput!) {
        termContractLocationCreate(termContractLocation: $termContractLocation) {
            termContractKey
            locationKey
        }
    }
`

export const CreateTermContractTerminalMutation = gql`
    mutation CreateTermContractTerminal($termContractTerminal: TermContractTerminalInput!) {
        termContractTerminalCreate(termContractTerminal: $termContractTerminal) {
            termContractKey
            terminalKey
        }
    }
`

export const CreateTermContractSupplierMutation = gql`
    mutation CreateTermContractSupplier($termContractSupplier: TermContractSupplierInput!) {
        termContractSupplierCreate(termContractSupplier: $termContractSupplier) {
            termContractKey
            supplierKey
        }
    }
`

interface Props {
}



export default function CreateTermContract(props: Props): ReactElement {
    const navigate = useNavigate()

    const [supplierKeys, setSupplierKeys] = useState<Array<number>>([])
    const [terminalKeys, setTerminalKeys] = useState<Array<number>>([])
    const [locationKeys, setLocationKeys] = useState<Array<number>>([])
    const [startDate, setStartDate] = useState(new Date(moment.utc().format('LL')))
    const [endDate, setEndDate] = useState(new Date(moment.utc().add(1, 'year').format('LL')))
    const [endDateHasBeenSet, setEndDateHasBeenSet] = useState(false)

    const [locationNote, setLocationNote] = useState<string>(undefined)

    const [CreateTermContract] = useCreateTermContractMutation()
    const [CreateTermContractLocation] = useCreateTermContractLocationMutation()
    const [CreateTermContractTerminal] = useCreateTermContractTerminalMutation()
    const [CreateTermContractSupplier] = useCreateTermContractSupplierMutation()

    const navigateToList = () => {
        navigate('/pricing/term-contract')
    }

    const submit = (variables: CreateTermContractMutationVariables) => {
        if (locationKeys === undefined || locationKeys.length === 0) {
            setLocationNote("Please select at least one location.")
            return
        }
        else {
            setLocationNote(undefined)
        }

        CreateTermContract({ variables })
            .then(_ => {
                if (terminalKeys) {
                    terminalKeys.forEach(t => {
                        CreateTermContractTerminal({ variables: { termContractTerminal: { termContractKey: _.data.termContractCreate.termContractKey, terminalKey: t } } })
                    })
                }
                locationKeys.forEach(l => {
                    CreateTermContractLocation({ variables: { termContractLocation: { termContractKey: _.data.termContractCreate.termContractKey, locationKey: l } } })
                })
                if (supplierKeys) {
                    supplierKeys.forEach(s => {
                        CreateTermContractSupplier({ variables: { termContractSupplier: { termContractKey: _.data.termContractCreate.termContractKey, supplierKey: s } } })
                    })
                }
            })
            .then(navigateToList)
    }

    const addSupplier = (num: number) => {
        if (supplierKeys === undefined) {
            setSupplierKeys([num])
        }
        else if (!supplierKeys.some(x => x === num)) {
            setSupplierKeys([...supplierKeys, num])
        }
    }

    const removeSupplier = (num: number) => {
        setSupplierKeys(supplierKeys.filter(x => x !== num))
    }

    const addTerminal = (num: number) => {
        if (terminalKeys === undefined) {
            setTerminalKeys([num])
        }
        else if (!terminalKeys.some(x => x === num)) {
            setTerminalKeys([...terminalKeys, num])
        }
    }

    const removeTerminal = (num: number) => {
        setTerminalKeys(terminalKeys.filter(x => x !== num))
    }

    const addLocation = (num: number) => {
        if (locationKeys === undefined) {
            setLocationKeys([num])
        }
        else if (!locationKeys.some(x => x === num)) {
            setLocationKeys([...locationKeys, num])
        }
    }

    const removeLocation = (num: number) => {
        setLocationKeys(locationKeys.filter(x => x !== num))
    }

    const onStartDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setStartDate(date)
            if (!endDateHasBeenSet) {
                setEndDate(new Date(moment(date).add(1, 'year').format('LL')))
            }
        }
    }

    const onEndDateChange = (prop: string, date: any) => {
        if (date !== null) {
            setEndDate(date)
            setEndDateHasBeenSet(true)
        }
    }

    const validationSchema = yup.object().shape({
        termContract: yup.object().shape({
            name: yup.string().required().trim(),
            startDate: yup.date().required(),
            endDate: yup.date().required().min(yup.ref('startDate'), 'End date must be after start date'),
            posts: yup.bool().required(),
            indexContracts: yup.bool().required(),
            notes: yup.string().notRequired().trim(),
            january: yup.number().required(),
            february: yup.number().required(),
            march: yup.number().required(),
            april: yup.number().required(),
            may: yup.number().required(),
            june: yup.number().required(),
            july: yup.number().required(),
            august: yup.number().required(),
            september: yup.number().required(),
            october: yup.number().required(),
            november: yup.number().required(),
            december: yup.number().required()
        })
    })

    const { register, handleSubmit, errors, setValue } = useForm<CreateTermContractMutationVariables>({ validationSchema })
    const commonProps = { register, errors, setValue }
    const onSubmit = handleSubmit(submit)

    const leftFooterContent = (<Button buttonType={ButtonType.Transparent} clickAction={navigateToList}>Cancel</Button>)
    const rightFooterContent = (<Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create Term Contract</Button>)

    return (
        <FormPage title="Create Term Contract" footerLeftContent={leftFooterContent} footerRightContent={rightFooterContent}>
            <form>
                <FormTile>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextInput
                                label="Name"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.name)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <HookedDateTime
                                label="Start Date"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.startDate)}
                                defaultValue={startDate}
                                Change={onStartDateChange}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedDateTime
                                label="End Date"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.endDate)}
                                defaultValue={endDate}
                                Change={onEndDateChange}
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-4">
                            <HookedCheckbox
                                label="Posts"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.posts)}
                                {...commonProps}
                            />
                            <HookedCheckbox
                                label="Index Contracts"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.indexContracts)}
                                {...commonProps}
                            />
                            <HookedCheckbox
                                label="Pre Purchase Agreements"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.prePurchaseAgreements)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MultiSupplierSearch
                                removeSupplier={removeSupplier}
                                addSupplier={addSupplier}
                                data={supplierKeys}
                            />
                        </div>
                        <div className="col-md-6">
                            <MultiTerminalSearch
                                removeTerminal={removeTerminal}
                                addTerminal={addTerminal}
                                data={terminalKeys}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {locationNote && <p style={{ color: "red" }}>{locationNote}</p>}
                            <MultiLocationSearch
                                removeLocation={removeLocation}
                                addLocation={addLocation}
                                data={locationKeys}
                                showCustomer={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <HookedTextArea
                                label="Notes"
                                propertyKey={propName<CreateTermContractMutationVariables>(t => t.termContract.notes)}
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
                <FormTile>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jan"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.january)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Feb"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.february)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Mar"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.march)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Apr"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.april)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="May"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.may)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jun"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.june)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Jul"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.july)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Aug"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.august)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Sep"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.september)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Oct"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.october)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Nov"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.november)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                        <div className="col-md-2">
                            <HookedTextInput
                                label="Dec"
                                propertyKey={propName<CreateTermContractMutationVariables>(o => o.termContract.december)}
                                defaultValue="0"
                                {...commonProps}
                            />
                        </div>
                    </div>
                </FormTile>
            </form>
        </FormPage>
    )
}