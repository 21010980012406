import * as yup from "yup"
import React from "react"
import Modal from "../../Modal/Modal"
import useForm from 'react-hook-form'
import propName from '../../../helpers/propName'
import { CreateCarrierRateMutationVariables, useCreateCarrierRateMutation } from "../../../generated/graphql"
import HookedDateTime from "../../General/Inputs/HookedDateTime"
import gql from "graphql-tag"
import HookedTextInput from "../../General/Inputs/HookedTextInput"
import Button, { ButtonType } from "../../General/Button/Button"
import HookedCheckbox from "../../General/Inputs/HookedCheckbox"
import moment from 'moment'

interface Props {
   closeModal(): void
   callback(): Promise<any>
   carrierKey: number
}

export const CreateCarrierRate = gql`
   mutation CreateCarrierRate($carrierRate: CarrierRateInput!) {
      carrierRateCreate(carrierRate: $carrierRate) {
         carrierRateKey
      }
   }
`

const CreateCarrierRateModal = (props: Props) => {
   const today = moment().startOf('day')
   const endDate = new Date(moment().add(10, 'years').format('LL'))
   const [createCarrierRateMutation] = useCreateCarrierRateMutation()
   const onCreate = (variables: CreateCarrierRateMutationVariables) => {
      //this is handled separately now, but still required on the model
      variables.carrierRate.fuelPercent = 100
      variables.carrierRate.carrierKey = props.carrierKey

      createCarrierRateMutation({ variables })
         .then(props.callback)
         .then(() => props.closeModal())
   }

   const validationSchema = yup.object().shape({
      carrierRate: yup.object().shape({
         startDate: yup.date().required(),
         endDate: yup.date().required(),
         basePercent: yup.number().required(),
         default: yup.boolean().required()
      })
   })
   const { register, setValue, handleSubmit, errors } = useForm<CreateCarrierRateMutationVariables>({ validationSchema })

   const onSubmit = handleSubmit(onCreate)

   const footerLeftContent = <Button buttonType={ButtonType.Secondary} clickAction={props.closeModal}>Cancel</Button>
   const footerRightContent = <Button buttonType={ButtonType.Success} clickAction={onSubmit}>Create Carrier Rate</Button>

   return (
      <Modal headerText="Create Carrier Rate" footerLeftContent={footerLeftContent} footerRightContent={footerRightContent}>
         <form onSubmit={onSubmit}>
            <div className="row">
               <div className="col-md-6">
                  <HookedDateTime
                     setValue={setValue}
                     register={register}
                     propertyKey={propName<CreateCarrierRateMutationVariables>(o => o.carrierRate.startDate)}
                     label="Start Date"
                     defaultValue={new Date(today.format('LL'))}
                     errors={errors}
                  />
               </div>
               <div className="col-md-6">
                  <HookedDateTime
                     setValue={setValue}
                     register={register}
                     propertyKey={propName<CreateCarrierRateMutationVariables>(o => o.carrierRate.endDate)}
                     label="End Date"
                     defaultValue={endDate}
                     errors={errors}
                  />
               </div>
            </div>

            <div className="row">
               <div className="col-md-4">
                  <HookedTextInput
                     defaultValue="100"
                     register={register}
                     propertyKey={propName<CreateCarrierRateMutationVariables>(o => o.carrierRate.basePercent)}
                     label="Rate"
                     errors={errors}
                  />
               </div>
               <div className="col-md-4">
                  <HookedCheckbox
                     register={register}
                     errors={errors}
                     label="Default for Rate Bands"
                     propertyKey={propName<CreateCarrierRateMutationVariables>(o => o.carrierRate.default)}
                  />
               </div>
            </div>
         </form>
      </Modal >
   )
}
export default CreateCarrierRateModal