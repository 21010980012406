import React, { ReactElement, useEffect, useState } from 'react'
import styles from './SelectOptions.module.scss'

export interface SelectOption<T> {
    key: T
    value?: string
}

interface Props<T> {
    options: SelectOption<T>[]
    setOption(option: T)
    setDefaultValue?: SelectOption<T>
    disabled?: boolean
}

export default function SelectOptions<T>(props: Props<T>): ReactElement {
    const [currentOption, setCurrentOption] = useState<SelectOption<T>>(null)
    const [initialized, setInitialized] = useState(false)
    const { options, setDefaultValue, setOption: setOptionProp } = props


    useEffect(() => {
        if (!initialized) {
            if (options && options.length > 0) {
                const startingValue = setDefaultValue ? setDefaultValue : options[0]
                setCurrentOption(startingValue)
                setOptionProp(startingValue.key)
                setInitialized(true)
            }
        }
    }, [options, setDefaultValue, initialized, setOptionProp])

    const setOption = (option: SelectOption<T>) => {
        setCurrentOption(option)
        setOptionProp(option.key)
    }


    const clickSetOption = (option: SelectOption<T>) => {
        if (!props.disabled) {
            setOption(option)
        }
    }

    const renderOption = (option: SelectOption<T>) => {
        const value = option.value ? option.value : option.key
        const isActive = currentOption && currentOption.key === option.key
        return <span
            onClick={() => clickSetOption(option)}
            className={`${styles.option} ${isActive && styles.option__active}`}
            key={option.key.toString()}>
            {value.toString()}
        </span>
    }

    return (
        <div className={`${styles.group} ${props.disabled ? styles.group__disabled : ''}`}>
            {props.options.map(o => renderOption(o))}
        </div>
    )
}
